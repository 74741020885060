﻿.body-no-ecomm {

    .cart-container {
        display: none !important;
    }

    .head-style-1 {
        @include media(">desktop") {
            .top--widget {
                width: 50% !important;
            }

            .masthead .head--main .head-right {
                width: 300px;
            }

            .masthead .head--main .head-center {
                width: calc(100% - 554px);
            }
        }

        @include media(">tablet") {
            .search--form {
                max-width: 100%;
            }
        }
    }

    .head-style-2 {


        @include media(">desktop") {
            .masthead .head--main .head-right {
                width: 300px;
                float: right;
            }
        }

        @include media(">tablet") {
            .top--widget {
                width: 50% !important;
            }
        }
    }

    .head-style-3 {
        @include media(">desktop") {
            .masthead .head--main .head-right {
                width: 340px;
            }

            .masthead .head--main .head-center {
                width: calc(100% - 594px);
            }
        }

        @include media(">tablet") {
            .top--widget {
                width: 100% !important;
            }
        }
    }

    .head-style-4 {
        .top--widget {
            @include media(">tablet") {
                float: right !important;
            }
        }
    }
}
