﻿﻿.custom-form {

    .max-cont {
        text-align: center;
        box-shadow: inset 0px 8px 7px #00000026;
    }

    .p-10 {
        padding: 10px;
    }

    @include media (">tablet") {
        .summary-boot {
            width: 350px;

            .custom-summary.fixed {
                position: fixed;
                top: 30px;
                left: 50%;
                transform: translateX(-685px);
                background-color: #fff;
                z-index: 50;
                width: 320px;
            }
        }

        .customise-boot {
            width: calc(100% - 350px);
            float: right;
        }
    }

    .step-one-options {
        .option {
            display: block;
            border: 1px solid #dedede;
            background-color: #fff;
            position: relative;
            padding: 15px;
            z-index: 10;

            &.selected {
            }

            .dummy-check {
                float: left;
                margin-right: 15px;
            }

            .title {
                float: left;
                line-height: 30px;
                font-weight: 700;
            }

            .right {
                line-height: 30px;
                float: right;
            }
        }
    }

    .custom-summary {
        border: 1px solid #dedede;
        border-radius: 3px;
        padding: 10px;
        margin-bottom: 30px;

        h2 {
            font-size: 1.1em;
            margin-top: 0px;
            margin-bottom: 10px;
        }

        .image {
            width: 70px;
            height: 70px;
            border: 1px solid #dbdbdb;
            float: left;
        }

        .item-info {
            float: left;
            padding-left: 10px;
            font-size: 0.8em;
            width: 75%;

            strong {
                margin-bottom: 10px;
                display: block;
            }
        }

        .breakdown {
            margin-top: 20px;

            .cost {
                padding: 10px 0px;
                position: relative;

                .price {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0px;
                    font-weight: bold;
                }
            }
        }
    }

    .step-number {
        border: 1px solid #dbdbdb;
        background-color: #fbfbfb;
        position: relative;
        padding: 15px 15px 15px 50px;

        .right-info {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 15px;

            .item {
                display: inline-block;
                padding: 5px 10px;
                border-radius: 5px;

                .svg {
                    display: inline-block;
                }

                svg {
                    width: 25px;
                    height: 25px;
                }

                span {
                    color: #fff;
                    display: inline-block;
                    width: auto;
                    position: static;
                    height: 35px;
                    vertical-align: bottom;
                    transform: none;
                    line-height: 35px;

                    @include media("<=phone") {
                        display: none;
                    }
                }
            }
        }



        span {
            width: 25px;
            height: 25px;
            color: #fff;
            border-radius: 100%;
            text-align: center;
            line-height: 25px;
            display: block;
            font-weight: bold;
            position: absolute;
            top: 50%;
            left: 15px;
            transform: translateY(-50%);
        }

        @include media("<=xs-phone") {
            min-height: 110px;

            span {
                top: 15px;
                transform: none;
            }

            .right-info {
                transform: none;
                top: auto;
                bottom: 10px;
            }
        }

        strong {
            font-size: 1.5em;
        }
    }

    .options {
        .step-content {
            background-color: #f7f7f7;
            border: 1px solid #dbdbdb;
            border-top: none;
            padding: 10px;
            margin-bottom: 15px;
        }
    }

    .app-select {
        cursor: pointer;
        display: block;
        border-radius: 3px;
        background-color: #fff;
        position: relative;
        border: 1px solid #dbdbdb;
        min-height: 90px;


        &:hover {
            box-shadow: 0px 11px 11px #00000026;
            transform: translateY(-3px);
        }

        input {
            display: none;
        }

        .inner {
            padding: 30px 30px 30px 75px;
            min-height: 90px;
            display: block;
            position: relative;
            z-index: 1;
        }

        .point {
            display: none;
            width: 30px;
            height: 30px;
            position: absolute;
            left: 50%;
            bottom: -15px;
            transform: translate(-50%) translateY(-21px) rotate( 45deg);
            background-color: #fff;
            animation: ease-in-out 0.2s;
        }

        img, svg {
            display: block;
            position: absolute;
            top: 50%;
            left: 15px;
            max-width: 50px;
            transform: translateY(-50%);
        }

        .dummy-check {
            float: left;
            margin-right: 15px;

            i {
                visibility: hidden;
            }
        }

        .txt {
            float: left;


            .title {
                display: block;
                font-weight: 700;
                font-size: 1.1em;

                &.notxt {
                    line-height: 25px;
                }

                width: 45px !important;
            }

            .txt {
                font-size: 0.8em;
            }
        }

        &.selected {
            .point {
                display: block;
                width: 30px;
                height: 30px;
                position: absolute;
                left: 50%;
                bottom: -15px;
                transform: translate(-50%) rotate(45deg);
                background-color: #fff;
            }

            .dummy-check {
                i {
                    visibility: visible;
                }
            }
        }
    }

    .info {
        display: none;
        width: 20px;
        height: 20px;
        background-color: #000;
        color: #fff;
        border-radius: 100%;
        text-align: center;
        line-height: 20px;
        font-size: 0.7em;
        position: absolute;
        top: 15px;
        right: 15px;

        .inner-rel {
            position: relative;
            display: block;
        }

        &:hover {
            .info-txt {
                display: block !important;
            }
        }

        .info-txt {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            z-index: 10;
            bottom: calc(100% + 40px);
            border-radius: 3px;
            background-color: #000;
            width: 300px;
            font-size: 1.3em;
            display: none;

            .inner-rel {
                position: relative;
                padding: 10px;

                &:before {
                    content: " ";
                    width: 25px;
                    height: 25px;
                    position: absolute;
                    background-color: #000;
                    border-radius: 3px;
                    bottom: -10px;
                    left: 50%;
                    z-index: -1;
                    transform: translateX(-50%) rotate(45deg);
                }
            }
        }
    }

    .logo-tboxes {
        .editor-label {
            font-weight: 700;
            margin: 10px 0px;
            display: block;
        }

        textarea {
            width: 100%;
            height: 37px;
            padding: 10px;
            min-height: 150px;
            border: 1px solid #dbdbdb;
        }

        input {
            width: 100%;
            height: 37px;
            padding: 0px 10px;
            border: 1px solid #dbdbdb;
        }
    }

    .choose-text-box {
        .logo-tboxes {
            .editor-label {
                font-weight: 700;
                margin: 10px 0px;
                display: block;
            }

            input {
                width: 100%;
                height: 37px;
                padding: 0px 10px;
                border: 1px solid #dbdbdb;
            }
        }


        @include media(">tablet") {
            .preview-boot {
                float: right;
            }
        }



        .text-preview {
            height: 419px;
            border: 1px solid #dbdbdb;
            position: relative;
            background-color: #fff;
            border-radius: 3px;

            @include media(">tablet") {
                height: 419px;
            }

            @include media("<=tablet") {
                height: 219px;
            }

            strong {
                position: absolute;
                top: 15px;
                left: 50%;
                transform: translateX(-50%);
                font-size: 1.7em;
            }

            .realtime-txt {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #333;
                font-weight: 700;
                width: 100%;

                span {
                    margin: 0px;
                    display: block;
                    text-align: center;
                    font-size: 2em;

                    @include media("<=tablet") {
                        line-height: 1;
                    }
                }
            }
        }
    }

    .choose-image-box {


        .upload-boot {
        }

        .logo-choice {
            display: block;
            padding: 10px;
            background-color: #fff;
            border: 1px solid #dbdbdb;

            input {
                display: none;
            }

            .dummy-upload {
                margin-right: 10px;
                display: inline-block;
                width: 145px;
                padding: 10px;
                text-align: center;
                font-weight: 700;
                color: #fff;
                border-radius: 3px;
            }

            .file-info {
                font-size: 0.8em;
            }
        }
    }
}

.customise-colour-drop {
    position: relative;

    .head {
        cursor: pointer;

        i {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
        }

        &.open {
            i {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }
}

.customise-font-drop {
    position: relative;


    .head {
        cursor: pointer;

        i {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
        }

        &.open {
            i {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }
}

.drop-options {
    position: absolute;
    top: 36px;
    left: 0px;
    width: 100%;
    z-index: 10;
    background-color: #fff;
    border: 1px solid #aaa;
    max-height: 200px;
    overflow: auto;

    &.colour {
        z-index: 12;
    }

    .option {
        padding: 10px;


        &:nth-child(odd) {
            background-color: #f8f8f8;
        }

        cursor: pointer;

        .colour-bx {
            width: 25px;
            height: 25px;
            border: 1px solid #333;
            margin-right: 5px;
            display: inline-block;
            border-radius: 3px;
            vertical-align: bottom;
        }

        b .txt {
            line-height: 25px;
        }

        &:hover {
            background-color: #ddd;
        }
    }
}

.customise-actions {
    margin-bottom: 60px;

    .btn {
        height: 46px;
        border: none;
        border-radius: 3px;
        color: #fff;
        font-size: 1em;
        font-weight: 700;
        padding: 0 20px;
        line-height: 46px;
    }

    .left {
        float: left;
    }

    .right {
        float: right;
    }
}

.position-option {
    @include media(">desktop") {
        width: 25%;
        float: left;
    }

    @include media(">tablet", "<=desktop") {
        width: 25%;
        float: left;
    }

    @include media(">phone", "<=tablet") {
        width: 25%;
        float: left;
    }

    @include media(">sm-phone", "<=phone") {
        width: 50%;
        float: left;
    }

    input {
        display: none;
    }

    padding: 10px;

    .inner-border {
        position: relative;
        cursor: pointer;
        display: block;
        background-color: #fff;
        border: 1px solid #dedede;
        border-radius: 3px;
        text-align: center;
        padding: 10px;



        .cost {
            position: absolute;
            top: 0px;
            right: 0px;
            padding: 5px;
            border-radius: 0px 0px 0px 5px;
            color: #fff;
            font-weight: 700;

            &.emb {
                right: auto;
                left: 0px;
                border-radius: 0px 0px 5px 0px;
            }
        }

        &:hover {
            box-shadow: 0px 11px 11px #00000026;
            transform: translateY(-5px);
        }

        .img {
            display: inline-block;

            .img-grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 15px;
                margin-top: 30px;
                margin-bottom: 15px;
            }

            img {
                display: block;
            }
        }

        .bottom {
            span {
                display: inline-block;
                margin-right: 10px;

                i {
                    opacity: 0;
                }
            }
        }
    }

    &.selected {
        .bottom span i {
            opacity: 1;
        }
    }
}

.custo-garm {
    padding: 10px;
    border: 1px solid #dbdbdb;
    background-color: #fff;
    position: relative;

    .custompos-flex {
        display: flex;
        grid-gap: 15px;

        @include media("<=desktop") {
            flex-direction: column-reverse;
        }
    }

    .left {
        display: flex;
        justify-content: center;
        flex-grow: 1;

        > div {
            display: grid;
            grid-template-columns: repeat(2,1fr);
            grid-gap: 15px;

            @include media("<=desktop") {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
            }

            img {
                @include media("<=sm-phone") {
                    max-width: 90px;
                }
            }
        }

        > div.small-imgs {
            img {
                max-width: 90px;
            }
        }

        h2 {
            margin: 0px 0px 15px 0px;
            font-size: 1.6em;

            @include media("<=sm-phone") {
                margin: 0 auto;
                text-align: center;
            }
        }
    }

    .right {

        .row {
            margin-bottom: 15px;

            .title {
                font-weight: 700;
                font-size: 1.16em;
                line-height: 40px;

                @include media(">desktop") {
                    width: 250px;
                    float: left;
                }

                @include media("<=desktop", ">tablet") {
                }
            }

            .actions {
                position: relative;

                .field-validation-error {
                    position: absolute;
                    border: 1px solid #ff0000;
                    color: #ff0000;
                    z-index: 10;
                    border-radius: 3px;
                    padding: 9px 10px;
                    font-weight: 700;
                    background-color: #F1DDE1;

                    @include media( ">tablet") {
                        top: 0px;
                        left: calc(100% + 10px);
                        display: block;
                        width: 255px;
                    }

                    @include media( "<=tablet") {
                    }
                }

                .section {
                    margin: 0px -6px;
                }

                .dw-xs-6 {
                    padding: 0px 6px;
                }

                @include media(">desktop") {
                    width: calc(100% - 250px);
                    float: left;
                    max-width: 320px;
                }

                @include media("<=desktop", ">tablet") {
                }

                input {
                    width: 100%;
                    padding: 15px;
                    height: 40px;
                    border: 1px #DBDBDB solid;
                    border-radius: 3px;
                }

                .app-opt {
                    height: 40px;
                    border-radius: 3px;
                    font-weight: 700;
                    text-align: center;
                    display: block;
                    line-height: 40px;
                    background-color: #ddd;
                    cursor: pointer;



                    &:hover {
                        box-shadow: 0px 11px 11px #00000026;
                        transform: translateY(-3px);
                    }

                    input {
                        display: none;
                    }
                }
            }
        }
    }

    .custom-settings {
        margin-top: 30px;
        border-radius: 3px;
        position: relative;

        .inner {
            background-color: #fff;
            padding: 15px;
            position: relative;
        }

        .point {
            position: absolute;
            width: 30px;
            height: 30px;
            top: -10px;

            .inner {
                transform: rotate(45deg);
                background-color: #fff;
                width: 30px;
                height: 30px;
            }

            @include media( ">l-desktop") {
                left: 32.5%;

                &.for-logo {
                }

                &.for-text {
                    transform: translateX(172px);
                }
            }

            @include media( "<=l-desktop", ">desktop") {
                left: 32%;

                &.for-logo {
                    transform: translateX(50px);
                }

                &.for-text {
                    transform: translateX(225px);
                }
            }

            @include media("<=desktop", ">tablet") {
                left: 50%;

                &.for-logo {
                    transform: translateX(-135px);
                }

                &.for-text {
                    transform: translateX(110px);
                }
            }

            @include media("<=tablet", ">phone") {
                left: 50%;

                &.for-logo {
                    transform: translateX(-135px);
                }

                &.for-text {
                    transform: translateX(110px);
                }
            }

            @include media("<=phone") {
                transform: translateX(-50%);

                &.for-logo {
                    left: 25%;
                }

                &.for-text {
                    left: 75%;
                }
            }
        }
    }
}

.logo-actions {

    .section {
        margin: 0px -6px;

        .dw-sm-4 {
            padding: 0px 6px;
        }
    }

    .btn {
        margin-bottom: 10px;
        width: 100%;
        height: 40px;
        position: relative;
        background-color: #dedede;
        border-radius: 3px;
        display: block;
        cursor: pointer;


        input {
            display: none;
        }

        &:hover {
            box-shadow: 0px 11px 11px #00000026;
            transform: translateY(-3px);
        }

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            display: block;
            padding: 0px 10px;
            text-align: center;
            font-weight: 700;
        }
    }
}

.logo-sub-actions {
    margin-top: 15px;
}

.logoselect {
    border: 1px solid #dbdbdb;
    border-radius: 3px;

    .title {
        background-color: #fbfbfb;
        border-bottom: 1px#dedede solid;
        padding: 15px;

        span {
            font-weight: 700;
            font-size: 1.2em;
        }
    }

    .logolist {
        padding: 5px;

        .logobx {
            padding: 5px;

            @include media (">desktop") {
                width: 25%;
                float: left;
            }


            label {
                input {
                    display: none;
                }

                display: block;
                text-align: center;
                border: 1px solid #dbdbdb;



                img {
                    display: block;
                    text-align: center;
                    margin: 0 auto;
                }

                padding: 10px;

                strong {
                    margin-top: 10px;
                    display: block;
                    text-align: center;
                }


                &:hover {
                    box-shadow: 0px 11px 11px #00000026;
                    transform: translateY(-3px);
                }
            }
        }
    }
}

.login-section {
    border: 1px solid #dbdbdb;
    border-radius: 3px;
    margin-bottom: 15px;
    position: relative;
    padding: 15px;
    background-color: #fff;

    @include media("<=phone") {
        text-align: center;
    }

    strong {
        font-size: 1.5em;
    }

    .btn-1 {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        height: 40px;
        line-height: 40px;
        border-radius: 3px;
        text-align: center;
        width: 100%;
        max-width: 150px;
        color: #fff;
        font-weight: 700;

        @include media("<=phone") {
            position: static;
            transform: none;
            display: inline-block;
            margin-top: 15px;
        }
    }
}

.img-controls {
    text-align: center;

    .actions {
        border: 1px solid #dbdbdb;
        border-radius: 3px;
        padding: 10px;
        background-color: #fff;
        display: block;
        clear: both;
        max-width: 100%;
        position: static !important;
        transform: none !important;
        opacity: 1 !important;

        .favicon-preview {
            width: 100%;
            margin: 0 auto 15px;
            border: 1px #dbdbdb solid;
            border-radius: 3px;
            max-width: 350px;
            height: 80px;
            background: url(../../../../../_assets/images/browser-tab.jpg);
            background-position: center left;
            background-size: cover;
            position: relative;

            .img-preview-bx {
                position: absolute;
                top: 15px;
                left: 15px;
                width: 20px;
            }
        }

        .drop-zone {
            border: 2px dashed #dbdbdb;
            position: relative;
            border-radius: 3px;
            margin-bottom: 15px;
            transition: background-color 0.2s ease;
            cursor: pointer;
            display: block;

            &:hover {
                background-color: #f1f1f1;
            }

            input {
                display: block;
                width: 100%;
                height: 150px;
                margin: 0px;
                border: none;
                position: relative;
                z-index: 99;
                cursor: pointer;
                opacity: 0;
            }

            .drop-txt {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #999;
                display: block;
                font-size: 1.1em;

                i {
                    display: block;
                    margin-bottom: 10px;
                    text-align: center;
                }
            }
        }

        .up {
            display: block;
            float: left;
            margin-right: 5px;
            width: 180px;
            height: 40px;
            background-color: #369D00;
            line-height: 40px;
            text-align: center;
            color: #fff;
            font-weight: 700;
            cursor: pointer;

            &:hover {
                background-color: #4BD800;
            }



            input {
                display: none;
            }
        }

        .image-info {
            display: block;
            padding: 10px;
            line-height: 20px;
            background-color: #fff;
            padding: 10px;
            font-size: 0.95em;
            font-weight: 700;
        }
    }

    .imgmsg, .field-validation-error {
        border: 1px solid #ff0000;
        color: #cc0000;
        border-radius: 3px;
        padding: 10px;
        background-color: #fff;
        font-weight: 700;
        line-height: 20px;
    }

    .img-preview-bx {
        text-align: center;
        min-height: 95px;

        img {
            display: inline-block;
            border: 1px solid #dbdbdb;
            border-radius: 3px;
            margin-bottom: 15px;
            max-height: 190px;
            width: auto;
        }
    }

    .imgmsg, .field-validation-error {
        float: none;
        display: inline-block;
        position: static;

        p {
            margin: 0px;
        }
    }
}

.aff-announcement {
    padding: 15px;
    border: 1px solid #dbdbdb;
    background-color: #fbfbfb;
    width: 100%;
    display: flex;
    align-items: center;
    grid-gap: 15px;
    font-size: 1.2rem;
    margin-bottom: 10px;

    i {
        font-size: 2rem;
    }

    @include media("<=tablet") {
        font-size: 0.9rem;

        i {
            font-size: 1.3rem;
        }
    }
}
