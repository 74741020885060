﻿.size-table {

    width: fit-content;

    .section {
        margin: 0px;
    }

    .section > div {
        padding: 0px 10px;
    }

    .head {
        padding: 10px 15px;
    }

    .row {

        &:nth-child(1) {
            font-weight: 700;
            border-bottom: 1px solid darkgrey;
        }

        &:nth-child(odd) {
            background-color: #f1f1f1;
        }
    }

    .key {
        width: 120px;
        padding: 10px;
        text-align: center;
        flex-shrink: 0;
        font-weight: 700;
        border-right: 1px solid darkgrey;
    }

    .value {
        width: 134px;
        flex-shrink: 0;
    }

}

.listing.bundle-listing {
    #products {
        display: flex;
        flex-wrap: wrap;
        grid-row-gap: 30px;
    }
}


.product--page {
    .listing-wishlist {
        width: 100%;
        text-align: center;
        font-weight: 800;
        padding: 20px;
        font-size: 1.6em;
        color: white;
        cursor: pointer;
        margin-bottom: 30px;
    }

    #login-partial-background {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: black;
        top: 0px;
        right: 0px;
        z-index: 11;
        opacity: 0.5;
    }

    #login-partial-container {
        position: fixed;
        width: 90%;
        height: 80%;
        top: 10%;
        right: 5%;
        z-index: 12;
        background-color: white;
        padding: 6px;
        border-radius: 5px;
        overflow: scroll;

        @include media(">desktop") {
            height: 60%;
            overflow: visible;
        }

        .container {
            width: auto !important;
        }
    }

    .prod--right {
        .brand-img-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }


    .boot.disabled {
        opacity: 0.4;
    }

    @include media(">tablet") {
        .prod--left {
            width: 50%;
            float: left;
        }

        .prod--right {
            width: 50%;
            float: left;

            .brand-img-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        @include media(">desktop") {
            .prod--left {
                padding-right: 25px;
            }

            .prod--right {
                padding-left: 25px;
            }
        }

        @include media("<=desktop", ">tablet") {
            .prod--left {
                padding-right: 15px;
            }

            .prod--right {
                padding-left: 15px;
            }
        }
    }

    h1 {
        text-align: left;
        margin: 15px 0px;
    }

    h2 {
        font-size: 1.4em;

        i {
            margin-right: 10px;
        }

        margin: 60px 0px 30px;
    }

    .step--title {
        padding-left: 50px;
        position: relative;
        margin: 25px 0px;

        &.nonum {
            padding: 0px !important;
        }

        .num {
            width: 25px;
            height: 25px;
            text-align: center;
            line-height: 25px;
            color: #fff;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0px;
            font-size: 1.05em;
            border-radius: 100%;
            font-weight: 700;
        }

        .title {
            font-weight: bold;
            width: calc(100% - 175px);
            float: left;
            font-size: 1.1em;
        }

        .right--tag {
            width: 175px;
            float: right;
            text-align: right;
            font-weight: bold;
            cursor: pointer;

            i {
                font-size: 1.1em;
                margin-right: 5px;
            }



            &.clickable {
                text-decoration: underline;
                cursor: pointer;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .price--break {
        background-color: #f7f7f7;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;

        @media screen and (max-width:992px) {
            justify-content:center;
        }
            .break.current {
                background-color: #fff;
                box-shadow: 0 0 13px 5px #b0b0b066;
                border-radius: 5px;
                transform-origin: center;
                transform: scale(1.04);

                .price {
                    font-size: 2.2rem;

                    @media screen and (max-width:992px) {
                        font-size: 2rem;
                    }

                    @media screen and (max-width:500px) {
                        font-size: 1.75rem;
                    }
                }
            }

            .break {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                @media screen and (min-width:501px) {
                    width: calc(100% / 5);
                    float: left;
                }

                @media screen and (max-width:500px) {
                    width: 95px;
                    display: inline-block;
                    vertical-align: top;
                }

                text-align: center;
                padding: 20px 0px;

                .count {
                    display: block;
                    font-size: 0.8em;
                }

                .price {
                    display: block;
                    font-weight: 700;
                    font-size: 1.6em;
                }

                .saving {
                    font-weight: 700;
                    color: #F2335C;
                    font-size: 0.8em;
                }
            }
        }

        .colour--select {
            .colours {
                .opt {
                    float: left;
                    width: 10%;

                    @media screen and (min-width:501px) {
                        width: 10%;
                    }

                    @media screen and (max-width:500px) {
                        width: 20%;
                    }

                    .colour--display {
                        margin: 0 auto;
                        width: 50px;
                        height: 50px;
                        padding: 5px;
                        border: 1px solid #fff;
                        border-radius: 100%;

                        .inner--colour {
                            width: 100%;
                            height: 100%;
                            border-radius: 100%;
                        }
                    }

                    .title {
                        color: #575757;
                        text-align: center;
                        font-size: 0.75em;
                    }
                }
            }
        }

        .prod-tabs {
            padding-top: 44px;
            position: relative;
            margin-bottom: 30px;

            .tab {
                cursor: pointer;
                line-height: 40px;
                height: 44px;
                position: absolute;
                text-align: center;
                font-weight: 700;
                width: calc((100% / 3) - 4px);
                background-color: #F7F7F7;
                border-top: 3px solid #fff;

                i {
                    font-size: 1.3em;
                    margin-right: 5px;
                }

                &:nth-child(1) {
                    left: 0px;
                    top: 0px;
                }

                &:nth-child(2) {
                    left: 50%;
                    transform: translateX(-50%);
                    top: 0px;
                }

                &:nth-child(3) {
                    right: 0px;
                    top: 0px;
                }

                &.open {
                    z-index: 1;
                    line-height: 44px;
                    background-color: #fff;
                    border-top: 1px solid #e0e0e0;
                    border-left: 1px solid #e0e0e0;
                    border-right: 1px solid #e0e0e0;
                }
            }

            .tab-body {
                background-color: #fff;
                border: 1px solid #e0e0e0;
                position: relative;
                padding: 30px 25px;
                display: none;

                &:before {
                    content: '';
                    height: 2px;
                    background-color: #fff;
                    width: calc((100% / 3) - 4px);
                    position: absolute;
                }


                &#body-1:before {
                    left: 0px;
                    top: -2px;
                }

                &#body-2:before {
                    left: 50%;
                    top: -2px;
                    transform: translateX(-50%);
                }

                &#body-3:before {
                    right: 0px;
                    top: -2px;
                }

                &.open {
                    display: block;
                }
            }
        }
    }

    .colour-select {
        border: 1px #dedede solid;
        padding: 10px;

        .colours {
            .c-tile {
                width: 44px;
                float: left;
                position: relative;
                padding: 7px;



                .hover-colour-title {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: calc(100% + 7px);
                    background-color: #333;
                    border-radius: 3px;
                    color: #fff;
                    text-align: center;
                    transition: opacity .2s ease-out;
                    opacity: 0;
                    display: none;

                    .inner-rel {
                        width: auto;
                        position: relative;
                        padding: 10px;

                        &:before {
                            content: "";
                            width: 15px;
                            height: 15px;
                            background-color: #333;
                            border-radius: 3px;
                            position: absolute;
                            display: block;
                            bottom: -6px;
                            left: 50%;
                            transform: translateX(-50%) rotate(45deg);
                        }
                    }
                }

                .c-inner {
                    position: relative;

                    &:before {
                        background-color: #333;
                        width: calc(100% + 1px);
                        height: calc(100% + 1px);
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        position: absolute;
                        content: "";
                        display: block;
                        border-radius: 100%;
                    }

                    &:hover {
                        .hover-colour-title {
                            display: block;
                            opacity: 1;
                        }
                    }
                }

                .coloursquare {
                    width: 30px;
                    height: 30px;
                    cursor: pointer;
                    border-radius: 100%;
                    overflow: hidden;

                    &.selected {
                        border: none !important;

                        .select-indicator {
                            transition: none !important;
                            width: 35px;
                            height: 35px;
                            border-radius: 100%;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                }
            }
        }
    }

.size-list {
    .size-opt {
        min-height: 110px;

        .size-quantity-editor {
            transform: translateX(50%);
            bottom: 10px;
            right: 50%;
            top: auto;
            margin: 0 auto;
            width: calc(100% - 20px);
        }
    }

    .size-opt.nopass {
        min-height: 45px;
    }

    @include media(">=tablet") {
        &.grid {
            margin: 0px -7px;

            .boot {
                padding: 0px 7px;

                @include media(">=desktop") {
                    width: calc(100% / 4);
                }

                @include media(">=tablet", "<desktop") {
                    width: calc(100% / 3);
                }

                float: left;
            }

            &.just-sizes {
                .boot {
                    @include media(">=desktop") {
                        width: calc(100% / 6);
                    }

                    @include media(">=tablet", "<desktop") {
                        width: calc(100% / 6);
                    }

                    .size-opt {
                        min-height: 10px;

                        .size-name {
                            transform: none;
                            position: static;
                        }
                    }
                }
            }
        }
    }

    @include media("<tablet") {
        &.grid {
            margin: 0px -7px;

            .boot {
                padding: 0px 7px;

                @include media(">=phone", "<tablet") {
                    width: calc(100% / 4);
                }

                @include media("<phone") {
                    width: calc(100% / 2);
                }

                float: left;
            }
        }
    }
}

    .size-opt {
        border: 1px solid #dedede;
        border-radius: 3px;
        position: relative;
        padding: 12px 18px;
        margin-bottom: 15px;

        @media screen and (max-width:500px) {
            height: 110px;
        }

        .check-container {
            width: 32px;
            display: block;
            float: left;
            display: none;
        }

        .size-name {
            display: block;
            font-size: 1.05em;
            padding-left: 0px;
            font-weight: 700;
            text-align: center;
        }

        .size-price {
            font-size: 1.05em;
            line-height: 30px;
            font-weight: 700;

            @media screen and (max-width:500px) {
                float: right;
            }
        }

        .size-quantity-editor {
            height: 40px;
            float: right;
            position: absolute;
            right: 18px;

            @media screen and (min-width:501px) {
                top: 50%;
                transform: translateY(-50%);
            }

            @media screen and (max-width:500px) {
                bottom: 10px;
            }
        }
    }

    .size-quantity-editor {
        height: 40px;
        border: 1px solid #dedede;
        min-width: 90px;
        width: 100%;
        border-radius: 3px;
        overflow: hidden;

        input {
            border: none;
            height: 100%;
            display: block;
            width: calc(100% - 56px);
            float: left;
            text-align: center;
            border-left: #dedede;
            border-right: #dedede;
            font-size: 1.1em;
            font-weight: 700;
        }

        .control {
            width: 28px;
            display: block;
            float: left;
            background-color: #F5F5F5;
            height: 100%;
            text-align: center;
            font-weight: 700;
            line-height: 38px;
            font-size: 1.3em;
            cursor: pointer;

            &:hover {
                background-color: #f0f0f0;
            }
        }
    }

    .dummy-check {
        border: 1px solid #dedede;
        width: 30px;
        height: 30px;
        border-radius: 3px;
        background-color: #fff;
        display: block;

        i {
            width: 100%;
            line-height: 28px;
            text-align: center;
        }
    }

    .prod-page-summary {
        border-radius: 3px;
        padding: 20px;
        position: relative;

        .brand-btn {
            border-radius: 3px;
            display: block;
            background-color: #00AE33;
            color: #fff;
            font-weight: 700;
            cursor: pointer;
            border: none;
            width: 100%;
            margin-top: 15px;
            line-height: 15px;
            padding: 15px;
            font-size: 1.05em;

            &:hover {
                background-color: #333;
                color: #fff;
            }
        }

        .continue-btn {
            border-radius: 3px;
            background-color: #F7F7F7;
            display: block;
            color: #333;
            border: none;
            font-weight: 700;
            width: 100%;
            margin-top: 15px;
            line-height: 15px;
            padding: 15px;
            font-size: 1.05em;

            &:hover {
                background-color: #333;
                color: #fff;
            }
        }

        .please-add {

            @include media(">desktop") {
                position: absolute;
                top: 50%;
                right: 15px;
                transform: translateY(-50%);
            }

            @include media("<=desktop") {
            }
        }

        .warn {
            background-color: #F5F5F5;
            text-align: center;
            padding: 10px;
            font-weight: 700;

            i {
                margin-right: 10px;
            }
        }

        .total-items {
            .title {
                display: block;
                margin-bottom: 10px;
            }

            padding: 10px 15px;
        }

        .price-per {
            .title {
                display: block;
                margin-bottom: 10px;
            }

            padding: 10px 15px;
        }

        .total {
            .title {
                display: block;
                margin-bottom: 10px;
            }

            padding: 10px 15px;
            background-color: #f1f1f1;
        }
    }

    .prod-img {
        text-align: center;
        margin-bottom: 30px;

        .bx-viewport {
            border: 1px solid #dedede;
        }
    }

    .price-estimate-bx {
        border: 1px solid #dedede;
        margin-top: 30px;
        margin-bottom: 30px;
        border-radius: 3px;
        position: relative;


        &:before {
            content: "";
            width: 30px;
            height: 30px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
            background-color: #fff;
            top: -15px;
            border: 1px solid #dedede;
        }

        .inner-bg {
            background-color: #fff;
            position: relative;
            z-index: 10;

            .top {
                text-align: center;
                padding: 15px;
                font-weight: 700;
                border-bottom: 1px solid #dedede;
            }

            .body {


                @media screen and (min-width:501px) {
                    padding: 30px;
                }

                @media screen and (max-width:500px) {
                    padding: 15px;

                    .dw-xs-6 {
                        width: 100%;
                        margin-bottom: 15px;
                    }
                }

                .content {
                    background-color: #f5f5f5;
                    text-align: center;

                    .head {
                        padding: 10px;
                        font-weight: 700;
                        color: #fff;
                        text-align: center;

                        &.bg {
                            background-color: #333;
                        }
                    }

                    .costs {
                        display: inline-block;
                        position: relative;
                        clear: both;
                        padding: 10px 10px 10px 30px;

                        .value {
                            font-weight: 700;
                        }

                        .quantity {
                            position: absolute;
                            left: 0px;
                            top: 50%;
                            transform: translateY(-50%);
                            font-weight: 700;
                        }
                    }

                    .estimate {
                        padding: 10px;
                        text-align: center;
                        border-top: 1px solid #fff;

                        span {
                            font-weight: 700;
                        }
                    }
                }
            }

            .sub-txt {
                text-align: center;
                padding: 0px 15px 30px 15px;
                font-size: 0.7em;

                &.narr {
                    max-width: 340px;
                    margin: 0 auto;
                }
            }
        }
    }

    .bx-controls {
        display: inline-block;

        .bx-pager-item {
            display: inline-block;
            margin: 5px;

            a {
                width: 20px;
                height: 20px;
                font-size: 0px;
                display: block;
                border-radius: 100%;
                background-color: #333;
            }
        }
    }

    .product-title-right {
        margin-bottom: 15px;


        h1 {
            display: inline-block;
            width: calc(100% - 60px);
            float: left;
        }


        .w-list-action {
            width: 50px;
            height: 50px;
            float: right;
            position: relative;
            margin-top: 10px;
            cursor: pointer;

            .heart {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 2.3em;

                &.on {
                    opacity: 0;
                }
            }

            .ico {

                .fa-check {
                    opacity: 0;
                }

                transform: scale(.6);
                background-color: #333;
                position: absolute;
                bottom: 0px;
                right: 0px;
                width: 30px;
                height: 30px;
                border-radius: 100%;


                .inner {
                    width: 100%;
                    height: 100%;

                    i {
                        color: #fff;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        font-size: 1.3em;
                        width: 100%;
                        text-align: center;
                        line-height: 30px;
                    }
                }
            }
        }
    }

    .custo-options {
        display: inline-block;
        color: #fff;
        line-height: 40px;
        border-radius: 3px;
        padding: 0px 10px;
        font-weight: 700;
    }

    .prod-price {
        display: block;
        float: none;

        .label {
        }

        .value {
            .price {
                font-weight: 700;
                font-size: 1.6em;

                span {
                    font-size: 0.6em;
                }
            }
        }
    }

    .printopts {
        .opt {
            max-width: 180px;
            border: 1px solid #dbdbdb;
            margin: 0px auto 15px;
            border-radius: 3px;

            .name {
                text-align: center;
                font-weight: 700;
                display: block;
                padding: 10px;
            }
        }
    }

    .slide-inner {
        .img {
            text-align: center;

            img {
                display: inline-block;
            }
        }
    }

    .custom-deliv-detail {
        .detail {
            width: 100%;
            color: #fff;
            font-weight: 700;
            text-align: center;
            padding: 11px 0px;
            border-radius: 0px;
            margin-top: 15px;

            .info-i {
                width: 20px;
                height: 20px;
                background-color: #fff;
                position: relative;
                display: inline-block;
                border-radius: 100%;
                vertical-align: bottom;
                margin-right: 5px;

                svg {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    .size-guide-drop {
        background-color: #F7F7F7;
        margin-bottom: 15px;
        border: 1px solid #f7f7f7;

        .top {
            padding: 15px;
            cursor: pointer;

            span {
                font-weight: 700;
                margin-left: 5px;
            }

            .fa-chevron-down {
                float: right;
            }
        }

        .body {
            max-height: none;
            overflow: hidden;
            transition: max-height 0.5s ease-in-out;

            .inner {
                padding: 15px;
            }

            background-color: #fff;
        }

        &.shut {
            .body {
                max-height: 0px;
            }
        }
    }

    .branding-check {
        display: block;
        background-color: #f1f1f1;
        margin-top: 15px;
        position: relative;
        padding: 15px;

        input {
            display: none;
        }

        cursor: pointer;

        .txt {
            font-weight: 700;
            padding-right: 30px;
            display: block;
        }

        .dummy-check {
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .bundle.box {
        box-shadow: 0px 11px 20px #00000026;
        padding: 30px 45px;
        margin-bottom: 45px;

        @include media("<=tablet") {
            padding: 15px;
        }

        .bundle-header {
            display: flex;
            justify-content: space-between;

            @include media("<=tablet") {
                flex-direction: column;
            }

            > div {
                display: flex;
                flex-direction: column;
                grid-gap: 10px;

                h1 {
                    font-size: 2rem;
                    text-align: start;
                    margin: 0;
                }

                .bundle-desc, .bundle-desc * {
                    font-weight: bold;
                    font-size: 1.5rem;
                    margin: 0;
                }

                .bundle-price {
                    font-size: 2.5rem;
                    font-weight: bold;
                }
            }


            img {
                max-height: 210px;
                align-self: flex-end;
            }
        }

        .bundle-steps {
            display: flex;
            grid-gap: 10px;
            flex-wrap: wrap;

            > div {
                max-width: fit-content;
                border-radius: 3px;
                padding: 15px;
                font-size: 1rem;
                font-weight: bold;
                cursor: pointer;
            }
        }

        .bundleprod {
            margin-top: 15px;
            display: flex;
            align-items: center;
            border-radius: 3px;
            border: 1px solid #00167F;
            grid-gap: 10px;
            padding-right: 30px;
            position: relative;
            justify-content: space-between;

            @include media("<=desktop") {
                flex-direction: column;
                align-items: flex-start;
                padding: 65px 30px 30px;
            }

            @include media("<=tablet") {
                padding: 65px 15px 15px;
            }

            .step-num {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 55px;
                font-size: 2.7rem;
                align-self: stretch;
                font-weight: bold;
                z-index: 2;

                @include media("<=desktop") {
                    border-bottom-right-radius: 10px;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }



            .bundle-item-name {
                color: #272727;
                font-size: 1.5rem;
                max-width: 130px;
                font-weight: bold;

                @include media("<=desktop") {
                    max-width: initial;
                }
            }

            .bundle-item-qty {
                color: #575757;
                font-weight: bold;
                font-size: 2.7rem;
                white-space: nowrap;
                position: relative;

                > span.red {
                    color: #fe0002;
                }

                input {
                    text-align: end;
                    padding: 0;
                    margin: 0;
                    border: none;
                    font-size: inherit;
                    font-family: inherit;
                    color: inherit;
                    font-weight: inherit;
                    max-width: 60px;
                    pointer-events: none;
                }

                .qty-msg {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    transform: translateY(100%);
                    font-size: 1rem;
                    max-width: 100%;
                }

                .qty-msg.fail {
                    color: #fe0002;
                }
            }

            .bundle-item-qty.green {
                color: #07a45f;
            }

            .bundle-colour-picker {
                display: flex;
                flex-direction: column;
                grid-gap: 15px;
                margin-left: 90px;
                flex-grow: 1;
                padding: 10px 0;
                max-width: 550px;

                @include media("<=desktop") {
                    margin-left: 0;
                    padding: 10px 0;
                    width: 100%;
                }

                .colour-title {
                    color: #272727;
                    font-size: 1.5rem;
                    font-weight: bold;
                    padding: 0 30px;

                    @include media("<=desktop") {
                        padding: 0;
                    }
                }

                .bundle-item-colours {
                    display: flex;
                    flex-wrap: wrap;
                    grid-gap: 10px;
                    padding: 0 30px;

                    @include media("<=desktop") {
                        padding: 0;
                    }

                    label {
                        height: 30px;
                        width: 30px;
                        border-radius: 50%;
                        box-shadow: 0 0 20px 2px rgba(0,0,0,.14902);
                        position: relative;

                        .hover-colour-title {
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            bottom: calc(100% + 7px);
                            background-color: #333;
                            border-radius: 3px;
                            color: #fff;
                            text-align: center;
                            transition: opacity .2s ease-out;
                            opacity: 0;
                            display: none;


                            .inner-rel {
                                position: absolute;
                                content: "";
                                width: 15px;
                                height: 15px;
                                background-color: #333;
                                border-radius: 3px;
                                position: absolute;
                                display: block;
                                bottom: -6px;
                                left: 50%;
                                transform: translateX(-50%) rotate(45deg);
                                z-index: -1;
                            }
                        }

                        input {
                            display: none;
                        }
                    }
                }

                label.selected {
                    border: 1px solid #fff;
                }

                label.selected:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    right: 50%;
                    background-color: #FF8800;
                    height: 35px;
                    width: 35px;
                    z-index: -1;
                    border-radius: 50%;
                    transform: translate(50%, -50%);
                }

                label:hover {
                    cursor: pointer;

                    .hover-colour-title {
                        display: block;
                        opacity: 1;
                        padding: 5px;
                    }
                }

                .buttons-divider {
                    width: 100%;
                    height: 1px;
                    background-color: #575757;
                }

                .bundle-prod-buttons {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 15px;

                    @include media("<=phone") {
                        flex-wrap: wrap;
                        grid-gap: 15px;
                        padding: 0;
                    }

                    .close-flex {
                        display: flex;
                        align-items: center;
                        grid-gap: 15px;
                        justify-content: space-between;

                        @include media("<=phone") {
                            width: 100%;
                        }
                    }

                    .fa-times {
                        font-size: 2rem;
                        color: #FE0002;
                        cursor: pointer;
                    }

                    .fa-times:hover {
                        color: #b10001;
                    }

                    .colour-name {
                        display: flex;
                        align-items: center;
                        grid-gap: 30px;

                        @include media("<=phone") {
                            width: 100%;
                            justify-content: space-between;
                        }

                        .colour-flex {
                            display: flex;
                            align-items: center;
                            grid-gap: 10px;

                            .colour {
                                height: 45px;
                                width: 45px;
                                border-radius: 50%;
                                box-shadow: 0 0 20px 2px rgba(0,0,0,.14902);
                            }

                            .sel-qty {
                                color: #575757;
                                font-weight: 700;
                                font-size: 2rem;
                            }
                        }

                        .name {
                            color: #272727;
                            font-size: 1.5rem;
                            font-weight: bold;
                            max-width: 180px;
                        }
                    }

                    .btn {
                        padding: 15px;
                        max-width: fit-content;
                        border-radius: 3px;
                        font-size: 1rem;
                        font-weight: bold;
                        cursor: pointer;
                    }
                }
            }
        }

        .bundle-popup {
            position: fixed;
            top: 0;
            right: 0;
            width: 100vw;
            height: 100vh;
            display: none;
            z-index: 99;
            background-color: #000000b3;
            overflow-y: auto;

            .popup-box {
                position: absolute;
                right: 50%;
                top: 50%;
                transform: translate(50%, -50%);
                background-color: #fff;

                .close-whole {
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    cursor: pointer;
                }

                @include media(">desktop") {
                    min-width: 1130px;
                }

                @include media("<=desktop") {
                    min-width: 90%;
                }

                @include media("<=tablet") {
                    flex-direction: column;
                    top: 30px;
                    transform: translate(50%, 0);
                }

                .popup-flex {
                    display: flex;
                    grid-gap: 15px;
                    justify-content: space-between;

                    @include media("<=desktop") {
                        flex-direction: column;

                        > div:first-child {
                            max-width: 150px;
                            margin-left: auto;
                        }
                    }

                    .size-opt {
                        padding: 12px 12px;
                    }

                    .size-opt:has(.qty:disabled) {
                        opacity: 0.3;
                    }

                    .sizes {
                        padding: 30px;
                        max-width: 60%;


                        @include media(">desktop") {
                            min-width: 50%;
                        }

                        @include media("<=desktop") {
                            max-width: initial;
                        }

                        h2 {
                            font-size: 2rem;
                            text-align: start;
                            margin: 0;
                        }

                        h3 {
                            font-weight: bold;
                            font-size: 1.5rem;
                            margin-bottom: 15px;
                        }

                        .pop-colour-flex {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 0 15px;
                            margin-bottom: 30px;

                            .pop-colour-name {
                                display: flex;
                                align-items: center;
                                grid-gap: 30px;

                                .colour {
                                    height: 30px;
                                    width: 30px;
                                    border-radius: 50%;
                                    box-shadow: 0 0 20px 2px rgba(0,0,0,.14902);
                                }

                                .name {
                                    color: #272727;
                                    font-size: 1.5rem;
                                    font-weight: bold;
                                    max-width: 275px;
                                }
                            }

                            .qty {
                                color: #575757;
                                font-weight: 700;
                                font-size: 2.7rem;

                                input {
                                    text-align: end;
                                    padding: 0;
                                    margin: 0;
                                    border: none;
                                    font-size: inherit;
                                    font-family: inherit;
                                    color: inherit;
                                    font-weight: inherit;
                                    pointer-events: none;
                                    max-width: 60px;
                                }
                            }
                        }

                        .size-name {
                            text-align: center;
                            font-size: 1.5rem;
                            white-space: nowrap;

                            @include media("<=sm-phone") {
                                font-size: 1.2rem;
                            }
                        }
                    }
                }

                .popup-footer {
                    padding: 30px;
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;

                    .confirm-btn {
                        font-size: 1rem;
                        padding: 15px;
                        max-width: fit-content;
                        font-weight: bold;
                        text-align: center;
                        width: 100%;
                        border-radius: 3px;
                        cursor: pointer;
                    }
                }
            }
        }

        .submit-flex {
            display: flex;
            padding-top: 30px;
            width: 100%;
            grid-gap: 15px;
            flex-direction: column;
            width: 100%;
            align-items: flex-end;

            h2 {
                margin: 0;
                padding: 0;
            }

            .add-flex {
                width: 100%;
                display: flex;
                grid-gap: 30px;
                justify-content: flex-end;

                @include media("<=tablet") {
                    flex-direction: column;
                    align-content: flex-end;
                    flex-wrap: wrap;
                }

                .total {
                    display: flex;
                    align-items: center;
                    grid-gap: 15px;
                    font-weight: bold;
                    font-size: 2rem !important;

                    @include media("<=tablet") {
                        justify-content: flex-end;
                    }

                    h2 {
                        font-size: 2rem !important;
                        margin: 0;
                        padding: 0;
                    }
                }
            }

            .summary-flex {
                display: flex;
                justify-content: space-between;
                width: 100%;

                #brandingform {
                    display: flex;
                    flex-direction: column;
                    grid-gap: 15px;
                    width: 100%;

                    h2 {
                        font-size: 1.75em !important;
                        margin: 0;
                        padding: 0;
                    }

                    .custom-summary {
                        display: flex;
                        font-weight: bold;
                        font-size: 1.2rem;
                        width: 100%;
                        grid-gap: 15px;

                        .sel-list {
                            flex-direction: column;
                            grid-gap: 10px;
                            flex-grow: 1;

                            .selections-grid {
                                display: grid;
                                grid-template-columns: repeat(4,1fr);
                                grid-gap: 10px;
                                padding: 25px 0;
                                border-top: 1px solid #575757;
                                font-size: 1.3rem;
                                text-align: center;

                                @include media("<=phone") {
                                    grid-template-columns: repeat(2,1fr);
                                }

                                @include media("<=sm-phone") {
                                    grid-template-columns: 1fr;
                                    text-align: start;
                                }

                                > span:last-child {
                                    color: #fe0002;
                                }
                            }

                            .selections-grid:last-child {
                                border-bottom: 1px solid #575757;
                            }
                        }

                        .btn-lg {
                            max-width: fit-content;
                            display: flex;
                            align-items: center;
                            border-bottom-left-radius: 0;
                            border-top-left-radius: 0;
                        }
                    }
                }
            }

            .btn-lg {
                font-size: 1rem;
                padding: 15px;
                max-width: fit-content;
                font-weight: bold;
                text-align: center;
                width: 100%;
                border-radius: 3px;
                border: none;
                cursor: pointer;

                @include media("<=xs-phone") {
                    max-width: 100%;
                }
            }


            .custsteps {
                position: fixed;
                top: 0;
                right: 0;
                width: 100vw;
                height: 100vh;
                display: none;
                z-index: 9999;
                background-color: #000000b3;
                overflow-y: auto;

                .popup-box {
                    background-color: #f7f7f7;
                    border: 1px solid #dbdbdb;
                    position: fixed;
                    right: 50%;
                    top: 50%;
                    transform: translate(50%,-50%);
                    padding: 30px;
                    max-height: 100vh;
                    overflow-y: auto;

                    @include media("<=tablet") {
                        flex-direction: column;
                        top: 30px;
                        transform: translate(50%, 0);
                    }

                    @include media(">desktop") {
                        min-width: 1130px;
                    }

                    @include media("<=desktop") {
                        min-width: 90%;
                    }

                    .close-cut-pop {
                        position: absolute;
                        right: 5px;
                        top: 5px;

                        i {
                            font-size: 2rem;
                            color: #fe0002;
                            cursor: pointer;
                        }

                        i:hover {
                            color: #b10001;
                        }
                    }
                }
            }
        }

        #login-popup {
            z-index: 9999;

            #login-partial-container {
                top: 50%;
                right: 50%;
                left: auto;
                transform: translate(50%,-50%);
                height: auto;

                .close {
                    right: 0;
                    top: 0;
                    z-index: 9;
                }
            }
        }
    }

    .bundle-listing {

        .bundle-card {
            box-shadow: 0 11px 20px rgba(0,0,0,.14901960784313725);
            position: relative;

            .bundle-title {
                padding: 15px;
                font-size: 2.5rem;
                text-align: center;
                margin: 0;
                font-weight: bold;

                @include media("<=tablet") {
                    font-size: 1.75rem;
                }
            }

            .save-roundel {
                position: absolute;
                right: 15px;
                bottom: 30px;
                height: 150px;
                width: 150px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                font-size: 3rem;
                font-weight: 700;
                text-align: center;
                line-height: .9;
                border-radius: 50%;

                @include media("<=l-desktop") {
                    height: 120px;
                    width: 120px;
                    font-size: 2.25rem;
                    bottom: 10px;
                    right: 10px;
                }

                @include media("<=tablet") {
                    height: 85px;
                    width: 85px;
                    font-size: 1.5rem;
                    bottom: -15px;
                    right: -15px;
                }
            }

            .was-price {
                text-align: center;
                width: 100%;
                font-size: 2rem;
                font-weight: bold;
                text-decoration: line-through;
                text-decoration-color: #FE0002;
                display: flex;
                justify-content: center;
                flex-direction: column;

                > div:last-child {
                    font-size: 0.9rem;
                    text-decoration: none;
                }
            }

            .price {
                margin-bottom: 15px;
                text-align: center;
                width: 100%;
                font-size: 4rem;
                font-weight: bold;
                display: flex;
                justify-content: center;
                flex-direction: column;

                @include media("<=tablet") {
                    font-size: 3rem;
                }

                > div:last-child {
                    font-size: 1.7rem;
                }
            }

            .view-now {
                font-weight: bold;
                font-size: 2.6rem;
                text-align: center;
                padding: 15px;
                width: 100%;

                @include media("<=tablet") {
                    font-size: 2rem;
                }
            }
        }
    }

    div.show-bundle {
        display: none;
    }

    .striped-colour {
        display: grid;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
    }

    .sizes-wrapper {
        padding: 15px;
        max-width: 100%;
        overflow: auto;

        td:nth-child(even), th:nth-child(even) {
            background: #f1f1f1;
        }

        th, td {
            padding: 10px 0;
        }
    }
