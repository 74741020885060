﻿
.custom--errmsg {
    border: 1px solid #C01B00;
    background-color: #F1DDE1;
    color: #C01B00;
    padding: 10px;
    text-align: center;
    border-radius: 3px;
}
.packingform {

    

    padding-bottom: 30px;

    .field-w-btn {
        .editor-label {
            float: left;
            width: 150px;
            line-height: 40px;
            margin-bottom: 0px !important;
        }

        .editor-field {
            float: left;
            width: calc(100% - 285px);

            input {
                height: 40px;
                width: 100%;
                padding-left: 10px;
            }

            .btn {
                font-size: 1.35em;
                background-color: #26b9e4;
                color: #fff;
                font-weight: bold;
                width: 120px;
                text-align: center;
                float: right;
                padding: 0px;
                line-height: 40px;
                border: none;

                &:hover {
                    background-color: #253645;
                }
            }
        }
    }

    .g-bx {
        background-color: #f1f1f1;
        padding: 15px;
        border: 1px solid #dbdbdb;
        border-radius: 3px;

        .editor-label {
            font-weight: 700;
            margin-bottom: 10px;
            margin-top: 0px;
        }

        .btn-1 {
            width: 120px;
            float: right;
            line-height: 40px;
            display: block;
            border-radius: 3px;
            text-align: center;
            font-weight: 700;
            color: #fff;
        }
    }

    .summary {
        .side--summary {
            border: 1px solid #dbdbdb;
            border-radius: 3px;

            h3 {
                margin: 0px;
                padding: 15px;
                background-color: #f1f1f1;
            }
        }

        .row {
            padding: 15px 15px 0px 15px;

            .assigned {
                font-weight: 700;
            }

            .title {
                font-weight: 700;
            }
        }

        .btn-1 {
            width: calc(100% - 30px);
            margin: 15px auto;
            display: block;
            line-height: 20px;
            padding: 10px;
            text-align: center;
            font-weight: 700;
            color: #fff;
            border-radius: 3px;
        }
    }

    .person {
        border: 1px solid #d4d4d4;
        margin-top: 15px;
        border-radius: 3px;

        &.shop-users {
            margin: 0px;
            border: none !important;

            .person-list {
                border: none !important;
                padding: 0px !important;
            }
        }

        .add--pop {
            position: fixed;
            width: 100%;
            height: 100%;
            left: 0px;
            top: 0px;
            z-index: 9999;

            select {
                width: 100%;
                height: 40px;
                border: 1px solid #ddd;
                border-radius: 3px;
            }

            .top {
                background-color: transparent;
                position: relative;
            }

            &.closed {
                display: none;
            }

            &.open {
                display: block;
            }

            .inner {
                width: 100%;
                height: 100%;
                position: relative;

                .overlay {
                    width: 100%;
                    height: 100%;
                    background-color: #000;
                    position: relative;
                    opacity: 0.5;
                }

                .content {
                    border-radius: 3px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 500px;
                    width: calc(100% - 30px);
                    max-height: calc(100% - 30px);
                    background-color: #fff;

                    .inner--content {
                        padding: 15px;

                        label {
                            margin-bottom: 5px;
                            font-size: 1.2em;
                            font-weight: 700;
                            display: block;
                        }

                        .additem {
                            color: #fff;
                            cursor: pointer;
                            line-height: 40px;
                            display: inline-block;
                            width: 95px;
                            text-align: center;
                            border-radius: 3px;
                            float: right;
                            font-weight: 700;
                            font-size: 1.3em;
                            margin-top: 15px;

                            &:hover {
                            }
                        }

                        .dw-xs-8 {
                            padding-right: 0px;
                            width: calc(100% - 125px);
                        }

                        .dw-xs-2 {
                            width: 125px;
                        }

                        .dw-xs-2 input {
                            width: 100%;
                        }
                    }

                    .top {
                        border-radius: 3px 3px 0px 0px;

                        span {
                            font-weight: 700;
                            font-size: 1.4em;
                            color: #333;
                        }

                        i {
                            cursor: pointer;
                            color: #fff;
                            background-color: #000;
                            width: 30px;
                            height: 30px;
                            line-height: 30px;
                            text-align: center;
                            border-radius: 100%;
                            position: absolute;
                            top: -15px;
                            right: -15px;
                        }
                    }
                }
            }
        }

        .top {
            background-color: #f1f1f1;
            padding: 15px;


            .removeperson {
                float: right;
                background-color: rgb(255, 0, 0);
                color: #fff;
                line-height: 25px;
                padding: 0px 15px;
                border-radius: 3px;
                font-weight: 700;
                font-size: 1.2em;
            }

            .removeperson:hover {
                float: right;
                background-color: #C01B00;
                color: #fff;
            }

            .name {
                width: calc(100% - 150px);
                float: left;
                font-weight: 700;
                font-size: 1.4em;

                i {
                    margin-right: 5px;
                    line-height: 25px;
                }
            }
        }

        .selections {
            padding: 15px;

            .selection {
                padding: 5px 32px 5px 5px;
                position: relative;
                margin-right: 5px;
                margin-bottom: 5px;
                float: left;
                border-radius: 3px;
                font-size: 1.2em;
                line-height: 20px;
                border: 1px solid #dbdbdb;

                .removeitem {
                    display: inline-block;
                    position: absolute;
                    line-height: 20px;
                    top: 0px;
                    right: 0px;
                    width: 32px;
                    line-height: 32px;
                    text-align: center;
                }

                .removeitem:hover {
                }

                &.add-item {
                    color: #fff;
                    cursor: pointer;
                    font-weight: 700;
                    text-align: center;
                    padding: 5px 15px !important;
                }

                &.add-item:hover {
                }
            }
        }
    }

    @include media(">tablet") {
        .summary {
            width: 350px;
        }

        .options {
            width: calc(100% - 350px);
        }
    }

    @include media(">phone", "<=tablet") {
        .summary {
            width: 300px;
        }

        .options {
            width: calc(100% - 300px);
        }
    }

    @include media(">phone") {
        .summary {
            float: right;
        }
    }

    @include media("<=phone") {
        .summary {
            width: 100%;
            margin-bottom: 15px;

            .row {
                width: 50%;
                float: left;
            }

            .options {
                width: 100%;
            }
        }

        .field-w-btn .editor-label {
            width: 100%;
            line-height: 1;
        }

        .field-w-btn .editor-label label {
            display: block;
            margin-bottom: 15px;
        }

        .field-w-btn .editor-field {
            width: calc(100% - 130px);
        }
    }

    @include media("<=sm-phone") {

        .summary {
            .row {
                width: 100%;
                float: none;
            }
        }
    }
}
