﻿.btn {
    cursor: pointer;

    &.main-style {
        cursor: pointer;
        border-radius: 3px;
        color: #fff;
        font-weight: 500;
        display: block;
        clear: both;
        max-width: 225px;
        text-align: center;
        padding: 10px;
        line-height: 25px;
        margin: 15px 0px;
        line-height: 40px;
        padding: 0px;
        border: none;
        font-weight: 700;
        width: 100%;
        font-size: 1.0em;
    }

    &.pay {
        background-color: #079700;
        width: 100%;
        max-width: 100%;
        height: 50px;

        &:hover {
            background-color: #00843d !important;
        }
    }

    &.delete {
        background-color: #ff0000;
        line-height: 40px;
        padding: 0px 10px;
        color: #fff;
        font-weight: 700;
        border-radius: 5px;
        min-width: 40px;
        display: block;
        min-height: 40px;
        text-align: center;

        &:hover {
            background-color: #c52e1c;
        }

        .ico {
            display: block;
        }

        .txt {
            display: none;
        }
    }
}

.btn-1, .btn-2 {
    transition: background-color 0.1s ease;
}

.btn-1.disabled, .btn-2.disabled{
    opacity: 0.5;
}

.outer-btn-i {
    position: relative;

    .infotog {
        position: absolute;
        top: -10px;
        right: -10px;
        background-color: #36373a;
    }
}