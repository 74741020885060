﻿.checkout--container {

    .two-grid {
        display:flex;
        align-items:center;
        justify-content:center;
        flex-wrap:wrap;
        grid-gap:5px;

        img {max-width:90px;}
    }

    .summary {
        border: 1px solid #dbdbdb;
        border-radius: 3px;

        .head {
            background-color: #f1f1f1;
            padding: 10px;
            line-height: 30px;
            font-size: 1.3em;
        }

        .row {
            padding: 10px;
            border-bottom: 1px solid #F4F4F4;

            .img {
                width: 96px;
                min-height: 96px;
                float: left;
            }

            .info {
                width: 100%;
                position: relative;
                float: left;
                min-height: 96px;
                padding-bottom: 25px;
                padding-left: 10px;

                .title {
                    font-weight: 700;
                    color: #000;
                    display: block;
                    max-width: 180px;
                    margin-bottom: 10px;
                }

                .count {
                    display: block;
                }

                .price {
                    position: absolute;
                    display: inline-block;
                    bottom: 0px;
                    right: 0px;
                    font-weight: 700;
                }

                .remove {
                    position: absolute;
                    left: 10px;
                    bottom: 0px;

                    i {
                        color: #ff0000;
                    }

                    &:hover {
                        i {
                            color: #000;
                        }
                    }
                }
            }
        }

        .subtotal {
            .sub-row {
                margin-top: 15px;
            }

            border-bottom: solid 1px #f1f1f1;
            padding: 10px;

            &.basket-prices {
                border-radius: 3px;
                padding: 15px;


                .sub-row {
                    margin: 0px 0px 15px 0px;
                }

                .checkoutbtn, .creditbtn {
                    width: 100%;
                    border: none;
                    color: #fff;
                    line-height: 40px;
                    border-radius: 3px;
                    font-weight: 700;
                    font-size: 1.2em;
                    display: block;
                    text-align: center;
                }
            }
        }

        .total {
            font-size: 1.6em;
            padding: 10px;
        }

        .left {
            display: inline-block;
            float: left;
        }

        .right {
            display: inline-block;
            float: right;
        }

        .block-checkout {
            position: relative;
            padding: 10px 10px 10px 50px;
            background-color: #f1f1f1;
            max-width: 410px;
            margin: 15px auto 0;
            border-radius: 3px;

            i {
                position: absolute;
                top: 50%;
                left: 10px;
                transform: translateY(-50%);
                width: 25px;
                height: 25px;
                border-radius: 100%;
                text-align: center;
                line-height: 25px;
                color: #fff;
            }
        }
    }

    .termsagree {
        @include media("<=phone") {
            margin-bottom: 15px;
        }
    }

    .checkform {
        .post-code-search {
            .btn-1 {
                text-align: center;
                border-radius: 3px;
            }
        }

        .checkout--form {
            margin-bottom: 30px;
            border: 1px solid #dbdbdb;
            border-radius: 3px;

            .step-title {
                padding: 15px;
                border-bottom: 1px solid #f1f1f1;
                background-color: #f1f1f1;

                .round {
                    width: 30px;
                    line-height: 30px;
                    height: 30px;
                    text-align: center;
                    display: inline-block;
                    color: #fff;
                    border-radius: 100%;
                    margin-right: 5px;
                    font-weight: 700;
                    font-size: 1.2em;
                }

                i {
                    font-size: 1.3em;
                    display: inline-block;
                    margin-right: 5px;
                }

                .title {
                    font-weight: 700;
                }
            }

            .step-body {
                padding: 15px;

                .address-split {
                    width: 100%;
                    margin: 0px -9px;

                    .split {
                        width: 50%;
                        float: left;
                        padding: 9px;
                    }
                }
            }

            h2 {
                margin: 15px 0px;
            }

           

            input[type=text], select {
                line-height: 40px;
                border: 1px solid #ddd;
                display: block;
                width: 100%;
                max-width: 100%;
                border-radius: 3px;
                padding: 0px 15px;
                height: 40px;
            }
        }

        .baskettable {
            @include media(">phone") {
                border: 1px solid #ddd;
                border-radius: 3px;
            }

            .basket-head {
                background-color: #f1f1f1;
                padding: 15px;

                @include media(">l-desktop") {
                    .prod-head, .title-boot {
                        width: 255px;
                    }

                    .colour-head, .colour-boot {
                        width: 130px;
                    }

                    .size-head, .size-boot {
                        width: 245px;
                    }

                    .quant-head {
                        width: 115px;
                    }
                }

                @include media("<=l-desktop", ">desktop") {
                    .prod-head {
                        width: 230px;
                    }

                    .colour-head {
                        width: 160px;
                    }

                    .size-head {
                        width: 160px;
                    }

                    .quant-head {
                        width: 112px;
                    }
                }

                @include media("<=desktop", ">tablet") {
                    .prod-head {
                        width: 130px;
                    }

                    .colour-head {
                        width: 100px;
                    }

                    .size-head {
                        width: 79px;
                    }

                    .quant-head {
                        text-align: center;
                        width: 140px;
                    }
                }

                @include media("<=tablet", ">phone") {
                    .prod-head {
                        width: 130px;
                    }

                    .colour-head {
                        width: 100px;
                    }

                    .size-head {
                        width: 139px;
                    }

                    .quant-head {
                        text-align: center;
                        width: 140px;
                    }
                }

                @include media("<=phone") {
                    display: none;
                }
            }

            .basket-body {
                padding-top: 15px;

                .row {
                    margin-bottom: 15px;
                    border-bottom: 1px solid #dbdbdb;
                    position: relative;


                    @include media(">desktop") {
                        .title-boot {
                            width: 260px;
                            float: left;
                        }

                        .colour-boot {
                            width: 130px;
                            float: left;
                            min-height: 40px;
                            position: relative;

                            span {
                                display: block;
                                max-width: 100%;
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                left: 10px;
                            }
                        }

                        .size-boot {
                            width: calc(100% - 390px);
                            float: left;

                            .sizecell {
                                width: calc(50% - 105px);
                            }

                            .countcell {
                                width: 140px;
                            }

                            .pricecell {
                                width: calc(50% - 105px);
                            }

                            .removecell {
                                width: 70px;
                            }
                        }
                    }

                    @include media("<=desktop") {
                        .title-boot {


                            @include media(">phone") {
                                width: 130px;
                                float: left;
                            }

                            @include media("<=phone") {
                                width: 100%;
                            }
                        }

                        .colour-boot {
                            @include media(">phone") {
                                width: 100px;
                                float: left;
                            }

                            @include media("<=phone") {
                            }
                        }

                        .size-boot {

                            @include media(">phone") {
                                width: calc(100% - 230px);
                                float: left;
                            }

                            @include media("<=phone") {
                            }

                            width: 100%;
                            float: left;

                            @include media(">xs-phone") {
                                .sizecell {
                                    width: calc(50% - 105px);
                                }

                                .countcell {
                                    width: 140px;
                                }

                                .pricecell {
                                    width: calc(50% - 105px);
                                    font-size: 0.95em;
                                }

                                .removecell {
                                    width: 70px;
                                }
                            }

                            @include media("<=xs-phone") {
                                .sizecell {
                                    width: calc(50% - 85px);
                                }

                                .countcell {
                                    width: 110px;
                                }

                                .pricecell {
                                    width: calc(50% - 85px);
                                    font-size: 0.80em;
                                }

                                .removecell {
                                    width: 60px;
                                }
                            }
                        }
                    }

                    @include media(">phone") {
                        padding-bottom: 15px;
                    }

                    @include media("<=phone") {
                        padding-bottom: 85px;
                        border: 1px solid #f1f1f1;
                        border-radius: 3px;
                        padding-top: 15px;

                        .price.col.last {
                            position: absolute;
                            bottom: 0px;
                            left: 0px;
                        }
                    }

                    &:last-child {
                        margin-bottom: 0px;
                        border-bottom: 0px;
                    }

                    &.special {
                        margin-top: 15px;
                        background-color: #fff;
                        border: 1px solid #dbdbdb;
                        padding: 15px !important;
                        border-radius: 3px;

                        .title {
                            display: block;
                        }

                        .field {
                            display: block;

                            input {
                                min-height: 40px;
                                width: 100%;
                                padding: 10px;
                                border: 1px solid #dbdbdb;
                                border-radius: 5px;
                            }
                        }

                        @include media(">phone") {
                            .title {
                                width: 280px;
                                float: left;
                            }

                            .field {
                                width: calc(100% - 280px);
                                float: left;
                            }
                        }

                        @include media("<=phone") {
                            .field {
                                input {
                                    margin-top: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .flex-baskettable {
            @include media(">phone") {
                border: 1px solid #ddd;
                border-radius: 3px;
            }

            @include media("<=phone") {
                .basket-head {
                    display: none !important;
                }

                .basket-body {
                    flex-wrap: wrap;
                }
            }



            .basket-head {
                background-color: #f1f1f1;
                padding: 15px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                > div {
                    width: 100%;
                }
            }

            .basket-body {
                .row {
                    padding: 15px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    @include media("<=phone") {
                        flex-wrap: wrap;
                    }


                    > div {
                        width: 100%;

                        @include media(">phone") {
                            .moblabel {display: none !important;}
                        }

                        @include media("<=phone") {
                            width: 100%;
                            max-width: 100%;
                            margin-bottom: 15px;
                            text-align: left !important;
                        }
                    }
                }
            }


            @include media(">phone") {
                .title-boot {
                    max-width: 250px;
                }

                .price-boot {
                    max-width: 100px;
                    text-align: center;
                }

                .size-boot {
                    max-width: 100px;
                    text-align: center;
                }

                .colour-boot {
                    max-width: 150px;
                    text-align: center;
                }

                .quant-boot {
                    max-width: 150px;
                    text-align: center;
                }

                .remove-boot {
                    max-width: 100px;
                    text-align: center;
                }
            }





            .notes {
                padding: 15px;

                strong {
                    display: block;
                    margin-bottom: 10px;
                }

                textarea {
                    width: 100%;
                    min-height: 100px;
                }
            }
        }

        .basket-actions {
            .btn {
                display: inline-block;
                border-radius: 3px;
                color: #fff;
                font-weight: 700;
                font-size: 1.2em;
                border: none;
                line-height: 40px;
                padding: 0px 15px;
                margin-bottom: 10px;
            }
        }
    }

    
    @include media(">desktop") {
        .summary {
            width: 290px;
            float: right;
        }

        .checkform {
            float: left;
            width: calc(100% - 290px);
            padding-right: 30px;
        }

        &.basket {
            .summary {
                width: 290px;
                float: right;
            }

            .checkform {
                float: left;
                width: calc(100% - 290px);
                padding-right: 30px;
            }
        }
    }

    @include media("<=desktop", ">tablet") {
        .summary {
            width: 290px;
            float: right;
        }

        .checkform {
            float: left;
            width: calc(100% - 290px);
            padding-right: 50px;
        }
    }

    @include media(">tablet") {
        .summary {
            position: sticky;
            top: 15px;
            right: 0px;
        }
    }

    @include media("<=tablet") {
        &.basket {
            .summary {
                margin-top: 15px;
            }
        }
    }

    @include media("<=tablet", ">phone") {
    }

    @include media("<=phone") {
    }
}

.discount--form {
    width: 100%;
    max-width: 980px;
    padding: 15px;
    background-color: #f1f1f1;
    margin-top: 30px;
    border-radius: 3px;
    margin-bottom: 60px;

    .submit{
        .btn-1{
            line-height: 40px;
            border: none;
            border-radius:3px;
            color: #fff;
            font-weight: 700;
            width: 100%;
            font-size: 1.3em;
        }
    }

    .field{
        input{
            width: 100%;
            display: block;
            padding: 0px 15px;
            line-height: 36px;
            max-width: 100%;
        }
    }

    @include media(">tablet") {
        .title{
            width: 390px;
            float: left;
            line-height: 40px;
        }
        .field{
            width: calc(100% - 570px); 
            float: left;
        }
        .submit{
            width: 180px;
            float: left;
        }
    }

    @include media("<=tablet") {

        max-width: 400px;
        margin: 30px auto;
        .title {
            text-align: center;
        }
        .field{
            margin: 15px 0px;
        }
        .submit{
            text-align: center;
            .btn-1{
                max-width:180px;
                margin: 0 auto;
            } 
        }

       
    }
}

.basket-body {
    .col {
        height: auto !important;

        &.last {
            .price-txt {
                margin-bottom: 15px;
            }

            .btn {
                float: right;
            }

            @include media("<=phone") {
                background-color: #f1f1f1;
                padding: 15px;
            }
        }
    }


    .basket-customisation {
        border: 1px solid #f1f1f1;
        border-radius: 3px;
        max-width: 870px;
        width: 100%;
        float: right;

        .head {
            background-color: #fafafa;
            border-bottom: 1px #f1f1f1 solid;
            padding: 15px;

            strong {
                line-height: 35px;
            }

            a {
                float: right;
                line-height: 35px;
                padding: 0px 10px;
                border-radius: 3px;
                color: #fff;
                font-weight: 700;
            }
        }

        .custom-row {
            padding: 10px 10px 0px 10px;

            .section {
                margin: 0px -5px;

                .custo-boot {
                    padding: 0px 5px;
                }
            }
        }

        .custo-bx {
            margin-bottom: 10px;

            .top {
                border-top: none !important;
                border-left: none !important;
                border-right: none !important;
                padding: 5px 10px;
                position: relative;

                &:before {
                    content: "";
                    display: block;
                    width: 1px;
                    height: 100%;
                    position: absolute;
                    left: 50%;
                    top: 0px;
                    background-color: #fff;
                }

                .log {
                    width: 50%;
                    float: left;

                    .svg {
                        width: 30px;
                        height: 30px;
                        position: relative;
                        margin-right: 5px;

                        @include media("<=tablet", ">phone") {
                            display: none;
                        }

                        @include media("<=xs-phone") {
                            display: none;
                        }

                        svg {
                            position: absolute;
                            width: 100%;
                            top: 50%;
                            left: 0px;
                            transform: translateY(-50%);
                        }

                        display: inline-block;
                        vertical-align: top;
                    }

                    span {
                        display: inline-block;
                        line-height: 30px;
                        color: #fff;
                        vertical-align: top;
                    }
                }

                .pos {
                    width: 50%;
                    float: left;
                    padding-left: 15px;

                    span {
                        display: inline-block;
                        line-height: 30px;
                        color: #fff;
                        vertical-align: top;
                    }
                }

                .log-type, .app-type {
                    width: 50%;
                    float: left;
                    text-align: center;
                    position: relative;
                    height: 120px;

                    svg {
                        max-width: 90px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }

            .bottom {
                position: relative;
                min-height: 95px;


                .div {
                    position: absolute;
                    height: 100%;
                    width: 1px;
                    left: 50%;
                    top: 0px;
                }

                .left {
                    width: 50%;
                    float: left;
                    text-align: center;
                    padding: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .right {
                    padding: 10px;
                    width: 50%;
                    float: left;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .title {
                    font-weight: 700;
                }

                .postxt {
                    display: block;
                    margin-top: 10px;
                }

                .btn-1 {
                    display: block;
                    padding: 10px;
                    font-weight: 700;
                    color: #fff;
                    border-radius: 3px;
                    margin-top: 15px;
                }

                .contact {
                    i {
                        display: block;
                        text-align: center;
                        margin: 10px 0px;
                    }

                    font-weight: 700;
                    font-size: 1.2em;
                }

                .option {
                    .line {
                        text-align: left;
                        margin-bottom: 5px;

                        strong {
                            margin-right: 5px;
                        }
                    }

                    img {
                        display: inline-block;
                        max-height: 70px;
                    }
                }

                .print-pos {
                    .img {
                        display: block;
                        width: fit-content;
                        float: left;
                    }

                    .postxt {
                        width: calc(100% - 55px);
                        float: left;
                        padding-left: 5px;
                    }
                }
            }
        }
    }
}

.size-row{
    margin-bottom: 15px;
    .title{
    
        width: calc(100% - 110px);
        min-height:40px;
        display: flex;
        align-items: center;

        width: 100%;

       
        float: left;
    }
    .size-quantity-editor{
        float: left;
    }
}

.baskettable{
    .prod-info{
        .title{
            font-size: 1.4em;
            line-height: 1;
            margin-bottom: 10px;
        }
        .code{
            font-size: 0.8em;
        }
    }
    .price-txt{
        font-size: 1.3em;
        line-height: 40px;
        font-weight: 700;
    }
}

.checkout-actions{
    .btn{
        color: #fff;
        font-weight: 700;
        display: block;
        border-radius: 5px;
        text-align: center;
        line-height: 40px;
        width:100%;
        border: none;
        font-size: 1.2em;
    }
}

.checkout--container {
    .validation-summary-errors {
        border: 1px solid #C01B00;
        color: #C01B00;
        padding: 15px;
        border-radius: 3px;
        background-color: #ffcccc;

        ul {
            margin: 0px;
        }

        li {
            font-weight: 700;
        }
    }
}

.basket-error {
    border: 1px solid #C01B00;
    color: #C01B00;
    padding: 15px;
    border-radius: 3px;
    background-color: #ffcccc;
    font-weight: 700;
    display: inline-block;

    ul {
        margin: 0px;
    }

    li {
        font-weight: 700;
    }

    margin-bottom: 30px;
}

.error-warn{
    padding: 10px;
    margin-top: 15px;
    border-radius: 3px;
}

.payment-methods {
    .section {
        margin: 0 -5px;
    }

    .bs {
        padding: 5px;
    }

    .payment-option {
        border: none !important;
        display: block;
        width: 100%;
        border-radius: 3px;
        position: relative;
        cursor: pointer;
        min-height: 50px;


        &.pp {
            background-color: rgb(255, 196, 58);
        }

        img {
            max-width: 100px;
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
        }

        strong{
            display: block;
            top: 50%;
            left: 10px;
            color: #fff;
            position:absolute;
            transform: translateY(-50%);
        }

        .dummycheck {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
            border: none !important;
            i{
                visibility: visible;
                opacity: 0;
            }
        }
        
        &:hover{
            .dummycheck{
                i{
                    opacity: 0.3;
                }
            }
        }

        &.on {
            .dummycheck {
                i {
                    opacity: 1 !important;
                }
            }
        }

        input {
            display: none;
        }
    }
}

.po-input {
    padding: 15px;
    background-color: #f1f1f1;
    border: 1px solid #ddd;
    margin-top: 15px;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    label {float: none !important;}
    max-width: 1020px;
    input {border-radius: 3px; height: 40px; border: 1px solid #ddd; width: calc(100% - 95px);}
}
#iFrameContent {
    .OK, .ok { margin: 0 auto 30px !important; text-align: center; max-width: 300px; padding: 15px !important;}
}
