﻿.promo--bullets {
    margin-top: 50px;
    margin-bottom: 20px;
    .bullet {
        border-radius: 3px;
        text-align: center;
        font-size: 1.2em;
        padding: 12px;
        margin-bottom: 30px;

        i {
            color: #fff;
            font-size: 1.2em;
            margin: 0px 15px;
        }

        span {
            text-transform: uppercase;
            color: #fff;
            font-weight: 700;
        }
    }
}



.shop--section {
    background-color: #F2F2F2;
    padding: 50px;
    
    .shop--link {
        background-color: #fff;
        text-align: center;
        display: block;
        max-width: 380px;
        margin: 0 auto;
        cursor: pointer;
        border-radius: 3px; 
        padding:30px;
        strong{
            display: block;
            font-size: 1.5em;
            margin-bottom: 30px ;
        }
        .btn {
            color: #fff;
            display: inline-block;
            width: 160px;
            border-radius: 3px;
            line-height: 40px;
        }
    }
}


.category-home-link {
    height: 305px;
    position: relative;
    display: block;
    cursor: pointer;
    background-color: #000;
    max-width: 662px;
    margin: 30px auto 0px;

    .border {
        width: calc(100% - 60px);
        height: calc(100% - 60px);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 5;
        border: 1px solid #fff;
    }
    .btn{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        max-width: 300px;
        width: calc(100% - 60px);
        border-radius: 3px;
        padding: 15px;
        color: #fff;
        font-weight: 400;
    }
}

.group-link {
    border-radius: 3px;
    box-shadow: 0px 11px 11px #00000026;
    display: block;

    .img--container {
        height: 240px;
        background-color: #fff;
        position: relative;
        border-radius: 3px 3px 0px 0px;
    }

    .bottom {
        line-height: 30px;
        padding: 10px;
        color: #fff;
        font-weight: 700;
        text-align: center;
    }
}

.large-cat-home-link {
    padding: 90px;
    padding: 80px;
    background-color: #36373A;
    margin-top: 100px;
    outline: 1px solid #fff;
    outline-offset: -32px;
    .txt-content {
        max-width: 450px;
    }
    h2 {
        margin-top: 0px; 
        padding-top: 0px;
        color: #fff;
        margin-bottom: 15px;
        padding-bottom: 0px;
        font-size: 2.6em;
    }
    .txt {
        color: #fff;
        display: block;
        margin-bottom: 30px;
    }
    a.btn{
        border-radius: 3px;
        text-align: center;
        line-height: 50px;
        height: 50px;
        width: 225px;
        display: block;
        text-transform: uppercase;
        font-weight: 700;
        cursor: pointer;
        background-color: #fff;
    }
}

.border-img {
    padding-top: 51.33%;
    background-color: #333;
    outline: 1px solid #fff;
    outline-offset: -29px;
}

.sitemap-page{
    ul{
        padding: 0px;
        list-style: none;
    }
    h1{
        text-align: left;
    }
    h2{
        font-size: 1.5em;
    }
}

@include media(">l-desktop") {
    .lg-flleft {
        float: left;
    }

    .lg-flright {
        float: right;
    }
}