﻿.blog {
    margin-bottom: 30px;

    @include media("<=tablet") {
        .flx-bx {
            flex-wrap: wrap;
        }

    }

    .blogpost {
        margin-bottom: 30px;

        @include media(">tablet") {
            &.hasimg {
                .blog-image {
                    width: 30%;

                    > * {
                        height: 100%;
                        background-size: cover;
                        background-position: center;
                    }
                }

                .blog-txt {
                    width: 70%;
                }
            }
        }

        @include media("<=tablet") {
          

            &.hasimg {
                .blog-image {
                    width: 100%;
                    height: 250px;

                    > * {
                        height: 100%;
                        background-size: cover;
                        background-position: center;
                    }
                }

                .blog-txt {
                    width: 100%;
                }
            }
        }
    }
}
