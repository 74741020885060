﻿.type-h2{
    text-align: center;
}

.type-jpgbann{
    .img-link{
        height: 490px;
        margin: 0 auto;
        display: block;
        img{
            width: 100%;
            
            height: auto;
        }
    }
}

.type-presetbann {
    img {
        max-width: none !important;
        width: auto;
        max-height: 490px;
    }

    .inner-rel {
        width: 100%;
        height: 100%;
        position: relative;

        @include media(">sm-phone") {
            height: 490px;
            
        }

        @include media("<=sm-phone") {
            height: 300px;

            img {
                max-height: 300px;
                width: auto;
            }

            &:before {
                content: "";
                display: block;
                width: calc(100% + 50px);
                left: 50%;
                transform: translateX(-50%);
                top: 0px;
                height: 100%;
                background-color: #000;
                position: absolute;
                z-index: 8;
                opacity: 0.3;
            }
        }
    }

    .bann-surround {
        margin: 0 auto;
        display: block;
        position: relative;
        overflow: hidden;



        &.iscentered {
            .banner-content {
                width: 100%;
                max-width: 460px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                z-index: 10;
            }

            @include media(">tablet") {

                @include media(">desktop") {
                    img {
                        display: block;
                        position: absolute;
                        bottom: 0px;
                        right: 0px;
                    }

                    img:first-child {
                        position: absolute;
                        left: 0px;
                        bottom: 0px;
                    }
                }

                @include media("<=desktop") {
                    img {
                        display: block;
                        position: absolute;
                        bottom: -50px;
                        right: 0px;
                    }

                    img:first-child {
                        position: absolute;
                        left: -50px;
                        bottom: 0px;
                    }
                }
            }

            @include media("<=tablet") {
                img {
                    display: block;
                    position: absolute;
                    bottom: 0px;
                    right: -100px;
                }

                img:first-child {
                    position: absolute;
                    left: -100px;
                    bottom: 0px;
                }
            }
        }

        &.notcentered {
            .banner-content {
                width: 100%;
                max-width: 460px;
                position: absolute;
                top: 50%;
                left: 0px;
                transform: translateY(-50%);
                z-index: 10;
            }

            @include media(">tablet") {

                @include media(">desktop") {
                    img {
                        display: block;
                        position: absolute;
                        bottom: 0px;
                        right: 0px;
                    }

                    img:first-child {
                        position: absolute;
                        right: 0px;
                        bottom: 0px;
                    }
                }

                @include media("<=desktop") {
                    img {
                        display: block;
                        position: absolute;
                        bottom: -50px;
                        right: 0px;
                    }
                }
            }

            @include media("<=tablet") {
                img {
                    display: block;
                    position: absolute;
                    bottom: 0px;
                    right: -100px;
                }
            }
        }

        .banner-content {

            .toptxt {
                padding: 10px 30px;
                font-weight: 700;
                font-size: 1.3em;
                line-height: 16px;
                display: inline-block;
                color: #fff;
                margin-bottom: 30px;
            }

            h2 {
                color: #333;
                margin: 0px 0px 30px 0px;
                font-size: 3.0em;

                @include media("<=sm-phone") {
                    colour: #fff;
                }

                @include media("<=sm-phone") {
                    colour: #fff;
                }
            }

            .txt {
                color: #333;
                font-size: 2em;
                margin-bottom: 30px;

                @include media("<=sm-phone") {
                    colour: #fff;
                }
            }

            .bannbtn {
                display: inline-block;
                background-color: #fff;
                padding: 0px 30px;
                height: 45px;
                line-height: 45px;
                min-width: 255px;
                font-weight: 700;
                text-transform: uppercase;
                border-radius: 3px;
                color: #fff;
                background-color: #36373A;
                text-align: center;
            }
        }
    }
}

.content-w-img {
    margin: 30px 0px;
    position: relative;

    

    @include media(">tablet") {
        .right {
            width: 50%;
            position: absolute;
            height: 100%;
            top: 0px;
            right: 0px;
        }

        .left {
            width: 50%;
            float: left;
        }
    }

    @include media("<=tablet") {
        .right {
            height: 300px;
        }
    }

    .right {
        background-position: center;
        background-size: cover;
    }

    .txt {
        padding: 50px 45px;
        color: #fff;

        h3 {
            color: #fff;
            margin: 0px 0px 30px 0px;
            font-size: 2em;
        }
    }
}

.type-customershop {
    .shop-bx {
        padding: 0px 15px;
        cursor: pointer;
        max-width: 440px;
        max-height: 300px;
        overflow: hidden;
        margin: 0 auto;
        transition: box-shadow .1s ease-in-out,transform .1s;

    }
}

.type-brandicons {
    margin: 100px 0px;

    .brand {
        text-align: center;
        position: relative;
        min-height: 50px;

        img {
            max-width: 100%;
            height: auto;
            
        }
    }
}

.widget-display {

    &.title {
        h1 {
            font-size: 2.2em;
        }

        h2 {
            font-size: 2em;
        }

        h3 {
            font-size: 1.8em;
        }
    }


    &.content {
        text-align: center;
        margin: -15px 0px;
    }


    @include media(">tablet") {
        margin: 30px auto;
    }

    @include media("<=tablet") {
        margin: 15px auto;
    }

    p {
        margin: 0 auto;
    }

    &:first-child {
        margin: 0px auto 15px !important;
    }

    &.title {
        h1, h2, h3 {
            margin: 15px 0px;
        }
    }

    &.content {
    }

    &.featuredproducts {
    }

    &.customershops {
    }

    &.banner {
        margin: 0px auto !important;
        a{ display:block; }
        .bx-wrapper {
            max-height: 490px;
            .bx-viewport{
                max-height: 490px;
            }
        }
    }

    &.bannerfifty {
        .bann {
            max-width: 675px;
            display: block;
            margin: 15px auto;
        }
    }

    &.bannerthirds {
        .bann {
            max-width: 440px;
            display: block;
            margin: 15px auto;
        }
    }

    &.brandsslider {
        max-width: 1350px;

        .slide {
            text-align: center;
            min-width: 150px;

            img {
                margin: 0 auto;
                width: auto;
                max-height: 50px;
            }
        }
    }

    &.featuredcategories {
        margin: 15px auto;

        .bx-wrapper {
            max-height: 340px;
        }
    }

    &.contentwithimg {
        .txtbx {
            color: #fff;
            padding: 30px;
        }

        .txt-right {
            position: relative;
            .txt-boot {
                min-height: 320px;
                display: flex; 
                align-items: center;
                &.dw-sm-9 {
                    @include media("<=tablet", ">phone") {
                        width: 100%;
                        float: none;
                    }
                }
            }
        }

        .txt-left {
            position: relative;

            .img-boot {

                &.dw-sm-3 {
                  

                    @include media("<=tablet", ">phone") {
                        width: 100%;
                        float: none;
                        padding: 15px;
                        text-align: center;

                        img {
                            display: inline-block;
                        }
                    }
                }
            }

            .txt-boot {
                &.dw-sm-9 {


                    @include media("<=tablet", ">phone") {
                        width: 100%;
                        float: none;
                    }
                }
            }
        }

        @include media("<=phone") {
            .img-boot {
                width: 100%;
                float: none !important;
                text-align: center;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .txt-boot {
                width: 100%;
                float: none !important;

                .txtbx {
                    padding: 15px;
                }
            }
        }
    }
}