﻿
/*SCROLL-BAR*/
/* width */
::-webkit-scrollbar {
    width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
    background: #d0d0d0;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: #404041;
    border-radius: 5px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #404041;
}

.rr-drop-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    background-color: #fff;
    padding: 1rem;
    grid-gap: 1rem;

    .rr-drop-col {
        > a {
            background-color: #c6c6c6;
            width: 100%;
            display: flex;
            justify-content: center;
            grid-gap: 1rem;
            padding: 0.5rem;
            color: #404041;
            font-weight: bold;
            align-items: center;
            border-radius: 3px;

            i {
                transition: transform 0.3s;
                display: none;
            }
        }

        .rr-drop-flx {
            padding-top: 0.5rem;
            position: relative;
            transition: max-height 0.3s;

            .rr-mob-parent {
                display: none;
            }

            > div {
                padding: 0.25rem;
                text-align: left
            }
        }
    }

    .rr-drop-flx:after {
        content: "";
        display: none;
        position: sticky;
        bottom: 0;
        left: 0;
        height: 0.5rem;
        width: 100%;
        background-color: #dbdbdb;
    }

    @include media("<tablet") {
        display: flex;
        flex-direction: column;
        padding: 0 1rem;
        background-color: transparent;
        grid-gap: 0;

        .rr-drop-col {
            > a {
                justify-content: space-between;
                background-color: transparent;
                border-bottom: 1px solid #808080;
                padding: 0.5rem 0;

                i {
                    display: block;
                }
            }

            .rr-drop-flx {
                border-bottom: 1px solid #808080;
                padding: 0;
                background-color: #fff;
                transition: max-height 0.5s;
                max-height: 0;
                overflow: hidden;

                .rr-mob-parent {
                    display: block;
                }

                > div {
                    padding: 0.25rem;
                    text-align: left
                }

                > div:not(:empty):not(:first-child) {
                    border-top: 1px solid #808080;
                }
            }

            .rr-drop-flx.open {
                max-height: 200px;
            }
        }

        .rr-drop-col:has(.open) i {
            transform: rotate(180deg);
        }

        .rr-drop-flx:after {
            background-color: #fff;
            height: 0.25rem;
            display: block;
        }
    }
}



.menu-section {
    .top-menu {
        /*  @include media('>l-desktop' ) {
            width: calc(100% - 380px);
        }

        @include media('<=l-desktop', '>desktop') {
            width: calc(100% - 300px);
        }

        @include media('<=desktop', '>tablet') {
            width: calc(100% - 270px);
        }*/
        @include media('<=tablet') {
            .original-menu {
                .nav-item:nth-child(2) {
                    margin-top: 50px;
                }
            }
        }
    }

    @include media(">tablet") {

        .active-hov .subcats {
            display: flex;
            width: 100%;
            text-align: left;
            padding: 0px 15px;
        }

        .active-hov .subcats .sub-cat-col {
            width: 20%;
            margin-bottom: 15px;
            min-height: 200px;

            .sub-cat-list {
                a {
                    display: block;
                    padding: 5px 0px;
                }
            }
        }

        .active-hov .subcats .sub-cat-col ul {
            padding: 0px;
            margin: 10px 0px 0px;
            border: none !important;
        }

        .active-hov .subcats .sub-cat-col.promo-col {
            margin-left: auto;
        }

        .active-hov .subcats > ul.sub-cat-list {
            padding: 0px;
            border: none !important;
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            margin-bottom: 15px;

            li {
                width: 20%;

                a {
                    padding: 10px 0px;
                    display: block;
                }
            }
        }

        .nav-dr-title {
            position: relative;

            .outter {


                .inner {
                    position: relative;
                    padding-right: 22px !important;
                    width: auto !important;
                    display: inline-block;
                    text-align: center;



                    i {
                        position: absolute;
                        top: 50%;
                        right: 0px;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }

    @include media("<=tablet") {
        .active-hov {
            display: none !important;
        }

        .nav-dr-body .brand-btn {
            display: block;
            width: 100%;
            padding: 10px;
        }
    }

    &.with-search {
        .top-menu {


            @include media('>l-desktop') {
                width: calc(100% - 380px);
                float: left;
            }

            @include media('<=l-desktop', '>desktop') {
                width: calc(100% - 300px);
                float: left;
            }

            @include media('<=desktop', '>tablet') {
                width: calc(100% - 270px);
                float: left;

                .nav-item {
                    width: calc(100% / 4) !important;
                }
            }
        }

        .top-search {

            @include media('>l-desktop') {
                width: 380px;
                float: right;
            }

            @include media('<=l-desktop', '>desktop') {
                width: 300px;
                float: right;
            }

            @include media('<=desktop', '>tablet') {
                width: 270px;
                float: right;
            }
        }

        @include media(">desktop") {
            .original-menu {
                &.has-blog {
                    .nav-item {
                        width: calc(100% / 4);
                    }
                }

                &.no-blog {
                    .nav-item {
                        width: calc(100% / 5);
                    }
                }
            }
        }
    }






    ul {
        list-style: none;
    }




    @include media('>tablet') {
        .original-menu {
            position: relative;
            display: flex;
            justify-content: space-between;

            .clear {
                display: none;
            }


            @include media("<=desktop") {


                .nav-item {
                    &.home-link {
                        display: none;
                    }

                    font-size: 0.95em;
                }
            }
        }



        .nav-item {
            text-align: center;
            transition: background-color 0.2s ease;
            display: block;


            @include media(">l-desktop") {
                width: auto;
            }

            @include media(">desktop") {
                padding: 0px 15px;
            }

            @include media("<=desktop", ">tablet") {
                padding: 0px 10px;
            }

            .linktxt {
            }

            &.nodrop {
                font-weight: 700;
                text-decoration: none !important;
                display: block;
                height: 60px;
                line-height: 20px;

                &:hover {
                    span {
                        color: #fff;
                    }
                }
            }

            .categoriesTitle, .shopsTitle, .shopslastTitle, &.home span {
                font-weight: 700;
                text-decoration: none !important;
                display: block;
                line-height: 20px;
                padding: 0px 0px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;

                a {
                }
            }

            &.dropout.custom-dropout {
                position: relative;

                &.hide-drop {
                    .title {
                        span {
                            a {
                                span {
                                    i {
                                        display: none !important;
                                    }
                                }
                            }
                        }
                    }
                }

                &:hover {
                    .title {
                        a {
                            span {
                                color: #fff !important;
                            }
                        }
                    }
                }

                .title {
                    span {
                        display: block;

                        a {
                            width: 100%;
                            height: 100%;
                            position: relative;
                            height: 60px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-weight: 700;

                            span {


                                i {
                                    @include media(">tablet") {
                                        display: inline-block !important;
                                        margin-left: 5px;
                                    }
                                }
                            }

                            &:hover {
                                span {
                                    color: #fff !important;
                                }
                            }
                        }
                    }
                }

                .drop {
                    @include media(">tablet") {
                        padding: 15px 0px;
                    }

                    width: 100% !important;
                }

                &.hide-drop {
                    i {
                        display: none !important;
                        opacity: 0;
                    }


                    a {
                        i {
                            display: none !important;
                        }
                    }


                    .nav-dr-body {
                        display: none !important;
                    }
                }
            }

            &.dropout:hover {
                .drop {
                    display: block;
                    border-radius: 0px 0px 5px 5px;
                    overflow: hidden;
                    box-shadow: 0px 11px 11px #00000026;
                }
            }

            .drop {
                display: none;
                transition: max-height 0.3s ease-in-out;
                border-radius: 0px 0px 3px 3px;
                position: absolute;
                top: calc(100% - 0px);
                left: 0px;
                width: 100%;
                z-index: 15;
                background-color: #fff;

                @media screen and (min-width:1400px) {
                    width: 1370px;
                }

                @media screen and (max-width:1399px) {
                    @include media(">desktop") {
                        width: 1200px;
                    }

                    @include media("<=desktop", ">tablet") {
                        width: 940px;
                    }

                    @include media("<=tablet") {
                        width: 100%;
                    }
                }

                @include media(">tablet") {
                }

                &.multi {
                    ul.newres {
                        list-style: none;
                        padding: 10px 15px;
                        margin: 0px;

                        @include media(">tablet") {
                            position: relative;

                            &:before {
                                content: "";
                                display: block;

                                @include media(">desktop") {
                                    width: calc(100% - 30px);
                                }

                                position: absolute;
                                left: 50%;
                                top: 79px;
                                transform: translateX(-50%);
                                border-bottom: 1px solid #e1e1e1;
                            }

                            li.toplevel.first {
                                .subcats.open {
                                    box-shadow: 0px 11px 11px #00000026;
                                }
                            }
                        }



                        li.toplevel, li.brandlink {
                            float: left;
                            width: calc(100% / 9);
                            display: block;
                            margin-bottom: 30px;
                            cursor: pointer;

                            &.hov-active {
                                .cat--selector {
                                    &:before {
                                        transform: translateY(-100%);
                                    }
                                }
                            }

                            .subcats {
                                clear: both;

                                .sub-cat-col {
                                    width: 100%;
                                    float: left;
                                    text-align: left;
                                    max-width: 270px;
                                    padding-bottom: 15px;

                                    &.promo-col {
                                        float: right;
                                        text-align: right;

                                        a {
                                            display: inline-block;
                                            width: 100%;
                                            max-width: 340px;
                                        }
                                    }
                                }
                            }
                            /* 
                            &.open {
                                .subcats {
                                    max-height: 9999999999px;
                                }
                            }
                            */
                            &.shut {
                                .subcats {
                                    @include media("<=tablet") {
                                        max-height: 0px;
                                    }
                                }
                            }

                            .cat--selector {
                                position: relative;
                                font-weight: 700;
                                font-size: 1.0em;
                                text-transform: uppercase;
                                padding: 25px 0px;
                                cursor: pointer;
                                overflow: hidden;

                                @include media(">tablet") {
                                    min-height: 70px;
                                }

                                .cat-txt {
                                    @include media(">tablet") {
                                        max-height: 100%;
                                        max-width: 100%;
                                        display: block;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        width: 100%;
                                        transform: translate(-50%, -50%);
                                    }
                                }

                                i {
                                    display: none;
                                }

                                &:before {
                                    content: "";
                                    width: 100%;
                                    height: 5px;
                                    background-color: #333;
                                    position: absolute;
                                    left: 0px;
                                    top: 100%;
                                    transition: all 0.4s ease-out;
                                }
                            }
                            /*&.open {
                                .cat--selector {
                                    &:before {
                                        transform: translateY(-100%) !important;
                                    }
                                }
                            }*/
                            .subcats {
                                padding: 0px;
                                margin: 0px;
                                top: 100%;
                                left: 0px;
                                width: 100%;
                                overflow: hidden;



                                ul {
                                    list-style: none;
                                    padding: 0px;
                                    display: block;
                                    width: 100%;
                                }



                                @include media(">tablet") {
                                    display: none !important;

                                    .bd-c-1 {
                                        border: none !important;
                                    }

                                    .sub-cat-list {


                                        @include media(">desktop") {
                                            &.shop-list {
                                                margin: 0px -18px;
                                            }
                                        }

                                        @include media("<=desktop", ">tablet") {
                                            &.shop-list {
                                                margin: 0px -5px;
                                            }
                                        }

                                        .sub-item {
                                            width: 20%;
                                            float: left;
                                            text-align: left;

                                            a {
                                                padding: 10px 0px;
                                            }
                                        }




                                        li.shoplink {
                                            @include media(">desktop") {
                                                width: calc(100% / 8);
                                                float: left;
                                                text-align: left;
                                                padding: 0px 18px;
                                            }

                                            @include media("<=desktop", ">tablet") {
                                                width: calc(100% / 6);
                                                float: left;
                                                text-align: left;
                                                padding: 0px 5px;
                                            }

                                            a {
                                                padding: 0px;

                                                img {
                                                    margin-bottom: 10px;
                                                }
                                            }
                                        }
                                    }
                                }

                                display: none;


                                a {
                                    display: block;
                                    color: #333;
                                    margin-top: 10px;
                                }
                            }
                        }

                        @include media(">tablet") {
                            .toplevel.med {
                                width: 11%;
                            }

                            .toplevel.large {
                                width: 16%;
                            }

                            .toplevel.last {
                                width: 6%;
                            }
                        }
                    }
                }

                ul.newres.condensed {
                    display: none !important;

                    &:before {
                        display: none !important;
                    }
                }

                ul.newres { li.mobile--aboutus { display: none; } }

            }

            &.single {
                ul {
                    margin: 0px;
                    padding: 0px;
                    list-style: none;
                    display: flex;
                    flex-wrap: wrap;

                    li {
                        a {
                            display: block;
                            padding: 10px;
                            font-weight: bold;

                            img {
                                margin: 0 auto;
                            }
                        }

                        .shop-list {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    @include media('<=desktop', '>tablet') {

        .original-menu .nav-item.home--link {
            display: none !important;
        }

        .original-menu {
        }

        .cat--selector {
            font-size: 0.8em !important;
        }
    }

    @include media('<=tablet') {

        .home-link {
            display: none;
        }

        .shoplink {
            a {
                img {
                    display: none;
                }

                font-weight: 700;
            }
        }

        .original-menu {
            overflow: hidden;

            &.shut {
                height: 0px;
            }

            &.open {
                height: auto;
                padding-top: 10px;
                padding-bottom: 15px;
            }

            .nav-item {
                &.home {
                    display: block;
                    padding: 10px;
                    border-bottom: 1px solid #c3c3c3;
                }

                &.nodrop {
                    display: block;
                    padding: 10px;
                    border-bottom: 1px solid #c3c3c3;
                }

                &.home-link {
                    display: none !important;
                }

                &.custom-dropout {
                    .drop {
                        a {
                            padding: 10px;
                            display: block;
                        }
                    }
                }


                .title {
                    border-bottom: 1px solid #c3c3c3;

                    &.open {
                        i {
                            transform: scaleY(-1) translateY(-50%);
                        }
                    }

                    span:not(.inner) {
                        display: block;
                        padding: 10px 0px 10px 10px;
                        cursor: pointer;
                        position: relative;

                        a {
                            span {
                                padding: 0px;
                                color: #000;
                            }
                        }


                        i {
                            position: absolute;
                            top: 50%;
                            transform-origin: top;
                            transform: translateY(-50%);
                            right: 0px;
                            width: 40px;
                            height: 40px;
                            line-height: 40px;
                            text-align: center;
                        }
                    }
                }

                .inner {
                    overflow: hidden;
                    min-height: 0px !important;

                    &.shut {
                        height: 0;
                        min-height: 0px !important;
                    }

                    &.open {
                        height: auto;
                    }
                }

                &.hide-drop {
                    i {
                        display: none !important;
                        opacity: 0;
                    }

                    .nav-dr-body {
                        display: none !important;
                    }
                }

                a.menu-item--aboutus {
                    @include media('<=tablet') {
                        pointer-events: none;

                        &.toplevel { pointer-events: initial; }

                    }
                }
            }
        }

        .newres {
            padding: 0px;
            margin: 0px;

            .toplevel {
                display: block;



                .cat--selector {
                    position: relative;

                    a {
                        display: block;
                        width: calc(100% - 40px);
                    }

                    .cat-txt {
                        display: block;
                        padding: 10px;
                    }

                    i {
                        position: absolute;
                        width: 40px;
                        height: 40px;
                        right: 0px;
                        top: 0px;
                        line-height: 40px;
                        text-align: center;
                        cursor: pointer;
                    }
                }

                .btn-1 {
                    background-color: transparent;
                    font-weight: 500;
                    display: block;
                    padding: 10px;
                    border-bottom: 1px solid #c3c3c3;

                    i {
                        float: right;
                    }
                }

                &.mob-shut {
                    .subcats {
                        height: 0px;
                    }
                }

                &.mob-open {
                    .subcats {
                        height: auto;
                    }

                    .cat--selector {
                        i {
                            transform: rotate(180deg);
                        }
                    }
                }
            }

            .brandlink {
                a {
                    display: block;
                    background-color: transparent;
                    padding: 10px;
                    font-weight: 500;
                }
            }

            .subcats {
                overflow: hidden;





                ul {
                    padding: 0px;
                    border: none !important;

                    li {
                        a {
                            padding: 10px;
                            display: block;
                        }
                    }
                }

                .sub-cat-col {
                    .subcat-title {
                        display: block;
                        padding: 10px;
                        position: relative;

                        i {
                            width: 40px;
                            height: 40px;
                            line-height: 40px;
                            text-align: center;
                            position: absolute;
                            top: 0px;
                            right: 0px;
                            cursor: pointer;
                        }
                    }

                    overflow: hidden;

                    &.shut {
                        max-height: 40px;
                    }

                    &.open {
                        max-height: 9999999px;

                        .subcat-title {
                            i {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }
        }
    }
}

.shops-list {

    .shoptext {
        text-align: left;
    }

    @include media(">desktop") {
        .toplevel {
            width: calc(100% / 8);
            float: left;
        }
    }



    @include media("<=desktop", ">tablet") {
        width: 940px;

        .toplevel {
            width: calc(100% / 4);
            float: left;
        }
    }

    @include media("<=tablet") {
        width: 100%;
    }
}

@include media(">tablet") {
    .nav-dr-body .brand-btn {
        display: inline-block;
        width: 100%;
        padding: 10px;
    }

    .toplevel {
        &:first-child {
            .subcats {
                max-height: 9999999999px !important;
                display: flex !important;
                z-index: 1 !important;

                &.no-show {
                    height: 0px;
                    width: 0px;
                    overflow: hidden;
                }
            }



            .cat--selector {


                &.sink {
                    &:before {
                        transform: none !important;
                    }
                }
            }
        }
    }
}

.menu-section {
    &.with-search {
        @include media("<=tablet") {
            .top-search {
                position: absolute;
                width: calc(100% - 20px);
                top: 10px;
                left: 50%;
                transform: translateX(-50%);
                max-width: 720px;

                .search--form {
                    margin: 0px !important;
                }

                @include media("<=phone") {
                    top: 10px;
                }
            }
        }
    }
}

.menu-section {
    .nav-dr-title .outter .inner {
        padding: 0px;
    }



    .nav-item.only .title a {
        color: #000;
    }

    .nav-item.dropout.custom-dropout .title span a {
        span {
            color: #000;
        }
    }
}

.menu-section.dark-style {

    .nav-dr-title .outter .inner {
        color: #fff;
        padding: 0px;
    }



    .nav-item.only .title a {
        color: #fff;
    }

    .nav-item.dropout.custom-dropout .title span a {
        span {
            color: #fff;
        }
    }

    @include media("<=tablet") {

        i {
            color: #fff !important;
        }

        .top-menu .drop a {
            color: #fff;
        }

        .cat-txt {
            color: #fff;
        }

        .subcat-title {
            color: #fff;
        }

        .top-menu .drop a .shop-txt {
            color: #fff;
        }

        .top-menu .drop a {
            color: #fff;
        }
    }
}
