﻿.cms-banner {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.cms-spacer {
    padding-top: 15px;
}
@media (min-width: 1230px) {
    .cms-spacer {
        padding-top: 30px;
    }

    .cms-prods {
        .dw-lg-3 {
            width: 20% !important;
        }
    }
}

.cms-banner.mobile {
    aspect-ratio: 530/530;
}

.cms-banner.desktop {
    aspect-ratio: 1900/490;
}

.cms-banner.desktopfull {
    aspect-ratio: 1900/290;
}

.bar-1 {
    height: 10px;
    margin-bottom: 2px;
}

.bar-2 {
    height: 5px;
}
.about-section {

    .box:nth-child(even) {
        background-color: #dfdfdf;

        @include media("<tablet") {
            .section {
                display: flex;
                flex-direction: column-reverse;
            }
        }
    }

    .box {
        .container {
            max-width: 1200px;
        }

        @include media("<tablet") {
            .section {

                > div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                }
            }
        }
    }

    .column-box {
        .section {
            display: flex;
            flex-direction: column;
            align-items:center;
            justify-content:center;
            text-align:center;
        }
    }
}
