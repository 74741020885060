﻿.footer--container {
    padding-top: 90px;
    padding-bottom: 40px;
    color: #fff;

    a {
        color: #fff;
    }

    .footercall {
        color: #fff;

        strong {}

        a {
            font-weight: 700;
            text-decoration: none;
            color: #fff;
        }
    }

    .img {
        display: inline-block;
        max-width: 200px;
    }

    .smediabtns {
        display: inline-block;
        vertical-align: super;

        a {
            margin-left: 15px;
            color: #fff;
            font-size: 1.3em; 
            &.fa-linkedin{
                font-size: 1.5em; 
            }
        
            &.tik{
                width: 20px;
                display: inline-block;
            }

        }

    }

    .footercall {
        margin-top: 25px;
    }

    .pagelinks {
        color: #fff;
        font-size: 0.8em;
        font-weight: 100;

        @include media('<=phone') {
            font-size: 0.8em;
        }

        a {
            cursor: pointer;
            color: #fff;
            &:hover{
                text-decoration: underline;
            }

            @include media('<=phone') {
                font-weight: 100;
            }
        }
    }

    .poweredby {
        @include media('>phone') {
            float: right;
        }

        @include media('<=phone') {
            margin: 50px 0px 15px;
        }

        color: #fff;
        text-transform: uppercase;
        font-size: 0.75em;

        img {
            width: 96px;
            height: auto;
        }
    }

    .right-txt {


        @include media('>phone') {
            text-align: right;
        }

        @include media('<=phone') {
            margin-top: 15px;
        }
    }

    .footer-middle {


        @include media('>phone') {
            margin: 130px 0px 70px;
        }

        @include media('<=phone') {
            margin: 50px 0px 30px;
        }
    }

    .footer-right {
        @include media('<=phone') {
            padding-top: 50px;
        }

        .footer--link {
            display: inline-block;
            font-weight: 700;
            margin-bottom: 15px;
        }
    }
}

.bottom-letter-form {
    position: relative;

    &:before {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        left: 0px;
        top: 0px;
        bottom: 0px;
        right: 0px;
        background-color: #000;
        z-index: 5;
        opacity: 0.2;
    }

    .form-container {
        padding: 50px 0px;
        position: relative;
        z-index: 10;
        margin: 0 auto;
        text-align: center;

        .form {
            display: inline-block;

            span {
                max-width: 360px;
                color: #fff;
                font-weight: 700;
                display: inline-block;
                text-align: left;
                font-size: 1.4em;
                line-height: 23px;
                vertical-align: bottom;

                @include media("<=tablet") {
                    display: block;
                    text-align: center;
                    margin-bottom: 15px;
                }
            }

            input {
                height: 46px;
                border: none;
                padding: 15px;
                border-radius: 3px;
                margin-right: 15px;

                @include media(">l-desktop") {
                    width: 470px;
                }
            }

            .btn {
                height: 46px;
                border: none;
                border-radius: 3px;
                color: #fff;
                font-size: 1.0em;
                font-weight: 700;
                padding: 0px 20px;

                @include media(">l-desktop") {
                    width: 140px;
                }
            }
        }

        .social-links {
            display: inline-block;
            vertical-align: bottom;

            @include media("<=tablet") {
                margin-top: 15px;
                display: block;
            }

            .social-link {
                width: 46px;
                height: 46px;
                display: inline-block;
                background-color: #fff;
                border-radius: 100%;
                vertical-align: bottom;
                margin: 0px 10px;

                i {
                    font-size: 1.4em;
                    line-height: 46px;
                }
            }
        }
    }
}

.footer-style-2 {
    padding: 80px 0px 0px !important;
    @include media(">tablet"){
        .right{
            float: right;
            width: calc(100% - 475px);
        }
        .left{
            float: left;
            width: 475px;
        }
    }
    .foot-title {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.05em;

        .line {
            width: 30px;
            height: 2px;
            margin: 10px 0px 30px;
        }
    }

    .main-links {
        a {
            display: inline-block;
            clear: both;
            margin-bottom: 20px;
            font-weight: 700;
        }
    }

    .intouch{
        .row{
            margin-bottom: 30px;
            position: relative;
            padding-left: 40px;
            max-width: 290px;
            i{
                position: absolute;
                top: 0px;
                left: 0px;
                width: 30px;
                text-align: center;
                font-size: 1.2em;
            }
        }
    }

    .footer-middle{
        margin: 50px 0px 30px;
    }

    .footer-bottom{
        padding: 10px;
        font-weight: 200;
        font-size: 0.8em;
    }

    &.bg-c-1 {
        i { color: #fff; }
    }
}


.footer-style-3 {
    padding: 80px 0px 0px !important;

    @include media(">tablet") {
        .right {
            float: right;
        }

        .left {
            float: left;
        }
    }

    .foot-title {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.05em;

        .line {
            width: 30px;
            height: 2px;
            margin: 10px 0px 30px;
            background-color: #fff;
        }
    }

    .main-links {
        a {
            display: inline-block;
            margin-bottom: 20px;
            font-weight: 700;
        }
    }

    .intouch {
        .row {
            margin-bottom: 30px;
            position: relative;
            padding-left: 40px;
            max-width: 290px;

            i {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 30px;
                text-align: center;
                font-size: 1.2em;
            }
        }
    }

    .footer-middle {
        margin: 50px 0px 30px;
    }

    .footer-bottom {
        padding: 10px;
        font-weight: 200;
        font-size: 0.8em;
    }
}

.footer-style-4 {
    padding: 80px 0px 0px !important;

    @include media(">tablet") {
        .right {
            float: right;
        }

        .left {
            float: left;
        }
    }

    .brdr {
        position: relative;
        @include media(">desktop"){
            border-right: 2px solid #fff;
            border-left: 2px solid #fff;
            padding-left:30px;
            transform: translateX(-30px);
        }
    }

    .footer--link {
        font-weight: 400 !important;
        display: inline-block;
    }

    .foot-title {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.05em;

        .line {
            width: 30px;
            height: 2px;
            margin: 10px 0px 30px;
        }
    }

    .main-links {
        a {
            display: inline-block;
            margin-bottom: 20px;
            font-weight: 700;
        }
    }

    .intouch {
        .row {
            margin-bottom: 30px;
            position: relative;
            padding-left: 40px;
            max-width: 290px;

            i {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 30px;
                text-align: center;
                font-size: 1.2em;
            }
        }
    }

    .footer-middle {
        margin: 50px 0px 30px;
    }

    .footer-bottom {
        padding: 10px;
        font-weight: 200;
        font-size: 0.8em;
        .copyright{
            margin-right:15px;
        }
        .pagelinks{
            display: inline-block;
            font-size: 1em; 

        }
    }
}

.footer--link {
    position: relative;

    &:before {
        width: 0%;
        height: 2px;
        content: "";
        position: absolute;
        top: 20px;
        left: 0px;
        background-color: #fff;
        transition: width .2s;
    }

    &:hover {
        &:before {
            width: 100%;
        }
    }
}

.call-footer{
    text-align: center;
    padding: 45px 0px;
    .phone-round{
        width: 60px;
        height: 60px;
        display: inline-block;
        background-color: #fff;
        line-height: 60px;
        border-radius: 100%;
        font-size: 1.8em;
        margin: 0px 30px;
        @include media("<=tablet"){
            display: block;
            margin: 0 auto 30px;
        }
    }
    span{
        font-size: 1.9em;
        color: #fff;
    }
    strong {
        color:#fff;
    } 
    a{
        color: #fff;
    }
}

.main-links {
    a {
        position: relative;

        &:before {
            width: 0%;
            height: 2px;
            content: "";
            position: absolute;
            top: 20px;
            left: 0px;
            background-color: #fff;
            transition: width .2s;
        }

        &:hover {
            &:before {
                width: 100%;
            }
        }
    }
}

.payment-providers {
    .img {
        display: block;
        float: left;
        min-height: 37px;
        margin-right: 15px;
        margin-bottom: 15px;
        img{

            max-width: none;
        }
    }
}
.footer-middle {
    .toright {
        width: 100%;

        .payment-providers {
            @include media(">tablet") {
                text-align: right;
            }

            .img {
                max-width: none;
                float: none;
                display: inline-block;
                margin: 0px 0px 15px 15px !important;
            }
        }
    }
}
