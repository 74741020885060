﻿.masthead {
    .contact--info {
        text-align: center;

        a {
            font-weight: 700;
        }

        line-height: 20px;
        padding: 15px 0px;
    }

    .newsletter--cta {
        text-align: center;
        padding: 8px 0px;
        line-height: 30px;


        @include media('<=phone') {
            margin-bottom: 25px;
        }

        .newsletter--form {
            @include media('>phone') {
                span {
                    color: #fff;
                    font-weight: 700;
                }

                input {
                    line-height: 30px;
                    padding: 0px 0px 0px 10px;
                    border: 0px;
                    border-radius: 3px;
                    margin: 0px 10px;

                    @include media('>desktop') {
                        width: 260px;
                    }
                }

                button {
                    line-height: 30px;
                    border: 0px;
                    border-radius: 3px;
                    color: #fff;
                    font-weight: 700;
                    text-align: center;
                    width: 120px;
                }
            }

            @include media('<=phone') {
                display: none;
            }
        }
    }

    .contact--info {
    }

    .head--main {

        @include media('<=tablet') {
            position: relative;


            @include media('<=tablet', '>phone') {
                padding-bottom: 30px;
            }

            @include media('<=phone') {
                padding-bottom: 10px;
            }
        }

        .head-left {


            @include media('>tablet') {
                height: 140px;
                width: 270px;

                a {
                    display: inline-block;
                    position: absolute;
                    top: 50%;
                    left: 15px;
                    transform: translateY(-50%);
                    max-width: calc(100% - 30px);
                }
            }



            @include media('<=tablet','>phone') {
                width: 50%;
                float: left;
                padding-right: 0px;
            }

            @include media('<=phone') {
                width: calc(100% - 150px);
                float: left;
                padding-right: 0px;

                a {
                    display: block;
                    max-height: 40px;

                    img {
                        max-height: 70px;
                        width: auto;
                        transform: translateY(-20px);
                    }
                }
            }
        }

        .head-center {
            @media (min-width: 1230px) {
                width: calc(100% - 715px);
            }

            @include media('>tablet', '<=desktop') {
                width: 38%;
            }

            .search--form {
                position: relative;

                input.field {
                    height: 45px;
                    line-height: 45px;
                    border: 1px solid #C3C3C3;
                    display: block;
                    border-radius: 3px;
                    width: 100%;
                    padding: 0px 15px;
                }

                button.submit {
                    height: 100%;
                    width: 45px;
                    display: block;
                    background-color: transparent;
                    border: none;
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    cursor: pointer;
                    font-size: 1.4em;

                    &:hover {
                        color: #000;
                    }
                }
            }

            @include media('<=tablet') {
                position: absolute;
                z-index: 1;
                width: 100%;
            }
        }

        .head-right {

            @media (min-width: 1230px) {
                width: 460px;
            }


            @include media('>tablet', '<=desktop') {
                width: 35%;
            }


            @include media('>tablet') {
                margin: 0px -8px;



                .top--widget {
                    width: calc(100% / 3);
                    float: left;
                    padding: 0px 8px;



                    a {
                        display: block;
                        cursor: pointer;
                        z-index:1;

                        @include media('>desktop') {
                            .inner {
                                display: block;
                                text-align: right;
                                line-height: 45px;

                                i {
                                    color: #fff;
                                    width: 40px;
                                    height: 40px;
                                    line-height: 40px;
                                    text-align: center;
                                    border-radius: 100%;
                                    float: left;
                                    font-size: 1.3em;
                                }

                                span {
                                    display: block;
                                    line-height: 40px;
                                    width: calc(100% - 40px);
                                    float: left;
                                    color: #000;
                                    text-align: left;
                                    padding-left: 10px;
                                    
                                }
                            }
                        }

                        @include media('>tablet', '<=desktop') {
                            .inner {
                                display: block;
                                text-align: center;
                                line-height: 45px;

                                i {
                                    color: #fff;
                                    width: 40px;
                                    height: 40px;
                                    line-height: 40px;
                                    text-align: center;
                                    border-radius: 100%;
                                }

                                span {
                                    display: block;
                                    line-height: 40px;
                                    color: #000;
                                    text-align: center;
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }

                .contact-container {
                }


                .account-container {
                }

                .cart-container {
                }

                .navicon-container {
                    display: none;
                }
            }

            @include media('<=tablet') {
                padding-left: 0px;
                float: left;
                text-align: right;

                @include media('<=tablet','>phone') {
                    width: 50%;
                }

                @include media('<=phone') {
                    width: 150px;
                }

                

                .contact-container {
                    vertical-align: top;
                    width: 30px;
                    height: 30px;
                    display: inline-block;

                    @include media('<=phone') {
                        display: none;
                    }

                    .inner {
                        background-color: transparent !important;
                        font-size: 1.75em;

                        i {
                            background-color: transparent;
                        }

                        span {
                            display: none;
                        }
                    }
                }

                .account-container {
                    vertical-align: top;
                    width: 30px;
                    height: 30px;
                    display: inline-block;
                    margin-left: 10px;

                    .inner {
                        background-color: transparent !important;
                        font-size: 1.9em;

                        i {
                            background-color: transparent;
                        }

                        span {
                            display: none;
                        }
                    }
                }

                .cart-container {
                    margin-left: 10px;
                    vertical-align: top;
                    width: 30px;
                    height: 30px;
                    display: inline-block;

                    .inner {
                        font-size: 1.9em;
                        background-color: transparent !important;

                        i {
                            background-color: transparent;
                        }

                        span {
                            display: none;
                        }
                    }
                }

                .navicon-container {
                    padding-top: 6px;
                    vertical-align: top;
                    margin-left: 15px;
                    display: inline-block;
                    width: 30px;
                    height: 45px;



                    .menu-icon {
                        width: 200%;
                        height: 100%;
                        transition: all .5s ease-in-out 0s;
                        transform: rotate(0deg) scale(0.5);
                        position: relative;
                        cursor: pointer;
                        transform-origin: top left;

                        span {
                            display: block;
                            position: absolute;
                            height: 8px;
                            border-radius: 10px;
                            opacity: 1;
                            left: 0px;
                            transform: rotate(0deg);
                            transition: all 0.25s ease-in-out 0s;

                            &:nth-child(1) {
                                top: 0px;
                                width: 100%;
                                transform-origin: left center;
                            }

                            &:nth-child(2) {
                                top: 18px;
                                width: 100%;
                                transform-origin: left center;
                            }

                            &:nth-child(3) {
                                top: 36px;
                                width: 100%;
                                transform-origin: left center;
                            }
                        }

                        &.open {
                            span {
                                &:nth-child(1) {
                                    transform: rotate(45deg);
                                    top: -3px;
                                    left: 8px;
                                }

                                &:nth-child(2) {
                                    width: 0%;
                                    opacity: 0;
                                }

                                &:nth-child(3) {
                                    transform: rotate(-45deg);
                                    top: 39px;
                                    left: 8px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .newsletter--cta {
        .newsletter-form {
            @include media('<=phone') {
                display: none;
            }

            max-width: 825px;
            margin: 0 auto;

            .title {
                @include media('>tablet') {
                    width: 470px;
                }

                @include media('<=tablet') {
                    width: 100%;
                }

                float: left;
                padding: 0px 7px;

                span {
                    color: #fff;
                    font-weight: 700;
                    line-height: 30px;
                }
            }

            .field {
                @include media('>tablet') {
                    width: calc(100% - 600px);
                }

                @include media('<=tablet') {
                    width: calc(100% - 130px);
                }

                float: left;
                padding: 0px 7px;

                input {
                    width: 100%;
                    border-radius: 3px;
                    border: none;
                    line-height: 30px;
                    padding-left: 15px;
                }
            }

            .submit {
                width: 130px;
                float: left;
                padding: 0px 7px;

                .btn {
                    color: #fff;
                    font-weight: 700;
                    border: none;
                    display: block;
                    line-height: 30px;
                    width: 100%;
                    border-radius: 3px;
                }
            }
        }
    }
}
.search--form {
    position: relative;



    @include media('>tablet') {
        margin: 7px 0px;
    }

    @include media('<=tablet', '>phone') {
        margin: 7px 0px 25px;
    }

    @include media('<=phone') {
        margin: 15px 0px 15px;
    }


    input.field {
        height: 46px;
        line-height: 46px;
        border: 1px solid #C3C3C3;
        display: block;
        border-radius: 3px;
        width: 100%;
        padding: 0px 15px;
    }

    button.submit {
        height: 100%;
        width: 45px;
        display: block;
        background-color: transparent;
        border: none;
        position: absolute;
        top: 0px;
        right: 0px;
        cursor: pointer;
        font-size: 1.4em;

        &:hover {
            color: #000;
        }
    }
}

.vat-switch {
    width: 165px;
    height: 30px;
    display: inline-block;
    position: relative;
    transform: none;

    .inner-rel{
        min-height: 30px;
    }

    .tag {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-weight: 700;

        &.inc {
            left: 0px;
        }

        &.ex {
            right: 0px;
        }
    }

    .switch {
        height: 20px;
        width: 34px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 70px;
        background-color: rgb(255, 255, 255);
        border-radius: 20px;
        cursor: pointer;

        .inner-rel {
            width: 100%;
            height: 100%;
            position: relative;
            min-height: auto;

            .switchbg {
                height: 15px;
                width: 30px;
                border-radius: 20px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                position: absolute;
            }

            .indicator {
                width: 11px;
                height: 11px;
                border-radius: 100%;
                position: absolute;
                top: 50%;
                transition: all 0.2s ease-in-out 0s;
            }
        }
    }

    &.exvat .switch .inner-rel .indicator {
        transform: translate(19px, -50%);
    }

    &.incvat .switch .inner-rel .indicator {
        transform: translate(4px, -50%);
    }


    &.exvat .tag.ex {
    }

    &.incvat .tag.inc {
    }
}

.currency-sel {

    

    @include media (">phone") {
        margin-left: 30px;
    }

    display: inline-block;

    .title {
        float: left;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 30px;
        color: #333;

        @include media(">xs-phone") {
            &.xs {
                display: none;
            }
        }

        @include media("<=xs-phone") {
            &.lg {
                display: none;
            }
        }
    }

    .select {
        float: left;
        margin-left: 15px;
        position: relative;

        .top {
            width: 90px;
            border: 1px solid #dbdbdb;
            position: relative;
            border-radius: 3px;
            height: 30px;
            padding: 5px 5px 5px 30px;

            .map {
                width: 23px;
                height: 23px;
                border-radius: 100%;
                display: inline-block;
                position: absolute;
                top: 50%;
                left: 3px;
                transform: translateY(-50%);
            }

            .curr {
                font-weight: 700;
                display: block;
                line-height: 20px;
                vertical-align: top;
                font-size: 0.9em;
                text-align: left;
            }

            i {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 5px;
            }
        }

        .list {
            width: 100%;
            position: absolute;
            top: 100%;
            border: 1px solid #dbdbdb;
            z-index: 100;
            background-color: #fff;

            .row {
                cursor: pointer;
                position: relative;
                padding: 5px 5px 5px 30px;

                .map {
                    width: 23px;
                    height: 23px;
                    border-radius: 100%;
                    display: inline-block;
                    position: absolute;
                    top: 50%;
                    left: 3px;
                    transform: translateY(-50%);
                }

                .curr {
                    font-weight: 700;
                    display: block;
                    line-height: 20px;
                    vertical-align: top;
                    font-size: 0.9em;
                    text-align: left;
                }

                &:hover {
                    background-color: #dbdbdb;
                }
            }
        }
    }
}

@include media("<=tablet") {
    .head-center {
        bottom: -55px;
    }

    .top-menu {
   

        .nav-item:first-child {
            margin-top: 50px;
        }
    }
}

.head-style-1 {


    .topbask.inner {
        position: relative;
        height: 40px;

        .items-circle {
            width: 25px !important;
            height: 25px;
            display: block;
            padding: 0px !important;
            line-height: 25px !important;
            color: #fff !important;
            text-align: center !important;
            border-radius: 100%;
            position: absolute;
            top: 0px;
            left: 25px;
            transform: translateY(-50%);
        }
    }
    @include media(">desktop") {
        &.square-logo-style {
            .masthead {
                padding-bottom: 15px;
            }

            .masthead .head-left a {
                top: calc(50% - 25px) !important;
            }

            .top-controls {
                margin-top: 30px;
            }
        }
    }

    @include media("<=desktop", ">tablet") {


        &.square-logo-style {
            .masthead {
                padding-bottom: 15px;
            }

            .masthead .head-left a {
                top: calc(50% - 25px) !important;
            }

            .top-controls {
                margin-top: 30px;
            }
        }
    }

    @include media(">tablet") {
        .head-left {
            height: 80px !important;

            a {
                top: 26% !important;
            }
        }
    }

    @include media("<=tablet") {
        .account-container {
            padding: 0px !important;
            margin-bottom: 0px;
        }

        .head-left {
            a {
                display: block;
                height: 80px;

                img {
                    max-height: 100%;
                    width: auto;
                }
            }
        }
    }

    @include media("<=desktop", ">tablet") {
        .topbask.inner {


            .items-circle {
                left: 48px;
            }
        }

        .masthead {
            padding-bottom: 15px;
        }

        .account-container {
            margin-bottom: 0px !important;
        }
    }

    @include media("<=tablet") {
        .topbask.inner {


            .items-circle {
                left: 18px;
                font-size: 0.5em;


                display: block !important;
            }
        }
    }

    @include media("<=tablet", ">phone") {



        .head-right {
            padding-top: 15px;
        }
    }

    @include media(">l-desktop") {
        .news-form {
            float: right;
        }

        .contact-info {
            float: left;
            color: #fff;

            a {
                color: #fff;
            }
        }
    }

    @include media("<=l-desktop") {



        .contact-info {
            display: none;
        }
    }



    .search--form {
        margin: 0px;
        width: 100%;

        @include media(">desktop") {
            float: right;
        }

        @include media(">tablet") {
            max-width: 530px;
        }
    }

    .top-controls {
        margin: 15px 0px;
        text-align: center;
    }


    #search {
        top: calc(100% + 10px) !important;

        @include media(">l-desktop") {
            width: 1400px;
            left: 64% !important;
        }

        @include media("<=l-desktop", ">desktop") {
            left: 69% !important;
            width: 1233px;
        }

        @include media("<=desktop", ">tablet") {
            left: 58% !important;
        }

        @include media("<=tablet", ">phone") {
        }

        @include media("<=phone", ">sm-phone") {
        }

        @include media("<=sm-phone", ">xs-phone") {
        }
    }
}
.head-style-2 {
    .topbask.inner {
        position: relative;
        height: 40px;

        .items-circle {
            width: 25px !important;
            height: 25px;
            display: block;
            padding: 0px !important;
            line-height: 25px !important;
            color: #fff !important;
            text-align: center !important;
            border-radius: 100%;
            position: absolute;
            top: 0px;
            left: 50px;
            transform: translateY(-50%);
        }
    }

    @include media(">desktop") {
        &.square-logo-style {
            .masthead {
                padding-bottom: 15px;
            }

            .masthead .head-left a {
                top: calc(50% - 8px) !important;
            }

            .top-controls {
                margin-bottom: 60px;
            }
        }
    }

    @include media("<=desktop", ">tablet") {
        &.square-logo-style {
            .masthead {
                padding-bottom: 15px;
            }

            .masthead .head-left a {
                top: calc(50% - 8px) !important;
            }

            .top-controls {
                margin-bottom: 60px;
            }
        }
    }

    @include media("<=tablet", ">phone") {
        &.square-logo-style {
            .masthead {
                padding-bottom: 15px;
            }

            .masthead {
                top: calc(50% - 8px) !important;

                .head-left {
                    height: 150px !important;
                    transform: translateY(-40px);

                    a {
                        height: 150px;
                        max-height: 150%;

                        img {
                            height: 150px;
                            max-height: 150px;
                        }
                    }
                }

                .head--main {
                    padding-bottom: 0px;
                    height: 110px;
                }
            }

            .top-controls {
                margin-bottom: 60px;
            }
        }
    }

    @include media("<=desktop", ">tablet") {
        .masthead {
            padding-bottom: 15px;
        }
    }

    @include media("<=tablet", ">phone") {
        .head-right {
            padding-top: 15px;
        }
    }

    .top-bar {
        height: 5px;
    }

    .masthead .head--main .head-right .top--widget a {
        font-weight: 700;
    }


    @include media(">desktop") {
        .head-left {
            height: 45px !important;
        }
    }

    @include media("<=desktop", ">tablet") {
        .head-left {
            height: 80px !important;
        }

        .head--main {
            height: 100px;
        }

        .topbask.inner {


            .items-circle {
               
                left: 50px !important;
            }
        }
    }

    @include media("<=tablet") {
        .head-left {
            height: 80px !important;

            a {
                max-height: 100%;
                display: block;

                img {
                    max-height: 80px;
                    width: auto;
                }
            }
        }

        .account-container {
            padding: 0px;
        }


        .topbask.inner {


            .items-circle {
                display: block !important;
                font-size: 0.5em;
                left: 18px;
            }
        }
    }

    @include media(">tablet") {
        .original-menu {
            padding-top: 0px !important;
        }
    }



    .top-controls {


        .left {
            float: left;

            @include media("<=tablet") {
                display: none;
            }
        }

        .right {
            float: right;

            @include media("<=phone") {
                float: none;
                width: 100%;
            }
        }



        @include media(">phone") {
            margin-bottom: 35px;
        }


        @include media("<=phone") {
            margin-bottom: 20px;
        }

        .contact-info {
            margin-top: 5px;

            @include media("<=phone") {
                float: left;
            }
        }

        .currency-sel {
            padding-left: 10px;

            @include media("<=phone") {
                float: right;
            }

            .title {
                color: #fff;
                font-size: 0.9em;
            }

            .top {
                border: none;

                .map {
                    border: 1px solid #fff !important;
                }

                .curr {
                    color: #fff;
                }

                i {
                    color: #fff;
                    font-size: 0.9em;
                }
            }
        }
    }
}
.head-style-3 {
    .items-circle {
        width: 25px;
        height: 25px;
        display: block;
        padding: 0px;
        line-height: 25px;
        color: #fff;
        text-align: center;
        border-radius: 100%;
        position: absolute;
        top: 0px;
        right: 0px;
        transform: translate(50%, -50%);
    }

    @include media(">desktop") {
        &.square-logo-style {
            .masthead {
                padding-bottom: 35px;
            }

            .masthead .head-left a {
                top: calc(50% - 20px) !important;
                height: 180px;
            }

            .top-controls {
                margin-bottom: 75px;
            }
        }
    }



    @include media("<=desktop", ">tablet") {
        &.square-logo-style {
            .masthead {
                padding-bottom: 15px;
            }

            .masthead .head-left a {
                top: calc(50% - 20px) !important;
                height: 140px;
            }

            .top-controls {
                margin-bottom: 60px;
            }
        }
    }

    @include media("<=tablet", ">phone") {
        &.square-logo-style {
            .head-left {
                position: relative;
                height: 80px;

                a {
                    height: 130px;
                    position: absolute;
                    left: 0px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }

    @include media(">tablet") {
        .head-left {
            height: 80px !important;

            a {
                height: 100px;
                top: 40% !important;

                img {
                    max-height: 100%;
                    width: auto;
                }
            }
        }
    }

    @include media("<=tablet") {
        .account-container {
            padding: 0px !important;
            margin-bottom: 0px;
        }

        .head-left {
            a {
                display: block;
                height: 80px;

                img {
                    max-height: 100%;
                    width: auto;
                }
            }
        }

        @include media("<=phone") {
            .head-right {
                transform: translateY(-10px);
            }
        }
    }

    .account-container {
        margin: 0px;
    }

    @include media("<=tablet", ">phone") {
        .head-right {
            padding-top: 15px;
        }
    }

    @include media("<=tablet") {

        .navicon-container {
            padding-top: 12px !important;
        }

        .menu-icon {
            .bg-c-1 {
                background-color: #fff !important;
            }
        }



        .masthead .head--main .head-right {
            .account-container {
                height: 45px;
            }

            .cart-container {
                height: 45px;

                a {
                    height: 45px;
                    display: block;

                    .inner {
                        i {
                            line-height: 45px;
                        }
                    }
                }
            }
        }
    }

    .search--form {
        margin: 0px;
    }

    .masthead .head--main .head-right .top--widget a {
        font-weight: 700;
    }

    .top-controls {
        position: relative;

        .vat-switch .tag {
            &.off {
                color: #fff;
            }
        }

        .inner-context {
            position: relative;
            z-index: 10;
            height: 30px;
        }

        .bg-overlay {
            position: absolute;
            top: 0px;
            left: 0px;
            height: 100%;
            width: 100%;
            background-color: #000;
            opacity: 0.3;
            z-index: 5;
        }


        .left {
            float: left;

            @include media("<=tablet") {
                display: none;
            }
        }

        .right {
            float: right;

            .map {
                background-color: #fff !important;
            }
        }

        margin-bottom: 35px;

        .contact-info {
            margin-top: 5px;
        }

        .currency-sel {
            padding-left: 10px;

            .title {
                color: #fff;
                font-size: 0.9em;
            }

            .top {
                border: none;

                .curr {
                    color: #fff;
                }

                i {
                    color: #fff;
                    font-size: 0.9em;
                }
            }
        }
    }

    .contact-info {
        font-size: 0.9em;
        color: #fff !important;

        a {
            color: #fff;
        }
    }



    .top--widget {


        @include media(">tablet") {
            width: 50% !important;
        }

        @include media("<=tablet") {
            i {
                color: #fff !important;
            }
        }

        .cart {
            @include media (">tablet") {
                background-color: #fff;
                position: relative;
                border-radius: 3px;

                .account {
                    display: inline-block;
                }

                .inner {
                    position: relative;
                    z-index: 7;
                    display: block;
                    height: 45px;
                    text-align: center !important;

                    span {
                        line-height: 45px !important;
                        color: #fff !important;
                        float: none !important;
                        display: inline-block !important;
                        width: auto !important;
                        vertical-align: top;
                    }

                    i {
                        line-height: 45px !important;
                        float: none !important;
                        display: inline-block;
                        width: auto !important;
                        vertical-align: top;
                    }
                }
            }
        }

        a.bg-c-1 {
            position: relative;
            border-radius: 3px;
            overflow: hidden;

            .inner {
                position: relative;
                z-index: 10;
                display: block;
                height: 45px;
                text-align: center !important;

                span {
                    line-height: 45px !important;
                    color: #fff !important;
                    float: none !important;

                    @include media(">tablet") {
                        display: inline-block !important;
                    }

                    width: auto !important;
                    vertical-align: top;
                }

                i {
                    line-height: 45px !important;
                    float: none !important;
                    display: inline-block;
                    width: auto !important;
                    vertical-align: top;
                }
            }

            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                background-color: #000;
                opacity: 0.3;
                z-index: 5;
            }
        }

        a {
            transition: transform 0.1s;

            &:hover {
                box-shadow: 0px 11px 11px #00000026;
                transform: translateY(-5px);
            }
        }
    }

    #search {
        top: calc(100% + 10px) !important;

        @include media(">l-desktop") {
            width: 1400px;
            left: 64% !important;
        }

        @include media("<=l-desktop", ">desktop") {
            left: 69% !important;
            width: 1233px;
        }

        @include media("<=desktop", ">tablet") {
            left: 58% !important;
        }

        @include media("<=tablet", ">phone") {
        }

        @include media("<=phone", ">sm-phone") {
        }

        @include media("<=sm-phone", ">xs-phone") {
        }
    }
}
.head-style-4 {



    @include media(">desktop") {
        &.square-logo-style {
            .masthead .head-left a {
                top: calc(50% - 10px);
            }

            .masthead {
                padding-bottom: 15px !important;
            }
        }
    }


    @include media("<=desktop", ">tablet") {
        &.square-logo-style {
            .masthead {
                padding-bottom: 15px;
            }

            .masthead .head-left a {
                top: calc(50% - 30px) !important;
                height: 140px;
            }

            .top-controls {
                margin-bottom: 60px;
            }
        }
    }

    @include media("<=tablet", ">phone") {
        &.square-logo-style {
            .top-controls {
                margin-bottom: 20px;
            }

            .head-left {
                position: relative;
                height: 80px;

                a {
                    height: 130px;
                    position: absolute !important;
                    left: 0px;
                    top: 50% !important;
                    transform: translateY(-50%) !important;
                }
            }
        }
    }

    @include media(">tablet", "<=desktop") {
        .head-left {
            height: 80px !important;

            a {
                height: 100px;
                top: 40% !important;

                img {
                    max-height: 100%;
                    width: auto;
                }
            }
        }
    }

    @include media("<=tablet") {
        .account-container {
            padding: 0px !important;
            margin-bottom: 0px;
            margin-top: 5px !important;
        }

        .head-left {
            a {
                display: block;
                height: 80px;

                img {
                    max-height: 100%;
                    width: auto;
                }
            }
        }

        @include media("<=phone") {
            .head-right {
                transform: translateY(-10px);
            }
        }
    }

    .account-container {
        margin: 0px;
    }

    @include media("<=tablet", ">phone") {
        .head-right {
            padding-top: 5px;
        }
    }


    .masthead {
        padding-top: 30px;
        padding-bottom: 0px !important;

        .head-left {
            a {
                top: 50%;
            }
        }
    }

    @include media("<=desktop", ">tablet") {
        .head-left {
            height: 45px;
            position: relative;
        }
    }

    .top-controls {
        margin-bottom: 15px;
    }

    .search--form {
        margin: 0px;
    }

    .masthead .head--main .head-right .top--widget a {
        font-weight: 700;
    }

    .contact-info {
        text-align: center;
        margin-bottom: 15px;
        font-size: 1.3em;
        line-height: 30px;

        @include media("<=l-desktop", ">desktop") {
            font-size: 1.0em;
        }
    }

    .top--widget {
        width: 50% !important;

        .cart {
            @include media (">tablet") {
                position: relative;
                border-radius: 3px;
            

                .inner {
                    position: relative;
                    z-index: 10;
                    display: block;
                    height: 45px;
                    text-align: center !important;



                    span {
                        line-height: 45px !important;
                        color: #fff !important;
                        display: inline-block !important;
                        width: auto !important;
                        float: none !important;
                        vertical-align: top;
                    }

                    i {
                        line-height: 45px !important;
                        display: inline-block !important;
                        width: auto !important;
                        float: none !important;
                    }
                }
            }
        }

        a.account {
            position: relative;
            border-radius: 3px;
            overflow: hidden;
            background-color: #36373A;

            .inner {
                position: relative;
                z-index: 10;
                display: block;
                height: 45px;
                text-align: center !important;
                text-align: center !important;



                span {
                    line-height: 45px !important;
                    color: #fff !important;
                    display: inline-block !important;
                    width: auto !important;
                    float: none !important;
                    vertical-align: top;
                }

                i {
                    line-height: 45px !important;
                    display: inline-block !important;
                    width: auto !important;
                    float: none !important;
                }
            }
        }

        a {
            transition: transform 0.1s;

            &:hover {
                box-shadow: 0px 11px 11px #00000026;
                transform: translateY(-5px);
            }
        }
    }


    .topbask.inner {
        position: relative;
        height: 40px;

        .items-circle {
            width: 25px !important;
            height: 25px;
            display: block;
            padding: 0px !important;
            line-height: 25px !important;
            color: #fff !important;
            text-align: center !important;
            border-radius: 100%;
            position: absolute;
            top: 0px;
            right: 0px;
            transform: translate(50%, -50%);
        @include media("<=tablet")
        {
            font-size: 0.5em;
            top: 8px;
        }

            
            }
    }



    .vat-switch {
        width: 50%;
        float: left;
        height: 30px;
        padding: 0px 8px;
        position: relative;
        transform: none;

        @include media("<=xs-phone") {
            width: calc(100% - 125px) !important;
        }

        .inner-rel {
            width: 100%;
            height: 30px;
            position: relative;
            max-width: 167px;
            margin: 0 auto;
        }

        .tag {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-weight: 700;
            font-size: 0.9em;

            &.inc {
                left: 0px;
            }

            &.ex {
                right: 0px;
            }
        }

        .switch {
            height: 20px;
            width: 34px;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            left: 50%;
            background-color: rgb(255, 255, 255);
            border-radius: 20px;
            cursor: pointer;

            .inner-rel {
                width: 100%;
                height: 100%;
                position: relative;

                .switchbg {
                    height: 15px;
                    width: 30px;
                    border-radius: 20px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    position: absolute;
                }

                .indicator {
                    width: 11px;
                    height: 11px;
                    border-radius: 100%;
                    position: absolute;
                    top: 50%;
                    transition: all 0.2s ease-in-out 0s;
                }
            }
        }

        &.exvat .switch .inner-rel .indicator {
            transform: translate(19px, -50%);
        }

        &.incvat .switch .inner-rel .indicator {
            transform: translate(4px, -50%);
        }


        &.exvat .tag.ex {
        }

        &.incvat .tag.inc {
        }
    }

    .currency-sel {
        padding: 0px 8px;
        width: 50%;
        float: right;
        margin: 0px;



        .inner {
            background-color: #dbdbdb;
            border-radius: 3px;
        }

        .title {
            float: left;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 30px;
            color: #333;
            padding: 0px 0px 0px 8px;
            font-size: 0.9em;
        }

        @include media("<=xs-phone") {
            width: 125px !important;

            .title {
                font-size: 1.1em;
            }
        }

        .select {
            float: right;
            position: relative;

            .top {
                width: 85px;
                border: none;
                position: relative;
                border-radius: 3px;
                height: 30px;
                padding: 5px 5px 5px 30px;

                .map {
                    width: 23px;
                    height: 23px;
                    border-radius: 100%;
                    display: inline-block;
                    position: absolute;
                    top: 50%;
                    left: 3px;
                    transform: translateY(-50%);
                }

                .curr {
                    font-weight: 700;
                    display: block;
                    line-height: 20px;
                    vertical-align: top;
                    font-size: 0.9em;
                    text-align: left;
                }

                i {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 5px;
                }
            }

            .list {
                width: 100%;
                position: absolute;
                top: 100%;
                border: 1px solid #dbdbdb;
                z-index: 100;
                background-color: #fff;

                .row {
                    cursor: pointer;
                    position: relative;
                    padding: 5px 5px 5px 30px;

                    .map {
                        width: 23px;
                        height: 23px;
                        border-radius: 100%;
                        display: inline-block;
                        position: absolute;
                        top: 50%;
                        left: 3px;
                        transform: translateY(-50%);
                    }

                    .curr {
                        font-weight: 700;
                        display: block;
                        line-height: 20px;
                        vertical-align: top;
                        font-size: 0.9em;
                        text-align: left;
                    }

                    &:hover {
                        background-color: #dbdbdb;
                    }
                }
            }
        }
    }

    @include media("<=desktop", ">tablet") {
        position: relative;
        padding-top: 40px;
        max-width: 970px;
        margin: 0 auto;

        .top-controls {
            position: absolute;
            width: 430px;
            top: 0px;
            right: 15px;
        }

        .contact-info {
            display: none;
        }

        .dw-md-4 {
            position: static;

            &.head-left {
                position: relative;

                a {
                    display: block;
                    position: absolute;
                    left: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }

    @include media("<=phone") {
        .top-controls {
            position: absolute;
            top: -30px !important;
        }
    }

    @include media("<=tablet") {
        position: relative;
        padding-top: 40px;
        max-width: 970px;
        margin: 0 auto;

        .top-controls {
            position: absolute;
            top: 0px;

            @include media("<=tablet", ">phone") {
                right: 50%;
                transform: translateX(50%);
            }

            @include media("<=phone") {
                right: 0px;

                .currency-sel {
                    .inner {
                        border-radius: 0px 0px 0px 3px;
                    }

                    padding-right: 0px;
                }
            }

            @include media(">sm-phone") {
                width: 430px;
            }

            @include media("<=sm-phone") {
                width: 100vw;
                font-size: 0.9em;

                .vat-switch {
                    width: 50%;
                }

                .currency-sel {
                    width: 50%;
                }
            }
        }

        .contact-info {
            display: none;
        }

        .dw-md-4 {
            position: static;
        }

        .masthead {
            padding-top: 0px;
        }

        .head--main {
            position: static !important;
        }

        .head-left a {
            position: static !important;
            transform: none !important;
        }

        .navicon-container {
            padding-top: 17px !important;
        }

        .cart-container, .account-container {
            padding-top: 6px;
            vertical-align: top;
            margin-left: 15px;
            display: inline-block;
            width: 30px !important;
            height: 45px;

            a {
                display: block;
                background-color: transparent !important;
                box-shadow: none !important;
                transform: none !important;

                i {
                    line-height: 45px;
                }
            }
        }

        .cart {
            background-color: transparent !important;
        }

        .head-center {
            left: 50%;
            transform: translateX(-50%);
            padding: 0px;
            max-width: 720px;
            width: calc(100% - 20px) !important;
        }
    }

    @include media("<=phone") {
        .head-left {
            padding-top: 10px;
        }
    }

    #search {
        top: calc(100% + 10px) !important;

        @include media(">l-desktop") {
            width: 1400px;
            left: 64% !important;
        }

        @include media("<=l-desktop", ">desktop") {
            left: 69% !important;
            width: 1233px;
        }

        @include media("<=desktop", ">tablet") {
            left: 58% !important;
        }

        @include media("<=tablet", ">phone") {
        }

        @include media("<=phone", ">sm-phone") {
        }

        @include media("<=sm-phone", ">xs-phone") {
        }
    }
}


.top-search {
    position: relative;

    .spinner {
        position: absolute;
        width: 40px;
        height: 40px;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        background-color: #fff;

        i {
            width: 100%;
            line-height: 40px;
            text-align: center;
            font-size: 1.6em;
            animation-name: spin;
            animation-duration: 2000ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
    }
}


.menu-section {
    #search {
        top: calc(100% + 10px) !important;

        @include media(">l-desktop") {
            width: 1370px;
            left: auto !important;
            right: 0px;
            transform: none !important;

            .container:before {
                right: 100px !important;
                left: auto !important;
            }
        }

        @include media("<=l-desktop", ">desktop") {
            left: auto !important;
            right: -15px;
            transform: none !important;

            .container:before {
                right: 100px !important;
                left: auto !important;
            }

            width: 1233px;
        }

        @include media("<=desktop", ">tablet") {
            left: auto !important;
            right: 0px;
            transform: none !important;

            .container:before {
                right: 100px !important;
                left: auto !important;
            }

            width: 972px;
        }

        @include media("<=tablet", ">phone") {
        }

        @include media("<=phone", ">sm-phone") {
        }

        @include media("<=sm-phone", ">xs-phone") {
        }
    }
}

.top-search #search {
    position: absolute;
    border: 1px solid rgb(214, 214, 214);
    left: 50%;
    transform: translateX(-50%);
    top: calc(100% - 1px);
    z-index: 20;
    border-radius: 3px;

    



}

.top-search #search .container {
    background-color: rgb(255, 255, 255);
    position: relative;
    max-width: 100%;
}

.top-search #search .container::before {
    content: "";
    width: 30px;
    height: 30px;
    left: 50%;
    top: 0px;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(214, 214, 214);
    position: absolute;
    z-index: -1;
}

.top-search #search .container .image-result {
    position: relative;
    display: block;


    @include media(">sm-phone") {
        margin-bottom: 30px;
    }

    @include media("<=sm-phone") {
        margin-bottom: 10px;
    }
}

.top-search #search .container .image-result .img-container {
    position: relative;
    width: 100px;
    height: 100px;
    border: 1px solid rgb(241, 241, 241);
}

.top-search #search .container .image-result .title {
    font-weight: 700;
}






.top-search #search .container .image-result .title {
    width: calc(100% - 120px);
    position: absolute;
    top: 10px;
    right: 0px;
}

.top-search #search .container .image-result .price {
    width: calc(100% - 120px);
    position: absolute;
    bottom: 0px;
    right: 0px;
    color: #000;
}

.top-search #search .section.rel {
    position: relative;
    overflow: hidden;
}

.top-search #search .section.rel .result-pages {
    position: absolute;
    bottom: 0px;
    left: 0px;
    border-top: 1px solid rgb(214, 214, 214);
}

.top-search #search .section.rel .result-pages .inner-rel {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 15px;
}

.top-search #search .section.rel .result-pages .inner-rel strong {
    color: rgb(0, 0, 0);
}

.top-search #search .section.rel .result-pages .inner-rel::after {
    position: absolute;
    content: "";
    width: 1px;
    height: 10000vh;
    background-color: rgb(214, 214, 214);
    left: calc(100% + 15px);
    bottom: -20px;
}

.top-search #search .main-results, .top-search #search .result-categories {
    padding: 30px;
}

.top-search #search .category-container {
    overflow-y: auto;

    @include media(">l-desktop") {
        max-height: 400px;
    }

    @include media("<=l-desktop", ">tablet") {
        max-height: 650px;
    }

    @include media("<=sm-phone") {
        max-height: 100px;
    }
}

.top-search #search .section-title {

    font-size: 1.4em;
    margin-bottom: 25px;
    display: block;
}

.top-search #search .result-categories a {
    font-size: 1.3em;
    display: inline-block;
    margin-bottom: 15px;
    cursor: pointer;
    color: #000;
}




#search .result-pages .inner-rel .search-paging {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.2em;
}



#search .result-pages .inner-rel .search-paging .searchpage {
    margin: 0px 4px;
    cursor: pointer;
}

#search .result-pages .inner-rel .search-paging .searchpage.disabled {
    color: rgb(175, 175, 175);
}

#search .main-results {
    @include media(">tablet") {
        min-height: 496px;
    }
}

#search .main-results.no-results {
    min-height: 250px;
}

#search .result-pages {
    min-height: 47px;
}

@include media(">tablet") {
    .top-search {
        display: block !important;
    }
}

@include media("<=tablet") {

    .top-search {
        display: none;

        .category-container {
            max-height: 150px ;
            background: linear-gradient(white 30%, rgba(255,255,255,0)), linear-gradient(rgba(255,255,255,0), white 70%) 0 100%, radial-gradient(50% 0, farthest-side, rgba(0,0,0,.2), rgba(0,0,0,0)), radial-gradient(50% 100%,farthest-side, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
            background: linear-gradient(white 30%, rgba(255,255,255,0)), linear-gradient(rgba(255,255,255,0), white 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)), radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
            background-repeat: no-repeat;
            background-color: white;
            background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
            background-attachment: local, local, scroll, scroll;
        }
    }

    .top-search #search {
        width: calc(100% - 30px);

        .product-results {
            max-height: 400px;
            overflow-y: auto;
            height: calc(100vh - 510px);
            background: linear-gradient(white 30%, rgba(255,255,255,0)), linear-gradient(rgba(255,255,255,0), white 70%) 0 100%, radial-gradient(50% 0, farthest-side, rgba(0,0,0,.2), rgba(0,0,0,0)), radial-gradient(50% 100%,farthest-side, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
            background: linear-gradient(white 30%, rgba(255,255,255,0)), linear-gradient(rgba(255,255,255,0), white 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)), radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
            background-repeat: no-repeat;
            background-color: white;
            background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
            background-attachment: local, local, scroll, scroll;
        }
    }



    .top-search #search .main-results {
        padding: 15px;
    }

    .top-search #search .result-categories {
        padding: 0px 15px 0px 15px;
        @include media("<=sm-phone"){
            a {font-size: 5vw; margin-bottom: 10px;}
        }
    }

    .top-search #search .result-pages {
        position: static !important;
        margin-top: 15px;

        .inner-rel {
            padding: 10px 0px !important;
        }

        @include media("<=sm-phone") {
            text-align: center;
            .search-paging {
                position: static;
                transform: none;
                font-size: 4vw;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 5px;

                .searchpage {
                    margin: 0px;
                    min-width: 20px;
                    text-align: center;
                }
            }
        }
    }
}


