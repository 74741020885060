﻿.home--slider {
    background-color: #333;
    max-width: 1900px;
    margin: 0px auto;
    .bx-controls-direction {
        display: none !important; 
    }
}

.container-banner{
    max-width:1900px;
    margin: 0 auto;
}

.noslide-desk {
    @include media('>desktop') {
        .bx-controls {
            display: none;
        }


        .category--slider {
            width: 100% !important;
            text-align: center !important;
            left: 0px !important;

            .dw-md-3 {
                display: inline-block ;
                float: none !important;
            }
        }

        .bx-clone {
            display: none !important;
        }
    }
}


.slider {
    position: relative;

    .bx-controls-direction {
        a {
            position: absolute;
            top: 50%;
            font-size: 1.3em;
            transform: translateY(-50%);
        }

        @include media('>tablet') {


            .bx-prev, .fa-angle-left {
                left: -25px;
            }

            .bx-next, .fa-angle-right {
                right: -25px;
            }
        }

        @include media('<=tablet') {
            .bx-prev, .fa-angle-left {
                left: 0px;
            }

            .bx-next, .fa-angle-right {
                right: 0px;
            }

            a {
                display: block;
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                background-color: #fff;
                border-radius: 100%;
                box-shadow: 0px 0px 11px #00000026;
            }
        }
    }

    .bx-pager {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 45px;

        .bx-pager-item {
            display: inline-block;
            width: 15px;
            height: 15px;
            margin: 0px 5px;

            a {
                width: 100%;
                height: 100%;
                cursor: pointer;
                display: block;
                border-radius: 100%;
                font-size: 0px;
            }
        }
    }



    .category--slider {
        margin-top: 30px;

        & .bx-viewport {
            height: 340px;
        }

        .dw-md-3 {
            max-width: 338px;
            padding-left: 10px !important;
            margin-right: 5px !important;
            padding-bottom: 30px;

            .cat--slide {
                cursor: pointer;
                display: block;
                box-shadow: 0 0 14px 2px #00000040;
                border-radius: 3px;



                .img--container {
                    height: 240px;
                    background-color: #fff;
                    position: relative;
                    border-radius: 3px 3px 0px 0px;

                    img {
                        position: absolute;
                        max-width: 100%;
                        height: auto;
                        bottom: 0px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }

                .bottom {
                    line-height: 30px;
                    padding: 10px;
                    color: #fff;
                    font-weight: 700;
                    text-align: center;
                }
            }
        }
    }
}

.hide-clones {
    .dw-md-3 {
        max-width: 334px !important;
    }
}