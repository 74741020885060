﻿

.login-panel {
    border: 1px solid #f1f1f1;
    border-radius: 3px;
    margin-bottom: 15px;
    padding: 25px;
    min-height: 390px;

    h2 {
        margin-top: 0px;
        margin-bottom: 30px;

        i {
            margin-right: 10px;
        }
    }

    .editor-label {
        font-weight: 700;
        margin: 10px 0px;
    }

    .editor-field {
        input {
            width: 100%;
            border: 1px solid #ddd;
            border-radius: 3px;
            padding: 10px;
        }
    }

    .btn {
        width: 100%;
        display: block;
        line-height: 40px;
        border-radius: 3px;
        cursor: pointer;
        border: none;
        color: #fff;
        text-align: center;
        font-weight: 700;
        font-size: 1.1em;
        margin: 15px 0px;
    }

    .forgot {
        float: right;
        display: inline-block;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

.account-pages {

    &.reorder {
        .account-dash {

            & > div.info {
                font-size: 1.2rem;
            }

            & > form > .section.flx {
                display: flex;
                flex-wrap: wrap;
                row-gap: 20px;
            }
        }
    }

    .reorder-header {
        display: flex;
        color: #fff;
        font-weight: bold;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        grid-gap: 10px;

        & > label {
            display: flex;
            align-items: center;
            gap: 10px;

            input[type=checkbox] {
                width: 25px;
                height: 25px;
            }
        }
    }


    .reorder-header.bg-c-2 {
        border-radius: 3px 3px 0 0;
    }

    .reorder-block {
        padding: 15px;
        border-radius: 0 0 3px 3px;
        overflow: hidden;
        position: relative;

        .size-list.grid .boot .size-opt {
            background-color: #fff;
        }

        .disabled-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #000;
            opacity: 0.65;
            z-index: 10;

            &.enabled {
                display: none;
            }
        }
    }

    .reorder-actions {
        .btn {
            color: #fff;
            line-height: 40px;
            border: none;
            width: 200px;
            text-align: center;
            font-weight: 700;
            border-radius: 3px;
        }
    }

    h1 {
        text-align: left;
    }

    .account-dash {
        min-height: 500px;

        .option {
            display: block;
            padding: 15px;
            text-align: center;
            font-size: 1.3em;
            margin-bottom: 30px;
            background-color: #fff;
            border-radius: 3px;

            i {
                font-size: 1.6em;
            }

            span {
                margin-top: 15px;
                display: block;
                font-weight: 700;
                color: #000;
            }

            cursor: pointer;
            transition: box-shadow 0.1s ease-in-out;
            transition: transform .1s;

            &:hover {
                box-shadow: 0px 11px 11px #00000026;
                transform: translateY(-5px);
            }
        }
    }

    .privacy-txt {
        display: block;
        max-width: 800px;
        margin: 0 auto;
        text-align: center;
    }

    .account-form {
        .validation-summary-errors {
            border: 1px solid #C01B00;
            color: #C01B00;
            padding: 15px;
            border-radius: 3px;
            background-color: #ffcccc;

            ul {
                margin: 0px;
            }

            li {
                font-weight: 700;
            }
        }

        h2 {
            margin: 15px 0px;
        }

        padding: 15px;
        border-radius: 3px;
        max-width: 1000px;
        background-color: #f7f7f7;
        border: 1px solid #f1f1f1;
        margin-bottom: 50px;

        .form-actions {
            margin-top: 30px;

            .btn {
                color: #fff;
                line-height: 40px;
                border: none;
                width: 200px;
                text-align: center;
                font-weight: 700;
                border-radius: 3px;
            }

            @include media(">sm-phone") {
                .left {
                    float: left;
                }

                .right {
                    float: right;
                }
            }
        }
    }
}

.account-container {
    max-width: 1100px;
    margin: 0 auto 50px;
    padding: 0px 15px;
}

.address-card {
    background-color: #f1f1f1;
    border: 1px solid #dbdbdb;
    padding: 15px;
    border-radius: 3px;
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
    display: block;
    min-height: 330px;
    margin-bottom: 30px;

    &.add {
        cursor: pointer;
        background-color: #fff;

        .content {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            font-size: 2.0em;
            width: 100%;

            i {
                margin-bottom: 15px;
            }
        }
    }

    .address-editors {
        .btn {
            display: block;
            color: #fff;
            font-weight: 700;
            width: 100%;
            text-align: center;
            border-radius: 3px;
            margin: 15px 0px;
            line-height: 1;
            padding: 13px;
        }

        width: 100%;
        padding: 15px;
    }

    @include media(">tablet") {
        .overlay {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background-color: #000;
            opacity: 0;
        }

        .address-editors {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
        }

        &:hover {
            .overlay {
                opacity: 0.5;
            }

            .address-editors {
                opacity: 1;
            }
        }
    }

    @include media("<=phone") {
        min-height: 10px;

        &.add {
            cursor: pointer;
            background-color: #fff;

            .content {
                position: static;
                transform: none;
                text-align: center;
                font-size: 2.0em;
                width: 100%;

                i {
                    margin-bottom: 15px;
                }
            }
        }
    }


    &.selected {


        .address-title {
            position: absolute;
            top: 0px;
            right: 0px;
            padding: 5px;
            color: #fff;
            font-weight: 700;
            border-radius: 0px 0px 0px 5px;
        }
    }
}

.bullet-w-txt {
    .ico {
        width: 50px;
        height: 50px;
        border-radius: 100%;

        i {
            line-height: 50px;
            width: 100%;
            display: block;
            text-align: center;
            color: #fff;
        }
    }

    .txt {
        span {
            font-weight: 700;
        }
    }

    @include media(">desktop") {
        .ico {
            float: left;

            i {
                font-size: 1.3em;
            }
        }

        .txt {
            height: 50px;
            width: calc(100% - 60px);
            float: right;
            position: relative;

            span {
                font-weight: 700;
                position: absolute;
                top: 50%;
                left: 0px;
                display: block;
                width: 100%;
                transform: translateY(-50%);
            }
        }
    }

    @include media("<=desktop") {
        .ico {
            margin: 0 auto;

            i {
                font-size: 1.3em;
            }
        }

        .txt {
            height: 50px;
            position: relative;

            span {
                text-align: center;
                position: absolute;
                top: 50%;
                left: 0px;
                display: block;
                width: 100%;
                transform: translateY(-50%);
            }
        }
    }
}

.register-form {
    border: 1px solid #dbdbdb;
    border-radius: 3px;
    max-width: 600px;
    margin: 30px auto;
    padding: 15px;

    h1 {
        text-align: center;
        margin-top: 15px;
    }

    .btn {
        border: none;
        line-height: 40px;
        width: 100%;
        display: flex;
        border-radius: 3px;
        margin: 30px auto 0px;
        color: #fff;
        font-weight: 700;
        max-width: 400px;
        font-size: 1.2em;
        font-family: proxima-nova, sans-serif;
        justify-content: center;
        align-items: center ;

        &.loading {
            color: transparent !important;
            position: relative;

            &:before {
                color: #fff;
                content: "Loading...";
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
            }
        }
    }

    button {
        border: none;
        line-height: 40px;
        width: 100%;
        display: block;
        border-radius: 3px;
        margin: 30px auto 0px;
        color: #fff;
        font-weight: 700;
        max-width: 400px;
        font-size: 1.2em;
        font-family: proxima-nova, sans-serif;
    }
}

.order-list {
    .section {
        margin: 0px -5px;
    }

    .bs {
        padding: 5px;
    }

    @include media(">l-desktop") {
        .bs {
            width: calc(100% / 4);
        }
    }

    @include media("<=l-desktop", ">desktop") {
        .bs {
            width: calc(100% / 3);
        }
    }

    @include media("<=desktop", ">tablet") {
        .bs {
            width: calc(100% / 3);
        }
    }

    @include media("<=tablet", ">phone") {
        .bs {
            width: calc(100% / 2);
        }
    }

    @include media("<=phone", ">sm-phone") {
        .bs {
            width: 100%;
        }
    }

    @include media("<=sm-phone") {
        .bs {
            width: 100%;
        }
    }

    .tile {
        border: 1px solid #dbdbdb;
        border-radius: 3px;
        padding: 15px;

        .row {
            margin-bottom: 10px;

            .title {
                float: left;
            }

            .val {
                float: right;
            }
        }

        .action {
            margin-top: 15px;
        }

        .btn {
            display: block;
            padding: 10px;
            color: #fff;
            text-align: center;
            font-weight: 700;
            border-radius: 3px;
        }
    }
}

.orderinner {
    max-width: 800px;
    margin: 0 auto;

    h1 {
        display: none;
    }
}
