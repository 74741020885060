@charset "UTF-8";
body {
  margin: 0px;
  font-family: proxima-nova, sans-serif;
  overflow-x: hidden;
}

body:has(.filter:not(.mob-shut)) .head-section {
  z-index: 0 !important;
}

.lazy {
  opacity: 0;
}

.center-text {
  text-align: center;
}

*, input, label {
  -webkit-transition: background-color 0.1s linear, color 0.1s, border 0.1s, opacity 0.1s, transform 0.1s, box-shadow 0.1s ease-in-out, opacity 0.3s ease-in-out, transform 0.1s;
  -ms-transition: background-color 0.1s linear, color 0.1s, border 0.1s, opacity 0.1s, transform 0.1s, box-shadow 0.1s ease-in-out, opacity 0.3s ease-in-out, transform 0.1s;
  transition: background-color 0.1s linear, color 0.1s, border 0.1s, opacity 0.1s, transform 0.1s, box-shadow 0.1s ease-in-out, opacity 0.3s ease-in-out, transform 0.1s;
}

div:before {
  -webkit-transition: background-color 0.1s linear, color 0.1s, border 0.1s, opacity 0.1s, transform 0.1s, box-shadow 0.1s ease-in-out;
  -ms-transition: background-color 0.1s linear, color 0.1s, border 0.1s, opacity 0.1s, transform 0.1s, box-shadow 0.1s ease-in-out;
  transition: background-color 0.1s linear, color 0.1s, border 0.1s, opacity 0.1s, transform 0.1s, box-shadow 0.1s ease-in-out;
}

.no-overflow {
  overflow: hidden;
}

.bx-shad {
  box-shadow: 0px 15px 20px #00000029;
}

.cur-pointer {
  cursor: pointer;
}

.grey-border {
  border: 1px solid #dedede;
  border-radius: 3px;
}

.grey-border-btm {
  border-bottom: 1px solid #dedede;
}

.grbg-fb {
  background-color: #fbfbfb;
}

.grbg-f7 {
  background-color: #f7f7f7;
}

.grbg-f5 {
  background-color: #f5f5f5;
}

.center-txt {
  text-align: center;
}

.flx-bx {
  display: flex;
}

.flx-al-cent {
  align-items: center;
}

.flx-ju-end {
  justify-content: end;
}

.flx-ju-space {
  justify-content: space-between;
}

.nomarg {
  margin: 0px;
}

.r-5 {
  border-radius: 5px;
}

.py10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.flx-bx {
  display: flex;
}

.block {
  display: block;
}

.fs12 {
  font-size: 1.2em;
}

.miw30 {
  min-width: 30px;
}

.mt15 {
  margin-top: 15px;
}

.gap10 {
  gap: 10px;
}

.p10 {
  padding: 10px;
}

.p15 {
  padding: 15px;
}

.mb10 {
  margin-bottom: 10px;
}

h3.lg {
  font-size: 1.6em;
}

.block {
  display: block;
}

.white-bg {
  background-color: #fff;
}

.bx-shad {
  box-shadow: 0 0 14px 2px #00000040;
}

.rad3 {
  border-radius: 3px;
}

.no-marg {
  margin: 0px !important;
}

.gr-bg {
  background-color: #f7f7f7;
  border: 1px solid #f1f1f1;
}

.no-overflow {
  overflow: hidden;
}

.padd-15 {
  padding: 15px;
}

.marg-btm-10 {
  margin-bottom: 10px;
}

.marg-btm-15 {
  margin-bottom: 15px;
}

#content {
  background-color: #fff;
  position: relative;
  z-index: 1;
}

input {
  font-family: proxima-nova, sans-serif;
}

textarea {
  font-family: proxima-nova, sans-serif;
}

img {
  border: 0;
  max-width: 100%;
  height: auto;
  display: block;
}

.left-txt {
  text-align: left;
}

.toright {
  float: right;
}

.colour-list {
  margin-top: 5px;
  min-height: 22px;
}
.colour-list .colours {
  width: 100%;
  /*width: calc(100% - 80px);*/
  float: left;
}
.colour-list .colours .colour {
  width: calc(100% / 10);
  float: left;
  padding: 1px;
}
.colour-list .colours .colour .inner {
  width: 100%;
  padding-top: 100%;
  border-radius: 100%;
  position: relative;
}
.colour-list .colours .colour .inner:before {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border: 1px solid #333;
  border-radius: 100%;
  z-index: 5;
}
.colour-list .colours .colour .inner .inner-colours {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  overflow: hidden;
}
.colour-list .colours .colour .inner .inner-colours span {
  display: block;
  float: left;
  height: 100%;
}
.colour-list .colours .more {
  width: calc((100% / 5) - 2px);
  float: right;
  text-align: right;
  color: #000;
  text-align: center;
  color: #fff;
  font-weight: 700;
  border-radius: 30px;
  line-height: 22px;
  /*display: none;*/
}
.colour-list .colours.sm-style .colour {
  width: calc(100% / 8) !important;
}
.colour-list .colours.sm-style .more {
  width: calc((100% / 4) - 2px);
}

#loading-container {
  display: none;
}

a {
  text-decoration: none;
}

h1 {
  display: block;
  text-align: center;
  margin: 30px 0px;
}
h1.sml-marg {
  margin: 30px 0px;
}
h1.left {
  text-align: left;
}
h1.xs-marg {
  margin: 5px 0px;
}
h1.neg-marg {
  margin: -10px 0px;
}

h2 {
  font-size: 2em;
  margin: 30px 0px;
}

.grey--bg {
  background-color: #F9F9F9;
}

.center--txt, .center-txt {
  text-align: center;
}

.breadcrumb {
  color: #999;
  font-size: 0.9em;
  margin: 30px 0px;
}
.breadcrumb a {
  color: #999;
  font-weight: 700;
}
.breadcrumb a:hover {
  text-decoration: underline;
}
.breadcrumb span {
  margin: 0px 5px;
  font-weight: 700;
}

.div-line {
  width: 100%;
  border-bottom: 1px solid #f1f1f1;
  margin: 30px 0px;
}

.head-section {
  position: relative;
  z-index: 10;
}
.head-section.low-index {
  z-index: 0 !important;
}

.main-content {
  z-index: 9;
  position: relative;
  background-color: #fff;
}

.menu-section {
  position: relative;
}

.err-page {
  min-height: 500px;
}
.err-page h1 {
  text-align: left;
}

.right-txt {
  text-align: right;
}

.terms-page {
  padding-bottom: 30px;
}
.terms-page h1 {
  text-align: left;
}
.terms-page h2 {
  font-size: 1.3em;
  margin: 15px 0px 10px;
}

.noflex {
  display: block !important;
}

.infotog {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  cursor: pointer;
  vertical-align: sub;
}
@keyframes bounce2 {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
.infotog.animate {
  animation: bounce2 1.5s ease 1;
  animation-delay: 1s;
}
.infotog:hover {
  animation: none;
  box-shadow: 0px 11px 11px #00000026;
  transform: translateY(-5px);
}

.ko {
  border: 1px solid #ff0000;
  padding: 15px;
  border-radius: 3px;
  background-color: #ffcccc;
  color: #ff0000;
  font-weight: 700;
}
.ko ul {
  margin: 0px;
}
.ko li {
  font-weight: 700;
}

.site-content {
  min-height: 400px;
}

.inner-rel {
  position: relative;
}

.no-p-marg {
  margin: 15px 0px;
}
.no-p-marg p {
  margin: 0px;
}

#iFrameBasket {
  margin-bottom: 30px;
}
#iFrameBasket strong {
  font-size: 2em;
}
#iFrameBasket .btn {
  border-radius: 3px;
  width: 100%;
  position: relative;
  padding: 15px;
  text-align: center;
  display: block;
  position: relative;
  font-weight: 700;
  font-size: 1.1em;
}
#iFrameBasket .btn i {
  margin-left: 15px;
}
#iFrameBasket .btn .items {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  position: absolute;
  top: 0px;
  right: 0px;
  transform: translate(50%, -50%);
  border-radius: 100%;
}

.homebtn.ifr {
  padding: 15px;
  text-align: center;
  display: block;
  position: relative;
  font-weight: 700;
  font-size: 1.1em;
  border-radius: 3px;
  max-width: 196px;
  margin-bottom: 15px;
}

.faqs .faq {
  padding: 0px;
  list-style: none;
}
.faqs .faq .top {
  cursor: pointer;
  font-size: 1.5em;
  display: flex;
  padding: 10px;
  background-color: #fff;
  border-bottom: 2px solid #ebebeb;
  justify-content: space-between;
  align-items: center;
}
.faqs .faq .body {
  padding: 30px;
}

/* BREAKPOINTS ------------ */
/*$breakpoints: ( 
    'phone': 320px, 
    'tablet': 768px, 
    'desktop': 1024px 
) !default;*/
/* COLOURS ------------ */
/* COLOURS ------------ */
/*Site Pallet*/
/*Text*/
﻿﻿*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@media (min-width: 1231px) {
  .visible-lg {
    display: block !important;
  }
}
@media (max-width: 1230px) {
  .visible-lg {
    display: none !important;
  }
}

.center-block {
  margin: 0 auto;
}

@media (max-width: 1230px) and (min-width: 992px) {
  .visible-md {
    display: block !important;
  }
}
@media (min-width: 1231px) {
  .visible-md {
    display: none !important;
  }
}
@media (max-width: 991px) {
  .visible-md {
    display: none !important;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .visible-sm {
    display: block !important;
  }
}
@media (min-width: 992px) {
  .visible-sm {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .visible-sm {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
}
@media (min-width: 768px) {
  .visible-xs {
    display: none !important;
  }
}

.container {
  margin-right: auto;
  margin-left: auto;
}

.container:before, .container:after, .section:before, .section:after {
  display: table;
  content: " ";
}

.container:after, .section:after {
  clear: both;
}

.clear {
  clear: both;
  display: block;
}

.svg-inline--fa.fa-w-18 {
  width: 60px;
  color: #2bbed8;
}

.section.flex {
  display: flex;
  flex-wrap: wrap;
}

.section.flex:before {
  display: none !important;
}

.section.flex:after {
  display: none !important;
}

.section.sml-g {
  margin: 0px -10px;
}
.section.sml-g > * {
  padding: 0px 10px;
}

.section.xsml-g {
  margin: 0px -7px !important;
}
.section.xsml-g.flex {
  grid-row-gap: 14px;
}
.section.xsml-g > * {
  padding: 0px 7px !important;
}

.gap-15 {
  gap: 15px;
}

.flx-bx {
  display: flex;
}

.fl-al-cent {
  align-items: center;
}

.fl-ju-cent {
  justify-content: center;
}

.fl-al-end {
  align-items: flex-end;
}

.fl-ju-cent {
  justify-content: center;
}

.fl-ju-space {
  justify-content: space-between;
}

.fl-ju-end {
  justify-content: end;
}

.fl-wrap {
  flex-wrap: wrap;
}

.fl-dir-rev {
  flex-direction: row-reverse;
}

.dw-xs-1, .dw-sm-1, .dw-md-1, .dw-lg-1,
.dw-xs-2, .dw-sm-2, .dw-md-2, .dw-lg-2,
.dw-xs-3, .dw-sm-3, .dw-md-3, .dw-lg-3,
.dw-xs-4, .dw-sm-4, .dw-md-4, .dw-lg-4,
.dw-xs-5, .dw-sm-5, .dw-md-5, .dw-lg-5,
.dw-xs-6, .dw-sm-6, .dw-md-6, .dw-lg-6,
.dw-xs-7, .dw-sm-7, .dw-md-7, .dw-lg-7,
.dw-xs-8, .dw-sm-8, .dw-md-8, .dw-lg-8,
.dw-xs-9, .dw-sm-9, .dw-md-9, .dw-lg-9,
.dw-xs-10, .dw-sm-10, .dw-md-10, .dw-lg-10,
.dw-xs-11, .dw-sm-11, .dw-md-11, .dw-lg-11,
.dw-xs-12, .dw-sm-12, .dw-md-12, .dw-lg-12 {
  position: relative;
  min-height: 1px;
  display: block;
}

@media (min-width: 768px) {
  .dw-xs-1, .dw-sm-1, .dw-md-1, .dw-lg-1,
.dw-xs-2, .dw-sm-2, .dw-md-2, .dw-lg-2,
.dw-xs-3, .dw-sm-3, .dw-md-3, .dw-lg-3,
.dw-xs-4, .dw-sm-4, .dw-md-4, .dw-lg-4,
.dw-xs-5, .dw-sm-5, .dw-md-5, .dw-lg-5,
.dw-xs-6, .dw-sm-6, .dw-md-6, .dw-lg-6,
.dw-xs-7, .dw-sm-7, .dw-md-7, .dw-lg-7,
.dw-xs-8, .dw-sm-8, .dw-md-8, .dw-lg-8,
.dw-xs-9, .dw-sm-9, .dw-md-9, .dw-lg-9,
.dw-xs-10, .dw-sm-10, .dw-md-10, .dw-lg-10,
.dw-xs-11, .dw-sm-11, .dw-md-11, .dw-lg-11,
.dw-xs-12, .dw-sm-12, .dw-md-12, .dw-lg-12,
.container {
    padding: 0 15px;
  }

  .section {
    margin-right: -15px;
    margin-left: -15px;
  }
  .section.narrow-gap {
    margin-left: -10px;
    margin-right: -10px;
  }
  .section.narrow-gap .boot {
    padding: 10px;
  }
}
@media (max-width: 767px) {
  .dw-xs-1, .dw-sm-1, .dw-md-1, .dw-lg-1,
.dw-xs-2, .dw-sm-2, .dw-md-2, .dw-lg-2,
.dw-xs-3, .dw-sm-3, .dw-md-3, .dw-lg-3,
.dw-xs-4, .dw-sm-4, .dw-md-4, .dw-lg-4,
.dw-xs-5, .dw-sm-5, .dw-md-5, .dw-lg-5,
.dw-xs-6, .dw-sm-6, .dw-md-6, .dw-lg-6,
.dw-xs-7, .dw-sm-7, .dw-md-7, .dw-lg-7,
.dw-xs-8, .dw-sm-8, .dw-md-8, .dw-lg-8,
.dw-xs-9, .dw-sm-9, .dw-md-9, .dw-lg-9,
.dw-xs-10, .dw-sm-10, .dw-md-10, .dw-lg-10,
.dw-xs-11, .dw-sm-11, .dw-md-11, .dw-lg-11,
.dw-xs-12, .dw-sm-12, .dw-md-12, .dw-lg-12,
.container {
    padding: 0 10px;
  }

  .section {
    margin-right: -10px;
    margin-left: -10px;
  }
  .section.narrow-gap {
    margin-left: -5px;
    margin-right: -5px;
  }
  .section.narrow-gap .boot {
    padding: 5px;
  }
}
.dw-xs-1, .dw-xs-2, .dw-xs-3, .dw-xs-4, .dw-xs-5, .dw-xs-6, .dw-xs-7, .dw-xs-8, .dw-xs-9, .dw-xs-10, .dw-xs-11 {
  float: left;
}

.dw-xs-12 {
  width: 100%;
}

.dw-xs-11 {
  width: 91.6666666667%;
}

.dw-xs-10 {
  width: 83.3333333333%;
}

.dw-xs-9 {
  width: 75%;
}

.dw-xs-8 {
  width: 66.6666666667%;
}

.dw-xs-7 {
  width: 58.3333333333%;
}

.dw-xs-6 {
  width: 50%;
}

.dw-xs-5 {
  width: 41.6666666667%;
}

.dw-xs-4 {
  width: 33.3333333333%;
}

.dw-xs-3 {
  width: 25%;
}

.dw-xs-2 {
  width: 16.6666666667%;
}

.dw-xs-1 {
  width: 8.3333333333%;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }

  .dw-sm-1, .dw-sm-2, .dw-sm-3, .dw-sm-4, .dw-sm-5, .dw-sm-6, .dw-sm-7, .dw-sm-8, .dw-sm-9, .dw-sm-10, .dw-sm-11 {
    float: left;
  }

  .dw-sm-12 {
    width: 100%;
  }

  .dw-sm-11 {
    width: 91.6666666667%;
  }

  .dw-sm-10 {
    width: 83.3333333333%;
  }

  .dw-sm-9 {
    width: 75%;
  }

  .dw-sm-8 {
    width: 66.6666666667%;
  }

  .dw-sm-7 {
    width: 58.3333333333%;
  }

  .dw-sm-6 {
    width: 50%;
  }

  .dw-sm-5 {
    width: 41.6666666667%;
  }

  .dw-sm-4 {
    width: 33.3333333333%;
  }

  .dw-sm-3 {
    width: 25%;
  }

  .dw-sm-2 {
    width: 16.6666666667%;
  }

  .dw-sm-1 {
    width: 8.3333333333%;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }

  .dw-md-1, .dw-md-2, .dw-md-3, .dw-md-4, .dw-md-5, .dw-md-6, .dw-md-7, .dw-md-8, .dw-md-9, .dw-md-10, .dw-md-11 {
    float: left;
  }

  .dw-md-12 {
    width: 100%;
  }

  .dw-md-11 {
    width: 91.6666666667%;
  }

  .dw-md-10 {
    width: 83.3333333333%;
  }

  .dw-md-9 {
    width: 75%;
  }

  .dw-md-8 {
    width: 66.6666666667%;
  }

  .dw-md-7 {
    width: 58.3333333333%;
  }

  .dw-md-6 {
    width: 50%;
  }

  .dw-md-5 {
    width: 41.6666666667%;
  }

  .dw-md-4 {
    width: 33.3333333333%;
  }

  .dw-md-3 {
    width: 25%;
  }

  .dw-md-2 {
    width: 16.6666666667%;
  }

  .dw-md-1 {
    width: 8.3333333333%;
  }
}
@media (min-width: 1230px) {
  .container {
    width: 1230px;
  }

  .dw-lg-1, .dw-lg-2, .dw-lg-3, .dw-lg-4, .dw-lg-5, .dw-lg-6, .dw-lg-7, .dw-lg-8, .dw-lg-9, .dw-lg-10, .dw-lg-11 {
    float: left;
  }

  .dw-lg-12 {
    width: 100%;
  }

  .dw-lg-11 {
    width: 91.6666666667%;
  }

  .dw-lg-10 {
    width: 83.3333333333%;
  }

  .dw-lg-9 {
    width: 75%;
  }

  .dw-lg-8 {
    width: 66.6666666667%;
  }

  .dw-lg-7 {
    width: 58.3333333333%;
  }

  .dw-lg-6 {
    width: 50%;
  }

  .dw-lg-5 {
    width: 41.6666666667%;
  }

  .dw-lg-4 {
    width: 33.3333333333%;
  }

  .dw-lg-3 {
    width: 25%;
  }

  .dw-lg-2 {
    width: 16.6666666667%;
  }

  .dw-lg-1 {
    width: 8.3333333333%;
  }
}
@media (min-width: 1400px) {
  .container {
    width: 100%;
    max-width: 1400px;
  }
}
@-ms-viewport {
  width: device-width;
}
.visible-xs {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-xs.visible-sm {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1229px) {
  .visible-xs.visible-md {
    display: block !important;
  }
}
@media (min-width: 1230px) {
  .visible-xs.visible-lg {
    display: block !important;
  }
}
.visible-sm {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-sm.visible-xs {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1229px) {
  .visible-sm.visible-md {
    display: block !important;
  }
}
@media (min-width: 1230px) {
  .visible-sm.visible-lg {
    display: block !important;
  }
}
.visible-md {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-md.visible-xs {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-md.visible-sm {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1229px) {
  .visible-md {
    display: block !important;
  }
}
@media (min-width: 1230px) {
  .visible-md.visible-lg {
    display: block !important;
  }
}
.visible-lg {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-lg.visible-xs {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-lg.visible-sm {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1229px) {
  .visible-lg.visible-md {
    display: block !important;
  }
}
@media (min-width: 1230px) {
  .visible-lg {
    display: block !important;
  }
}
.hidden-xs {
  display: block !important;
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-xs.hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1229px) {
  .hidden-xs.hidden-md {
    display: none !important;
  }
}
@media (min-width: 1230px) {
  .hidden-xs.hidden-lg {
    display: none !important;
  }
}
.hidden-sm {
  display: block !important;
}

@media (max-width: 767px) {
  .hidden-sm.hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1229px) {
  .hidden-sm.hidden-md {
    display: none !important;
  }
}
@media (min-width: 1230px) {
  .hidden-sm.hidden-lg {
    display: none !important;
  }
}
.hidden-md {
  display: block !important;
}

@media (max-width: 767px) {
  .hidden-md.hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-md.hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1229px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1230px) {
  .hidden-md.hidden-lg {
    display: none !important;
  }
}
.hidden-lg {
  display: block !important;
}

@media (max-width: 767px) {
  .hidden-lg.hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-lg.hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1229px) {
  .hidden-lg.hidden-md {
    display: none !important;
  }
}
@media (min-width: 1230px) {
  .hidden-lg {
    display: none !important;
  }
}
.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  z-index: 1;
}

.carousel-inner > .item {
  position: relative;
  display: none;
  -webkit-transition: 0.6s ease-in-out left;
  -o-transition: 0.6s ease-in-out left;
  transition: 0.6s ease-in-out left;
}

.carousel-inner > .item > img, .carousel-inner > .item > a > img {
  line-height: 1;
}

.carousel-inner > .active, .carousel-inner > .next, .carousel-inner > .prev {
  display: block;
}

.carousel-inner > .active {
  left: 0;
}

.carousel-inner > .next, .carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%;
}

.carousel-inner > .next {
  left: 100%;
}

.carousel-inner > .prev {
  left: -100%;
}

.carousel-inner > .next.left, .carousel-inner > .prev.right {
  left: 0;
}

.carousel-inner > .active.left {
  left: -100%;
}

.carousel-inner > .active.right {
  left: 100%;
}

/*.carousel-inner .item a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999999;
}*/
.carousel-indicators {
  position: absolute;
  z-index: 9;
  bottom: 20px;
  width: 100%;
  padding: 0;
  margin: 0;
  text-align: center;
  list-style: none;
}

.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  text-indent: -999px;
  cursor: pointer;
  border-radius: 10px;
}

.carousel-indicators .active {
  background-color: #2bbed8;
}

.carousel-caption {
  position: absolute;
  left: 50%;
  width: 100%;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 10;
  padding: 0 40px;
  text-align: center;
}

@media (min-width: 1230px) {
  .carousel-caption {
    width: 1230px;
  }
}
@media (max-width: 1229px) and (min-width: 992px) {
  .carousel-caption {
    max-width: 970px;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .carousel-caption {
    max-width: 750px;
  }
}
@media (max-width: 767px) {
  .carousel-caption {
    padding: 0 10px;
  }

  .carousel-caption span {
    display: none;
  }
}
.max-w-100 {
  max-width: 100px;
}

.max-w-110 {
  max-width: 110px;
}

.max-w-120 {
  max-width: 120px;
}

.max-w-130 {
  max-width: 130px;
}

.max-w-140 {
  max-width: 140px;
}

.max-w-150 {
  max-width: 150px;
}

.max-w-160 {
  max-width: 160px;
}

.max-w-170 {
  max-width: 170px;
}

.max-w-180 {
  max-width: 180px;
}

.max-w-190 {
  max-width: 190px;
}

.max-w-200 {
  max-width: 200px;
}

.max-w-210 {
  max-width: 210px;
}

.max-w-220 {
  max-width: 220px;
}

.max-w-230 {
  max-width: 230px;
}

.max-w-240 {
  max-width: 240px;
}

.max-w-250 {
  max-width: 250px;
}

.max-w-260 {
  max-width: 260px;
}

.max-w-270 {
  max-width: 270px;
}

.max-w-280 {
  max-width: 280px;
}

.max-w-290 {
  max-width: 290px;
}

.max-w-300 {
  max-width: 300px;
}

.max-w-310 {
  max-width: 310px;
}

.max-w-320 {
  max-width: 320px;
}

.max-w-330 {
  max-width: 330px;
}

.max-w-340 {
  max-width: 340px;
}

.max-w-350 {
  max-width: 350px;
}

.max-w-360 {
  max-width: 360px;
}

.max-w-370 {
  max-width: 370px;
}

.max-w-380 {
  max-width: 380px;
}

.max-w-390 {
  max-width: 390px;
}

.max-w-400 {
  max-width: 400px;
}

.max-w-410 {
  max-width: 410px;
}

.max-w-420 {
  max-width: 420px;
}

.max-w-430 {
  max-width: 430px;
}

.max-w-440 {
  max-width: 440px;
}

.max-w-450 {
  max-width: 450px;
}

.max-w-460 {
  max-width: 460px;
}

.max-w-470 {
  max-width: 470px;
}

.max-w-480 {
  max-width: 480px;
}

.max-w-490 {
  max-width: 490px;
}

.max-w-500 {
  max-width: 500px;
}

.max-w-510 {
  max-width: 510px;
}

.max-w-520 {
  max-width: 520px;
}

.max-w-530 {
  max-width: 530px;
}

.max-w-540 {
  max-width: 540px;
}

.max-w-550 {
  max-width: 550px;
}

.max-w-560 {
  max-width: 560px;
}

.max-w-570 {
  max-width: 570px;
}

.max-w-580 {
  max-width: 580px;
}

.max-w-590 {
  max-width: 590px;
}

.max-w-600 {
  max-width: 600px;
}

.max-w-610 {
  max-width: 610px;
}

.max-w-620 {
  max-width: 620px;
}

.max-w-630 {
  max-width: 630px;
}

.max-w-640 {
  max-width: 640px;
}

.max-w-650 {
  max-width: 650px;
}

.max-w-660 {
  max-width: 660px;
}

.max-w-670 {
  max-width: 670px;
}

.max-w-680 {
  max-width: 680px;
}

.max-w-690 {
  max-width: 690px;
}

.max-w-700 {
  max-width: 700px;
}

.max-w-710 {
  max-width: 710px;
}

.max-w-720 {
  max-width: 720px;
}

.max-w-730 {
  max-width: 730px;
}

.max-w-740 {
  max-width: 740px;
}

.max-w-750 {
  max-width: 750px;
}

.max-w-760 {
  max-width: 760px;
}

.max-w-770 {
  max-width: 770px;
}

.max-w-780 {
  max-width: 780px;
}

.max-w-790 {
  max-width: 790px;
}

.max-w-800 {
  max-width: 800px;
}

.max-w-810 {
  max-width: 810px;
}

.max-w-820 {
  max-width: 820px;
}

.max-w-830 {
  max-width: 830px;
}

.max-w-840 {
  max-width: 840px;
}

.max-w-850 {
  max-width: 850px;
}

.max-w-860 {
  max-width: 860px;
}

.max-w-870 {
  max-width: 870px;
}

.max-w-880 {
  max-width: 880px;
}

.max-w-890 {
  max-width: 890px;
}

.max-w-900 {
  max-width: 900px;
}

.max-w-910 {
  max-width: 910px;
}

.max-w-920 {
  max-width: 920px;
}

.max-w-930 {
  max-width: 930px;
}

.max-w-940 {
  max-width: 940px;
}

.max-w-950 {
  max-width: 950px;
}

.max-w-960 {
  max-width: 960px;
}

.max-w-970 {
  max-width: 970px;
}

.max-w-980 {
  max-width: 980px;
}

.max-w-990 {
  max-width: 990px;
}

.max-w-1000 {
  max-width: 1000px;
}

.max-w-1010 {
  max-width: 1010px;
}

.max-w-1020 {
  max-width: 1020px;
}

.max-w-1030 {
  max-width: 1030px;
}

.max-w-1040 {
  max-width: 1040px;
}

.max-w-1050 {
  max-width: 1050px;
}

.max-w-1060 {
  max-width: 1060px;
}

.max-w-1070 {
  max-width: 1070px;
}

.max-w-1080 {
  max-width: 1080px;
}

.max-w-1090 {
  max-width: 1090px;
}

.max-w-1000 {
  max-width: 1100px;
}

.max-w-1010 {
  max-width: 1110px;
}

.max-w-1020 {
  max-width: 1120px;
}

.max-w-1030 {
  max-width: 1130px;
}

.max-w-1040 {
  max-width: 1140px;
}

.max-w-1050 {
  max-width: 1150px;
}

.max-w-1060 {
  max-width: 1160px;
}

.max-w-1070 {
  max-width: 1170px;
}

.max-w-1080 {
  max-width: 1180px;
}

.max-w-1090 {
  max-width: 1190px;
}

.max-w-1200 {
  max-width: 1200px;
}

.max-w-1210 {
  max-width: 1210px;
}

.max-w-1220 {
  max-width: 1220px;
}

.max-w-1230 {
  max-width: 1230px;
}

.max-w-1240 {
  max-width: 1240px;
}

.max-w-1250 {
  max-width: 1250px;
}

.max-w-1260 {
  max-width: 1260px;
}

.max-w-1270 {
  max-width: 1270px;
}

.max-w-1280 {
  max-width: 1280px;
}

.max-w-1290 {
  max-width: 1290px;
}

.max-w-1300 {
  max-width: 1300px;
}

.max-w-1310 {
  max-width: 1310px;
}

.max-w-1320 {
  max-width: 1320px;
}

.max-w-1330 {
  max-width: 1330px;
}

.max-w-1340 {
  max-width: 1340px;
}

.max-w-1350 {
  max-width: 1350px;
}

.max-w-1360 {
  max-width: 1360px;
}

.max-w-1370 {
  max-width: 1370px;
}

.max-w-1380 {
  max-width: 1380px;
}

.max-w-1390 {
  max-width: 1390px;
}

.max-w-1400 {
  max-width: 1400px;
}

.max-w-1410 {
  max-width: 1410px;
}

.max-w-1420 {
  max-width: 1420px;
}

.max-w-1430 {
  max-width: 1430px;
}

.max-w-1440 {
  max-width: 1440px;
}

.max-w-1450 {
  max-width: 1450px;
}

.max-w-1460 {
  max-width: 1460px;
}

.max-w-1470 {
  max-width: 1470px;
}

.max-w-1480 {
  max-width: 1480px;
}

.max-w-1490 {
  max-width: 1490px;
}

.max-w-1500 {
  max-width: 1500px;
}

.max-w-1510 {
  max-width: 1510px;
}

.max-w-1520 {
  max-width: 1520px;
}

.max-w-1530 {
  max-width: 1530px;
}

.max-w-1540 {
  max-width: 1540px;
}

.max-w-1550 {
  max-width: 1550px;
}

.max-w-1560 {
  max-width: 1560px;
}

.max-w-1570 {
  max-width: 1570px;
}

.max-w-1580 {
  max-width: 1580px;
}

.max-w-1590 {
  max-width: 1590px;
}

.max-w-1600 {
  max-width: 1600px;
}

.max-w-1610 {
  max-width: 1610px;
}

.max-w-1620 {
  max-width: 1620px;
}

.max-w-1630 {
  max-width: 1630px;
}

.max-w-1640 {
  max-width: 1640px;
}

.max-w-1650 {
  max-width: 1650px;
}

.max-w-1660 {
  max-width: 1660px;
}

.max-w-1670 {
  max-width: 1670px;
}

.max-w-1680 {
  max-width: 1680px;
}

.max-w-1690 {
  max-width: 1690px;
}

.max-w-1700 {
  max-width: 1700px;
}

.max-w-1710 {
  max-width: 1710px;
}

.max-w-1720 {
  max-width: 1720px;
}

.max-w-1730 {
  max-width: 1730px;
}

.max-w-1740 {
  max-width: 1740px;
}

.max-w-1750 {
  max-width: 1750px;
}

.max-w-1760 {
  max-width: 1760px;
}

.max-w-1770 {
  max-width: 1770px;
}

.max-w-1780 {
  max-width: 1780px;
}

.max-w-1790 {
  max-width: 1790px;
}

.max-w-1800 {
  max-width: 1800px;
}

.max-w-1810 {
  max-width: 1810px;
}

.max-w-1820 {
  max-width: 1820px;
}

.max-w-1830 {
  max-width: 1830px;
}

.max-w-1840 {
  max-width: 1840px;
}

.max-w-1850 {
  max-width: 1850px;
}

.max-w-1860 {
  max-width: 1860px;
}

.max-w-1870 {
  max-width: 1870px;
}

.max-w-1880 {
  max-width: 1880px;
}

.max-w-1890 {
  max-width: 1890px;
}

.max-w-1900 {
  max-width: 1900px;
}

.max-w-1910 {
  max-width: 1910px;
}

.max-w-1920 {
  max-width: 1920px;
}

.max-w-1930 {
  max-width: 1930px;
}

.max-w-1940 {
  max-width: 1940px;
}

.max-w-1950 {
  max-width: 1950px;
}

.max-w-1960 {
  max-width: 1960px;
}

.max-w-1970 {
  max-width: 1970px;
}

.max-w-1980 {
  max-width: 1980px;
}

.max-w-1990 {
  max-width: 1990px;
}

.max-w-2000 {
  max-width: 2000px;
}

.max-w-2010 {
  max-width: 2010px;
}

.max-w-2020 {
  max-width: 2020px;
}

.max-w-2030 {
  max-width: 2030px;
}

.max-w-2040 {
  max-width: 2040px;
}

.max-w-2050 {
  max-width: 2050px;
}

.max-w-2060 {
  max-width: 2060px;
}

.max-w-2070 {
  max-width: 2070px;
}

.max-w-2080 {
  max-width: 2080px;
}

.max-w-2090 {
  max-width: 2090px;
}

.min-h-1 {
  min-height: 1px;
}

.min-h-2 {
  min-height: 2px;
}

.min-h-10 {
  min-height: 10px;
}

.min-h-15 {
  min-height: 15px;
}

.min-h-20 {
  min-height: 20px;
}

.min-h-25 {
  min-height: 25px;
}

.min-h-30 {
  min-height: 30px;
}

.min-h-35 {
  min-height: 35px;
}

.min-h-40 {
  min-height: 40px;
}

.min-h-45 {
  min-height: 45px;
}

.min-h-50 {
  min-height: 50px;
}

.min-h-55 {
  min-height: 55px;
}

.min-h-60 {
  min-height: 60px;
}

.min-h-65 {
  min-height: 65px;
}

.min-h-70 {
  min-height: 70px;
}

.min-h-75 {
  min-height: 75px;
}

.min-h-80 {
  min-height: 80px;
}

.min-h-85 {
  min-height: 85px;
}

.min-h-20 {
  min-height: 20px;
}

.min-h-100 {
  min-height: 100px;
}

.min-h-110 {
  min-height: 110px;
}

.min-h-120 {
  min-height: 120px;
}

.min-h-130 {
  min-height: 130px;
}

.min-h-140 {
  min-height: 140px;
}

.min-h-150 {
  min-height: 150px;
}

.min-h-160 {
  min-height: 160px;
}

.min-h-190 {
  min-height: 190px;
}

.min-h-200 {
  min-height: 200px;
}

.min-h-210 {
  min-height: 210px;
}

.min-h-220 {
  min-height: 220px;
}

.min-h-230 {
  min-height: 230px;
}

.min-h-250 {
  min-height: 250px;
}

.min-h-270 {
  min-height: 270px;
}

.min-h-500 {
  min-height: 500px;
}

.vert-padd-5 {
  padding: 5px 0px;
}

.vert-padd-10 {
  padding: 10px 0px;
}

.vert-padd-15 {
  padding: 15px 0px;
}

.vert-padd-20 {
  padding: 20px 0px;
}

.vert-padd-30 {
  padding: 30px 0px;
}

.vert-padd-45 {
  padding: 45px 0px;
}

.vert-padd-60 {
  padding: 60px 0px;
}

.full-w {
  width: 100%;
}

.center-txt {
  text-align: center;
}

.marg-top-30 {
  margin-top: 30px;
}

.dw-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  /*TABLET*/
}
.dw-grid .dw-xxs-12 {
  grid-column: span 12;
}
.dw-grid .dw-xxs-11 {
  grid-column: span 11;
}
.dw-grid .dw-xxs-10 {
  grid-column: span 10;
}
.dw-grid .dw-xxs-9 {
  grid-column: span 9;
}
.dw-grid .dw-xxs-8 {
  grid-column: span 8;
}
.dw-grid .dw-xxs-7 {
  grid-column: span 7;
}
.dw-grid .dw-xxs-6 {
  grid-column: span 6;
}
.dw-grid .dw-xxs-5 {
  grid-column: span 5;
}
.dw-grid .dw-xxs-4 {
  grid-column: span 4;
}
.dw-grid .dw-xxs-3 {
  grid-column: span 3;
}
.dw-grid .dw-xxs-2 {
  grid-column: span 2;
}
.dw-grid .dw-xxs-1 {
  grid-column: span 1;
}
@media (min-width: 500px) {
  .dw-grid {
    /*FLEXBOX*/
    /*FLEX ITEM*/
  }
  .dw-grid .dw-xs-12 {
    grid-column: span 12;
  }
  .dw-grid .dw-xs-11 {
    grid-column: span 11;
  }
  .dw-grid .dw-xs-10 {
    grid-column: span 10;
  }
  .dw-grid .dw-xs-9 {
    grid-column: span 9;
  }
  .dw-grid .dw-xs-8 {
    grid-column: span 8;
  }
  .dw-grid .dw-xs-7 {
    grid-column: span 7;
  }
  .dw-grid .dw-xs-6 {
    grid-column: span 6;
  }
  .dw-grid .dw-xs-5 {
    grid-column: span 5;
  }
  .dw-grid .dw-xs-4 {
    grid-column: span 4;
  }
  .dw-grid .dw-xs-3 {
    grid-column: span 3;
  }
  .dw-grid .dw-xs-2 {
    grid-column: span 2;
  }
  .dw-grid .dw-xs-1 {
    grid-column: span 1;
  }
  .dw-grid .xs-flx-col {
    flex-direction: column;
  }
  .dw-grid .xs-flx-row {
    flex-direction: row;
  }
  .dw-grid .xs-flx-col-rev {
    flex-direction: column-reverse;
  }
  .dw-grid .xs-flx-row-rev {
    flex-direction: row-reverse;
  }
  .dw-grid .xs-al-start {
    align-items: center;
  }
  .dw-grid .xs-al-center {
    align-items: center;
  }
  .dw-grid .xs-al-end {
    align-items: flex-end;
  }
  .dw-grid .xs-ju-start {
    justify-content: flex-start;
  }
  .dw-grid .xs-ju-center {
    justify-content: center;
  }
  .dw-grid .xs-ju-end {
    justify-content: end;
  }
  .dw-grid .xs-ju-evenly {
    justify-content: space-evenly;
  }
  .dw-grid .xs-ju-around {
    justify-content: space-around;
  }
  .dw-grid .xs-ju-between {
    justify-content: space-between;
  }
  .dw-grid .xs-self-start {
    align-self: flex-start;
  }
  .dw-grid .xs-self-end {
    align-self: flex-end;
  }
  .dw-grid .xs-self-center {
    align-self: center;
  }
}
@media (min-width: 768px) {
  .dw-grid .dw-sm-12 {
    grid-column: span 12;
  }
  .dw-grid .dw-sm-11 {
    grid-column: span 11;
  }
  .dw-grid .dw-sm-10 {
    grid-column: span 10;
  }
  .dw-grid .dw-sm-9 {
    grid-column: span 9;
  }
  .dw-grid .dw-sm-8 {
    grid-column: span 8;
  }
  .dw-grid .dw-sm-7 {
    grid-column: span 7;
  }
  .dw-grid .dw-sm-6 {
    grid-column: span 6;
  }
  .dw-grid .dw-sm-5 {
    grid-column: span 5;
  }
  .dw-grid .dw-sm-4 {
    grid-column: span 4;
  }
  .dw-grid .dw-sm-3 {
    grid-column: span 3;
  }
  .dw-grid .dw-sm-2 {
    grid-column: span 2;
  }
  .dw-grid .dw-sm-1 {
    grid-column: span 1;
  }
  .dw-grid .visible-xs {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .dw-grid .dw-md-12 {
    grid-column: span 12;
  }
  .dw-grid .dw-md-11 {
    grid-column: span 11;
  }
  .dw-grid .dw-md-10 {
    grid-column: span 10;
  }
  .dw-grid .dw-md-9 {
    grid-column: span 9;
  }
  .dw-grid .dw-md-8 {
    grid-column: span 8;
  }
  .dw-grid .dw-md-7 {
    grid-column: span 7;
  }
  .dw-grid .dw-md-6 {
    grid-column: span 6;
  }
  .dw-grid .dw-md-5 {
    grid-column: span 5;
  }
  .dw-grid .dw-md-4 {
    grid-column: span 4;
  }
  .dw-grid .dw-md-3 {
    grid-column: span 3;
  }
  .dw-grid .dw-md-2 {
    grid-column: span 2;
  }
  .dw-grid .dw-md-1 {
    grid-column: span 1;
  }
}
@media (min-width: 1230px) {
  .dw-grid .dw-lg-12 {
    grid-column: span 12;
  }
  .dw-grid .dw-lg-11 {
    grid-column: span 11;
  }
  .dw-grid .dw-lg-10 {
    grid-column: span 10;
  }
  .dw-grid .dw-lg-9 {
    grid-column: span 9;
  }
  .dw-grid .dw-lg-8 {
    grid-column: span 8;
  }
  .dw-grid .dw-lg-7 {
    grid-column: span 7;
  }
  .dw-grid .dw-lg-6 {
    grid-column: span 6;
  }
  .dw-grid .dw-lg-5 {
    grid-column: span 5;
  }
  .dw-grid .dw-lg-4 {
    grid-column: span 4;
  }
  .dw-grid .dw-lg-3 {
    grid-column: span 3;
  }
  .dw-grid .dw-lg-2 {
    grid-column: span 2;
  }
  .dw-grid .dw-lg-1 {
    grid-column: span 1;
  }
}
@media (min-width: 1671px) {
  .dw-grid .dw-xl-12 {
    grid-column: span 12;
  }
  .dw-grid .dw-xl-11 {
    grid-column: span 11;
  }
  .dw-grid .dw-xl-10 {
    grid-column: span 10;
  }
  .dw-grid .dw-xl-9 {
    grid-column: span 9;
  }
  .dw-grid .dw-xl-8 {
    grid-column: span 8;
  }
  .dw-grid .dw-xl-7 {
    grid-column: span 7;
  }
  .dw-grid .dw-xl-6 {
    grid-column: span 6;
  }
  .dw-grid .dw-xl-5 {
    grid-column: span 5;
  }
  .dw-grid .dw-xl-4 {
    grid-column: span 4;
  }
  .dw-grid .dw-xl-3 {
    grid-column: span 3;
  }
  .dw-grid .dw-xl-2 {
    grid-column: span 2;
  }
  .dw-grid .dw-xl-1 {
    grid-column: span 1;
  }
}
@media (min-width: 1671px) {
  .dw-grid .dw-xxl-12 {
    grid-column: span 12;
  }
  .dw-grid .dw-xxl-11 {
    grid-column: span 11;
  }
  .dw-grid .dw-xxl-10 {
    grid-column: span 10;
  }
  .dw-grid .dw-xxl-9 {
    grid-column: span 9;
  }
  .dw-grid .dw-xxl-8 {
    grid-column: span 8;
  }
  .dw-grid .dw-xxl-7 {
    grid-column: span 7;
  }
  .dw-grid .dw-xxl-6 {
    grid-column: span 6;
  }
  .dw-grid .dw-xxl-5 {
    grid-column: span 5;
  }
  .dw-grid .dw-xxl-4 {
    grid-column: span 4;
  }
  .dw-grid .dw-xxl-3 {
    grid-column: span 3;
  }
  .dw-grid .dw-xxl-2 {
    grid-column: span 2;
  }
  .dw-grid .dw-xxl-1 {
    grid-column: span 1;
  }
}

.cms-banner {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.cms-spacer {
  padding-top: 15px;
}

@media (min-width: 1230px) {
  .cms-spacer {
    padding-top: 30px;
  }

  .cms-prods .dw-lg-3 {
    width: 20% !important;
  }
}
.cms-banner.mobile {
  aspect-ratio: 530/530;
}

.cms-banner.desktop {
  aspect-ratio: 1900/490;
}

.cms-banner.desktopfull {
  aspect-ratio: 1900/290;
}

.bar-1 {
  height: 10px;
  margin-bottom: 2px;
}

.bar-2 {
  height: 5px;
}

.about-section .box:nth-child(even) {
  background-color: #dfdfdf;
}
@media (max-width: 990px) {
  .about-section .box:nth-child(even) .section {
    display: flex;
    flex-direction: column-reverse;
  }
}
.about-section .box .container {
  max-width: 1200px;
}
@media (max-width: 990px) {
  .about-section .box .section > div {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
.about-section .column-box .section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.masthead .contact--info {
  text-align: center;
  line-height: 20px;
  padding: 15px 0px;
}
.masthead .contact--info a {
  font-weight: 700;
}
.masthead .newsletter--cta {
  text-align: center;
  padding: 8px 0px;
  line-height: 30px;
}
@media (max-width: 767px) {
  .masthead .newsletter--cta {
    margin-bottom: 25px;
  }
}
@media (min-width: 768px) {
  .masthead .newsletter--cta .newsletter--form span {
    color: #fff;
    font-weight: 700;
  }
  .masthead .newsletter--cta .newsletter--form input {
    line-height: 30px;
    padding: 0px 0px 0px 10px;
    border: 0px;
    border-radius: 3px;
    margin: 0px 10px;
  }
}
@media (min-width: 768px) and (min-width: 1231px) {
  .masthead .newsletter--cta .newsletter--form input {
    width: 260px;
  }
}
@media (min-width: 768px) {
  .masthead .newsletter--cta .newsletter--form button {
    line-height: 30px;
    border: 0px;
    border-radius: 3px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    width: 120px;
  }
}
@media (max-width: 767px) {
  .masthead .newsletter--cta .newsletter--form {
    display: none;
  }
}
@media (max-width: 991px) {
  .masthead .head--main {
    position: relative;
  }
}
@media (max-width: 991px) and (max-width: 991px) and (min-width: 768px) {
  .masthead .head--main {
    padding-bottom: 30px;
  }
}
@media (max-width: 991px) and (max-width: 767px) {
  .masthead .head--main {
    padding-bottom: 10px;
  }
}
@media (min-width: 992px) {
  .masthead .head--main .head-left {
    height: 140px;
    width: 270px;
  }
  .masthead .head--main .head-left a {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    max-width: calc(100% - 30px);
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .masthead .head--main .head-left {
    width: 50%;
    float: left;
    padding-right: 0px;
  }
}
@media (max-width: 767px) {
  .masthead .head--main .head-left {
    width: calc(100% - 150px);
    float: left;
    padding-right: 0px;
  }
  .masthead .head--main .head-left a {
    display: block;
    max-height: 40px;
  }
  .masthead .head--main .head-left a img {
    max-height: 70px;
    width: auto;
    transform: translateY(-20px);
  }
}
@media (min-width: 1230px) {
  .masthead .head--main .head-center {
    width: calc(100% - 715px);
  }
}
@media (min-width: 992px) and (max-width: 1230px) {
  .masthead .head--main .head-center {
    width: 38%;
  }
}
.masthead .head--main .head-center .search--form {
  position: relative;
}
.masthead .head--main .head-center .search--form input.field {
  height: 45px;
  line-height: 45px;
  border: 1px solid #C3C3C3;
  display: block;
  border-radius: 3px;
  width: 100%;
  padding: 0px 15px;
}
.masthead .head--main .head-center .search--form button.submit {
  height: 100%;
  width: 45px;
  display: block;
  background-color: transparent;
  border: none;
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  font-size: 1.4em;
}
.masthead .head--main .head-center .search--form button.submit:hover {
  color: #000;
}
@media (max-width: 991px) {
  .masthead .head--main .head-center {
    position: absolute;
    z-index: 1;
    width: 100%;
  }
}
@media (min-width: 1230px) {
  .masthead .head--main .head-right {
    width: 460px;
  }
}
@media (min-width: 992px) and (max-width: 1230px) {
  .masthead .head--main .head-right {
    width: 35%;
  }
}
@media (min-width: 992px) {
  .masthead .head--main .head-right {
    margin: 0px -8px;
  }
  .masthead .head--main .head-right .top--widget {
    width: calc(100% / 3);
    float: left;
    padding: 0px 8px;
  }
  .masthead .head--main .head-right .top--widget a {
    display: block;
    cursor: pointer;
    z-index: 1;
  }
}
@media (min-width: 992px) and (min-width: 1231px) {
  .masthead .head--main .head-right .top--widget a .inner {
    display: block;
    text-align: right;
    line-height: 45px;
  }
  .masthead .head--main .head-right .top--widget a .inner i {
    color: #fff;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 100%;
    float: left;
    font-size: 1.3em;
  }
  .masthead .head--main .head-right .top--widget a .inner span {
    display: block;
    line-height: 40px;
    width: calc(100% - 40px);
    float: left;
    color: #000;
    text-align: left;
    padding-left: 10px;
  }
}
@media (min-width: 992px) and (min-width: 992px) and (max-width: 1230px) {
  .masthead .head--main .head-right .top--widget a .inner {
    display: block;
    text-align: center;
    line-height: 45px;
  }
  .masthead .head--main .head-right .top--widget a .inner i {
    color: #fff;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 100%;
  }
  .masthead .head--main .head-right .top--widget a .inner span {
    display: block;
    line-height: 40px;
    color: #000;
    text-align: center;
    font-weight: 700;
  }
}
@media (min-width: 992px) {
  .masthead .head--main .head-right .navicon-container {
    display: none;
  }
}
@media (max-width: 991px) {
  .masthead .head--main .head-right {
    padding-left: 0px;
    float: left;
    text-align: right;
  }
}
@media (max-width: 991px) and (max-width: 991px) and (min-width: 768px) {
  .masthead .head--main .head-right {
    width: 50%;
  }
}
@media (max-width: 991px) and (max-width: 767px) {
  .masthead .head--main .head-right {
    width: 150px;
  }
}
@media (max-width: 991px) {
  .masthead .head--main .head-right .contact-container {
    vertical-align: top;
    width: 30px;
    height: 30px;
    display: inline-block;
  }
}
@media (max-width: 991px) and (max-width: 767px) {
  .masthead .head--main .head-right .contact-container {
    display: none;
  }
}
@media (max-width: 991px) {
  .masthead .head--main .head-right .contact-container .inner {
    background-color: transparent !important;
    font-size: 1.75em;
  }
  .masthead .head--main .head-right .contact-container .inner i {
    background-color: transparent;
  }
  .masthead .head--main .head-right .contact-container .inner span {
    display: none;
  }
}
@media (max-width: 991px) {
  .masthead .head--main .head-right .account-container {
    vertical-align: top;
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-left: 10px;
  }
  .masthead .head--main .head-right .account-container .inner {
    background-color: transparent !important;
    font-size: 1.9em;
  }
  .masthead .head--main .head-right .account-container .inner i {
    background-color: transparent;
  }
  .masthead .head--main .head-right .account-container .inner span {
    display: none;
  }
}
@media (max-width: 991px) {
  .masthead .head--main .head-right .cart-container {
    margin-left: 10px;
    vertical-align: top;
    width: 30px;
    height: 30px;
    display: inline-block;
  }
  .masthead .head--main .head-right .cart-container .inner {
    font-size: 1.9em;
    background-color: transparent !important;
  }
  .masthead .head--main .head-right .cart-container .inner i {
    background-color: transparent;
  }
  .masthead .head--main .head-right .cart-container .inner span {
    display: none;
  }
}
@media (max-width: 991px) {
  .masthead .head--main .head-right .navicon-container {
    padding-top: 6px;
    vertical-align: top;
    margin-left: 15px;
    display: inline-block;
    width: 30px;
    height: 45px;
  }
  .masthead .head--main .head-right .navicon-container .menu-icon {
    width: 200%;
    height: 100%;
    transition: all 0.5s ease-in-out 0s;
    transform: rotate(0deg) scale(0.5);
    position: relative;
    cursor: pointer;
    transform-origin: top left;
  }
  .masthead .head--main .head-right .navicon-container .menu-icon span {
    display: block;
    position: absolute;
    height: 8px;
    border-radius: 10px;
    opacity: 1;
    left: 0px;
    transform: rotate(0deg);
    transition: all 0.25s ease-in-out 0s;
  }
  .masthead .head--main .head-right .navicon-container .menu-icon span:nth-child(1) {
    top: 0px;
    width: 100%;
    transform-origin: left center;
  }
  .masthead .head--main .head-right .navicon-container .menu-icon span:nth-child(2) {
    top: 18px;
    width: 100%;
    transform-origin: left center;
  }
  .masthead .head--main .head-right .navicon-container .menu-icon span:nth-child(3) {
    top: 36px;
    width: 100%;
    transform-origin: left center;
  }
  .masthead .head--main .head-right .navicon-container .menu-icon.open span:nth-child(1) {
    transform: rotate(45deg);
    top: -3px;
    left: 8px;
  }
  .masthead .head--main .head-right .navicon-container .menu-icon.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }
  .masthead .head--main .head-right .navicon-container .menu-icon.open span:nth-child(3) {
    transform: rotate(-45deg);
    top: 39px;
    left: 8px;
  }
}
.masthead .newsletter--cta .newsletter-form {
  max-width: 825px;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .masthead .newsletter--cta .newsletter-form {
    display: none;
  }
}
.masthead .newsletter--cta .newsletter-form .title {
  float: left;
  padding: 0px 7px;
}
@media (min-width: 992px) {
  .masthead .newsletter--cta .newsletter-form .title {
    width: 470px;
  }
}
@media (max-width: 991px) {
  .masthead .newsletter--cta .newsletter-form .title {
    width: 100%;
  }
}
.masthead .newsletter--cta .newsletter-form .title span {
  color: #fff;
  font-weight: 700;
  line-height: 30px;
}
.masthead .newsletter--cta .newsletter-form .field {
  float: left;
  padding: 0px 7px;
}
@media (min-width: 992px) {
  .masthead .newsletter--cta .newsletter-form .field {
    width: calc(100% - 600px);
  }
}
@media (max-width: 991px) {
  .masthead .newsletter--cta .newsletter-form .field {
    width: calc(100% - 130px);
  }
}
.masthead .newsletter--cta .newsletter-form .field input {
  width: 100%;
  border-radius: 3px;
  border: none;
  line-height: 30px;
  padding-left: 15px;
}
.masthead .newsletter--cta .newsletter-form .submit {
  width: 130px;
  float: left;
  padding: 0px 7px;
}
.masthead .newsletter--cta .newsletter-form .submit .btn {
  color: #fff;
  font-weight: 700;
  border: none;
  display: block;
  line-height: 30px;
  width: 100%;
  border-radius: 3px;
}

.search--form {
  position: relative;
}
@media (min-width: 992px) {
  .search--form {
    margin: 7px 0px;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .search--form {
    margin: 7px 0px 25px;
  }
}
@media (max-width: 767px) {
  .search--form {
    margin: 15px 0px 15px;
  }
}
.search--form input.field {
  height: 46px;
  line-height: 46px;
  border: 1px solid #C3C3C3;
  display: block;
  border-radius: 3px;
  width: 100%;
  padding: 0px 15px;
}
.search--form button.submit {
  height: 100%;
  width: 45px;
  display: block;
  background-color: transparent;
  border: none;
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  font-size: 1.4em;
}
.search--form button.submit:hover {
  color: #000;
}

.vat-switch {
  width: 165px;
  height: 30px;
  display: inline-block;
  position: relative;
  transform: none;
}
.vat-switch .inner-rel {
  min-height: 30px;
}
.vat-switch .tag {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 700;
}
.vat-switch .tag.inc {
  left: 0px;
}
.vat-switch .tag.ex {
  right: 0px;
}
.vat-switch .switch {
  height: 20px;
  width: 34px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 70px;
  background-color: white;
  border-radius: 20px;
  cursor: pointer;
}
.vat-switch .switch .inner-rel {
  width: 100%;
  height: 100%;
  position: relative;
  min-height: auto;
}
.vat-switch .switch .inner-rel .switchbg {
  height: 15px;
  width: 30px;
  border-radius: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
.vat-switch .switch .inner-rel .indicator {
  width: 11px;
  height: 11px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  transition: all 0.2s ease-in-out 0s;
}
.vat-switch.exvat .switch .inner-rel .indicator {
  transform: translate(19px, -50%);
}
.vat-switch.incvat .switch .inner-rel .indicator {
  transform: translate(4px, -50%);
}
.currency-sel {
  display: inline-block;
}
@media (min-width: 768px) {
  .currency-sel {
    margin-left: 30px;
  }
}
.currency-sel .title {
  float: left;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 30px;
  color: #333;
}
@media (min-width: 401px) {
  .currency-sel .title.xs {
    display: none;
  }
}
@media (max-width: 400px) {
  .currency-sel .title.lg {
    display: none;
  }
}
.currency-sel .select {
  float: left;
  margin-left: 15px;
  position: relative;
}
.currency-sel .select .top {
  width: 90px;
  border: 1px solid #dbdbdb;
  position: relative;
  border-radius: 3px;
  height: 30px;
  padding: 5px 5px 5px 30px;
}
.currency-sel .select .top .map {
  width: 23px;
  height: 23px;
  border-radius: 100%;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 3px;
  transform: translateY(-50%);
}
.currency-sel .select .top .curr {
  font-weight: 700;
  display: block;
  line-height: 20px;
  vertical-align: top;
  font-size: 0.9em;
  text-align: left;
}
.currency-sel .select .top i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
}
.currency-sel .select .list {
  width: 100%;
  position: absolute;
  top: 100%;
  border: 1px solid #dbdbdb;
  z-index: 100;
  background-color: #fff;
}
.currency-sel .select .list .row {
  cursor: pointer;
  position: relative;
  padding: 5px 5px 5px 30px;
}
.currency-sel .select .list .row .map {
  width: 23px;
  height: 23px;
  border-radius: 100%;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 3px;
  transform: translateY(-50%);
}
.currency-sel .select .list .row .curr {
  font-weight: 700;
  display: block;
  line-height: 20px;
  vertical-align: top;
  font-size: 0.9em;
  text-align: left;
}
.currency-sel .select .list .row:hover {
  background-color: #dbdbdb;
}

@media (max-width: 991px) {
  .head-center {
    bottom: -55px;
  }

  .top-menu .nav-item:first-child {
    margin-top: 50px;
  }
}
.head-style-1 .topbask.inner {
  position: relative;
  height: 40px;
}
.head-style-1 .topbask.inner .items-circle {
  width: 25px !important;
  height: 25px;
  display: block;
  padding: 0px !important;
  line-height: 25px !important;
  color: #fff !important;
  text-align: center !important;
  border-radius: 100%;
  position: absolute;
  top: 0px;
  left: 25px;
  transform: translateY(-50%);
}
@media (min-width: 1231px) {
  .head-style-1.square-logo-style .masthead {
    padding-bottom: 15px;
  }
  .head-style-1.square-logo-style .masthead .head-left a {
    top: calc(50% - 25px) !important;
  }
  .head-style-1.square-logo-style .top-controls {
    margin-top: 30px;
  }
}
@media (max-width: 1230px) and (min-width: 992px) {
  .head-style-1.square-logo-style .masthead {
    padding-bottom: 15px;
  }
  .head-style-1.square-logo-style .masthead .head-left a {
    top: calc(50% - 25px) !important;
  }
  .head-style-1.square-logo-style .top-controls {
    margin-top: 30px;
  }
}
@media (min-width: 992px) {
  .head-style-1 .head-left {
    height: 80px !important;
  }
  .head-style-1 .head-left a {
    top: 26% !important;
  }
}
@media (max-width: 991px) {
  .head-style-1 .account-container {
    padding: 0px !important;
    margin-bottom: 0px;
  }
  .head-style-1 .head-left a {
    display: block;
    height: 80px;
  }
  .head-style-1 .head-left a img {
    max-height: 100%;
    width: auto;
  }
}
@media (max-width: 1230px) and (min-width: 992px) {
  .head-style-1 .topbask.inner .items-circle {
    left: 48px;
  }
  .head-style-1 .masthead {
    padding-bottom: 15px;
  }
  .head-style-1 .account-container {
    margin-bottom: 0px !important;
  }
}
@media (max-width: 991px) {
  .head-style-1 .topbask.inner .items-circle {
    left: 18px;
    font-size: 0.5em;
    display: block !important;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .head-style-1 .head-right {
    padding-top: 15px;
  }
}
@media (min-width: 1401px) {
  .head-style-1 .news-form {
    float: right;
  }
  .head-style-1 .contact-info {
    float: left;
    color: #fff;
  }
  .head-style-1 .contact-info a {
    color: #fff;
  }
}
@media (max-width: 1400px) {
  .head-style-1 .contact-info {
    display: none;
  }
}
.head-style-1 .search--form {
  margin: 0px;
  width: 100%;
}
@media (min-width: 1231px) {
  .head-style-1 .search--form {
    float: right;
  }
}
@media (min-width: 992px) {
  .head-style-1 .search--form {
    max-width: 530px;
  }
}
.head-style-1 .top-controls {
  margin: 15px 0px;
  text-align: center;
}
.head-style-1 #search {
  top: calc(100% + 10px) !important;
}
@media (min-width: 1401px) {
  .head-style-1 #search {
    width: 1400px;
    left: 64% !important;
  }
}
@media (max-width: 1400px) and (min-width: 1231px) {
  .head-style-1 #search {
    left: 69% !important;
    width: 1233px;
  }
}
@media (max-width: 1230px) and (min-width: 992px) {
  .head-style-1 #search {
    left: 58% !important;
  }
}
.head-style-2 .topbask.inner {
  position: relative;
  height: 40px;
}
.head-style-2 .topbask.inner .items-circle {
  width: 25px !important;
  height: 25px;
  display: block;
  padding: 0px !important;
  line-height: 25px !important;
  color: #fff !important;
  text-align: center !important;
  border-radius: 100%;
  position: absolute;
  top: 0px;
  left: 50px;
  transform: translateY(-50%);
}
@media (min-width: 1231px) {
  .head-style-2.square-logo-style .masthead {
    padding-bottom: 15px;
  }
  .head-style-2.square-logo-style .masthead .head-left a {
    top: calc(50% - 8px) !important;
  }
  .head-style-2.square-logo-style .top-controls {
    margin-bottom: 60px;
  }
}
@media (max-width: 1230px) and (min-width: 992px) {
  .head-style-2.square-logo-style .masthead {
    padding-bottom: 15px;
  }
  .head-style-2.square-logo-style .masthead .head-left a {
    top: calc(50% - 8px) !important;
  }
  .head-style-2.square-logo-style .top-controls {
    margin-bottom: 60px;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .head-style-2.square-logo-style .masthead {
    padding-bottom: 15px;
  }
  .head-style-2.square-logo-style .masthead {
    top: calc(50% - 8px) !important;
  }
  .head-style-2.square-logo-style .masthead .head-left {
    height: 150px !important;
    transform: translateY(-40px);
  }
  .head-style-2.square-logo-style .masthead .head-left a {
    height: 150px;
    max-height: 150%;
  }
  .head-style-2.square-logo-style .masthead .head-left a img {
    height: 150px;
    max-height: 150px;
  }
  .head-style-2.square-logo-style .masthead .head--main {
    padding-bottom: 0px;
    height: 110px;
  }
  .head-style-2.square-logo-style .top-controls {
    margin-bottom: 60px;
  }
}
@media (max-width: 1230px) and (min-width: 992px) {
  .head-style-2 .masthead {
    padding-bottom: 15px;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .head-style-2 .head-right {
    padding-top: 15px;
  }
}
.head-style-2 .top-bar {
  height: 5px;
}
.head-style-2 .masthead .head--main .head-right .top--widget a {
  font-weight: 700;
}
@media (min-width: 1231px) {
  .head-style-2 .head-left {
    height: 45px !important;
  }
}
@media (max-width: 1230px) and (min-width: 992px) {
  .head-style-2 .head-left {
    height: 80px !important;
  }
  .head-style-2 .head--main {
    height: 100px;
  }
  .head-style-2 .topbask.inner .items-circle {
    left: 50px !important;
  }
}
@media (max-width: 991px) {
  .head-style-2 .head-left {
    height: 80px !important;
  }
  .head-style-2 .head-left a {
    max-height: 100%;
    display: block;
  }
  .head-style-2 .head-left a img {
    max-height: 80px;
    width: auto;
  }
  .head-style-2 .account-container {
    padding: 0px;
  }
  .head-style-2 .topbask.inner .items-circle {
    display: block !important;
    font-size: 0.5em;
    left: 18px;
  }
}
@media (min-width: 992px) {
  .head-style-2 .original-menu {
    padding-top: 0px !important;
  }
}
.head-style-2 .top-controls .left {
  float: left;
}
@media (max-width: 991px) {
  .head-style-2 .top-controls .left {
    display: none;
  }
}
.head-style-2 .top-controls .right {
  float: right;
}
@media (max-width: 767px) {
  .head-style-2 .top-controls .right {
    float: none;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .head-style-2 .top-controls {
    margin-bottom: 35px;
  }
}
@media (max-width: 767px) {
  .head-style-2 .top-controls {
    margin-bottom: 20px;
  }
}
.head-style-2 .top-controls .contact-info {
  margin-top: 5px;
}
@media (max-width: 767px) {
  .head-style-2 .top-controls .contact-info {
    float: left;
  }
}
.head-style-2 .top-controls .currency-sel {
  padding-left: 10px;
}
@media (max-width: 767px) {
  .head-style-2 .top-controls .currency-sel {
    float: right;
  }
}
.head-style-2 .top-controls .currency-sel .title {
  color: #fff;
  font-size: 0.9em;
}
.head-style-2 .top-controls .currency-sel .top {
  border: none;
}
.head-style-2 .top-controls .currency-sel .top .map {
  border: 1px solid #fff !important;
}
.head-style-2 .top-controls .currency-sel .top .curr {
  color: #fff;
}
.head-style-2 .top-controls .currency-sel .top i {
  color: #fff;
  font-size: 0.9em;
}

.head-style-3 .items-circle {
  width: 25px;
  height: 25px;
  display: block;
  padding: 0px;
  line-height: 25px;
  color: #fff;
  text-align: center;
  border-radius: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  transform: translate(50%, -50%);
}
@media (min-width: 1231px) {
  .head-style-3.square-logo-style .masthead {
    padding-bottom: 35px;
  }
  .head-style-3.square-logo-style .masthead .head-left a {
    top: calc(50% - 20px) !important;
    height: 180px;
  }
  .head-style-3.square-logo-style .top-controls {
    margin-bottom: 75px;
  }
}
@media (max-width: 1230px) and (min-width: 992px) {
  .head-style-3.square-logo-style .masthead {
    padding-bottom: 15px;
  }
  .head-style-3.square-logo-style .masthead .head-left a {
    top: calc(50% - 20px) !important;
    height: 140px;
  }
  .head-style-3.square-logo-style .top-controls {
    margin-bottom: 60px;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .head-style-3.square-logo-style .head-left {
    position: relative;
    height: 80px;
  }
  .head-style-3.square-logo-style .head-left a {
    height: 130px;
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media (min-width: 992px) {
  .head-style-3 .head-left {
    height: 80px !important;
  }
  .head-style-3 .head-left a {
    height: 100px;
    top: 40% !important;
  }
  .head-style-3 .head-left a img {
    max-height: 100%;
    width: auto;
  }
}
@media (max-width: 991px) {
  .head-style-3 .account-container {
    padding: 0px !important;
    margin-bottom: 0px;
  }
  .head-style-3 .head-left a {
    display: block;
    height: 80px;
  }
  .head-style-3 .head-left a img {
    max-height: 100%;
    width: auto;
  }
}
@media (max-width: 991px) and (max-width: 767px) {
  .head-style-3 .head-right {
    transform: translateY(-10px);
  }
}
.head-style-3 .account-container {
  margin: 0px;
}
@media (max-width: 991px) and (min-width: 768px) {
  .head-style-3 .head-right {
    padding-top: 15px;
  }
}
@media (max-width: 991px) {
  .head-style-3 .navicon-container {
    padding-top: 12px !important;
  }
  .head-style-3 .menu-icon .bg-c-1 {
    background-color: #fff !important;
  }
  .head-style-3 .masthead .head--main .head-right .account-container {
    height: 45px;
  }
  .head-style-3 .masthead .head--main .head-right .cart-container {
    height: 45px;
  }
  .head-style-3 .masthead .head--main .head-right .cart-container a {
    height: 45px;
    display: block;
  }
  .head-style-3 .masthead .head--main .head-right .cart-container a .inner i {
    line-height: 45px;
  }
}
.head-style-3 .search--form {
  margin: 0px;
}
.head-style-3 .masthead .head--main .head-right .top--widget a {
  font-weight: 700;
}
.head-style-3 .top-controls {
  position: relative;
  margin-bottom: 35px;
}
.head-style-3 .top-controls .vat-switch .tag.off {
  color: #fff;
}
.head-style-3 .top-controls .inner-context {
  position: relative;
  z-index: 10;
  height: 30px;
}
.head-style-3 .top-controls .bg-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.3;
  z-index: 5;
}
.head-style-3 .top-controls .left {
  float: left;
}
@media (max-width: 991px) {
  .head-style-3 .top-controls .left {
    display: none;
  }
}
.head-style-3 .top-controls .right {
  float: right;
}
.head-style-3 .top-controls .right .map {
  background-color: #fff !important;
}
.head-style-3 .top-controls .contact-info {
  margin-top: 5px;
}
.head-style-3 .top-controls .currency-sel {
  padding-left: 10px;
}
.head-style-3 .top-controls .currency-sel .title {
  color: #fff;
  font-size: 0.9em;
}
.head-style-3 .top-controls .currency-sel .top {
  border: none;
}
.head-style-3 .top-controls .currency-sel .top .curr {
  color: #fff;
}
.head-style-3 .top-controls .currency-sel .top i {
  color: #fff;
  font-size: 0.9em;
}
.head-style-3 .contact-info {
  font-size: 0.9em;
  color: #fff !important;
}
.head-style-3 .contact-info a {
  color: #fff;
}
@media (min-width: 992px) {
  .head-style-3 .top--widget {
    width: 50% !important;
  }
}
@media (max-width: 991px) {
  .head-style-3 .top--widget i {
    color: #fff !important;
  }
}
@media (min-width: 992px) {
  .head-style-3 .top--widget .cart {
    background-color: #fff;
    position: relative;
    border-radius: 3px;
  }
  .head-style-3 .top--widget .cart .account {
    display: inline-block;
  }
  .head-style-3 .top--widget .cart .inner {
    position: relative;
    z-index: 7;
    display: block;
    height: 45px;
    text-align: center !important;
  }
  .head-style-3 .top--widget .cart .inner span {
    line-height: 45px !important;
    color: #fff !important;
    float: none !important;
    display: inline-block !important;
    width: auto !important;
    vertical-align: top;
  }
  .head-style-3 .top--widget .cart .inner i {
    line-height: 45px !important;
    float: none !important;
    display: inline-block;
    width: auto !important;
    vertical-align: top;
  }
}
.head-style-3 .top--widget a.bg-c-1 {
  position: relative;
  border-radius: 3px;
  overflow: hidden;
}
.head-style-3 .top--widget a.bg-c-1 .inner {
  position: relative;
  z-index: 10;
  display: block;
  height: 45px;
  text-align: center !important;
}
.head-style-3 .top--widget a.bg-c-1 .inner span {
  line-height: 45px !important;
  color: #fff !important;
  float: none !important;
  width: auto !important;
  vertical-align: top;
}
@media (min-width: 992px) {
  .head-style-3 .top--widget a.bg-c-1 .inner span {
    display: inline-block !important;
  }
}
.head-style-3 .top--widget a.bg-c-1 .inner i {
  line-height: 45px !important;
  float: none !important;
  display: inline-block;
  width: auto !important;
  vertical-align: top;
}
.head-style-3 .top--widget a.bg-c-1:before {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.3;
  z-index: 5;
}
.head-style-3 .top--widget a {
  transition: transform 0.1s;
}
.head-style-3 .top--widget a:hover {
  box-shadow: 0px 11px 11px #00000026;
  transform: translateY(-5px);
}
.head-style-3 #search {
  top: calc(100% + 10px) !important;
}
@media (min-width: 1401px) {
  .head-style-3 #search {
    width: 1400px;
    left: 64% !important;
  }
}
@media (max-width: 1400px) and (min-width: 1231px) {
  .head-style-3 #search {
    left: 69% !important;
    width: 1233px;
  }
}
@media (max-width: 1230px) and (min-width: 992px) {
  .head-style-3 #search {
    left: 58% !important;
  }
}
@media (min-width: 1231px) {
  .head-style-4.square-logo-style .masthead .head-left a {
    top: calc(50% - 10px);
  }
  .head-style-4.square-logo-style .masthead {
    padding-bottom: 15px !important;
  }
}
@media (max-width: 1230px) and (min-width: 992px) {
  .head-style-4.square-logo-style .masthead {
    padding-bottom: 15px;
  }
  .head-style-4.square-logo-style .masthead .head-left a {
    top: calc(50% - 30px) !important;
    height: 140px;
  }
  .head-style-4.square-logo-style .top-controls {
    margin-bottom: 60px;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .head-style-4.square-logo-style .top-controls {
    margin-bottom: 20px;
  }
  .head-style-4.square-logo-style .head-left {
    position: relative;
    height: 80px;
  }
  .head-style-4.square-logo-style .head-left a {
    height: 130px;
    position: absolute !important;
    left: 0px;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }
}
@media (min-width: 992px) and (max-width: 1230px) {
  .head-style-4 .head-left {
    height: 80px !important;
  }
  .head-style-4 .head-left a {
    height: 100px;
    top: 40% !important;
  }
  .head-style-4 .head-left a img {
    max-height: 100%;
    width: auto;
  }
}
@media (max-width: 991px) {
  .head-style-4 .account-container {
    padding: 0px !important;
    margin-bottom: 0px;
    margin-top: 5px !important;
  }
  .head-style-4 .head-left a {
    display: block;
    height: 80px;
  }
  .head-style-4 .head-left a img {
    max-height: 100%;
    width: auto;
  }
}
@media (max-width: 991px) and (max-width: 767px) {
  .head-style-4 .head-right {
    transform: translateY(-10px);
  }
}
.head-style-4 .account-container {
  margin: 0px;
}
@media (max-width: 991px) and (min-width: 768px) {
  .head-style-4 .head-right {
    padding-top: 5px;
  }
}
.head-style-4 .masthead {
  padding-top: 30px;
  padding-bottom: 0px !important;
}
.head-style-4 .masthead .head-left a {
  top: 50%;
}
@media (max-width: 1230px) and (min-width: 992px) {
  .head-style-4 .head-left {
    height: 45px;
    position: relative;
  }
}
.head-style-4 .top-controls {
  margin-bottom: 15px;
}
.head-style-4 .search--form {
  margin: 0px;
}
.head-style-4 .masthead .head--main .head-right .top--widget a {
  font-weight: 700;
}
.head-style-4 .contact-info {
  text-align: center;
  margin-bottom: 15px;
  font-size: 1.3em;
  line-height: 30px;
}
@media (max-width: 1400px) and (min-width: 1231px) {
  .head-style-4 .contact-info {
    font-size: 1em;
  }
}
.head-style-4 .top--widget {
  width: 50% !important;
}
@media (min-width: 992px) {
  .head-style-4 .top--widget .cart {
    position: relative;
    border-radius: 3px;
  }
  .head-style-4 .top--widget .cart .inner {
    position: relative;
    z-index: 10;
    display: block;
    height: 45px;
    text-align: center !important;
  }
  .head-style-4 .top--widget .cart .inner span {
    line-height: 45px !important;
    color: #fff !important;
    display: inline-block !important;
    width: auto !important;
    float: none !important;
    vertical-align: top;
  }
  .head-style-4 .top--widget .cart .inner i {
    line-height: 45px !important;
    display: inline-block !important;
    width: auto !important;
    float: none !important;
  }
}
.head-style-4 .top--widget a.account {
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  background-color: #36373A;
}
.head-style-4 .top--widget a.account .inner {
  position: relative;
  z-index: 10;
  display: block;
  height: 45px;
  text-align: center !important;
  text-align: center !important;
}
.head-style-4 .top--widget a.account .inner span {
  line-height: 45px !important;
  color: #fff !important;
  display: inline-block !important;
  width: auto !important;
  float: none !important;
  vertical-align: top;
}
.head-style-4 .top--widget a.account .inner i {
  line-height: 45px !important;
  display: inline-block !important;
  width: auto !important;
  float: none !important;
}
.head-style-4 .top--widget a {
  transition: transform 0.1s;
}
.head-style-4 .top--widget a:hover {
  box-shadow: 0px 11px 11px #00000026;
  transform: translateY(-5px);
}
.head-style-4 .topbask.inner {
  position: relative;
  height: 40px;
}
.head-style-4 .topbask.inner .items-circle {
  width: 25px !important;
  height: 25px;
  display: block;
  padding: 0px !important;
  line-height: 25px !important;
  color: #fff !important;
  text-align: center !important;
  border-radius: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  transform: translate(50%, -50%);
}
@media (max-width: 991px) {
  .head-style-4 .topbask.inner .items-circle {
    font-size: 0.5em;
    top: 8px;
  }
}
.head-style-4 .vat-switch {
  width: 50%;
  float: left;
  height: 30px;
  padding: 0px 8px;
  position: relative;
  transform: none;
}
@media (max-width: 400px) {
  .head-style-4 .vat-switch {
    width: calc(100% - 125px) !important;
  }
}
.head-style-4 .vat-switch .inner-rel {
  width: 100%;
  height: 30px;
  position: relative;
  max-width: 167px;
  margin: 0 auto;
}
.head-style-4 .vat-switch .tag {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 700;
  font-size: 0.9em;
}
.head-style-4 .vat-switch .tag.inc {
  left: 0px;
}
.head-style-4 .vat-switch .tag.ex {
  right: 0px;
}
.head-style-4 .vat-switch .switch {
  height: 20px;
  width: 34px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  background-color: white;
  border-radius: 20px;
  cursor: pointer;
}
.head-style-4 .vat-switch .switch .inner-rel {
  width: 100%;
  height: 100%;
  position: relative;
}
.head-style-4 .vat-switch .switch .inner-rel .switchbg {
  height: 15px;
  width: 30px;
  border-radius: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
.head-style-4 .vat-switch .switch .inner-rel .indicator {
  width: 11px;
  height: 11px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  transition: all 0.2s ease-in-out 0s;
}
.head-style-4 .vat-switch.exvat .switch .inner-rel .indicator {
  transform: translate(19px, -50%);
}
.head-style-4 .vat-switch.incvat .switch .inner-rel .indicator {
  transform: translate(4px, -50%);
}
.head-style-4 .currency-sel {
  padding: 0px 8px;
  width: 50%;
  float: right;
  margin: 0px;
}
.head-style-4 .currency-sel .inner {
  background-color: #dbdbdb;
  border-radius: 3px;
}
.head-style-4 .currency-sel .title {
  float: left;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 30px;
  color: #333;
  padding: 0px 0px 0px 8px;
  font-size: 0.9em;
}
@media (max-width: 400px) {
  .head-style-4 .currency-sel {
    width: 125px !important;
  }
  .head-style-4 .currency-sel .title {
    font-size: 1.1em;
  }
}
.head-style-4 .currency-sel .select {
  float: right;
  position: relative;
}
.head-style-4 .currency-sel .select .top {
  width: 85px;
  border: none;
  position: relative;
  border-radius: 3px;
  height: 30px;
  padding: 5px 5px 5px 30px;
}
.head-style-4 .currency-sel .select .top .map {
  width: 23px;
  height: 23px;
  border-radius: 100%;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 3px;
  transform: translateY(-50%);
}
.head-style-4 .currency-sel .select .top .curr {
  font-weight: 700;
  display: block;
  line-height: 20px;
  vertical-align: top;
  font-size: 0.9em;
  text-align: left;
}
.head-style-4 .currency-sel .select .top i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
}
.head-style-4 .currency-sel .select .list {
  width: 100%;
  position: absolute;
  top: 100%;
  border: 1px solid #dbdbdb;
  z-index: 100;
  background-color: #fff;
}
.head-style-4 .currency-sel .select .list .row {
  cursor: pointer;
  position: relative;
  padding: 5px 5px 5px 30px;
}
.head-style-4 .currency-sel .select .list .row .map {
  width: 23px;
  height: 23px;
  border-radius: 100%;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 3px;
  transform: translateY(-50%);
}
.head-style-4 .currency-sel .select .list .row .curr {
  font-weight: 700;
  display: block;
  line-height: 20px;
  vertical-align: top;
  font-size: 0.9em;
  text-align: left;
}
.head-style-4 .currency-sel .select .list .row:hover {
  background-color: #dbdbdb;
}
@media (max-width: 1230px) and (min-width: 992px) {
  .head-style-4 {
    position: relative;
    padding-top: 40px;
    max-width: 970px;
    margin: 0 auto;
  }
  .head-style-4 .top-controls {
    position: absolute;
    width: 430px;
    top: 0px;
    right: 15px;
  }
  .head-style-4 .contact-info {
    display: none;
  }
  .head-style-4 .dw-md-4 {
    position: static;
  }
  .head-style-4 .dw-md-4.head-left {
    position: relative;
  }
  .head-style-4 .dw-md-4.head-left a {
    display: block;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media (max-width: 767px) {
  .head-style-4 .top-controls {
    position: absolute;
    top: -30px !important;
  }
}
@media (max-width: 991px) {
  .head-style-4 {
    position: relative;
    padding-top: 40px;
    max-width: 970px;
    margin: 0 auto;
  }
  .head-style-4 .top-controls {
    position: absolute;
    top: 0px;
  }
}
@media (max-width: 991px) and (max-width: 991px) and (min-width: 768px) {
  .head-style-4 .top-controls {
    right: 50%;
    transform: translateX(50%);
  }
}
@media (max-width: 991px) and (max-width: 767px) {
  .head-style-4 .top-controls {
    right: 0px;
  }
  .head-style-4 .top-controls .currency-sel {
    padding-right: 0px;
  }
  .head-style-4 .top-controls .currency-sel .inner {
    border-radius: 0px 0px 0px 3px;
  }
}
@media (max-width: 991px) and (min-width: 551px) {
  .head-style-4 .top-controls {
    width: 430px;
  }
}
@media (max-width: 991px) and (max-width: 550px) {
  .head-style-4 .top-controls {
    width: 100vw;
    font-size: 0.9em;
  }
  .head-style-4 .top-controls .vat-switch {
    width: 50%;
  }
  .head-style-4 .top-controls .currency-sel {
    width: 50%;
  }
}
@media (max-width: 991px) {
  .head-style-4 .contact-info {
    display: none;
  }
}
@media (max-width: 991px) {
  .head-style-4 .dw-md-4 {
    position: static;
  }
}
@media (max-width: 991px) {
  .head-style-4 .masthead {
    padding-top: 0px;
  }
}
@media (max-width: 991px) {
  .head-style-4 .head--main {
    position: static !important;
  }
}
@media (max-width: 991px) {
  .head-style-4 .head-left a {
    position: static !important;
    transform: none !important;
  }
}
@media (max-width: 991px) {
  .head-style-4 .navicon-container {
    padding-top: 17px !important;
  }
}
@media (max-width: 991px) {
  .head-style-4 .cart-container, .head-style-4 .account-container {
    padding-top: 6px;
    vertical-align: top;
    margin-left: 15px;
    display: inline-block;
    width: 30px !important;
    height: 45px;
  }
  .head-style-4 .cart-container a, .head-style-4 .account-container a {
    display: block;
    background-color: transparent !important;
    box-shadow: none !important;
    transform: none !important;
  }
  .head-style-4 .cart-container a i, .head-style-4 .account-container a i {
    line-height: 45px;
  }
}
@media (max-width: 991px) {
  .head-style-4 .cart {
    background-color: transparent !important;
  }
}
@media (max-width: 991px) {
  .head-style-4 .head-center {
    left: 50%;
    transform: translateX(-50%);
    padding: 0px;
    max-width: 720px;
    width: calc(100% - 20px) !important;
  }
}
@media (max-width: 767px) {
  .head-style-4 .head-left {
    padding-top: 10px;
  }
}
.head-style-4 #search {
  top: calc(100% + 10px) !important;
}
@media (min-width: 1401px) {
  .head-style-4 #search {
    width: 1400px;
    left: 64% !important;
  }
}
@media (max-width: 1400px) and (min-width: 1231px) {
  .head-style-4 #search {
    left: 69% !important;
    width: 1233px;
  }
}
@media (max-width: 1230px) and (min-width: 992px) {
  .head-style-4 #search {
    left: 58% !important;
  }
}
.top-search {
  position: relative;
}
.top-search .spinner {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
}
.top-search .spinner i {
  width: 100%;
  line-height: 40px;
  text-align: center;
  font-size: 1.6em;
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.menu-section #search {
  top: calc(100% + 10px) !important;
}
@media (min-width: 1401px) {
  .menu-section #search {
    width: 1370px;
    left: auto !important;
    right: 0px;
    transform: none !important;
  }
  .menu-section #search .container:before {
    right: 100px !important;
    left: auto !important;
  }
}
@media (max-width: 1400px) and (min-width: 1231px) {
  .menu-section #search {
    left: auto !important;
    right: -15px;
    transform: none !important;
    width: 1233px;
  }
  .menu-section #search .container:before {
    right: 100px !important;
    left: auto !important;
  }
}
@media (max-width: 1230px) and (min-width: 992px) {
  .menu-section #search {
    left: auto !important;
    right: 0px;
    transform: none !important;
    width: 972px;
  }
  .menu-section #search .container:before {
    right: 100px !important;
    left: auto !important;
  }
}
.top-search #search {
  position: absolute;
  border: 1px solid #d6d6d6;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% - 1px);
  z-index: 20;
  border-radius: 3px;
}

.top-search #search .container {
  background-color: white;
  position: relative;
  max-width: 100%;
}

.top-search #search .container::before {
  content: "";
  width: 30px;
  height: 30px;
  left: 50%;
  top: 0px;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: white;
  border: 1px solid #d6d6d6;
  position: absolute;
  z-index: -1;
}

.top-search #search .container .image-result {
  position: relative;
  display: block;
}
@media (min-width: 551px) {
  .top-search #search .container .image-result {
    margin-bottom: 30px;
  }
}
@media (max-width: 550px) {
  .top-search #search .container .image-result {
    margin-bottom: 10px;
  }
}

.top-search #search .container .image-result .img-container {
  position: relative;
  width: 100px;
  height: 100px;
  border: 1px solid #f1f1f1;
}

.top-search #search .container .image-result .title {
  font-weight: 700;
}

.top-search #search .container .image-result .title {
  width: calc(100% - 120px);
  position: absolute;
  top: 10px;
  right: 0px;
}

.top-search #search .container .image-result .price {
  width: calc(100% - 120px);
  position: absolute;
  bottom: 0px;
  right: 0px;
  color: #000;
}

.top-search #search .section.rel {
  position: relative;
  overflow: hidden;
}

.top-search #search .section.rel .result-pages {
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-top: 1px solid #d6d6d6;
}

.top-search #search .section.rel .result-pages .inner-rel {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 15px;
}

.top-search #search .section.rel .result-pages .inner-rel strong {
  color: black;
}

.top-search #search .section.rel .result-pages .inner-rel::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 10000vh;
  background-color: #d6d6d6;
  left: calc(100% + 15px);
  bottom: -20px;
}

.top-search #search .main-results, .top-search #search .result-categories {
  padding: 30px;
}

.top-search #search .category-container {
  overflow-y: auto;
}
@media (min-width: 1401px) {
  .top-search #search .category-container {
    max-height: 400px;
  }
}
@media (max-width: 1400px) and (min-width: 992px) {
  .top-search #search .category-container {
    max-height: 650px;
  }
}
@media (max-width: 550px) {
  .top-search #search .category-container {
    max-height: 100px;
  }
}

.top-search #search .section-title {
  font-size: 1.4em;
  margin-bottom: 25px;
  display: block;
}

.top-search #search .result-categories a {
  font-size: 1.3em;
  display: inline-block;
  margin-bottom: 15px;
  cursor: pointer;
  color: #000;
}

#search .result-pages .inner-rel .search-paging {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.2em;
}

#search .result-pages .inner-rel .search-paging .searchpage {
  margin: 0px 4px;
  cursor: pointer;
}

#search .result-pages .inner-rel .search-paging .searchpage.disabled {
  color: #afafaf;
}

@media (min-width: 992px) {
  #search .main-results {
    min-height: 496px;
  }
}

#search .main-results.no-results {
  min-height: 250px;
}

#search .result-pages {
  min-height: 47px;
}

@media (min-width: 992px) {
  .top-search {
    display: block !important;
  }
}
@media (max-width: 991px) {
  .top-search {
    display: none;
  }
  .top-search .category-container {
    max-height: 150px;
    background: linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background: linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    background-attachment: local, local, scroll, scroll;
  }

  .top-search #search {
    width: calc(100% - 30px);
  }
  .top-search #search .product-results {
    max-height: 400px;
    overflow-y: auto;
    height: calc(100vh - 510px);
    background: linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background: linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    background-attachment: local, local, scroll, scroll;
  }

  .top-search #search .main-results {
    padding: 15px;
  }

  .top-search #search .result-categories {
    padding: 0px 15px 0px 15px;
  }
}
@media (max-width: 991px) and (max-width: 550px) {
  .top-search #search .result-categories a {
    font-size: 5vw;
    margin-bottom: 10px;
  }
}
@media (max-width: 991px) {
  .top-search #search .result-pages {
    position: static !important;
    margin-top: 15px;
  }
  .top-search #search .result-pages .inner-rel {
    padding: 10px 0px !important;
  }
}
@media (max-width: 991px) and (max-width: 550px) {
  .top-search #search .result-pages {
    text-align: center;
  }
  .top-search #search .result-pages .search-paging {
    position: static;
    transform: none;
    font-size: 4vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
  }
  .top-search #search .result-pages .search-paging .searchpage {
    margin: 0px;
    min-width: 20px;
    text-align: center;
  }
}
/*SCROLL-BAR*/
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d0d0d0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #404041;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #404041;
}

.rr-drop-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  background-color: #fff;
  padding: 1rem;
  grid-gap: 1rem;
}
.rr-drop-grid .rr-drop-col > a {
  background-color: #c6c6c6;
  width: 100%;
  display: flex;
  justify-content: center;
  grid-gap: 1rem;
  padding: 0.5rem;
  color: #404041;
  font-weight: bold;
  align-items: center;
  border-radius: 3px;
}
.rr-drop-grid .rr-drop-col > a i {
  transition: transform 0.3s;
  display: none;
}
.rr-drop-grid .rr-drop-col .rr-drop-flx {
  padding-top: 0.5rem;
  position: relative;
  transition: max-height 0.3s;
}
.rr-drop-grid .rr-drop-col .rr-drop-flx .rr-mob-parent {
  display: none;
}
.rr-drop-grid .rr-drop-col .rr-drop-flx > div {
  padding: 0.25rem;
  text-align: left;
}
.rr-drop-grid .rr-drop-flx:after {
  content: "";
  display: none;
  position: sticky;
  bottom: 0;
  left: 0;
  height: 0.5rem;
  width: 100%;
  background-color: #dbdbdb;
}
@media (max-width: 990px) {
  .rr-drop-grid {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    background-color: transparent;
    grid-gap: 0;
  }
  .rr-drop-grid .rr-drop-col > a {
    justify-content: space-between;
    background-color: transparent;
    border-bottom: 1px solid #808080;
    padding: 0.5rem 0;
  }
  .rr-drop-grid .rr-drop-col > a i {
    display: block;
  }
  .rr-drop-grid .rr-drop-col .rr-drop-flx {
    border-bottom: 1px solid #808080;
    padding: 0;
    background-color: #fff;
    transition: max-height 0.5s;
    max-height: 0;
    overflow: hidden;
  }
  .rr-drop-grid .rr-drop-col .rr-drop-flx .rr-mob-parent {
    display: block;
  }
  .rr-drop-grid .rr-drop-col .rr-drop-flx > div {
    padding: 0.25rem;
    text-align: left;
  }
  .rr-drop-grid .rr-drop-col .rr-drop-flx > div:not(:empty):not(:first-child) {
    border-top: 1px solid #808080;
  }
  .rr-drop-grid .rr-drop-col .rr-drop-flx.open {
    max-height: 200px;
  }
  .rr-drop-grid .rr-drop-col:has(.open) i {
    transform: rotate(180deg);
  }
  .rr-drop-grid .rr-drop-flx:after {
    background-color: #fff;
    height: 0.25rem;
    display: block;
  }
}

.menu-section .top-menu {
  /*  @include media('>l-desktop' ) {
      width: calc(100% - 380px);
  }

  @include media('<=l-desktop', '>desktop') {
      width: calc(100% - 300px);
  }

  @include media('<=desktop', '>tablet') {
      width: calc(100% - 270px);
  }*/
}
@media (max-width: 991px) {
  .menu-section .top-menu .original-menu .nav-item:nth-child(2) {
    margin-top: 50px;
  }
}
@media (min-width: 992px) {
  .menu-section .active-hov .subcats {
    display: flex;
    width: 100%;
    text-align: left;
    padding: 0px 15px;
  }
  .menu-section .active-hov .subcats .sub-cat-col {
    width: 20%;
    margin-bottom: 15px;
    min-height: 200px;
  }
  .menu-section .active-hov .subcats .sub-cat-col .sub-cat-list a {
    display: block;
    padding: 5px 0px;
  }
  .menu-section .active-hov .subcats .sub-cat-col ul {
    padding: 0px;
    margin: 10px 0px 0px;
    border: none !important;
  }
  .menu-section .active-hov .subcats .sub-cat-col.promo-col {
    margin-left: auto;
  }
  .menu-section .active-hov .subcats > ul.sub-cat-list {
    padding: 0px;
    border: none !important;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 15px;
  }
  .menu-section .active-hov .subcats > ul.sub-cat-list li {
    width: 20%;
  }
  .menu-section .active-hov .subcats > ul.sub-cat-list li a {
    padding: 10px 0px;
    display: block;
  }
  .menu-section .nav-dr-title {
    position: relative;
  }
  .menu-section .nav-dr-title .outter .inner {
    position: relative;
    padding-right: 22px !important;
    width: auto !important;
    display: inline-block;
    text-align: center;
  }
  .menu-section .nav-dr-title .outter .inner i {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
  }
}
@media (max-width: 991px) {
  .menu-section .active-hov {
    display: none !important;
  }
  .menu-section .nav-dr-body .brand-btn {
    display: block;
    width: 100%;
    padding: 10px;
  }
}
@media (min-width: 1401px) {
  .menu-section.with-search .top-menu {
    width: calc(100% - 380px);
    float: left;
  }
}
@media (max-width: 1400px) and (min-width: 1231px) {
  .menu-section.with-search .top-menu {
    width: calc(100% - 300px);
    float: left;
  }
}
@media (max-width: 1230px) and (min-width: 992px) {
  .menu-section.with-search .top-menu {
    width: calc(100% - 270px);
    float: left;
  }
  .menu-section.with-search .top-menu .nav-item {
    width: calc(100% / 4) !important;
  }
}
@media (min-width: 1401px) {
  .menu-section.with-search .top-search {
    width: 380px;
    float: right;
  }
}
@media (max-width: 1400px) and (min-width: 1231px) {
  .menu-section.with-search .top-search {
    width: 300px;
    float: right;
  }
}
@media (max-width: 1230px) and (min-width: 992px) {
  .menu-section.with-search .top-search {
    width: 270px;
    float: right;
  }
}
@media (min-width: 1231px) {
  .menu-section.with-search .original-menu.has-blog .nav-item {
    width: calc(100% / 4);
  }
  .menu-section.with-search .original-menu.no-blog .nav-item {
    width: calc(100% / 5);
  }
}
.menu-section ul {
  list-style: none;
}
@media (min-width: 992px) {
  .menu-section .original-menu {
    position: relative;
    display: flex;
    justify-content: space-between;
  }
  .menu-section .original-menu .clear {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1230px) {
  .menu-section .original-menu .nav-item {
    font-size: 0.95em;
  }
  .menu-section .original-menu .nav-item.home-link {
    display: none;
  }
}
@media (min-width: 992px) {
  .menu-section .nav-item {
    text-align: center;
    transition: background-color 0.2s ease;
    display: block;
  }
}
@media (min-width: 992px) and (min-width: 1401px) {
  .menu-section .nav-item {
    width: auto;
  }
}
@media (min-width: 992px) and (min-width: 1231px) {
  .menu-section .nav-item {
    padding: 0px 15px;
  }
}
@media (min-width: 992px) and (max-width: 1230px) and (min-width: 992px) {
  .menu-section .nav-item {
    padding: 0px 10px;
  }
}
@media (min-width: 992px) {
  .menu-section .nav-item.nodrop {
    font-weight: 700;
    text-decoration: none !important;
    display: block;
    height: 60px;
    line-height: 20px;
  }
  .menu-section .nav-item.nodrop:hover span {
    color: #fff;
  }
}
@media (min-width: 992px) {
  .menu-section .nav-item .categoriesTitle, .menu-section .nav-item .shopsTitle, .menu-section .nav-item .shopslastTitle, .menu-section .nav-item.home span {
    font-weight: 700;
    text-decoration: none !important;
    display: block;
    line-height: 20px;
    padding: 0px 0px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (min-width: 992px) {
  .menu-section .nav-item.dropout.custom-dropout {
    position: relative;
  }
  .menu-section .nav-item.dropout.custom-dropout.hide-drop .title span a span i {
    display: none !important;
  }
  .menu-section .nav-item.dropout.custom-dropout:hover .title a span {
    color: #fff !important;
  }
  .menu-section .nav-item.dropout.custom-dropout .title span {
    display: block;
  }
  .menu-section .nav-item.dropout.custom-dropout .title span a {
    width: 100%;
    height: 100%;
    position: relative;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  .menu-section .nav-item.dropout.custom-dropout .title span a span i {
    display: inline-block !important;
    margin-left: 5px;
  }
}
@media (min-width: 992px) {
  .menu-section .nav-item.dropout.custom-dropout .title span a:hover span {
    color: #fff !important;
  }
}
@media (min-width: 992px) {
  .menu-section .nav-item.dropout.custom-dropout .drop {
    width: 100% !important;
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  .menu-section .nav-item.dropout.custom-dropout .drop {
    padding: 15px 0px;
  }
}
@media (min-width: 992px) {
  .menu-section .nav-item.dropout.custom-dropout.hide-drop i {
    display: none !important;
    opacity: 0;
  }
  .menu-section .nav-item.dropout.custom-dropout.hide-drop a i {
    display: none !important;
  }
  .menu-section .nav-item.dropout.custom-dropout.hide-drop .nav-dr-body {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .menu-section .nav-item.dropout:hover .drop {
    display: block;
    border-radius: 0px 0px 5px 5px;
    overflow: hidden;
    box-shadow: 0px 11px 11px #00000026;
  }
}
@media (min-width: 992px) {
  .menu-section .nav-item .drop {
    display: none;
    transition: max-height 0.3s ease-in-out;
    border-radius: 0px 0px 3px 3px;
    position: absolute;
    top: calc(100% - 0px);
    left: 0px;
    width: 100%;
    z-index: 15;
    background-color: #fff;
  }
}
@media screen and (min-width: 992px) and (min-width: 1400px) {
  .menu-section .nav-item .drop {
    width: 1370px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1399px) and (min-width: 1231px) {
  .menu-section .nav-item .drop {
    width: 1200px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1399px) and (max-width: 1230px) and (min-width: 992px) {
  .menu-section .nav-item .drop {
    width: 940px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1399px) and (max-width: 991px) {
  .menu-section .nav-item .drop {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .menu-section .nav-item .drop.multi ul.newres {
    list-style: none;
    padding: 10px 15px;
    margin: 0px;
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  .menu-section .nav-item .drop.multi ul.newres {
    position: relative;
  }
  .menu-section .nav-item .drop.multi ul.newres:before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 79px;
    transform: translateX(-50%);
    border-bottom: 1px solid #e1e1e1;
  }
}
@media (min-width: 992px) and (min-width: 992px) and (min-width: 1231px) {
  .menu-section .nav-item .drop.multi ul.newres:before {
    width: calc(100% - 30px);
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  .menu-section .nav-item .drop.multi ul.newres li.toplevel.first .subcats.open {
    box-shadow: 0px 11px 11px #00000026;
  }
}
@media (min-width: 992px) {
  .menu-section .nav-item .drop.multi ul.newres li.toplevel, .menu-section .nav-item .drop.multi ul.newres li.brandlink {
    float: left;
    width: calc(100% / 9);
    display: block;
    margin-bottom: 30px;
    cursor: pointer;
    /* 
    &.open {
        .subcats {
            max-height: 9999999999px;
        }
    }
    */
    /*&.open {
        .cat--selector {
            &:before {
                transform: translateY(-100%) !important;
            }
        }
    }*/
  }
  .menu-section .nav-item .drop.multi ul.newres li.toplevel.hov-active .cat--selector:before, .menu-section .nav-item .drop.multi ul.newres li.brandlink.hov-active .cat--selector:before {
    transform: translateY(-100%);
  }
  .menu-section .nav-item .drop.multi ul.newres li.toplevel .subcats, .menu-section .nav-item .drop.multi ul.newres li.brandlink .subcats {
    clear: both;
  }
  .menu-section .nav-item .drop.multi ul.newres li.toplevel .subcats .sub-cat-col, .menu-section .nav-item .drop.multi ul.newres li.brandlink .subcats .sub-cat-col {
    width: 100%;
    float: left;
    text-align: left;
    max-width: 270px;
    padding-bottom: 15px;
  }
  .menu-section .nav-item .drop.multi ul.newres li.toplevel .subcats .sub-cat-col.promo-col, .menu-section .nav-item .drop.multi ul.newres li.brandlink .subcats .sub-cat-col.promo-col {
    float: right;
    text-align: right;
  }
  .menu-section .nav-item .drop.multi ul.newres li.toplevel .subcats .sub-cat-col.promo-col a, .menu-section .nav-item .drop.multi ul.newres li.brandlink .subcats .sub-cat-col.promo-col a {
    display: inline-block;
    width: 100%;
    max-width: 340px;
  }
}
@media (min-width: 992px) and (max-width: 991px) {
  .menu-section .nav-item .drop.multi ul.newres li.toplevel.shut .subcats, .menu-section .nav-item .drop.multi ul.newres li.brandlink.shut .subcats {
    max-height: 0px;
  }
}
@media (min-width: 992px) {
  .menu-section .nav-item .drop.multi ul.newres li.toplevel .cat--selector, .menu-section .nav-item .drop.multi ul.newres li.brandlink .cat--selector {
    position: relative;
    font-weight: 700;
    font-size: 1em;
    text-transform: uppercase;
    padding: 25px 0px;
    cursor: pointer;
    overflow: hidden;
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  .menu-section .nav-item .drop.multi ul.newres li.toplevel .cat--selector, .menu-section .nav-item .drop.multi ul.newres li.brandlink .cat--selector {
    min-height: 70px;
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  .menu-section .nav-item .drop.multi ul.newres li.toplevel .cat--selector .cat-txt, .menu-section .nav-item .drop.multi ul.newres li.brandlink .cat--selector .cat-txt {
    max-height: 100%;
    max-width: 100%;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
}
@media (min-width: 992px) {
  .menu-section .nav-item .drop.multi ul.newres li.toplevel .cat--selector i, .menu-section .nav-item .drop.multi ul.newres li.brandlink .cat--selector i {
    display: none;
  }
}
@media (min-width: 992px) {
  .menu-section .nav-item .drop.multi ul.newres li.toplevel .cat--selector:before, .menu-section .nav-item .drop.multi ul.newres li.brandlink .cat--selector:before {
    content: "";
    width: 100%;
    height: 5px;
    background-color: #333;
    position: absolute;
    left: 0px;
    top: 100%;
    transition: all 0.4s ease-out;
  }
}
@media (min-width: 992px) {
  .menu-section .nav-item .drop.multi ul.newres li.toplevel .subcats, .menu-section .nav-item .drop.multi ul.newres li.brandlink .subcats {
    padding: 0px;
    margin: 0px;
    top: 100%;
    left: 0px;
    width: 100%;
    overflow: hidden;
    display: none;
  }
  .menu-section .nav-item .drop.multi ul.newres li.toplevel .subcats ul, .menu-section .nav-item .drop.multi ul.newres li.brandlink .subcats ul {
    list-style: none;
    padding: 0px;
    display: block;
    width: 100%;
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  .menu-section .nav-item .drop.multi ul.newres li.toplevel .subcats, .menu-section .nav-item .drop.multi ul.newres li.brandlink .subcats {
    display: none !important;
  }
  .menu-section .nav-item .drop.multi ul.newres li.toplevel .subcats .bd-c-1, .menu-section .nav-item .drop.multi ul.newres li.brandlink .subcats .bd-c-1 {
    border: none !important;
  }
}
@media (min-width: 992px) and (min-width: 992px) and (min-width: 1231px) {
  .menu-section .nav-item .drop.multi ul.newres li.toplevel .subcats .sub-cat-list.shop-list, .menu-section .nav-item .drop.multi ul.newres li.brandlink .subcats .sub-cat-list.shop-list {
    margin: 0px -18px;
  }
}
@media (min-width: 992px) and (min-width: 992px) and (max-width: 1230px) and (min-width: 992px) {
  .menu-section .nav-item .drop.multi ul.newres li.toplevel .subcats .sub-cat-list.shop-list, .menu-section .nav-item .drop.multi ul.newres li.brandlink .subcats .sub-cat-list.shop-list {
    margin: 0px -5px;
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  .menu-section .nav-item .drop.multi ul.newres li.toplevel .subcats .sub-cat-list .sub-item, .menu-section .nav-item .drop.multi ul.newres li.brandlink .subcats .sub-cat-list .sub-item {
    width: 20%;
    float: left;
    text-align: left;
  }
  .menu-section .nav-item .drop.multi ul.newres li.toplevel .subcats .sub-cat-list .sub-item a, .menu-section .nav-item .drop.multi ul.newres li.brandlink .subcats .sub-cat-list .sub-item a {
    padding: 10px 0px;
  }
}
@media (min-width: 992px) and (min-width: 992px) and (min-width: 1231px) {
  .menu-section .nav-item .drop.multi ul.newres li.toplevel .subcats .sub-cat-list li.shoplink, .menu-section .nav-item .drop.multi ul.newres li.brandlink .subcats .sub-cat-list li.shoplink {
    width: calc(100% / 8);
    float: left;
    text-align: left;
    padding: 0px 18px;
  }
}
@media (min-width: 992px) and (min-width: 992px) and (max-width: 1230px) and (min-width: 992px) {
  .menu-section .nav-item .drop.multi ul.newres li.toplevel .subcats .sub-cat-list li.shoplink, .menu-section .nav-item .drop.multi ul.newres li.brandlink .subcats .sub-cat-list li.shoplink {
    width: calc(100% / 6);
    float: left;
    text-align: left;
    padding: 0px 5px;
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  .menu-section .nav-item .drop.multi ul.newres li.toplevel .subcats .sub-cat-list li.shoplink a, .menu-section .nav-item .drop.multi ul.newres li.brandlink .subcats .sub-cat-list li.shoplink a {
    padding: 0px;
  }
  .menu-section .nav-item .drop.multi ul.newres li.toplevel .subcats .sub-cat-list li.shoplink a img, .menu-section .nav-item .drop.multi ul.newres li.brandlink .subcats .sub-cat-list li.shoplink a img {
    margin-bottom: 10px;
  }
}
@media (min-width: 992px) {
  .menu-section .nav-item .drop.multi ul.newres li.toplevel .subcats a, .menu-section .nav-item .drop.multi ul.newres li.brandlink .subcats a {
    display: block;
    color: #333;
    margin-top: 10px;
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  .menu-section .nav-item .drop.multi ul.newres .toplevel.med {
    width: 11%;
  }
  .menu-section .nav-item .drop.multi ul.newres .toplevel.large {
    width: 16%;
  }
  .menu-section .nav-item .drop.multi ul.newres .toplevel.last {
    width: 6%;
  }
}
@media (min-width: 992px) {
  .menu-section .nav-item .drop ul.newres.condensed {
    display: none !important;
  }
  .menu-section .nav-item .drop ul.newres.condensed:before {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .menu-section .nav-item .drop ul.newres li.mobile--aboutus {
    display: none;
  }
}
@media (min-width: 992px) {
  .menu-section .nav-item.single ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }
  .menu-section .nav-item.single ul li a {
    display: block;
    padding: 10px;
    font-weight: bold;
  }
  .menu-section .nav-item.single ul li a img {
    margin: 0 auto;
  }
  .menu-section .nav-item.single ul li .shop-list {
    display: block;
  }
}
@media (max-width: 1230px) and (min-width: 992px) {
  .menu-section .original-menu .nav-item.home--link {
    display: none !important;
  }
  .menu-section .cat--selector {
    font-size: 0.8em !important;
  }
}
@media (max-width: 991px) {
  .menu-section .home-link {
    display: none;
  }
  .menu-section .shoplink a {
    font-weight: 700;
  }
  .menu-section .shoplink a img {
    display: none;
  }
  .menu-section .original-menu {
    overflow: hidden;
  }
  .menu-section .original-menu.shut {
    height: 0px;
  }
  .menu-section .original-menu.open {
    height: auto;
    padding-top: 10px;
    padding-bottom: 15px;
  }
  .menu-section .original-menu .nav-item.home {
    display: block;
    padding: 10px;
    border-bottom: 1px solid #c3c3c3;
  }
  .menu-section .original-menu .nav-item.nodrop {
    display: block;
    padding: 10px;
    border-bottom: 1px solid #c3c3c3;
  }
  .menu-section .original-menu .nav-item.home-link {
    display: none !important;
  }
  .menu-section .original-menu .nav-item.custom-dropout .drop a {
    padding: 10px;
    display: block;
  }
  .menu-section .original-menu .nav-item .title {
    border-bottom: 1px solid #c3c3c3;
  }
  .menu-section .original-menu .nav-item .title.open i {
    transform: scaleY(-1) translateY(-50%);
  }
  .menu-section .original-menu .nav-item .title span:not(.inner) {
    display: block;
    padding: 10px 0px 10px 10px;
    cursor: pointer;
    position: relative;
  }
  .menu-section .original-menu .nav-item .title span:not(.inner) a span {
    padding: 0px;
    color: #000;
  }
  .menu-section .original-menu .nav-item .title span:not(.inner) i {
    position: absolute;
    top: 50%;
    transform-origin: top;
    transform: translateY(-50%);
    right: 0px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }
  .menu-section .original-menu .nav-item .inner {
    overflow: hidden;
    min-height: 0px !important;
  }
  .menu-section .original-menu .nav-item .inner.shut {
    height: 0;
    min-height: 0px !important;
  }
  .menu-section .original-menu .nav-item .inner.open {
    height: auto;
  }
  .menu-section .original-menu .nav-item.hide-drop i {
    display: none !important;
    opacity: 0;
  }
  .menu-section .original-menu .nav-item.hide-drop .nav-dr-body {
    display: none !important;
  }
}
@media (max-width: 991px) and (max-width: 991px) {
  .menu-section .original-menu .nav-item a.menu-item--aboutus {
    pointer-events: none;
  }
  .menu-section .original-menu .nav-item a.menu-item--aboutus.toplevel {
    pointer-events: initial;
  }
}
@media (max-width: 991px) {
  .menu-section .newres {
    padding: 0px;
    margin: 0px;
  }
  .menu-section .newres .toplevel {
    display: block;
  }
  .menu-section .newres .toplevel .cat--selector {
    position: relative;
  }
  .menu-section .newres .toplevel .cat--selector a {
    display: block;
    width: calc(100% - 40px);
  }
  .menu-section .newres .toplevel .cat--selector .cat-txt {
    display: block;
    padding: 10px;
  }
  .menu-section .newres .toplevel .cat--selector i {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 0px;
    top: 0px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
  }
  .menu-section .newres .toplevel .btn-1 {
    background-color: transparent;
    font-weight: 500;
    display: block;
    padding: 10px;
    border-bottom: 1px solid #c3c3c3;
  }
  .menu-section .newres .toplevel .btn-1 i {
    float: right;
  }
  .menu-section .newres .toplevel.mob-shut .subcats {
    height: 0px;
  }
  .menu-section .newres .toplevel.mob-open .subcats {
    height: auto;
  }
  .menu-section .newres .toplevel.mob-open .cat--selector i {
    transform: rotate(180deg);
  }
  .menu-section .newres .brandlink a {
    display: block;
    background-color: transparent;
    padding: 10px;
    font-weight: 500;
  }
  .menu-section .newres .subcats {
    overflow: hidden;
  }
  .menu-section .newres .subcats ul {
    padding: 0px;
    border: none !important;
  }
  .menu-section .newres .subcats ul li a {
    padding: 10px;
    display: block;
  }
  .menu-section .newres .subcats .sub-cat-col {
    overflow: hidden;
  }
  .menu-section .newres .subcats .sub-cat-col .subcat-title {
    display: block;
    padding: 10px;
    position: relative;
  }
  .menu-section .newres .subcats .sub-cat-col .subcat-title i {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
  }
  .menu-section .newres .subcats .sub-cat-col.shut {
    max-height: 40px;
  }
  .menu-section .newres .subcats .sub-cat-col.open {
    max-height: 9999999px;
  }
  .menu-section .newres .subcats .sub-cat-col.open .subcat-title i {
    transform: rotate(180deg);
  }
}

.shops-list .shoptext {
  text-align: left;
}
@media (min-width: 1231px) {
  .shops-list .toplevel {
    width: calc(100% / 8);
    float: left;
  }
}
@media (max-width: 1230px) and (min-width: 992px) {
  .shops-list {
    width: 940px;
  }
  .shops-list .toplevel {
    width: calc(100% / 4);
    float: left;
  }
}
@media (max-width: 991px) {
  .shops-list {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .nav-dr-body .brand-btn {
    display: inline-block;
    width: 100%;
    padding: 10px;
  }

  .toplevel:first-child .subcats {
    max-height: 9999999999px !important;
    display: flex !important;
    z-index: 1 !important;
  }
  .toplevel:first-child .subcats.no-show {
    height: 0px;
    width: 0px;
    overflow: hidden;
  }
  .toplevel:first-child .cat--selector.sink:before {
    transform: none !important;
  }
}
@media (max-width: 991px) {
  .menu-section.with-search .top-search {
    position: absolute;
    width: calc(100% - 20px);
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 720px;
  }
  .menu-section.with-search .top-search .search--form {
    margin: 0px !important;
  }
}
@media (max-width: 991px) and (max-width: 767px) {
  .menu-section.with-search .top-search {
    top: 10px;
  }
}

.menu-section .nav-dr-title .outter .inner {
  padding: 0px;
}
.menu-section .nav-item.only .title a {
  color: #000;
}
.menu-section .nav-item.dropout.custom-dropout .title span a span {
  color: #000;
}

.menu-section.dark-style .nav-dr-title .outter .inner {
  color: #fff;
  padding: 0px;
}
.menu-section.dark-style .nav-item.only .title a {
  color: #fff;
}
.menu-section.dark-style .nav-item.dropout.custom-dropout .title span a span {
  color: #fff;
}
@media (max-width: 991px) {
  .menu-section.dark-style i {
    color: #fff !important;
  }
  .menu-section.dark-style .top-menu .drop a {
    color: #fff;
  }
  .menu-section.dark-style .cat-txt {
    color: #fff;
  }
  .menu-section.dark-style .subcat-title {
    color: #fff;
  }
  .menu-section.dark-style .top-menu .drop a .shop-txt {
    color: #fff;
  }
  .menu-section.dark-style .top-menu .drop a {
    color: #fff;
  }
}

.footer--container {
  padding-top: 90px;
  padding-bottom: 40px;
  color: #fff;
}
.footer--container a {
  color: #fff;
}
.footer--container .footercall {
  color: #fff;
}
.footer--container .footercall a {
  font-weight: 700;
  text-decoration: none;
  color: #fff;
}
.footer--container .img {
  display: inline-block;
  max-width: 200px;
}
.footer--container .smediabtns {
  display: inline-block;
  vertical-align: super;
}
.footer--container .smediabtns a {
  margin-left: 15px;
  color: #fff;
  font-size: 1.3em;
}
.footer--container .smediabtns a.fa-linkedin {
  font-size: 1.5em;
}
.footer--container .smediabtns a.tik {
  width: 20px;
  display: inline-block;
}
.footer--container .footercall {
  margin-top: 25px;
}
.footer--container .pagelinks {
  color: #fff;
  font-size: 0.8em;
  font-weight: 100;
}
@media (max-width: 767px) {
  .footer--container .pagelinks {
    font-size: 0.8em;
  }
}
.footer--container .pagelinks a {
  cursor: pointer;
  color: #fff;
}
.footer--container .pagelinks a:hover {
  text-decoration: underline;
}
@media (max-width: 767px) {
  .footer--container .pagelinks a {
    font-weight: 100;
  }
}
.footer--container .poweredby {
  color: #fff;
  text-transform: uppercase;
  font-size: 0.75em;
}
@media (min-width: 768px) {
  .footer--container .poweredby {
    float: right;
  }
}
@media (max-width: 767px) {
  .footer--container .poweredby {
    margin: 50px 0px 15px;
  }
}
.footer--container .poweredby img {
  width: 96px;
  height: auto;
}
@media (min-width: 768px) {
  .footer--container .right-txt {
    text-align: right;
  }
}
@media (max-width: 767px) {
  .footer--container .right-txt {
    margin-top: 15px;
  }
}
@media (min-width: 768px) {
  .footer--container .footer-middle {
    margin: 130px 0px 70px;
  }
}
@media (max-width: 767px) {
  .footer--container .footer-middle {
    margin: 50px 0px 30px;
  }
}
@media (max-width: 767px) {
  .footer--container .footer-right {
    padding-top: 50px;
  }
}
.footer--container .footer-right .footer--link {
  display: inline-block;
  font-weight: 700;
  margin-bottom: 15px;
}

.bottom-letter-form {
  position: relative;
}
.bottom-letter-form:before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  background-color: #000;
  z-index: 5;
  opacity: 0.2;
}
.bottom-letter-form .form-container {
  padding: 50px 0px;
  position: relative;
  z-index: 10;
  margin: 0 auto;
  text-align: center;
}
.bottom-letter-form .form-container .form {
  display: inline-block;
}
.bottom-letter-form .form-container .form span {
  max-width: 360px;
  color: #fff;
  font-weight: 700;
  display: inline-block;
  text-align: left;
  font-size: 1.4em;
  line-height: 23px;
  vertical-align: bottom;
}
@media (max-width: 991px) {
  .bottom-letter-form .form-container .form span {
    display: block;
    text-align: center;
    margin-bottom: 15px;
  }
}
.bottom-letter-form .form-container .form input {
  height: 46px;
  border: none;
  padding: 15px;
  border-radius: 3px;
  margin-right: 15px;
}
@media (min-width: 1401px) {
  .bottom-letter-form .form-container .form input {
    width: 470px;
  }
}
.bottom-letter-form .form-container .form .btn {
  height: 46px;
  border: none;
  border-radius: 3px;
  color: #fff;
  font-size: 1em;
  font-weight: 700;
  padding: 0px 20px;
}
@media (min-width: 1401px) {
  .bottom-letter-form .form-container .form .btn {
    width: 140px;
  }
}
.bottom-letter-form .form-container .social-links {
  display: inline-block;
  vertical-align: bottom;
}
@media (max-width: 991px) {
  .bottom-letter-form .form-container .social-links {
    margin-top: 15px;
    display: block;
  }
}
.bottom-letter-form .form-container .social-links .social-link {
  width: 46px;
  height: 46px;
  display: inline-block;
  background-color: #fff;
  border-radius: 100%;
  vertical-align: bottom;
  margin: 0px 10px;
}
.bottom-letter-form .form-container .social-links .social-link i {
  font-size: 1.4em;
  line-height: 46px;
}

.footer-style-2 {
  padding: 80px 0px 0px !important;
}
@media (min-width: 992px) {
  .footer-style-2 .right {
    float: right;
    width: calc(100% - 475px);
  }
  .footer-style-2 .left {
    float: left;
    width: 475px;
  }
}
.footer-style-2 .foot-title {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.05em;
}
.footer-style-2 .foot-title .line {
  width: 30px;
  height: 2px;
  margin: 10px 0px 30px;
}
.footer-style-2 .main-links a {
  display: inline-block;
  clear: both;
  margin-bottom: 20px;
  font-weight: 700;
}
.footer-style-2 .intouch .row {
  margin-bottom: 30px;
  position: relative;
  padding-left: 40px;
  max-width: 290px;
}
.footer-style-2 .intouch .row i {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 30px;
  text-align: center;
  font-size: 1.2em;
}
.footer-style-2 .footer-middle {
  margin: 50px 0px 30px;
}
.footer-style-2 .footer-bottom {
  padding: 10px;
  font-weight: 200;
  font-size: 0.8em;
}
.footer-style-2.bg-c-1 i {
  color: #fff;
}

.footer-style-3 {
  padding: 80px 0px 0px !important;
}
@media (min-width: 992px) {
  .footer-style-3 .right {
    float: right;
  }
  .footer-style-3 .left {
    float: left;
  }
}
.footer-style-3 .foot-title {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.05em;
}
.footer-style-3 .foot-title .line {
  width: 30px;
  height: 2px;
  margin: 10px 0px 30px;
  background-color: #fff;
}
.footer-style-3 .main-links a {
  display: inline-block;
  margin-bottom: 20px;
  font-weight: 700;
}
.footer-style-3 .intouch .row {
  margin-bottom: 30px;
  position: relative;
  padding-left: 40px;
  max-width: 290px;
}
.footer-style-3 .intouch .row i {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 30px;
  text-align: center;
  font-size: 1.2em;
}
.footer-style-3 .footer-middle {
  margin: 50px 0px 30px;
}
.footer-style-3 .footer-bottom {
  padding: 10px;
  font-weight: 200;
  font-size: 0.8em;
}

.footer-style-4 {
  padding: 80px 0px 0px !important;
}
@media (min-width: 992px) {
  .footer-style-4 .right {
    float: right;
  }
  .footer-style-4 .left {
    float: left;
  }
}
.footer-style-4 .brdr {
  position: relative;
}
@media (min-width: 1231px) {
  .footer-style-4 .brdr {
    border-right: 2px solid #fff;
    border-left: 2px solid #fff;
    padding-left: 30px;
    transform: translateX(-30px);
  }
}
.footer-style-4 .footer--link {
  font-weight: 400 !important;
  display: inline-block;
}
.footer-style-4 .foot-title {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.05em;
}
.footer-style-4 .foot-title .line {
  width: 30px;
  height: 2px;
  margin: 10px 0px 30px;
}
.footer-style-4 .main-links a {
  display: inline-block;
  margin-bottom: 20px;
  font-weight: 700;
}
.footer-style-4 .intouch .row {
  margin-bottom: 30px;
  position: relative;
  padding-left: 40px;
  max-width: 290px;
}
.footer-style-4 .intouch .row i {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 30px;
  text-align: center;
  font-size: 1.2em;
}
.footer-style-4 .footer-middle {
  margin: 50px 0px 30px;
}
.footer-style-4 .footer-bottom {
  padding: 10px;
  font-weight: 200;
  font-size: 0.8em;
}
.footer-style-4 .footer-bottom .copyright {
  margin-right: 15px;
}
.footer-style-4 .footer-bottom .pagelinks {
  display: inline-block;
  font-size: 1em;
}

.footer--link {
  position: relative;
}
.footer--link:before {
  width: 0%;
  height: 2px;
  content: "";
  position: absolute;
  top: 20px;
  left: 0px;
  background-color: #fff;
  transition: width 0.2s;
}
.footer--link:hover:before {
  width: 100%;
}

.call-footer {
  text-align: center;
  padding: 45px 0px;
}
.call-footer .phone-round {
  width: 60px;
  height: 60px;
  display: inline-block;
  background-color: #fff;
  line-height: 60px;
  border-radius: 100%;
  font-size: 1.8em;
  margin: 0px 30px;
}
@media (max-width: 991px) {
  .call-footer .phone-round {
    display: block;
    margin: 0 auto 30px;
  }
}
.call-footer span {
  font-size: 1.9em;
  color: #fff;
}
.call-footer strong {
  color: #fff;
}
.call-footer a {
  color: #fff;
}

.main-links a {
  position: relative;
}
.main-links a:before {
  width: 0%;
  height: 2px;
  content: "";
  position: absolute;
  top: 20px;
  left: 0px;
  background-color: #fff;
  transition: width 0.2s;
}
.main-links a:hover:before {
  width: 100%;
}

.payment-providers .img {
  display: block;
  float: left;
  min-height: 37px;
  margin-right: 15px;
  margin-bottom: 15px;
}
.payment-providers .img img {
  max-width: none;
}

.footer-middle .toright {
  width: 100%;
}
@media (min-width: 992px) {
  .footer-middle .toright .payment-providers {
    text-align: right;
  }
}
.footer-middle .toright .payment-providers .img {
  max-width: none;
  float: none;
  display: inline-block;
  margin: 0px 0px 15px 15px !important;
}

.listing #products {
  opacity: 1;
}
.listing #products.loading {
  opacity: 0;
}
@media (min-width: 1231px) {
  .listing.home .category {
    width: 20% !important;
  }
}
.listing .category .listing-link {
  max-width: 220px;
  margin: 0 auto 30px auto;
  display: block;
  position: relative;
}
@media (min-width: 768px) {
  .listing .category .listing-link {
    min-height: 465px;
  }
}
.listing .category .listing-link .prod-actions .btn {
  width: calc(100% - 40px);
  float: left;
}
.listing .category .listing-link .add-fav {
  width: 40px;
  height: 40px;
  position: absolute;
  margin-top: 15px;
  bottom: 0px;
  right: 0px;
}
.listing .category .listing-link .add-fav .heart {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.8em;
}
.listing .category .listing-link .add-fav .plus {
  color: #333;
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: #fff;
  border-radius: 100%;
  font-size: 0.9em;
}
.listing .category .listing-link .img--container {
  position: relative;
}
.listing .category .listing-link .img--container .sticker {
  position: absolute;
  top: 0px;
  right: 0px;
}
.listing .category .listing-link .img--container .avaliable--options {
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.listing .category .listing-link .img--container .avaliable--options .tag {
  color: #fff;
  font-weight: 700;
  margin-top: 6px;
  line-height: 20px;
  padding: 0px 10px;
  font-size: 0.75em;
  display: inline-block;
}
.listing .category .listing-link .title {
  font-weight: 700;
  max-width: 250px;
  min-height: 60px;
  margin-top: 30px;
}
.listing .category .listing-link .price {
  margin-top: 15px;
}
.listing .category .listing-link .price .val {
  font-weight: 700;
}
.listing .category .listing-link .btn {
  max-width: 160px;
  line-height: 20px;
  font-size: 0.9em;
  padding: 10px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  margin-top: 15px;
  display: block;
}

div.links.hide--wish {
  display: none;
}

.product-listingpage {
  margin-top: 25px;
}
.product-listingpage .paging {
  margin: 40px auto 30px 60px;
  text-align: center;
  display: inline-block;
  clear: both;
}
.product-listingpage .paging .pagination {
  display: block;
  float: left;
  width: 43px;
  height: 43px;
  line-height: 43px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  color: #36373A;
  font-weight: 700;
}
.product-listingpage .paging .pagination:hover {
  background-color: #ccc;
}
.product-listingpage .paging .pagination:first-child {
  border-left: 1px solid #ccc;
}
.product-listingpage .paging .pagination.current {
  background-color: #36373A;
  color: #fff;
}
@media (min-width: 992px) {
  .product-listingpage .listing-container {
    padding-left: 10px;
    /*            @include media('>desktop') {
        width: calc(100% - 288px);
        float: left;
    }

    @include media('<=desktop', '>tablet') {
        width: calc(100% - 250px);
        float: left;
    }*/
  }
}
.product-listingpage .listing-container .listing-head {
  margin-bottom: 30px;
}
.product-listingpage .listing-container .listing-head h1 {
  margin: 0px 0px 15px 0px;
  text-align: left;
}
.product-listingpage .listing-container .filter--btn {
  line-height: 30px;
  margin-bottom: 30px;
  border-radius: 3px;
  text-align: center;
  color: #fff;
}

.filter {
  background-color: #F7F7F7;
  border: 1px solid #f1f1f1;
  padding: 15px;
}
.filter h3 {
  font-size: 1.3em;
  margin-top: 0px;
}
.filter h3 span {
  line-height: 30px;
}
.filter h3 i {
  margin-right: 10px;
  font-size: 1.1em;
  line-height: 30px;
}
.filter .prod-colour-picker .section {
  margin: 0px -5px;
}
.filter .prod-colour-picker .section .sml-pad {
  padding: 5px;
  width: calc(100% / 6);
}
.filter .coloursquare {
  width: 28px;
  height: 28px;
  position: relative;
  display: block;
  border-radius: 100%;
  overflow: hidden;
  cursor: pointer;
  margin: 0 auto;
  border: 1px solid #000;
}
.filter .coloursquare input {
  display: none;
}
.filter .row label {
  display: block;
  position: relative;
  padding: 10px 10px 10px 40px;
  cursor: pointer;
}
.filter .row label input {
  display: none;
}
.filter .row label .dummycheck {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
}
@media (min-width: 992px) {
  .filter .filter-overlay {
    display: none !important;
  }
}
@media (max-width: 991px) {
  .filter {
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 280px;
    z-index: 9999;
    overflow: auto;
    height: 100vh;
    transform: translate(0%);
    transition-duration: 0.2s;
  }
  .filter.mob-shut {
    transform: translateX(-100%);
  }
}

@media (max-width: 991px) {
  .filter-overlay {
    opacity: 0.5;
    display: block;
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: #000;
    z-index: 9998;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    transition: opacity 0.2s ease-out;
  }
  .filter-overlay.hide {
    opacity: 0;
    height: 0;
  }
}
.filter-accordion .top {
  padding: 5px 50px 5px 0px;
  position: relative;
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 10px;
  margin-top: 10px;
}
.filter-accordion .top i {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}
.filter-accordion .top.closed i {
  transform: translateY(-50%) rotate(180deg);
}
.filter-accordion .top strong {
  font-size: 1.15em;
}
.filter-accordion .body {
  overflow: hidden;
}
.filter-accordion .body.open {
  max-height: 999999px;
}
.filter-accordion .body.closed {
  max-height: 0px;
}
.filter-accordion .selected-filters {
  border: 1px solid #f1f1f1;
  padding: 15px;
  border-radius: 3px;
  background-color: #fff;
}
.filter-accordion .selected-filters .selected-filter {
  margin-bottom: 10px;
  cursor: pointer;
}
.filter-accordion .selected-filters .selected-filter i {
  margin-right: 5px;
}
.filter-accordion .selected-filters .selected-filter span {
  font-size: 0.9em;
  text-decoration: underline;
}
.filter-accordion .selected-filters .selected-filter:hover span {
  text-decoration: none;
}
.filter-accordion .selected-filters .clear-filters {
  padding: 10px;
  background-color: #f7f7f7;
  color: #000;
  border-radius: 3px;
  text-align: center;
  display: block;
  font-weight: 700;
  cursor: pointer;
}
.filter-accordion .selected-filters .clear-filters:hover {
  background-color: #333;
  color: #fff;
}

.dummycheck {
  width: 30px;
  height: 30px;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  display: block;
  cursor: pointer;
}
.dummycheck i {
  width: 30px;
  line-height: 28px;
  text-align: center;
  visibility: hidden;
}

label:has(:checked) .dummycheck i {
  visibility: visible !important;
}

@media (min-width: 992px) {
  .filter-boot {
    width: 285px;
    position: sticky;
    left: 0px;
    top: 15px;
  }
  .filter-boot .filter-accordion {
    max-height: calc(100vh - 120px);
    overflow: auto;
    padding: 0px 15px;
    margin: 0px -15px;
  }

  .listing-boot {
    width: calc(100% - 285px);
  }
}
.cat-link {
  cursor: pointer;
  display: block;
  box-shadow: 0px 11px 11px #00000026;
  border-radius: 3px;
  margin: 15px auto;
}
.cat-link .img-container {
  height: 240px;
  background-color: #fff;
  position: relative;
  border-radius: 3px 3px 0px 0px;
}
.cat-link .img-container img {
  position: absolute;
  max-width: 100%;
  height: auto;
  left: 50%;
  top: 50%;
  max-height: 100%;
  transform: translateX(-50%) translateY(-50%);
}
.cat-link .img-container img.pimg {
  width: auto;
}
.cat-link .bottom {
  line-height: 30px;
  padding: 10px;
  color: #fff;
  font-weight: 700;
  text-align: center;
}

.dep-sel {
  margin-bottom: 30px;
}
.dep-sel .select-department-btn {
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-weight: 700;
  border-radius: 3px;
  max-width: 300px;
  margin: 0 auto;
}

.dep-pop {
  position: fixed;
  left: 0px;
  top: 0px;
  display: grid;
  grid-row-start: 0;
  grid-column-start: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999999999;
}
.dep-pop .inner {
  display: grid;
}
.dep-pop .inner > * {
  grid-row-start: 1;
  grid-column-start: 1;
}
.dep-pop .overlay {
  opacity: 0.5;
  background-color: #000;
  width: 100%;
  height: 100%;
}
.dep-pop .content {
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  padding: 30px;
  width: calc(100% - 60px);
  max-width: 1000px;
}
.dep-pop .content .close {
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0px;
  transform: translate(50%, -50%);
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.dep-pop .flx-bx {
  width: 100%;
  height: 100vh;
}
.dep-pop .dep-list {
  max-height: calc(100vh - 200px);
  min-height: 350px;
  overflow: auto;
  padding: 0px 15px;
}

@keyframes bouncing-loader {
  100% {
    opacity: 0.1;
    transform: translate3d(0px, -1rem, 0px);
  }
}
.loader {
  text-align: center;
}

.loading-text h2 {
  margin-bottom: 90px;
}

.bouncing-loader {
  display: flex;
  justify-content: center;
  position: absolute;
  top: calc(50%);
  left: 50%;
  transition: none;
  transform: translate(-50%, -50%);
  text-align: center;
}

.bouncing-loader > div {
  width: 1rem;
  height: 1rem;
  margin: 3rem 0.2rem;
  border-radius: 50%;
  transition: none;
  animation: 0.6s ease 0s infinite alternate none running bouncing-loader;
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

.bouncing-loader > div:nth-child(4) {
  animation-delay: 0.6s;
}

.box .heading {
  min-height: 30px;
}

.cats-toggler {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1.5rem;
  border-radius: 3px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.cats-toggler i {
  font-size: 1.2rem;
}
@media (min-width: 991px) {
  .cats-toggler {
    display: none;
  }
}

.cats-toggler.toggled i {
  transform: rotate(180deg);
}

.condensed-cats .cat-title {
  padding: 0.75rem 1rem 0;
  max-width: fit-content;
  font-weight: bold;
}
@media (max-width: 990px) {
  .condensed-cats .cat-title {
    display: none;
  }
}
.condensed-cats .cat-flex {
  display: flex;
  grid-gap: 0.75rem;
  flex-wrap: wrap;
}
.condensed-cats .border {
  padding: 1rem;
  border-radius: 0 3px 3px 3px;
}
.condensed-cats .cat-link {
  margin: 0;
}
@media (max-width: 990px) {
  .condensed-cats .cat-link {
    flex-grow: 1;
  }
}
.condensed-cats .cat-link.first {
  order: -1;
}
@media (max-width: 990px) {
  .condensed-cats {
    display: none;
  }
}

.pword-bar {
  font-weight: 700;
  color: #fff;
  padding: 15px 25px;
  display: flex;
  align-items: center;
  gap: 15px;
  border-radius: 4px;
}
.pword-bar span u {
  cursor: pointer;
}

.password-required {
  font-size: 0.95rem;
}

.promo--bullets {
  margin-top: 50px;
  margin-bottom: 20px;
}
.promo--bullets .bullet {
  border-radius: 3px;
  text-align: center;
  font-size: 1.2em;
  padding: 12px;
  margin-bottom: 30px;
}
.promo--bullets .bullet i {
  color: #fff;
  font-size: 1.2em;
  margin: 0px 15px;
}
.promo--bullets .bullet span {
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
}

.shop--section {
  background-color: #F2F2F2;
  padding: 50px;
}
.shop--section .shop--link {
  background-color: #fff;
  text-align: center;
  display: block;
  max-width: 380px;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 3px;
  padding: 30px;
}
.shop--section .shop--link strong {
  display: block;
  font-size: 1.5em;
  margin-bottom: 30px;
}
.shop--section .shop--link .btn {
  color: #fff;
  display: inline-block;
  width: 160px;
  border-radius: 3px;
  line-height: 40px;
}

.category-home-link {
  height: 305px;
  position: relative;
  display: block;
  cursor: pointer;
  background-color: #000;
  max-width: 662px;
  margin: 30px auto 0px;
}
.category-home-link .border {
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  border: 1px solid #fff;
}
.category-home-link .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  max-width: 300px;
  width: calc(100% - 60px);
  border-radius: 3px;
  padding: 15px;
  color: #fff;
  font-weight: 400;
}

.group-link {
  border-radius: 3px;
  box-shadow: 0px 11px 11px #00000026;
  display: block;
}
.group-link .img--container {
  height: 240px;
  background-color: #fff;
  position: relative;
  border-radius: 3px 3px 0px 0px;
}
.group-link .bottom {
  line-height: 30px;
  padding: 10px;
  color: #fff;
  font-weight: 700;
  text-align: center;
}

.large-cat-home-link {
  padding: 90px;
  padding: 80px;
  background-color: #36373A;
  margin-top: 100px;
  outline: 1px solid #fff;
  outline-offset: -32px;
}
.large-cat-home-link .txt-content {
  max-width: 450px;
}
.large-cat-home-link h2 {
  margin-top: 0px;
  padding-top: 0px;
  color: #fff;
  margin-bottom: 15px;
  padding-bottom: 0px;
  font-size: 2.6em;
}
.large-cat-home-link .txt {
  color: #fff;
  display: block;
  margin-bottom: 30px;
}
.large-cat-home-link a.btn {
  border-radius: 3px;
  text-align: center;
  line-height: 50px;
  height: 50px;
  width: 225px;
  display: block;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  background-color: #fff;
}

.border-img {
  padding-top: 51.33%;
  background-color: #333;
  outline: 1px solid #fff;
  outline-offset: -29px;
}

.sitemap-page ul {
  padding: 0px;
  list-style: none;
}
.sitemap-page h1 {
  text-align: left;
}
.sitemap-page h2 {
  font-size: 1.5em;
}

@media (min-width: 1401px) {
  .lg-flleft {
    float: left;
  }

  .lg-flright {
    float: right;
  }
}
.home--slider {
  background-color: #333;
  max-width: 1900px;
  margin: 0px auto;
}
.home--slider .bx-controls-direction {
  display: none !important;
}

.container-banner {
  max-width: 1900px;
  margin: 0 auto;
}

@media (min-width: 1231px) {
  .noslide-desk .bx-controls {
    display: none;
  }
  .noslide-desk .category--slider {
    width: 100% !important;
    text-align: center !important;
    left: 0px !important;
  }
  .noslide-desk .category--slider .dw-md-3 {
    display: inline-block;
    float: none !important;
  }
  .noslide-desk .bx-clone {
    display: none !important;
  }
}

.slider {
  position: relative;
}
.slider .bx-controls-direction a {
  position: absolute;
  top: 50%;
  font-size: 1.3em;
  transform: translateY(-50%);
}
@media (min-width: 992px) {
  .slider .bx-controls-direction .bx-prev, .slider .bx-controls-direction .fa-angle-left {
    left: -25px;
  }
  .slider .bx-controls-direction .bx-next, .slider .bx-controls-direction .fa-angle-right {
    right: -25px;
  }
}
@media (max-width: 991px) {
  .slider .bx-controls-direction .bx-prev, .slider .bx-controls-direction .fa-angle-left {
    left: 0px;
  }
  .slider .bx-controls-direction .bx-next, .slider .bx-controls-direction .fa-angle-right {
    right: 0px;
  }
  .slider .bx-controls-direction a {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #fff;
    border-radius: 100%;
    box-shadow: 0px 0px 11px #00000026;
  }
}
.slider .bx-pager {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 45px;
}
.slider .bx-pager .bx-pager-item {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0px 5px;
}
.slider .bx-pager .bx-pager-item a {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: block;
  border-radius: 100%;
  font-size: 0px;
}
.slider .category--slider {
  margin-top: 30px;
}
.slider .category--slider .bx-viewport {
  height: 340px;
}
.slider .category--slider .dw-md-3 {
  max-width: 338px;
  padding-left: 10px !important;
  margin-right: 5px !important;
  padding-bottom: 30px;
}
.slider .category--slider .dw-md-3 .cat--slide {
  cursor: pointer;
  display: block;
  box-shadow: 0 0 14px 2px #00000040;
  border-radius: 3px;
}
.slider .category--slider .dw-md-3 .cat--slide .img--container {
  height: 240px;
  background-color: #fff;
  position: relative;
  border-radius: 3px 3px 0px 0px;
}
.slider .category--slider .dw-md-3 .cat--slide .img--container img {
  position: absolute;
  max-width: 100%;
  height: auto;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}
.slider .category--slider .dw-md-3 .cat--slide .bottom {
  line-height: 30px;
  padding: 10px;
  color: #fff;
  font-weight: 700;
  text-align: center;
}

.hide-clones .dw-md-3 {
  max-width: 334px !important;
}

.size-table {
  width: fit-content;
}
.size-table .section {
  margin: 0px;
}
.size-table .section > div {
  padding: 0px 10px;
}
.size-table .head {
  padding: 10px 15px;
}
.size-table .row:nth-child(1) {
  font-weight: 700;
  border-bottom: 1px solid darkgrey;
}
.size-table .row:nth-child(odd) {
  background-color: #f1f1f1;
}
.size-table .key {
  width: 120px;
  padding: 10px;
  text-align: center;
  flex-shrink: 0;
  font-weight: 700;
  border-right: 1px solid darkgrey;
}
.size-table .value {
  width: 134px;
  flex-shrink: 0;
}

.listing.bundle-listing #products {
  display: flex;
  flex-wrap: wrap;
  grid-row-gap: 30px;
}

.product--page .listing-wishlist {
  width: 100%;
  text-align: center;
  font-weight: 800;
  padding: 20px;
  font-size: 1.6em;
  color: white;
  cursor: pointer;
  margin-bottom: 30px;
}
.product--page #login-partial-background {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: black;
  top: 0px;
  right: 0px;
  z-index: 11;
  opacity: 0.5;
}
.product--page #login-partial-container {
  position: fixed;
  width: 90%;
  height: 80%;
  top: 10%;
  right: 5%;
  z-index: 12;
  background-color: white;
  padding: 6px;
  border-radius: 5px;
  overflow: scroll;
}
@media (min-width: 1231px) {
  .product--page #login-partial-container {
    height: 60%;
    overflow: visible;
  }
}
.product--page #login-partial-container .container {
  width: auto !important;
}
.product--page .prod--right .brand-img-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.product--page .boot.disabled {
  opacity: 0.4;
}
@media (min-width: 992px) {
  .product--page .prod--left {
    width: 50%;
    float: left;
  }
  .product--page .prod--right {
    width: 50%;
    float: left;
  }
  .product--page .prod--right .brand-img-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media (min-width: 992px) and (min-width: 1231px) {
  .product--page .prod--left {
    padding-right: 25px;
  }
  .product--page .prod--right {
    padding-left: 25px;
  }
}
@media (min-width: 992px) and (max-width: 1230px) and (min-width: 992px) {
  .product--page .prod--left {
    padding-right: 15px;
  }
  .product--page .prod--right {
    padding-left: 15px;
  }
}
.product--page h1 {
  text-align: left;
  margin: 15px 0px;
}
.product--page h2 {
  font-size: 1.4em;
  margin: 60px 0px 30px;
}
.product--page h2 i {
  margin-right: 10px;
}
.product--page .step--title {
  padding-left: 50px;
  position: relative;
  margin: 25px 0px;
}
.product--page .step--title.nonum {
  padding: 0px !important;
}
.product--page .step--title .num {
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
  font-size: 1.05em;
  border-radius: 100%;
  font-weight: 700;
}
.product--page .step--title .title {
  font-weight: bold;
  width: calc(100% - 175px);
  float: left;
  font-size: 1.1em;
}
.product--page .step--title .right--tag {
  width: 175px;
  float: right;
  text-align: right;
  font-weight: bold;
  cursor: pointer;
}
.product--page .step--title .right--tag i {
  font-size: 1.1em;
  margin-right: 5px;
}
.product--page .step--title .right--tag.clickable {
  text-decoration: underline;
  cursor: pointer;
}
.product--page .step--title .right--tag.clickable:hover {
  text-decoration: none;
}
.product--page .price--break {
  background-color: #f7f7f7;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}
@media screen and (max-width: 992px) {
  .product--page .price--break {
    justify-content: center;
  }
}
.product--page .price--break .break.current {
  background-color: #fff;
  box-shadow: 0 0 13px 5px #b0b0b066;
  border-radius: 5px;
  transform-origin: center;
  transform: scale(1.04);
}
.product--page .price--break .break.current .price {
  font-size: 2.2rem;
}
@media screen and (max-width: 992px) {
  .product--page .price--break .break.current .price {
    font-size: 2rem;
  }
}
@media screen and (max-width: 500px) {
  .product--page .price--break .break.current .price {
    font-size: 1.75rem;
  }
}
.product--page .price--break .break {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 20px 0px;
}
@media screen and (min-width: 501px) {
  .product--page .price--break .break {
    width: calc(100% / 5);
    float: left;
  }
}
@media screen and (max-width: 500px) {
  .product--page .price--break .break {
    width: 95px;
    display: inline-block;
    vertical-align: top;
  }
}
.product--page .price--break .break .count {
  display: block;
  font-size: 0.8em;
}
.product--page .price--break .break .price {
  display: block;
  font-weight: 700;
  font-size: 1.6em;
}
.product--page .price--break .break .saving {
  font-weight: 700;
  color: #F2335C;
  font-size: 0.8em;
}
.product--page .colour--select .colours .opt {
  float: left;
  width: 10%;
}
@media screen and (min-width: 501px) {
  .product--page .colour--select .colours .opt {
    width: 10%;
  }
}
@media screen and (max-width: 500px) {
  .product--page .colour--select .colours .opt {
    width: 20%;
  }
}
.product--page .colour--select .colours .opt .colour--display {
  margin: 0 auto;
  width: 50px;
  height: 50px;
  padding: 5px;
  border: 1px solid #fff;
  border-radius: 100%;
}
.product--page .colour--select .colours .opt .colour--display .inner--colour {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.product--page .colour--select .colours .opt .title {
  color: #575757;
  text-align: center;
  font-size: 0.75em;
}
.product--page .prod-tabs {
  padding-top: 44px;
  position: relative;
  margin-bottom: 30px;
}
.product--page .prod-tabs .tab {
  cursor: pointer;
  line-height: 40px;
  height: 44px;
  position: absolute;
  text-align: center;
  font-weight: 700;
  width: calc((100% / 3) - 4px);
  background-color: #F7F7F7;
  border-top: 3px solid #fff;
}
.product--page .prod-tabs .tab i {
  font-size: 1.3em;
  margin-right: 5px;
}
.product--page .prod-tabs .tab:nth-child(1) {
  left: 0px;
  top: 0px;
}
.product--page .prod-tabs .tab:nth-child(2) {
  left: 50%;
  transform: translateX(-50%);
  top: 0px;
}
.product--page .prod-tabs .tab:nth-child(3) {
  right: 0px;
  top: 0px;
}
.product--page .prod-tabs .tab.open {
  z-index: 1;
  line-height: 44px;
  background-color: #fff;
  border-top: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}
.product--page .prod-tabs .tab-body {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  position: relative;
  padding: 30px 25px;
  display: none;
}
.product--page .prod-tabs .tab-body:before {
  content: "";
  height: 2px;
  background-color: #fff;
  width: calc((100% / 3) - 4px);
  position: absolute;
}
.product--page .prod-tabs .tab-body#body-1:before {
  left: 0px;
  top: -2px;
}
.product--page .prod-tabs .tab-body#body-2:before {
  left: 50%;
  top: -2px;
  transform: translateX(-50%);
}
.product--page .prod-tabs .tab-body#body-3:before {
  right: 0px;
  top: -2px;
}
.product--page .prod-tabs .tab-body.open {
  display: block;
}

.colour-select {
  border: 1px #dedede solid;
  padding: 10px;
}
.colour-select .colours .c-tile {
  width: 44px;
  float: left;
  position: relative;
  padding: 7px;
}
.colour-select .colours .c-tile .hover-colour-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(100% + 7px);
  background-color: #333;
  border-radius: 3px;
  color: #fff;
  text-align: center;
  transition: opacity 0.2s ease-out;
  opacity: 0;
  display: none;
}
.colour-select .colours .c-tile .hover-colour-title .inner-rel {
  width: auto;
  position: relative;
  padding: 10px;
}
.colour-select .colours .c-tile .hover-colour-title .inner-rel:before {
  content: "";
  width: 15px;
  height: 15px;
  background-color: #333;
  border-radius: 3px;
  position: absolute;
  display: block;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.colour-select .colours .c-tile .c-inner {
  position: relative;
}
.colour-select .colours .c-tile .c-inner:before {
  background-color: #333;
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  content: "";
  display: block;
  border-radius: 100%;
}
.colour-select .colours .c-tile .c-inner:hover .hover-colour-title {
  display: block;
  opacity: 1;
}
.colour-select .colours .c-tile .coloursquare {
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 100%;
  overflow: hidden;
}
.colour-select .colours .c-tile .coloursquare.selected {
  border: none !important;
}
.colour-select .colours .c-tile .coloursquare.selected .select-indicator {
  transition: none !important;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.size-list .size-opt {
  min-height: 110px;
}
.size-list .size-opt .size-quantity-editor {
  transform: translateX(50%);
  bottom: 10px;
  right: 50%;
  top: auto;
  margin: 0 auto;
  width: calc(100% - 20px);
}
.size-list .size-opt.nopass {
  min-height: 45px;
}
@media (min-width: 991px) {
  .size-list.grid {
    margin: 0px -7px;
  }
  .size-list.grid .boot {
    padding: 0px 7px;
    float: left;
  }
}
@media (min-width: 991px) and (min-width: 1230px) {
  .size-list.grid .boot {
    width: calc(100% / 4);
  }
}
@media (min-width: 991px) and (min-width: 991px) and (max-width: 1229px) {
  .size-list.grid .boot {
    width: calc(100% / 3);
  }
}
@media (min-width: 991px) and (min-width: 1230px) {
  .size-list.grid.just-sizes .boot {
    width: calc(100% / 6);
  }
}
@media (min-width: 991px) and (min-width: 991px) and (max-width: 1229px) {
  .size-list.grid.just-sizes .boot {
    width: calc(100% / 6);
  }
}
@media (min-width: 991px) {
  .size-list.grid.just-sizes .boot .size-opt {
    min-height: 10px;
  }
  .size-list.grid.just-sizes .boot .size-opt .size-name {
    transform: none;
    position: static;
  }
}
@media (max-width: 990px) {
  .size-list.grid {
    margin: 0px -7px;
  }
  .size-list.grid .boot {
    padding: 0px 7px;
    float: left;
  }
}
@media (max-width: 990px) and (min-width: 767px) and (max-width: 990px) {
  .size-list.grid .boot {
    width: calc(100% / 4);
  }
}
@media (max-width: 990px) and (max-width: 766px) {
  .size-list.grid .boot {
    width: calc(100% / 2);
  }
}

.size-opt {
  border: 1px solid #dedede;
  border-radius: 3px;
  position: relative;
  padding: 12px 18px;
  margin-bottom: 15px;
}
@media screen and (max-width: 500px) {
  .size-opt {
    height: 110px;
  }
}
.size-opt .check-container {
  width: 32px;
  display: block;
  float: left;
  display: none;
}
.size-opt .size-name {
  display: block;
  font-size: 1.05em;
  padding-left: 0px;
  font-weight: 700;
  text-align: center;
}
.size-opt .size-price {
  font-size: 1.05em;
  line-height: 30px;
  font-weight: 700;
}
@media screen and (max-width: 500px) {
  .size-opt .size-price {
    float: right;
  }
}
.size-opt .size-quantity-editor {
  height: 40px;
  float: right;
  position: absolute;
  right: 18px;
}
@media screen and (min-width: 501px) {
  .size-opt .size-quantity-editor {
    top: 50%;
    transform: translateY(-50%);
  }
}
@media screen and (max-width: 500px) {
  .size-opt .size-quantity-editor {
    bottom: 10px;
  }
}

.size-quantity-editor {
  height: 40px;
  border: 1px solid #dedede;
  min-width: 90px;
  width: 100%;
  border-radius: 3px;
  overflow: hidden;
}
.size-quantity-editor input {
  border: none;
  height: 100%;
  display: block;
  width: calc(100% - 56px);
  float: left;
  text-align: center;
  border-left: #dedede;
  border-right: #dedede;
  font-size: 1.1em;
  font-weight: 700;
}
.size-quantity-editor .control {
  width: 28px;
  display: block;
  float: left;
  background-color: #F5F5F5;
  height: 100%;
  text-align: center;
  font-weight: 700;
  line-height: 38px;
  font-size: 1.3em;
  cursor: pointer;
}
.size-quantity-editor .control:hover {
  background-color: #f0f0f0;
}

.dummy-check {
  border: 1px solid #dedede;
  width: 30px;
  height: 30px;
  border-radius: 3px;
  background-color: #fff;
  display: block;
}
.dummy-check i {
  width: 100%;
  line-height: 28px;
  text-align: center;
}

.prod-page-summary {
  border-radius: 3px;
  padding: 20px;
  position: relative;
}
.prod-page-summary .brand-btn {
  border-radius: 3px;
  display: block;
  background-color: #00AE33;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  border: none;
  width: 100%;
  margin-top: 15px;
  line-height: 15px;
  padding: 15px;
  font-size: 1.05em;
}
.prod-page-summary .brand-btn:hover {
  background-color: #333;
  color: #fff;
}
.prod-page-summary .continue-btn {
  border-radius: 3px;
  background-color: #F7F7F7;
  display: block;
  color: #333;
  border: none;
  font-weight: 700;
  width: 100%;
  margin-top: 15px;
  line-height: 15px;
  padding: 15px;
  font-size: 1.05em;
}
.prod-page-summary .continue-btn:hover {
  background-color: #333;
  color: #fff;
}
@media (min-width: 1231px) {
  .prod-page-summary .please-add {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
}
.prod-page-summary .warn {
  background-color: #F5F5F5;
  text-align: center;
  padding: 10px;
  font-weight: 700;
}
.prod-page-summary .warn i {
  margin-right: 10px;
}
.prod-page-summary .total-items {
  padding: 10px 15px;
}
.prod-page-summary .total-items .title {
  display: block;
  margin-bottom: 10px;
}
.prod-page-summary .price-per {
  padding: 10px 15px;
}
.prod-page-summary .price-per .title {
  display: block;
  margin-bottom: 10px;
}
.prod-page-summary .total {
  padding: 10px 15px;
  background-color: #f1f1f1;
}
.prod-page-summary .total .title {
  display: block;
  margin-bottom: 10px;
}

.prod-img {
  text-align: center;
  margin-bottom: 30px;
}
.prod-img .bx-viewport {
  border: 1px solid #dedede;
}

.price-estimate-bx {
  border: 1px solid #dedede;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 3px;
  position: relative;
}
.price-estimate-bx:before {
  content: "";
  width: 30px;
  height: 30px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  background-color: #fff;
  top: -15px;
  border: 1px solid #dedede;
}
.price-estimate-bx .inner-bg {
  background-color: #fff;
  position: relative;
  z-index: 10;
}
.price-estimate-bx .inner-bg .top {
  text-align: center;
  padding: 15px;
  font-weight: 700;
  border-bottom: 1px solid #dedede;
}
@media screen and (min-width: 501px) {
  .price-estimate-bx .inner-bg .body {
    padding: 30px;
  }
}
@media screen and (max-width: 500px) {
  .price-estimate-bx .inner-bg .body {
    padding: 15px;
  }
  .price-estimate-bx .inner-bg .body .dw-xs-6 {
    width: 100%;
    margin-bottom: 15px;
  }
}
.price-estimate-bx .inner-bg .body .content {
  background-color: #f5f5f5;
  text-align: center;
}
.price-estimate-bx .inner-bg .body .content .head {
  padding: 10px;
  font-weight: 700;
  color: #fff;
  text-align: center;
}
.price-estimate-bx .inner-bg .body .content .head.bg {
  background-color: #333;
}
.price-estimate-bx .inner-bg .body .content .costs {
  display: inline-block;
  position: relative;
  clear: both;
  padding: 10px 10px 10px 30px;
}
.price-estimate-bx .inner-bg .body .content .costs .value {
  font-weight: 700;
}
.price-estimate-bx .inner-bg .body .content .costs .quantity {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 700;
}
.price-estimate-bx .inner-bg .body .content .estimate {
  padding: 10px;
  text-align: center;
  border-top: 1px solid #fff;
}
.price-estimate-bx .inner-bg .body .content .estimate span {
  font-weight: 700;
}
.price-estimate-bx .inner-bg .sub-txt {
  text-align: center;
  padding: 0px 15px 30px 15px;
  font-size: 0.7em;
}
.price-estimate-bx .inner-bg .sub-txt.narr {
  max-width: 340px;
  margin: 0 auto;
}

.bx-controls {
  display: inline-block;
}
.bx-controls .bx-pager-item {
  display: inline-block;
  margin: 5px;
}
.bx-controls .bx-pager-item a {
  width: 20px;
  height: 20px;
  font-size: 0px;
  display: block;
  border-radius: 100%;
  background-color: #333;
}

.product-title-right {
  margin-bottom: 15px;
}
.product-title-right h1 {
  display: inline-block;
  width: calc(100% - 60px);
  float: left;
}
.product-title-right .w-list-action {
  width: 50px;
  height: 50px;
  float: right;
  position: relative;
  margin-top: 10px;
  cursor: pointer;
}
.product-title-right .w-list-action .heart {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.3em;
}
.product-title-right .w-list-action .heart.on {
  opacity: 0;
}
.product-title-right .w-list-action .ico {
  transform: scale(0.6);
  background-color: #333;
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
}
.product-title-right .w-list-action .ico .fa-check {
  opacity: 0;
}
.product-title-right .w-list-action .ico .inner {
  width: 100%;
  height: 100%;
}
.product-title-right .w-list-action .ico .inner i {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.3em;
  width: 100%;
  text-align: center;
  line-height: 30px;
}

.custo-options {
  display: inline-block;
  color: #fff;
  line-height: 40px;
  border-radius: 3px;
  padding: 0px 10px;
  font-weight: 700;
}

.prod-price {
  display: block;
  float: none;
}
.prod-price .value .price {
  font-weight: 700;
  font-size: 1.6em;
}
.prod-price .value .price span {
  font-size: 0.6em;
}

.printopts .opt {
  max-width: 180px;
  border: 1px solid #dbdbdb;
  margin: 0px auto 15px;
  border-radius: 3px;
}
.printopts .opt .name {
  text-align: center;
  font-weight: 700;
  display: block;
  padding: 10px;
}

.slide-inner .img {
  text-align: center;
}
.slide-inner .img img {
  display: inline-block;
}

.custom-deliv-detail .detail {
  width: 100%;
  color: #fff;
  font-weight: 700;
  text-align: center;
  padding: 11px 0px;
  border-radius: 0px;
  margin-top: 15px;
}
.custom-deliv-detail .detail .info-i {
  width: 20px;
  height: 20px;
  background-color: #fff;
  position: relative;
  display: inline-block;
  border-radius: 100%;
  vertical-align: bottom;
  margin-right: 5px;
}
.custom-deliv-detail .detail .info-i svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.size-guide-drop {
  background-color: #F7F7F7;
  margin-bottom: 15px;
  border: 1px solid #f7f7f7;
}
.size-guide-drop .top {
  padding: 15px;
  cursor: pointer;
}
.size-guide-drop .top span {
  font-weight: 700;
  margin-left: 5px;
}
.size-guide-drop .top .fa-chevron-down {
  float: right;
}
.size-guide-drop .body {
  max-height: none;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  background-color: #fff;
}
.size-guide-drop .body .inner {
  padding: 15px;
}
.size-guide-drop.shut .body {
  max-height: 0px;
}

.branding-check {
  display: block;
  background-color: #f1f1f1;
  margin-top: 15px;
  position: relative;
  padding: 15px;
  cursor: pointer;
}
.branding-check input {
  display: none;
}
.branding-check .txt {
  font-weight: 700;
  padding-right: 30px;
  display: block;
}
.branding-check .dummy-check {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.bundle.box {
  box-shadow: 0px 11px 20px #00000026;
  padding: 30px 45px;
  margin-bottom: 45px;
}
@media (max-width: 991px) {
  .bundle.box {
    padding: 15px;
  }
}
.bundle.box .bundle-header {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 991px) {
  .bundle.box .bundle-header {
    flex-direction: column;
  }
}
.bundle.box .bundle-header > div {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
}
.bundle.box .bundle-header > div h1 {
  font-size: 2rem;
  text-align: start;
  margin: 0;
}
.bundle.box .bundle-header > div .bundle-desc, .bundle.box .bundle-header > div .bundle-desc * {
  font-weight: bold;
  font-size: 1.5rem;
  margin: 0;
}
.bundle.box .bundle-header > div .bundle-price {
  font-size: 2.5rem;
  font-weight: bold;
}
.bundle.box .bundle-header img {
  max-height: 210px;
  align-self: flex-end;
}
.bundle.box .bundle-steps {
  display: flex;
  grid-gap: 10px;
  flex-wrap: wrap;
}
.bundle.box .bundle-steps > div {
  max-width: fit-content;
  border-radius: 3px;
  padding: 15px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
}
.bundle.box .bundleprod {
  margin-top: 15px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #00167F;
  grid-gap: 10px;
  padding-right: 30px;
  position: relative;
  justify-content: space-between;
}
@media (max-width: 1230px) {
  .bundle.box .bundleprod {
    flex-direction: column;
    align-items: flex-start;
    padding: 65px 30px 30px;
  }
}
@media (max-width: 991px) {
  .bundle.box .bundleprod {
    padding: 65px 15px 15px;
  }
}
.bundle.box .bundleprod .step-num {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  font-size: 2.7rem;
  align-self: stretch;
  font-weight: bold;
  z-index: 2;
}
@media (max-width: 1230px) {
  .bundle.box .bundleprod .step-num {
    border-bottom-right-radius: 10px;
    position: absolute;
    left: 0;
    top: 0;
  }
}
.bundle.box .bundleprod .bundle-item-name {
  color: #272727;
  font-size: 1.5rem;
  max-width: 130px;
  font-weight: bold;
}
@media (max-width: 1230px) {
  .bundle.box .bundleprod .bundle-item-name {
    max-width: initial;
  }
}
.bundle.box .bundleprod .bundle-item-qty {
  color: #575757;
  font-weight: bold;
  font-size: 2.7rem;
  white-space: nowrap;
  position: relative;
}
.bundle.box .bundleprod .bundle-item-qty > span.red {
  color: #fe0002;
}
.bundle.box .bundleprod .bundle-item-qty input {
  text-align: end;
  padding: 0;
  margin: 0;
  border: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  font-weight: inherit;
  max-width: 60px;
  pointer-events: none;
}
.bundle.box .bundleprod .bundle-item-qty .qty-msg {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  font-size: 1rem;
  max-width: 100%;
}
.bundle.box .bundleprod .bundle-item-qty .qty-msg.fail {
  color: #fe0002;
}
.bundle.box .bundleprod .bundle-item-qty.green {
  color: #07a45f;
}
.bundle.box .bundleprod .bundle-colour-picker {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  margin-left: 90px;
  flex-grow: 1;
  padding: 10px 0;
  max-width: 550px;
}
@media (max-width: 1230px) {
  .bundle.box .bundleprod .bundle-colour-picker {
    margin-left: 0;
    padding: 10px 0;
    width: 100%;
  }
}
.bundle.box .bundleprod .bundle-colour-picker .colour-title {
  color: #272727;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0 30px;
}
@media (max-width: 1230px) {
  .bundle.box .bundleprod .bundle-colour-picker .colour-title {
    padding: 0;
  }
}
.bundle.box .bundleprod .bundle-colour-picker .bundle-item-colours {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  padding: 0 30px;
}
@media (max-width: 1230px) {
  .bundle.box .bundleprod .bundle-colour-picker .bundle-item-colours {
    padding: 0;
  }
}
.bundle.box .bundleprod .bundle-colour-picker .bundle-item-colours label {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.14902);
  position: relative;
}
.bundle.box .bundleprod .bundle-colour-picker .bundle-item-colours label .hover-colour-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(100% + 7px);
  background-color: #333;
  border-radius: 3px;
  color: #fff;
  text-align: center;
  transition: opacity 0.2s ease-out;
  opacity: 0;
  display: none;
}
.bundle.box .bundleprod .bundle-colour-picker .bundle-item-colours label .hover-colour-title .inner-rel {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  background-color: #333;
  border-radius: 3px;
  position: absolute;
  display: block;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  z-index: -1;
}
.bundle.box .bundleprod .bundle-colour-picker .bundle-item-colours label input {
  display: none;
}
.bundle.box .bundleprod .bundle-colour-picker label.selected {
  border: 1px solid #fff;
}
.bundle.box .bundleprod .bundle-colour-picker label.selected:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 50%;
  background-color: #FF8800;
  height: 35px;
  width: 35px;
  z-index: -1;
  border-radius: 50%;
  transform: translate(50%, -50%);
}
.bundle.box .bundleprod .bundle-colour-picker label:hover {
  cursor: pointer;
}
.bundle.box .bundleprod .bundle-colour-picker label:hover .hover-colour-title {
  display: block;
  opacity: 1;
  padding: 5px;
}
.bundle.box .bundleprod .bundle-colour-picker .buttons-divider {
  width: 100%;
  height: 1px;
  background-color: #575757;
}
.bundle.box .bundleprod .bundle-colour-picker .bundle-prod-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}
@media (max-width: 767px) {
  .bundle.box .bundleprod .bundle-colour-picker .bundle-prod-buttons {
    flex-wrap: wrap;
    grid-gap: 15px;
    padding: 0;
  }
}
.bundle.box .bundleprod .bundle-colour-picker .bundle-prod-buttons .close-flex {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .bundle.box .bundleprod .bundle-colour-picker .bundle-prod-buttons .close-flex {
    width: 100%;
  }
}
.bundle.box .bundleprod .bundle-colour-picker .bundle-prod-buttons .fa-times {
  font-size: 2rem;
  color: #FE0002;
  cursor: pointer;
}
.bundle.box .bundleprod .bundle-colour-picker .bundle-prod-buttons .fa-times:hover {
  color: #b10001;
}
.bundle.box .bundleprod .bundle-colour-picker .bundle-prod-buttons .colour-name {
  display: flex;
  align-items: center;
  grid-gap: 30px;
}
@media (max-width: 767px) {
  .bundle.box .bundleprod .bundle-colour-picker .bundle-prod-buttons .colour-name {
    width: 100%;
    justify-content: space-between;
  }
}
.bundle.box .bundleprod .bundle-colour-picker .bundle-prod-buttons .colour-name .colour-flex {
  display: flex;
  align-items: center;
  grid-gap: 10px;
}
.bundle.box .bundleprod .bundle-colour-picker .bundle-prod-buttons .colour-name .colour-flex .colour {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.14902);
}
.bundle.box .bundleprod .bundle-colour-picker .bundle-prod-buttons .colour-name .colour-flex .sel-qty {
  color: #575757;
  font-weight: 700;
  font-size: 2rem;
}
.bundle.box .bundleprod .bundle-colour-picker .bundle-prod-buttons .colour-name .name {
  color: #272727;
  font-size: 1.5rem;
  font-weight: bold;
  max-width: 180px;
}
.bundle.box .bundleprod .bundle-colour-picker .bundle-prod-buttons .btn {
  padding: 15px;
  max-width: fit-content;
  border-radius: 3px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
}
.bundle.box .bundle-popup {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: none;
  z-index: 99;
  background-color: #000000b3;
  overflow-y: auto;
}
.bundle.box .bundle-popup .popup-box {
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  background-color: #fff;
}
.bundle.box .bundle-popup .popup-box .close-whole {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
@media (min-width: 1231px) {
  .bundle.box .bundle-popup .popup-box {
    min-width: 1130px;
  }
}
@media (max-width: 1230px) {
  .bundle.box .bundle-popup .popup-box {
    min-width: 90%;
  }
}
@media (max-width: 991px) {
  .bundle.box .bundle-popup .popup-box {
    flex-direction: column;
    top: 30px;
    transform: translate(50%, 0);
  }
}
.bundle.box .bundle-popup .popup-box .popup-flex {
  display: flex;
  grid-gap: 15px;
  justify-content: space-between;
}
@media (max-width: 1230px) {
  .bundle.box .bundle-popup .popup-box .popup-flex {
    flex-direction: column;
  }
  .bundle.box .bundle-popup .popup-box .popup-flex > div:first-child {
    max-width: 150px;
    margin-left: auto;
  }
}
.bundle.box .bundle-popup .popup-box .popup-flex .size-opt {
  padding: 12px 12px;
}
.bundle.box .bundle-popup .popup-box .popup-flex .size-opt:has(.qty:disabled) {
  opacity: 0.3;
}
.bundle.box .bundle-popup .popup-box .popup-flex .sizes {
  padding: 30px;
  max-width: 60%;
}
@media (min-width: 1231px) {
  .bundle.box .bundle-popup .popup-box .popup-flex .sizes {
    min-width: 50%;
  }
}
@media (max-width: 1230px) {
  .bundle.box .bundle-popup .popup-box .popup-flex .sizes {
    max-width: initial;
  }
}
.bundle.box .bundle-popup .popup-box .popup-flex .sizes h2 {
  font-size: 2rem;
  text-align: start;
  margin: 0;
}
.bundle.box .bundle-popup .popup-box .popup-flex .sizes h3 {
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 15px;
}
.bundle.box .bundle-popup .popup-box .popup-flex .sizes .pop-colour-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  margin-bottom: 30px;
}
.bundle.box .bundle-popup .popup-box .popup-flex .sizes .pop-colour-flex .pop-colour-name {
  display: flex;
  align-items: center;
  grid-gap: 30px;
}
.bundle.box .bundle-popup .popup-box .popup-flex .sizes .pop-colour-flex .pop-colour-name .colour {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.14902);
}
.bundle.box .bundle-popup .popup-box .popup-flex .sizes .pop-colour-flex .pop-colour-name .name {
  color: #272727;
  font-size: 1.5rem;
  font-weight: bold;
  max-width: 275px;
}
.bundle.box .bundle-popup .popup-box .popup-flex .sizes .pop-colour-flex .qty {
  color: #575757;
  font-weight: 700;
  font-size: 2.7rem;
}
.bundle.box .bundle-popup .popup-box .popup-flex .sizes .pop-colour-flex .qty input {
  text-align: end;
  padding: 0;
  margin: 0;
  border: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  font-weight: inherit;
  pointer-events: none;
  max-width: 60px;
}
.bundle.box .bundle-popup .popup-box .popup-flex .sizes .size-name {
  text-align: center;
  font-size: 1.5rem;
  white-space: nowrap;
}
@media (max-width: 550px) {
  .bundle.box .bundle-popup .popup-box .popup-flex .sizes .size-name {
    font-size: 1.2rem;
  }
}
.bundle.box .bundle-popup .popup-box .popup-footer {
  padding: 30px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.bundle.box .bundle-popup .popup-box .popup-footer .confirm-btn {
  font-size: 1rem;
  padding: 15px;
  max-width: fit-content;
  font-weight: bold;
  text-align: center;
  width: 100%;
  border-radius: 3px;
  cursor: pointer;
}
.bundle.box .submit-flex {
  display: flex;
  padding-top: 30px;
  width: 100%;
  grid-gap: 15px;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
}
.bundle.box .submit-flex h2 {
  margin: 0;
  padding: 0;
}
.bundle.box .submit-flex .add-flex {
  width: 100%;
  display: flex;
  grid-gap: 30px;
  justify-content: flex-end;
}
@media (max-width: 991px) {
  .bundle.box .submit-flex .add-flex {
    flex-direction: column;
    align-content: flex-end;
    flex-wrap: wrap;
  }
}
.bundle.box .submit-flex .add-flex .total {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  font-weight: bold;
  font-size: 2rem !important;
}
@media (max-width: 991px) {
  .bundle.box .submit-flex .add-flex .total {
    justify-content: flex-end;
  }
}
.bundle.box .submit-flex .add-flex .total h2 {
  font-size: 2rem !important;
  margin: 0;
  padding: 0;
}
.bundle.box .submit-flex .summary-flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.bundle.box .submit-flex .summary-flex #brandingform {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  width: 100%;
}
.bundle.box .submit-flex .summary-flex #brandingform h2 {
  font-size: 1.75em !important;
  margin: 0;
  padding: 0;
}
.bundle.box .submit-flex .summary-flex #brandingform .custom-summary {
  display: flex;
  font-weight: bold;
  font-size: 1.2rem;
  width: 100%;
  grid-gap: 15px;
}
.bundle.box .submit-flex .summary-flex #brandingform .custom-summary .sel-list {
  flex-direction: column;
  grid-gap: 10px;
  flex-grow: 1;
}
.bundle.box .submit-flex .summary-flex #brandingform .custom-summary .sel-list .selections-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  padding: 25px 0;
  border-top: 1px solid #575757;
  font-size: 1.3rem;
  text-align: center;
}
@media (max-width: 767px) {
  .bundle.box .submit-flex .summary-flex #brandingform .custom-summary .sel-list .selections-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 550px) {
  .bundle.box .submit-flex .summary-flex #brandingform .custom-summary .sel-list .selections-grid {
    grid-template-columns: 1fr;
    text-align: start;
  }
}
.bundle.box .submit-flex .summary-flex #brandingform .custom-summary .sel-list .selections-grid > span:last-child {
  color: #fe0002;
}
.bundle.box .submit-flex .summary-flex #brandingform .custom-summary .sel-list .selections-grid:last-child {
  border-bottom: 1px solid #575757;
}
.bundle.box .submit-flex .summary-flex #brandingform .custom-summary .btn-lg {
  max-width: fit-content;
  display: flex;
  align-items: center;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.bundle.box .submit-flex .btn-lg {
  font-size: 1rem;
  padding: 15px;
  max-width: fit-content;
  font-weight: bold;
  text-align: center;
  width: 100%;
  border-radius: 3px;
  border: none;
  cursor: pointer;
}
@media (max-width: 400px) {
  .bundle.box .submit-flex .btn-lg {
    max-width: 100%;
  }
}
.bundle.box .submit-flex .custsteps {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: none;
  z-index: 9999;
  background-color: #000000b3;
  overflow-y: auto;
}
.bundle.box .submit-flex .custsteps .popup-box {
  background-color: #f7f7f7;
  border: 1px solid #dbdbdb;
  position: fixed;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  padding: 30px;
  max-height: 100vh;
  overflow-y: auto;
}
@media (max-width: 991px) {
  .bundle.box .submit-flex .custsteps .popup-box {
    flex-direction: column;
    top: 30px;
    transform: translate(50%, 0);
  }
}
@media (min-width: 1231px) {
  .bundle.box .submit-flex .custsteps .popup-box {
    min-width: 1130px;
  }
}
@media (max-width: 1230px) {
  .bundle.box .submit-flex .custsteps .popup-box {
    min-width: 90%;
  }
}
.bundle.box .submit-flex .custsteps .popup-box .close-cut-pop {
  position: absolute;
  right: 5px;
  top: 5px;
}
.bundle.box .submit-flex .custsteps .popup-box .close-cut-pop i {
  font-size: 2rem;
  color: #fe0002;
  cursor: pointer;
}
.bundle.box .submit-flex .custsteps .popup-box .close-cut-pop i:hover {
  color: #b10001;
}
.bundle.box #login-popup {
  z-index: 9999;
}
.bundle.box #login-popup #login-partial-container {
  top: 50%;
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
  height: auto;
}
.bundle.box #login-popup #login-partial-container .close {
  right: 0;
  top: 0;
  z-index: 9;
}

.bundle-listing .bundle-card {
  box-shadow: 0 11px 20px rgba(0, 0, 0, 0.1490196078);
  position: relative;
}
.bundle-listing .bundle-card .bundle-title {
  padding: 15px;
  font-size: 2.5rem;
  text-align: center;
  margin: 0;
  font-weight: bold;
}
@media (max-width: 991px) {
  .bundle-listing .bundle-card .bundle-title {
    font-size: 1.75rem;
  }
}
.bundle-listing .bundle-card .save-roundel {
  position: absolute;
  right: 15px;
  bottom: 30px;
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  line-height: 0.9;
  border-radius: 50%;
}
@media (max-width: 1400px) {
  .bundle-listing .bundle-card .save-roundel {
    height: 120px;
    width: 120px;
    font-size: 2.25rem;
    bottom: 10px;
    right: 10px;
  }
}
@media (max-width: 991px) {
  .bundle-listing .bundle-card .save-roundel {
    height: 85px;
    width: 85px;
    font-size: 1.5rem;
    bottom: -15px;
    right: -15px;
  }
}
.bundle-listing .bundle-card .was-price {
  text-align: center;
  width: 100%;
  font-size: 2rem;
  font-weight: bold;
  text-decoration: line-through;
  text-decoration-color: #FE0002;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.bundle-listing .bundle-card .was-price > div:last-child {
  font-size: 0.9rem;
  text-decoration: none;
}
.bundle-listing .bundle-card .price {
  margin-bottom: 15px;
  text-align: center;
  width: 100%;
  font-size: 4rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@media (max-width: 991px) {
  .bundle-listing .bundle-card .price {
    font-size: 3rem;
  }
}
.bundle-listing .bundle-card .price > div:last-child {
  font-size: 1.7rem;
}
.bundle-listing .bundle-card .view-now {
  font-weight: bold;
  font-size: 2.6rem;
  text-align: center;
  padding: 15px;
  width: 100%;
}
@media (max-width: 991px) {
  .bundle-listing .bundle-card .view-now {
    font-size: 2rem;
  }
}

div.show-bundle {
  display: none;
}

.striped-colour {
  display: grid;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}

.sizes-wrapper {
  padding: 15px;
  max-width: 100%;
  overflow: auto;
}
.sizes-wrapper td:nth-child(even), .sizes-wrapper th:nth-child(even) {
  background: #f1f1f1;
}
.sizes-wrapper th, .sizes-wrapper td {
  padding: 10px 0;
}

.checkout--container .two-grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  grid-gap: 5px;
}
.checkout--container .two-grid img {
  max-width: 90px;
}
.checkout--container .summary {
  border: 1px solid #dbdbdb;
  border-radius: 3px;
}
.checkout--container .summary .head {
  background-color: #f1f1f1;
  padding: 10px;
  line-height: 30px;
  font-size: 1.3em;
}
.checkout--container .summary .row {
  padding: 10px;
  border-bottom: 1px solid #F4F4F4;
}
.checkout--container .summary .row .img {
  width: 96px;
  min-height: 96px;
  float: left;
}
.checkout--container .summary .row .info {
  width: 100%;
  position: relative;
  float: left;
  min-height: 96px;
  padding-bottom: 25px;
  padding-left: 10px;
}
.checkout--container .summary .row .info .title {
  font-weight: 700;
  color: #000;
  display: block;
  max-width: 180px;
  margin-bottom: 10px;
}
.checkout--container .summary .row .info .count {
  display: block;
}
.checkout--container .summary .row .info .price {
  position: absolute;
  display: inline-block;
  bottom: 0px;
  right: 0px;
  font-weight: 700;
}
.checkout--container .summary .row .info .remove {
  position: absolute;
  left: 10px;
  bottom: 0px;
}
.checkout--container .summary .row .info .remove i {
  color: #ff0000;
}
.checkout--container .summary .row .info .remove:hover i {
  color: #000;
}
.checkout--container .summary .subtotal {
  border-bottom: solid 1px #f1f1f1;
  padding: 10px;
}
.checkout--container .summary .subtotal .sub-row {
  margin-top: 15px;
}
.checkout--container .summary .subtotal.basket-prices {
  border-radius: 3px;
  padding: 15px;
}
.checkout--container .summary .subtotal.basket-prices .sub-row {
  margin: 0px 0px 15px 0px;
}
.checkout--container .summary .subtotal.basket-prices .checkoutbtn, .checkout--container .summary .subtotal.basket-prices .creditbtn {
  width: 100%;
  border: none;
  color: #fff;
  line-height: 40px;
  border-radius: 3px;
  font-weight: 700;
  font-size: 1.2em;
  display: block;
  text-align: center;
}
.checkout--container .summary .total {
  font-size: 1.6em;
  padding: 10px;
}
.checkout--container .summary .left {
  display: inline-block;
  float: left;
}
.checkout--container .summary .right {
  display: inline-block;
  float: right;
}
.checkout--container .summary .block-checkout {
  position: relative;
  padding: 10px 10px 10px 50px;
  background-color: #f1f1f1;
  max-width: 410px;
  margin: 15px auto 0;
  border-radius: 3px;
}
.checkout--container .summary .block-checkout i {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  border-radius: 100%;
  text-align: center;
  line-height: 25px;
  color: #fff;
}
@media (max-width: 767px) {
  .checkout--container .termsagree {
    margin-bottom: 15px;
  }
}
.checkout--container .checkform .post-code-search .btn-1 {
  text-align: center;
  border-radius: 3px;
}
.checkout--container .checkform .checkout--form {
  margin-bottom: 30px;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
}
.checkout--container .checkform .checkout--form .step-title {
  padding: 15px;
  border-bottom: 1px solid #f1f1f1;
  background-color: #f1f1f1;
}
.checkout--container .checkform .checkout--form .step-title .round {
  width: 30px;
  line-height: 30px;
  height: 30px;
  text-align: center;
  display: inline-block;
  color: #fff;
  border-radius: 100%;
  margin-right: 5px;
  font-weight: 700;
  font-size: 1.2em;
}
.checkout--container .checkform .checkout--form .step-title i {
  font-size: 1.3em;
  display: inline-block;
  margin-right: 5px;
}
.checkout--container .checkform .checkout--form .step-title .title {
  font-weight: 700;
}
.checkout--container .checkform .checkout--form .step-body {
  padding: 15px;
}
.checkout--container .checkform .checkout--form .step-body .address-split {
  width: 100%;
  margin: 0px -9px;
}
.checkout--container .checkform .checkout--form .step-body .address-split .split {
  width: 50%;
  float: left;
  padding: 9px;
}
.checkout--container .checkform .checkout--form h2 {
  margin: 15px 0px;
}
.checkout--container .checkform .checkout--form input[type=text], .checkout--container .checkform .checkout--form select {
  line-height: 40px;
  border: 1px solid #ddd;
  display: block;
  width: 100%;
  max-width: 100%;
  border-radius: 3px;
  padding: 0px 15px;
  height: 40px;
}
@media (min-width: 768px) {
  .checkout--container .checkform .baskettable {
    border: 1px solid #ddd;
    border-radius: 3px;
  }
}
.checkout--container .checkform .baskettable .basket-head {
  background-color: #f1f1f1;
  padding: 15px;
}
@media (min-width: 1401px) {
  .checkout--container .checkform .baskettable .basket-head .prod-head, .checkout--container .checkform .baskettable .basket-head .title-boot {
    width: 255px;
  }
  .checkout--container .checkform .baskettable .basket-head .colour-head, .checkout--container .checkform .baskettable .basket-head .colour-boot {
    width: 130px;
  }
  .checkout--container .checkform .baskettable .basket-head .size-head, .checkout--container .checkform .baskettable .basket-head .size-boot {
    width: 245px;
  }
  .checkout--container .checkform .baskettable .basket-head .quant-head {
    width: 115px;
  }
}
@media (max-width: 1400px) and (min-width: 1231px) {
  .checkout--container .checkform .baskettable .basket-head .prod-head {
    width: 230px;
  }
  .checkout--container .checkform .baskettable .basket-head .colour-head {
    width: 160px;
  }
  .checkout--container .checkform .baskettable .basket-head .size-head {
    width: 160px;
  }
  .checkout--container .checkform .baskettable .basket-head .quant-head {
    width: 112px;
  }
}
@media (max-width: 1230px) and (min-width: 992px) {
  .checkout--container .checkform .baskettable .basket-head .prod-head {
    width: 130px;
  }
  .checkout--container .checkform .baskettable .basket-head .colour-head {
    width: 100px;
  }
  .checkout--container .checkform .baskettable .basket-head .size-head {
    width: 79px;
  }
  .checkout--container .checkform .baskettable .basket-head .quant-head {
    text-align: center;
    width: 140px;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .checkout--container .checkform .baskettable .basket-head .prod-head {
    width: 130px;
  }
  .checkout--container .checkform .baskettable .basket-head .colour-head {
    width: 100px;
  }
  .checkout--container .checkform .baskettable .basket-head .size-head {
    width: 139px;
  }
  .checkout--container .checkform .baskettable .basket-head .quant-head {
    text-align: center;
    width: 140px;
  }
}
@media (max-width: 767px) {
  .checkout--container .checkform .baskettable .basket-head {
    display: none;
  }
}
.checkout--container .checkform .baskettable .basket-body {
  padding-top: 15px;
}
.checkout--container .checkform .baskettable .basket-body .row {
  margin-bottom: 15px;
  border-bottom: 1px solid #dbdbdb;
  position: relative;
}
@media (min-width: 1231px) {
  .checkout--container .checkform .baskettable .basket-body .row .title-boot {
    width: 260px;
    float: left;
  }
  .checkout--container .checkform .baskettable .basket-body .row .colour-boot {
    width: 130px;
    float: left;
    min-height: 40px;
    position: relative;
  }
  .checkout--container .checkform .baskettable .basket-body .row .colour-boot span {
    display: block;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
  }
  .checkout--container .checkform .baskettable .basket-body .row .size-boot {
    width: calc(100% - 390px);
    float: left;
  }
  .checkout--container .checkform .baskettable .basket-body .row .size-boot .sizecell {
    width: calc(50% - 105px);
  }
  .checkout--container .checkform .baskettable .basket-body .row .size-boot .countcell {
    width: 140px;
  }
  .checkout--container .checkform .baskettable .basket-body .row .size-boot .pricecell {
    width: calc(50% - 105px);
  }
  .checkout--container .checkform .baskettable .basket-body .row .size-boot .removecell {
    width: 70px;
  }
}
@media (max-width: 1230px) and (min-width: 768px) {
  .checkout--container .checkform .baskettable .basket-body .row .title-boot {
    width: 130px;
    float: left;
  }
}
@media (max-width: 1230px) and (max-width: 767px) {
  .checkout--container .checkform .baskettable .basket-body .row .title-boot {
    width: 100%;
  }
}
@media (max-width: 1230px) and (min-width: 768px) {
  .checkout--container .checkform .baskettable .basket-body .row .colour-boot {
    width: 100px;
    float: left;
  }
}
@media (max-width: 1230px) {
  .checkout--container .checkform .baskettable .basket-body .row .size-boot {
    width: 100%;
    float: left;
  }
}
@media (max-width: 1230px) and (min-width: 768px) {
  .checkout--container .checkform .baskettable .basket-body .row .size-boot {
    width: calc(100% - 230px);
    float: left;
  }
}
@media (max-width: 1230px) and (min-width: 401px) {
  .checkout--container .checkform .baskettable .basket-body .row .size-boot .sizecell {
    width: calc(50% - 105px);
  }
  .checkout--container .checkform .baskettable .basket-body .row .size-boot .countcell {
    width: 140px;
  }
  .checkout--container .checkform .baskettable .basket-body .row .size-boot .pricecell {
    width: calc(50% - 105px);
    font-size: 0.95em;
  }
  .checkout--container .checkform .baskettable .basket-body .row .size-boot .removecell {
    width: 70px;
  }
}
@media (max-width: 1230px) and (max-width: 400px) {
  .checkout--container .checkform .baskettable .basket-body .row .size-boot .sizecell {
    width: calc(50% - 85px);
  }
  .checkout--container .checkform .baskettable .basket-body .row .size-boot .countcell {
    width: 110px;
  }
  .checkout--container .checkform .baskettable .basket-body .row .size-boot .pricecell {
    width: calc(50% - 85px);
    font-size: 0.8em;
  }
  .checkout--container .checkform .baskettable .basket-body .row .size-boot .removecell {
    width: 60px;
  }
}
@media (min-width: 768px) {
  .checkout--container .checkform .baskettable .basket-body .row {
    padding-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .checkout--container .checkform .baskettable .basket-body .row {
    padding-bottom: 85px;
    border: 1px solid #f1f1f1;
    border-radius: 3px;
    padding-top: 15px;
  }
  .checkout--container .checkform .baskettable .basket-body .row .price.col.last {
    position: absolute;
    bottom: 0px;
    left: 0px;
  }
}
.checkout--container .checkform .baskettable .basket-body .row:last-child {
  margin-bottom: 0px;
  border-bottom: 0px;
}
.checkout--container .checkform .baskettable .basket-body .row.special {
  margin-top: 15px;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  padding: 15px !important;
  border-radius: 3px;
}
.checkout--container .checkform .baskettable .basket-body .row.special .title {
  display: block;
}
.checkout--container .checkform .baskettable .basket-body .row.special .field {
  display: block;
}
.checkout--container .checkform .baskettable .basket-body .row.special .field input {
  min-height: 40px;
  width: 100%;
  padding: 10px;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
}
@media (min-width: 768px) {
  .checkout--container .checkform .baskettable .basket-body .row.special .title {
    width: 280px;
    float: left;
  }
  .checkout--container .checkform .baskettable .basket-body .row.special .field {
    width: calc(100% - 280px);
    float: left;
  }
}
@media (max-width: 767px) {
  .checkout--container .checkform .baskettable .basket-body .row.special .field input {
    margin-top: 10px;
  }
}
@media (min-width: 768px) {
  .checkout--container .checkform .flex-baskettable {
    border: 1px solid #ddd;
    border-radius: 3px;
  }
}
@media (max-width: 767px) {
  .checkout--container .checkform .flex-baskettable .basket-head {
    display: none !important;
  }
  .checkout--container .checkform .flex-baskettable .basket-body {
    flex-wrap: wrap;
  }
}
.checkout--container .checkform .flex-baskettable .basket-head {
  background-color: #f1f1f1;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.checkout--container .checkform .flex-baskettable .basket-head > div {
  width: 100%;
}
.checkout--container .checkform .flex-baskettable .basket-body .row {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 767px) {
  .checkout--container .checkform .flex-baskettable .basket-body .row {
    flex-wrap: wrap;
  }
}
.checkout--container .checkform .flex-baskettable .basket-body .row > div {
  width: 100%;
}
@media (min-width: 768px) {
  .checkout--container .checkform .flex-baskettable .basket-body .row > div .moblabel {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .checkout--container .checkform .flex-baskettable .basket-body .row > div {
    width: 100%;
    max-width: 100%;
    margin-bottom: 15px;
    text-align: left !important;
  }
}
@media (min-width: 768px) {
  .checkout--container .checkform .flex-baskettable .title-boot {
    max-width: 250px;
  }
  .checkout--container .checkform .flex-baskettable .price-boot {
    max-width: 100px;
    text-align: center;
  }
  .checkout--container .checkform .flex-baskettable .size-boot {
    max-width: 100px;
    text-align: center;
  }
  .checkout--container .checkform .flex-baskettable .colour-boot {
    max-width: 150px;
    text-align: center;
  }
  .checkout--container .checkform .flex-baskettable .quant-boot {
    max-width: 150px;
    text-align: center;
  }
  .checkout--container .checkform .flex-baskettable .remove-boot {
    max-width: 100px;
    text-align: center;
  }
}
.checkout--container .checkform .flex-baskettable .notes {
  padding: 15px;
}
.checkout--container .checkform .flex-baskettable .notes strong {
  display: block;
  margin-bottom: 10px;
}
.checkout--container .checkform .flex-baskettable .notes textarea {
  width: 100%;
  min-height: 100px;
}
.checkout--container .checkform .basket-actions .btn {
  display: inline-block;
  border-radius: 3px;
  color: #fff;
  font-weight: 700;
  font-size: 1.2em;
  border: none;
  line-height: 40px;
  padding: 0px 15px;
  margin-bottom: 10px;
}
@media (min-width: 1231px) {
  .checkout--container .summary {
    width: 290px;
    float: right;
  }
  .checkout--container .checkform {
    float: left;
    width: calc(100% - 290px);
    padding-right: 30px;
  }
  .checkout--container.basket .summary {
    width: 290px;
    float: right;
  }
  .checkout--container.basket .checkform {
    float: left;
    width: calc(100% - 290px);
    padding-right: 30px;
  }
}
@media (max-width: 1230px) and (min-width: 992px) {
  .checkout--container .summary {
    width: 290px;
    float: right;
  }
  .checkout--container .checkform {
    float: left;
    width: calc(100% - 290px);
    padding-right: 50px;
  }
}
@media (min-width: 992px) {
  .checkout--container .summary {
    position: sticky;
    top: 15px;
    right: 0px;
  }
}
@media (max-width: 991px) {
  .checkout--container.basket .summary {
    margin-top: 15px;
  }
}
.discount--form {
  width: 100%;
  max-width: 980px;
  padding: 15px;
  background-color: #f1f1f1;
  margin-top: 30px;
  border-radius: 3px;
  margin-bottom: 60px;
}
.discount--form .submit .btn-1 {
  line-height: 40px;
  border: none;
  border-radius: 3px;
  color: #fff;
  font-weight: 700;
  width: 100%;
  font-size: 1.3em;
}
.discount--form .field input {
  width: 100%;
  display: block;
  padding: 0px 15px;
  line-height: 36px;
  max-width: 100%;
}
@media (min-width: 992px) {
  .discount--form .title {
    width: 390px;
    float: left;
    line-height: 40px;
  }
  .discount--form .field {
    width: calc(100% - 570px);
    float: left;
  }
  .discount--form .submit {
    width: 180px;
    float: left;
  }
}
@media (max-width: 991px) {
  .discount--form {
    max-width: 400px;
    margin: 30px auto;
  }
  .discount--form .title {
    text-align: center;
  }
  .discount--form .field {
    margin: 15px 0px;
  }
  .discount--form .submit {
    text-align: center;
  }
  .discount--form .submit .btn-1 {
    max-width: 180px;
    margin: 0 auto;
  }
}

.basket-body .col {
  height: auto !important;
}
.basket-body .col.last .price-txt {
  margin-bottom: 15px;
}
.basket-body .col.last .btn {
  float: right;
}
@media (max-width: 767px) {
  .basket-body .col.last {
    background-color: #f1f1f1;
    padding: 15px;
  }
}
.basket-body .basket-customisation {
  border: 1px solid #f1f1f1;
  border-radius: 3px;
  max-width: 870px;
  width: 100%;
  float: right;
}
.basket-body .basket-customisation .head {
  background-color: #fafafa;
  border-bottom: 1px #f1f1f1 solid;
  padding: 15px;
}
.basket-body .basket-customisation .head strong {
  line-height: 35px;
}
.basket-body .basket-customisation .head a {
  float: right;
  line-height: 35px;
  padding: 0px 10px;
  border-radius: 3px;
  color: #fff;
  font-weight: 700;
}
.basket-body .basket-customisation .custom-row {
  padding: 10px 10px 0px 10px;
}
.basket-body .basket-customisation .custom-row .section {
  margin: 0px -5px;
}
.basket-body .basket-customisation .custom-row .section .custo-boot {
  padding: 0px 5px;
}
.basket-body .basket-customisation .custo-bx {
  margin-bottom: 10px;
}
.basket-body .basket-customisation .custo-bx .top {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  padding: 5px 10px;
  position: relative;
}
.basket-body .basket-customisation .custo-bx .top:before {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 0px;
  background-color: #fff;
}
.basket-body .basket-customisation .custo-bx .top .log {
  width: 50%;
  float: left;
}
.basket-body .basket-customisation .custo-bx .top .log .svg {
  width: 30px;
  height: 30px;
  position: relative;
  margin-right: 5px;
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 991px) and (min-width: 768px) {
  .basket-body .basket-customisation .custo-bx .top .log .svg {
    display: none;
  }
}
@media (max-width: 400px) {
  .basket-body .basket-customisation .custo-bx .top .log .svg {
    display: none;
  }
}
.basket-body .basket-customisation .custo-bx .top .log .svg svg {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
}
.basket-body .basket-customisation .custo-bx .top .log span {
  display: inline-block;
  line-height: 30px;
  color: #fff;
  vertical-align: top;
}
.basket-body .basket-customisation .custo-bx .top .pos {
  width: 50%;
  float: left;
  padding-left: 15px;
}
.basket-body .basket-customisation .custo-bx .top .pos span {
  display: inline-block;
  line-height: 30px;
  color: #fff;
  vertical-align: top;
}
.basket-body .basket-customisation .custo-bx .top .log-type, .basket-body .basket-customisation .custo-bx .top .app-type {
  width: 50%;
  float: left;
  text-align: center;
  position: relative;
  height: 120px;
}
.basket-body .basket-customisation .custo-bx .top .log-type svg, .basket-body .basket-customisation .custo-bx .top .app-type svg {
  max-width: 90px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.basket-body .basket-customisation .custo-bx .bottom {
  position: relative;
  min-height: 95px;
}
.basket-body .basket-customisation .custo-bx .bottom .div {
  position: absolute;
  height: 100%;
  width: 1px;
  left: 50%;
  top: 0px;
}
.basket-body .basket-customisation .custo-bx .bottom .left {
  width: 50%;
  float: left;
  text-align: center;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.basket-body .basket-customisation .custo-bx .bottom .right {
  padding: 10px;
  width: 50%;
  float: left;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.basket-body .basket-customisation .custo-bx .bottom .title {
  font-weight: 700;
}
.basket-body .basket-customisation .custo-bx .bottom .postxt {
  display: block;
  margin-top: 10px;
}
.basket-body .basket-customisation .custo-bx .bottom .btn-1 {
  display: block;
  padding: 10px;
  font-weight: 700;
  color: #fff;
  border-radius: 3px;
  margin-top: 15px;
}
.basket-body .basket-customisation .custo-bx .bottom .contact {
  font-weight: 700;
  font-size: 1.2em;
}
.basket-body .basket-customisation .custo-bx .bottom .contact i {
  display: block;
  text-align: center;
  margin: 10px 0px;
}
.basket-body .basket-customisation .custo-bx .bottom .option .line {
  text-align: left;
  margin-bottom: 5px;
}
.basket-body .basket-customisation .custo-bx .bottom .option .line strong {
  margin-right: 5px;
}
.basket-body .basket-customisation .custo-bx .bottom .option img {
  display: inline-block;
  max-height: 70px;
}
.basket-body .basket-customisation .custo-bx .bottom .print-pos .img {
  display: block;
  width: fit-content;
  float: left;
}
.basket-body .basket-customisation .custo-bx .bottom .print-pos .postxt {
  width: calc(100% - 55px);
  float: left;
  padding-left: 5px;
}

.size-row {
  margin-bottom: 15px;
}
.size-row .title {
  width: calc(100% - 110px);
  min-height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
  float: left;
}
.size-row .size-quantity-editor {
  float: left;
}

.baskettable .prod-info .title {
  font-size: 1.4em;
  line-height: 1;
  margin-bottom: 10px;
}
.baskettable .prod-info .code {
  font-size: 0.8em;
}
.baskettable .price-txt {
  font-size: 1.3em;
  line-height: 40px;
  font-weight: 700;
}

.checkout-actions .btn {
  color: #fff;
  font-weight: 700;
  display: block;
  border-radius: 5px;
  text-align: center;
  line-height: 40px;
  width: 100%;
  border: none;
  font-size: 1.2em;
}

.checkout--container .validation-summary-errors {
  border: 1px solid #C01B00;
  color: #C01B00;
  padding: 15px;
  border-radius: 3px;
  background-color: #ffcccc;
}
.checkout--container .validation-summary-errors ul {
  margin: 0px;
}
.checkout--container .validation-summary-errors li {
  font-weight: 700;
}

.basket-error {
  border: 1px solid #C01B00;
  color: #C01B00;
  padding: 15px;
  border-radius: 3px;
  background-color: #ffcccc;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 30px;
}
.basket-error ul {
  margin: 0px;
}
.basket-error li {
  font-weight: 700;
}

.error-warn {
  padding: 10px;
  margin-top: 15px;
  border-radius: 3px;
}

.payment-methods .section {
  margin: 0 -5px;
}
.payment-methods .bs {
  padding: 5px;
}
.payment-methods .payment-option {
  border: none !important;
  display: block;
  width: 100%;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  min-height: 50px;
}
.payment-methods .payment-option.pp {
  background-color: #ffc43a;
}
.payment-methods .payment-option img {
  max-width: 100px;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}
.payment-methods .payment-option strong {
  display: block;
  top: 50%;
  left: 10px;
  color: #fff;
  position: absolute;
  transform: translateY(-50%);
}
.payment-methods .payment-option .dummycheck {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  border: none !important;
}
.payment-methods .payment-option .dummycheck i {
  visibility: visible;
  opacity: 0;
}
.payment-methods .payment-option:hover .dummycheck i {
  opacity: 0.3;
}
.payment-methods .payment-option.on .dummycheck i {
  opacity: 1 !important;
}
.payment-methods .payment-option input {
  display: none;
}

.po-input {
  padding: 15px;
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  margin-top: 15px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1020px;
}
.po-input label {
  float: none !important;
}
.po-input input {
  border-radius: 3px;
  height: 40px;
  border: 1px solid #ddd;
  width: calc(100% - 95px);
}

#iFrameContent .OK, #iFrameContent .ok {
  margin: 0 auto 30px !important;
  text-align: center;
  max-width: 300px;
  padding: 15px !important;
}

.login-panel {
  border: 1px solid #f1f1f1;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 25px;
  min-height: 390px;
}
.login-panel h2 {
  margin-top: 0px;
  margin-bottom: 30px;
}
.login-panel h2 i {
  margin-right: 10px;
}
.login-panel .editor-label {
  font-weight: 700;
  margin: 10px 0px;
}
.login-panel .editor-field input {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 10px;
}
.login-panel .btn {
  width: 100%;
  display: block;
  line-height: 40px;
  border-radius: 3px;
  cursor: pointer;
  border: none;
  color: #fff;
  text-align: center;
  font-weight: 700;
  font-size: 1.1em;
  margin: 15px 0px;
}
.login-panel .forgot {
  float: right;
  display: inline-block;
  text-decoration: underline;
}
.login-panel .forgot:hover {
  text-decoration: none;
}

.account-pages.reorder .account-dash > div.info {
  font-size: 1.2rem;
}
.account-pages.reorder .account-dash > form > .section.flx {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
}
.account-pages .reorder-header {
  display: flex;
  color: #fff;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  grid-gap: 10px;
}
.account-pages .reorder-header > label {
  display: flex;
  align-items: center;
  gap: 10px;
}
.account-pages .reorder-header > label input[type=checkbox] {
  width: 25px;
  height: 25px;
}
.account-pages .reorder-header.bg-c-2 {
  border-radius: 3px 3px 0 0;
}
.account-pages .reorder-block {
  padding: 15px;
  border-radius: 0 0 3px 3px;
  overflow: hidden;
  position: relative;
}
.account-pages .reorder-block .size-list.grid .boot .size-opt {
  background-color: #fff;
}
.account-pages .reorder-block .disabled-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.65;
  z-index: 10;
}
.account-pages .reorder-block .disabled-overlay.enabled {
  display: none;
}
.account-pages .reorder-actions .btn {
  color: #fff;
  line-height: 40px;
  border: none;
  width: 200px;
  text-align: center;
  font-weight: 700;
  border-radius: 3px;
}
.account-pages h1 {
  text-align: left;
}
.account-pages .account-dash {
  min-height: 500px;
}
.account-pages .account-dash .option {
  display: block;
  padding: 15px;
  text-align: center;
  font-size: 1.3em;
  margin-bottom: 30px;
  background-color: #fff;
  border-radius: 3px;
  cursor: pointer;
  transition: box-shadow 0.1s ease-in-out;
  transition: transform 0.1s;
}
.account-pages .account-dash .option i {
  font-size: 1.6em;
}
.account-pages .account-dash .option span {
  margin-top: 15px;
  display: block;
  font-weight: 700;
  color: #000;
}
.account-pages .account-dash .option:hover {
  box-shadow: 0px 11px 11px #00000026;
  transform: translateY(-5px);
}
.account-pages .privacy-txt {
  display: block;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}
.account-pages .account-form {
  padding: 15px;
  border-radius: 3px;
  max-width: 1000px;
  background-color: #f7f7f7;
  border: 1px solid #f1f1f1;
  margin-bottom: 50px;
}
.account-pages .account-form .validation-summary-errors {
  border: 1px solid #C01B00;
  color: #C01B00;
  padding: 15px;
  border-radius: 3px;
  background-color: #ffcccc;
}
.account-pages .account-form .validation-summary-errors ul {
  margin: 0px;
}
.account-pages .account-form .validation-summary-errors li {
  font-weight: 700;
}
.account-pages .account-form h2 {
  margin: 15px 0px;
}
.account-pages .account-form .form-actions {
  margin-top: 30px;
}
.account-pages .account-form .form-actions .btn {
  color: #fff;
  line-height: 40px;
  border: none;
  width: 200px;
  text-align: center;
  font-weight: 700;
  border-radius: 3px;
}
@media (min-width: 551px) {
  .account-pages .account-form .form-actions .left {
    float: left;
  }
  .account-pages .account-form .form-actions .right {
    float: right;
  }
}

.account-container {
  max-width: 1100px;
  margin: 0 auto 50px;
  padding: 0px 15px;
}

.address-card {
  background-color: #f1f1f1;
  border: 1px solid #dbdbdb;
  padding: 15px;
  border-radius: 3px;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  display: block;
  min-height: 330px;
  margin-bottom: 30px;
}
.address-card.add {
  cursor: pointer;
  background-color: #fff;
}
.address-card.add .content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 2em;
  width: 100%;
}
.address-card.add .content i {
  margin-bottom: 15px;
}
.address-card .address-editors {
  width: 100%;
  padding: 15px;
}
.address-card .address-editors .btn {
  display: block;
  color: #fff;
  font-weight: 700;
  width: 100%;
  text-align: center;
  border-radius: 3px;
  margin: 15px 0px;
  line-height: 1;
  padding: 13px;
}
@media (min-width: 992px) {
  .address-card .overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0;
  }
  .address-card .address-editors {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }
  .address-card:hover .overlay {
    opacity: 0.5;
  }
  .address-card:hover .address-editors {
    opacity: 1;
  }
}
@media (max-width: 767px) {
  .address-card {
    min-height: 10px;
  }
  .address-card.add {
    cursor: pointer;
    background-color: #fff;
  }
  .address-card.add .content {
    position: static;
    transform: none;
    text-align: center;
    font-size: 2em;
    width: 100%;
  }
  .address-card.add .content i {
    margin-bottom: 15px;
  }
}
.address-card.selected .address-title {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px;
  color: #fff;
  font-weight: 700;
  border-radius: 0px 0px 0px 5px;
}

.bullet-w-txt .ico {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}
.bullet-w-txt .ico i {
  line-height: 50px;
  width: 100%;
  display: block;
  text-align: center;
  color: #fff;
}
.bullet-w-txt .txt span {
  font-weight: 700;
}
@media (min-width: 1231px) {
  .bullet-w-txt .ico {
    float: left;
  }
  .bullet-w-txt .ico i {
    font-size: 1.3em;
  }
  .bullet-w-txt .txt {
    height: 50px;
    width: calc(100% - 60px);
    float: right;
    position: relative;
  }
  .bullet-w-txt .txt span {
    font-weight: 700;
    position: absolute;
    top: 50%;
    left: 0px;
    display: block;
    width: 100%;
    transform: translateY(-50%);
  }
}
@media (max-width: 1230px) {
  .bullet-w-txt .ico {
    margin: 0 auto;
  }
  .bullet-w-txt .ico i {
    font-size: 1.3em;
  }
  .bullet-w-txt .txt {
    height: 50px;
    position: relative;
  }
  .bullet-w-txt .txt span {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0px;
    display: block;
    width: 100%;
    transform: translateY(-50%);
  }
}

.register-form {
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  max-width: 600px;
  margin: 30px auto;
  padding: 15px;
}
.register-form h1 {
  text-align: center;
  margin-top: 15px;
}
.register-form .btn {
  border: none;
  line-height: 40px;
  width: 100%;
  display: flex;
  border-radius: 3px;
  margin: 30px auto 0px;
  color: #fff;
  font-weight: 700;
  max-width: 400px;
  font-size: 1.2em;
  font-family: proxima-nova, sans-serif;
  justify-content: center;
  align-items: center;
}
.register-form .btn.loading {
  color: transparent !important;
  position: relative;
}
.register-form .btn.loading:before {
  color: #fff;
  content: "Loading...";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.register-form button {
  border: none;
  line-height: 40px;
  width: 100%;
  display: block;
  border-radius: 3px;
  margin: 30px auto 0px;
  color: #fff;
  font-weight: 700;
  max-width: 400px;
  font-size: 1.2em;
  font-family: proxima-nova, sans-serif;
}

.order-list .section {
  margin: 0px -5px;
}
.order-list .bs {
  padding: 5px;
}
@media (min-width: 1401px) {
  .order-list .bs {
    width: calc(100% / 4);
  }
}
@media (max-width: 1400px) and (min-width: 1231px) {
  .order-list .bs {
    width: calc(100% / 3);
  }
}
@media (max-width: 1230px) and (min-width: 992px) {
  .order-list .bs {
    width: calc(100% / 3);
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .order-list .bs {
    width: calc(100% / 2);
  }
}
@media (max-width: 767px) and (min-width: 551px) {
  .order-list .bs {
    width: 100%;
  }
}
@media (max-width: 550px) {
  .order-list .bs {
    width: 100%;
  }
}
.order-list .tile {
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  padding: 15px;
}
.order-list .tile .row {
  margin-bottom: 10px;
}
.order-list .tile .row .title {
  float: left;
}
.order-list .tile .row .val {
  float: right;
}
.order-list .tile .action {
  margin-top: 15px;
}
.order-list .tile .btn {
  display: block;
  padding: 10px;
  color: #fff;
  text-align: center;
  font-weight: 700;
  border-radius: 3px;
}

.orderinner {
  max-width: 800px;
  margin: 0 auto;
}
.orderinner h1 {
  display: none;
}

.btn {
  cursor: pointer;
}
.btn.main-style {
  cursor: pointer;
  border-radius: 3px;
  color: #fff;
  font-weight: 500;
  display: block;
  clear: both;
  max-width: 225px;
  text-align: center;
  padding: 10px;
  line-height: 25px;
  margin: 15px 0px;
  line-height: 40px;
  padding: 0px;
  border: none;
  font-weight: 700;
  width: 100%;
  font-size: 1em;
}
.btn.pay {
  background-color: #079700;
  width: 100%;
  max-width: 100%;
  height: 50px;
}
.btn.pay:hover {
  background-color: #00843d !important;
}
.btn.delete {
  background-color: #ff0000;
  line-height: 40px;
  padding: 0px 10px;
  color: #fff;
  font-weight: 700;
  border-radius: 5px;
  min-width: 40px;
  display: block;
  min-height: 40px;
  text-align: center;
}
.btn.delete:hover {
  background-color: #c52e1c;
}
.btn.delete .ico {
  display: block;
}
.btn.delete .txt {
  display: none;
}

.btn-1, .btn-2 {
  transition: background-color 0.1s ease;
}

.btn-1.disabled, .btn-2.disabled {
  opacity: 0.5;
}

.outer-btn-i {
  position: relative;
}
.outer-btn-i .infotog {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #36373a;
}

﻿.custom-form .max-cont {
  text-align: center;
  box-shadow: inset 0px 8px 7px #00000026;
}
﻿.custom-form .p-10 {
  padding: 10px;
}
@media (min-width: 992px) {
  ﻿.custom-form .summary-boot {
    width: 350px;
  }
  ﻿.custom-form .summary-boot .custom-summary.fixed {
    position: fixed;
    top: 30px;
    left: 50%;
    transform: translateX(-685px);
    background-color: #fff;
    z-index: 50;
    width: 320px;
  }
  ﻿.custom-form .customise-boot {
    width: calc(100% - 350px);
    float: right;
  }
}
﻿.custom-form .step-one-options .option {
  display: block;
  border: 1px solid #dedede;
  background-color: #fff;
  position: relative;
  padding: 15px;
  z-index: 10;
}
﻿.custom-form .step-one-options .option .dummy-check {
  float: left;
  margin-right: 15px;
}
﻿.custom-form .step-one-options .option .title {
  float: left;
  line-height: 30px;
  font-weight: 700;
}
﻿.custom-form .step-one-options .option .right {
  line-height: 30px;
  float: right;
}
﻿.custom-form .custom-summary {
  border: 1px solid #dedede;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 30px;
}
﻿.custom-form .custom-summary h2 {
  font-size: 1.1em;
  margin-top: 0px;
  margin-bottom: 10px;
}
﻿.custom-form .custom-summary .image {
  width: 70px;
  height: 70px;
  border: 1px solid #dbdbdb;
  float: left;
}
﻿.custom-form .custom-summary .item-info {
  float: left;
  padding-left: 10px;
  font-size: 0.8em;
  width: 75%;
}
﻿.custom-form .custom-summary .item-info strong {
  margin-bottom: 10px;
  display: block;
}
﻿.custom-form .custom-summary .breakdown {
  margin-top: 20px;
}
﻿.custom-form .custom-summary .breakdown .cost {
  padding: 10px 0px;
  position: relative;
}
﻿.custom-form .custom-summary .breakdown .cost .price {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
  font-weight: bold;
}
﻿.custom-form .step-number {
  border: 1px solid #dbdbdb;
  background-color: #fbfbfb;
  position: relative;
  padding: 15px 15px 15px 50px;
}
﻿.custom-form .step-number .right-info {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}
﻿.custom-form .step-number .right-info .item {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
}
﻿.custom-form .step-number .right-info .item .svg {
  display: inline-block;
}
﻿.custom-form .step-number .right-info .item svg {
  width: 25px;
  height: 25px;
}
﻿.custom-form .step-number .right-info .item span {
  color: #fff;
  display: inline-block;
  width: auto;
  position: static;
  height: 35px;
  vertical-align: bottom;
  transform: none;
  line-height: 35px;
}
@media (max-width: 767px) {
  ﻿.custom-form .step-number .right-info .item span {
    display: none;
  }
}
﻿.custom-form .step-number span {
  width: 25px;
  height: 25px;
  color: #fff;
  border-radius: 100%;
  text-align: center;
  line-height: 25px;
  display: block;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}
@media (max-width: 400px) {
  ﻿.custom-form .step-number {
    min-height: 110px;
  }
  ﻿.custom-form .step-number span {
    top: 15px;
    transform: none;
  }
  ﻿.custom-form .step-number .right-info {
    transform: none;
    top: auto;
    bottom: 10px;
  }
}
﻿.custom-form .step-number strong {
  font-size: 1.5em;
}
﻿.custom-form .options .step-content {
  background-color: #f7f7f7;
  border: 1px solid #dbdbdb;
  border-top: none;
  padding: 10px;
  margin-bottom: 15px;
}
﻿.custom-form .app-select {
  cursor: pointer;
  display: block;
  border-radius: 3px;
  background-color: #fff;
  position: relative;
  border: 1px solid #dbdbdb;
  min-height: 90px;
}
﻿.custom-form .app-select:hover {
  box-shadow: 0px 11px 11px #00000026;
  transform: translateY(-3px);
}
﻿.custom-form .app-select input {
  display: none;
}
﻿.custom-form .app-select .inner {
  padding: 30px 30px 30px 75px;
  min-height: 90px;
  display: block;
  position: relative;
  z-index: 1;
}
﻿.custom-form .app-select .point {
  display: none;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 50%;
  bottom: -15px;
  transform: translate(-50%) translateY(-21px) rotate(45deg);
  background-color: #fff;
  animation: ease-in-out 0.2s;
}
﻿.custom-form .app-select img, ﻿.custom-form .app-select svg {
  display: block;
  position: absolute;
  top: 50%;
  left: 15px;
  max-width: 50px;
  transform: translateY(-50%);
}
﻿.custom-form .app-select .dummy-check {
  float: left;
  margin-right: 15px;
}
﻿.custom-form .app-select .dummy-check i {
  visibility: hidden;
}
﻿.custom-form .app-select .txt {
  float: left;
}
﻿.custom-form .app-select .txt .title {
  display: block;
  font-weight: 700;
  font-size: 1.1em;
  width: 45px !important;
}
﻿.custom-form .app-select .txt .title.notxt {
  line-height: 25px;
}
﻿.custom-form .app-select .txt .txt {
  font-size: 0.8em;
}
﻿.custom-form .app-select.selected .point {
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 50%;
  bottom: -15px;
  transform: translate(-50%) rotate(45deg);
  background-color: #fff;
}
﻿.custom-form .app-select.selected .dummy-check i {
  visibility: visible;
}
﻿.custom-form .info {
  display: none;
  width: 20px;
  height: 20px;
  background-color: #000;
  color: #fff;
  border-radius: 100%;
  text-align: center;
  line-height: 20px;
  font-size: 0.7em;
  position: absolute;
  top: 15px;
  right: 15px;
}
﻿.custom-form .info .inner-rel {
  position: relative;
  display: block;
}
﻿.custom-form .info:hover .info-txt {
  display: block !important;
}
﻿.custom-form .info .info-txt {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  bottom: calc(100% + 40px);
  border-radius: 3px;
  background-color: #000;
  width: 300px;
  font-size: 1.3em;
  display: none;
}
﻿.custom-form .info .info-txt .inner-rel {
  position: relative;
  padding: 10px;
}
﻿.custom-form .info .info-txt .inner-rel:before {
  content: " ";
  width: 25px;
  height: 25px;
  position: absolute;
  background-color: #000;
  border-radius: 3px;
  bottom: -10px;
  left: 50%;
  z-index: -1;
  transform: translateX(-50%) rotate(45deg);
}
﻿.custom-form .logo-tboxes .editor-label {
  font-weight: 700;
  margin: 10px 0px;
  display: block;
}
﻿.custom-form .logo-tboxes textarea {
  width: 100%;
  height: 37px;
  padding: 10px;
  min-height: 150px;
  border: 1px solid #dbdbdb;
}
﻿.custom-form .logo-tboxes input {
  width: 100%;
  height: 37px;
  padding: 0px 10px;
  border: 1px solid #dbdbdb;
}
﻿.custom-form .choose-text-box .logo-tboxes .editor-label {
  font-weight: 700;
  margin: 10px 0px;
  display: block;
}
﻿.custom-form .choose-text-box .logo-tboxes input {
  width: 100%;
  height: 37px;
  padding: 0px 10px;
  border: 1px solid #dbdbdb;
}
@media (min-width: 992px) {
  ﻿.custom-form .choose-text-box .preview-boot {
    float: right;
  }
}
﻿.custom-form .choose-text-box .text-preview {
  height: 419px;
  border: 1px solid #dbdbdb;
  position: relative;
  background-color: #fff;
  border-radius: 3px;
}
@media (min-width: 992px) {
  ﻿.custom-form .choose-text-box .text-preview {
    height: 419px;
  }
}
@media (max-width: 991px) {
  ﻿.custom-form .choose-text-box .text-preview {
    height: 219px;
  }
}
﻿.custom-form .choose-text-box .text-preview strong {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.7em;
}
﻿.custom-form .choose-text-box .text-preview .realtime-txt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #333;
  font-weight: 700;
  width: 100%;
}
﻿.custom-form .choose-text-box .text-preview .realtime-txt span {
  margin: 0px;
  display: block;
  text-align: center;
  font-size: 2em;
}
@media (max-width: 991px) {
  ﻿.custom-form .choose-text-box .text-preview .realtime-txt span {
    line-height: 1;
  }
}
﻿.custom-form .choose-image-box .logo-choice {
  display: block;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #dbdbdb;
}
﻿.custom-form .choose-image-box .logo-choice input {
  display: none;
}
﻿.custom-form .choose-image-box .logo-choice .dummy-upload {
  margin-right: 10px;
  display: inline-block;
  width: 145px;
  padding: 10px;
  text-align: center;
  font-weight: 700;
  color: #fff;
  border-radius: 3px;
}
﻿.custom-form .choose-image-box .logo-choice .file-info {
  font-size: 0.8em;
}

.customise-colour-drop {
  position: relative;
}
.customise-colour-drop .head {
  cursor: pointer;
}
.customise-colour-drop .head i {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
.customise-colour-drop .head.open i {
  transform: translateY(-50%) rotate(180deg);
}

.customise-font-drop {
  position: relative;
}
.customise-font-drop .head {
  cursor: pointer;
}
.customise-font-drop .head i {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
.customise-font-drop .head.open i {
  transform: translateY(-50%) rotate(180deg);
}

.drop-options {
  position: absolute;
  top: 36px;
  left: 0px;
  width: 100%;
  z-index: 10;
  background-color: #fff;
  border: 1px solid #aaa;
  max-height: 200px;
  overflow: auto;
}
.drop-options.colour {
  z-index: 12;
}
.drop-options .option {
  padding: 10px;
  cursor: pointer;
}
.drop-options .option:nth-child(odd) {
  background-color: #f8f8f8;
}
.drop-options .option .colour-bx {
  width: 25px;
  height: 25px;
  border: 1px solid #333;
  margin-right: 5px;
  display: inline-block;
  border-radius: 3px;
  vertical-align: bottom;
}
.drop-options .option b .txt {
  line-height: 25px;
}
.drop-options .option:hover {
  background-color: #ddd;
}

.customise-actions {
  margin-bottom: 60px;
}
.customise-actions .btn {
  height: 46px;
  border: none;
  border-radius: 3px;
  color: #fff;
  font-size: 1em;
  font-weight: 700;
  padding: 0 20px;
  line-height: 46px;
}
.customise-actions .left {
  float: left;
}
.customise-actions .right {
  float: right;
}

.position-option {
  padding: 10px;
}
@media (min-width: 1231px) {
  .position-option {
    width: 25%;
    float: left;
  }
}
@media (min-width: 992px) and (max-width: 1230px) {
  .position-option {
    width: 25%;
    float: left;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .position-option {
    width: 25%;
    float: left;
  }
}
@media (min-width: 551px) and (max-width: 767px) {
  .position-option {
    width: 50%;
    float: left;
  }
}
.position-option input {
  display: none;
}
.position-option .inner-border {
  position: relative;
  cursor: pointer;
  display: block;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 3px;
  text-align: center;
  padding: 10px;
}
.position-option .inner-border .cost {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px;
  border-radius: 0px 0px 0px 5px;
  color: #fff;
  font-weight: 700;
}
.position-option .inner-border .cost.emb {
  right: auto;
  left: 0px;
  border-radius: 0px 0px 5px 0px;
}
.position-option .inner-border:hover {
  box-shadow: 0px 11px 11px #00000026;
  transform: translateY(-5px);
}
.position-option .inner-border .img {
  display: inline-block;
}
.position-option .inner-border .img .img-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  margin-top: 30px;
  margin-bottom: 15px;
}
.position-option .inner-border .img img {
  display: block;
}
.position-option .inner-border .bottom span {
  display: inline-block;
  margin-right: 10px;
}
.position-option .inner-border .bottom span i {
  opacity: 0;
}
.position-option.selected .bottom span i {
  opacity: 1;
}

.custo-garm {
  padding: 10px;
  border: 1px solid #dbdbdb;
  background-color: #fff;
  position: relative;
}
.custo-garm .custompos-flex {
  display: flex;
  grid-gap: 15px;
}
@media (max-width: 1230px) {
  .custo-garm .custompos-flex {
    flex-direction: column-reverse;
  }
}
.custo-garm .left {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}
.custo-garm .left > div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
}
@media (max-width: 1230px) {
  .custo-garm .left > div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
@media (max-width: 550px) {
  .custo-garm .left > div img {
    max-width: 90px;
  }
}
.custo-garm .left > div.small-imgs img {
  max-width: 90px;
}
.custo-garm .left h2 {
  margin: 0px 0px 15px 0px;
  font-size: 1.6em;
}
@media (max-width: 550px) {
  .custo-garm .left h2 {
    margin: 0 auto;
    text-align: center;
  }
}
.custo-garm .right .row {
  margin-bottom: 15px;
}
.custo-garm .right .row .title {
  font-weight: 700;
  font-size: 1.16em;
  line-height: 40px;
}
@media (min-width: 1231px) {
  .custo-garm .right .row .title {
    width: 250px;
    float: left;
  }
}
.custo-garm .right .row .actions {
  position: relative;
}
.custo-garm .right .row .actions .field-validation-error {
  position: absolute;
  border: 1px solid #ff0000;
  color: #ff0000;
  z-index: 10;
  border-radius: 3px;
  padding: 9px 10px;
  font-weight: 700;
  background-color: #F1DDE1;
}
@media (min-width: 992px) {
  .custo-garm .right .row .actions .field-validation-error {
    top: 0px;
    left: calc(100% + 10px);
    display: block;
    width: 255px;
  }
}
.custo-garm .right .row .actions .section {
  margin: 0px -6px;
}
.custo-garm .right .row .actions .dw-xs-6 {
  padding: 0px 6px;
}
@media (min-width: 1231px) {
  .custo-garm .right .row .actions {
    width: calc(100% - 250px);
    float: left;
    max-width: 320px;
  }
}
.custo-garm .right .row .actions input {
  width: 100%;
  padding: 15px;
  height: 40px;
  border: 1px #DBDBDB solid;
  border-radius: 3px;
}
.custo-garm .right .row .actions .app-opt {
  height: 40px;
  border-radius: 3px;
  font-weight: 700;
  text-align: center;
  display: block;
  line-height: 40px;
  background-color: #ddd;
  cursor: pointer;
}
.custo-garm .right .row .actions .app-opt:hover {
  box-shadow: 0px 11px 11px #00000026;
  transform: translateY(-3px);
}
.custo-garm .right .row .actions .app-opt input {
  display: none;
}
.custo-garm .custom-settings {
  margin-top: 30px;
  border-radius: 3px;
  position: relative;
}
.custo-garm .custom-settings .inner {
  background-color: #fff;
  padding: 15px;
  position: relative;
}
.custo-garm .custom-settings .point {
  position: absolute;
  width: 30px;
  height: 30px;
  top: -10px;
}
.custo-garm .custom-settings .point .inner {
  transform: rotate(45deg);
  background-color: #fff;
  width: 30px;
  height: 30px;
}
@media (min-width: 1401px) {
  .custo-garm .custom-settings .point {
    left: 32.5%;
  }
  .custo-garm .custom-settings .point.for-text {
    transform: translateX(172px);
  }
}
@media (max-width: 1400px) and (min-width: 1231px) {
  .custo-garm .custom-settings .point {
    left: 32%;
  }
  .custo-garm .custom-settings .point.for-logo {
    transform: translateX(50px);
  }
  .custo-garm .custom-settings .point.for-text {
    transform: translateX(225px);
  }
}
@media (max-width: 1230px) and (min-width: 992px) {
  .custo-garm .custom-settings .point {
    left: 50%;
  }
  .custo-garm .custom-settings .point.for-logo {
    transform: translateX(-135px);
  }
  .custo-garm .custom-settings .point.for-text {
    transform: translateX(110px);
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .custo-garm .custom-settings .point {
    left: 50%;
  }
  .custo-garm .custom-settings .point.for-logo {
    transform: translateX(-135px);
  }
  .custo-garm .custom-settings .point.for-text {
    transform: translateX(110px);
  }
}
@media (max-width: 767px) {
  .custo-garm .custom-settings .point {
    transform: translateX(-50%);
  }
  .custo-garm .custom-settings .point.for-logo {
    left: 25%;
  }
  .custo-garm .custom-settings .point.for-text {
    left: 75%;
  }
}

.logo-actions .section {
  margin: 0px -6px;
}
.logo-actions .section .dw-sm-4 {
  padding: 0px 6px;
}
.logo-actions .btn {
  margin-bottom: 10px;
  width: 100%;
  height: 40px;
  position: relative;
  background-color: #dedede;
  border-radius: 3px;
  display: block;
  cursor: pointer;
}
.logo-actions .btn input {
  display: none;
}
.logo-actions .btn:hover {
  box-shadow: 0px 11px 11px #00000026;
  transform: translateY(-3px);
}
.logo-actions .btn span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: block;
  padding: 0px 10px;
  text-align: center;
  font-weight: 700;
}

.logo-sub-actions {
  margin-top: 15px;
}

.logoselect {
  border: 1px solid #dbdbdb;
  border-radius: 3px;
}
.logoselect .title {
  background-color: #fbfbfb;
  border-bottom: 1px #dedede solid;
  padding: 15px;
}
.logoselect .title span {
  font-weight: 700;
  font-size: 1.2em;
}
.logoselect .logolist {
  padding: 5px;
}
.logoselect .logolist .logobx {
  padding: 5px;
}
@media (min-width: 1231px) {
  .logoselect .logolist .logobx {
    width: 25%;
    float: left;
  }
}
.logoselect .logolist .logobx label {
  display: block;
  text-align: center;
  border: 1px solid #dbdbdb;
  padding: 10px;
}
.logoselect .logolist .logobx label input {
  display: none;
}
.logoselect .logolist .logobx label img {
  display: block;
  text-align: center;
  margin: 0 auto;
}
.logoselect .logolist .logobx label strong {
  margin-top: 10px;
  display: block;
  text-align: center;
}
.logoselect .logolist .logobx label:hover {
  box-shadow: 0px 11px 11px #00000026;
  transform: translateY(-3px);
}

.login-section {
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  margin-bottom: 15px;
  position: relative;
  padding: 15px;
  background-color: #fff;
}
@media (max-width: 767px) {
  .login-section {
    text-align: center;
  }
}
.login-section strong {
  font-size: 1.5em;
}
.login-section .btn-1 {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  text-align: center;
  width: 100%;
  max-width: 150px;
  color: #fff;
  font-weight: 700;
}
@media (max-width: 767px) {
  .login-section .btn-1 {
    position: static;
    transform: none;
    display: inline-block;
    margin-top: 15px;
  }
}

.img-controls {
  text-align: center;
}
.img-controls .actions {
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  padding: 10px;
  background-color: #fff;
  display: block;
  clear: both;
  max-width: 100%;
  position: static !important;
  transform: none !important;
  opacity: 1 !important;
}
.img-controls .actions .favicon-preview {
  width: 100%;
  margin: 0 auto 15px;
  border: 1px #dbdbdb solid;
  border-radius: 3px;
  max-width: 350px;
  height: 80px;
  background: url(../../../../../_assets/images/browser-tab.jpg);
  background-position: center left;
  background-size: cover;
  position: relative;
}
.img-controls .actions .favicon-preview .img-preview-bx {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 20px;
}
.img-controls .actions .drop-zone {
  border: 2px dashed #dbdbdb;
  position: relative;
  border-radius: 3px;
  margin-bottom: 15px;
  transition: background-color 0.2s ease;
  cursor: pointer;
  display: block;
}
.img-controls .actions .drop-zone:hover {
  background-color: #f1f1f1;
}
.img-controls .actions .drop-zone input {
  display: block;
  width: 100%;
  height: 150px;
  margin: 0px;
  border: none;
  position: relative;
  z-index: 99;
  cursor: pointer;
  opacity: 0;
}
.img-controls .actions .drop-zone .drop-txt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #999;
  display: block;
  font-size: 1.1em;
}
.img-controls .actions .drop-zone .drop-txt i {
  display: block;
  margin-bottom: 10px;
  text-align: center;
}
.img-controls .actions .up {
  display: block;
  float: left;
  margin-right: 5px;
  width: 180px;
  height: 40px;
  background-color: #369D00;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
}
.img-controls .actions .up:hover {
  background-color: #4BD800;
}
.img-controls .actions .up input {
  display: none;
}
.img-controls .actions .image-info {
  display: block;
  padding: 10px;
  line-height: 20px;
  background-color: #fff;
  padding: 10px;
  font-size: 0.95em;
  font-weight: 700;
}
.img-controls .imgmsg, .img-controls .field-validation-error {
  border: 1px solid #ff0000;
  color: #cc0000;
  border-radius: 3px;
  padding: 10px;
  background-color: #fff;
  font-weight: 700;
  line-height: 20px;
}
.img-controls .img-preview-bx {
  text-align: center;
  min-height: 95px;
}
.img-controls .img-preview-bx img {
  display: inline-block;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  margin-bottom: 15px;
  max-height: 190px;
  width: auto;
}
.img-controls .imgmsg, .img-controls .field-validation-error {
  float: none;
  display: inline-block;
  position: static;
}
.img-controls .imgmsg p, .img-controls .field-validation-error p {
  margin: 0px;
}

.aff-announcement {
  padding: 15px;
  border: 1px solid #dbdbdb;
  background-color: #fbfbfb;
  width: 100%;
  display: flex;
  align-items: center;
  grid-gap: 15px;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.aff-announcement i {
  font-size: 2rem;
}
@media (max-width: 991px) {
  .aff-announcement {
    font-size: 0.9rem;
  }
  .aff-announcement i {
    font-size: 1.3rem;
  }
}

.editor-label {
  font-weight: 700;
  margin: 10px 0px;
}

.editor-field input {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 10px;
}
.editor-field input.input-validation-error {
  border: 1px solid #c01b00;
}
.editor-field select {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 10px;
}
.editor-field textarea {
  display: block;
  width: 100%;
  border: 1px solid #ddd;
  height: 150px;
  padding: 15px;
}
.editor-field.sml input {
  padding: 5px;
}
.editor-field.nobord input {
  border: none !important;
}
.editor-field.center-txt input {
  text-align: center;
}

.right-btn.narr {
  max-width: 500px;
}
.right-btn .text-box, .right-btn input, .right-btn select {
  width: calc(100% - 170px) !important;
  float: left;
}
.right-btn .btn {
  float: right;
  width: 155px !important;
  height: 40px;
  line-height: 40px;
  padding: 0px;
  color: #fff;
  font-weight: 700;
  border-radius: 3px;
  text-align: center;
  font-size: 1.2em;
  border: none !important;
}

.editor-field {
  position: relative;
}
.editor-field span.forgot {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.1em;
}
.editor-field .g-recaptcha {
  max-width: 302px;
  margin: 0 auto;
}

.checkbx-style {
  display: inline-block;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.checkbx-style input[type=checkbox] {
  display: none;
}
.checkbx-style i {
  width: 100%;
  line-height: 28px;
  display: block;
  opacity: 0;
}
.checkbx-style:hover i {
  opacity: 0.3;
}
.checkbx-style.on i {
  opacity: 1 !important;
}

.field-validation-error {
  color: #c01b00;
}

@media (min-width: 992px) {
  .lr-form-row {
    margin-bottom: 15px;
  }
  .lr-form-row .title {
    width: 250px;
    float: left;
  }
  .lr-form-row .field {
    width: calc(100% - 250px);
    float: left;
  }
}
.panel.checkstripe {
  max-width: 600px;
  width: calc(100% - 30px);
  margin: 30px auto;
  border: 1px solid #dedede;
  padding: 30px;
}
.panel.checkstripe label.pay-field {
  display: block;
  padding: 10px;
  border: 1px solid #dedede;
  border-radius: 3px;
}

.discount-msg .KO {
  padding: 10px 15px;
  color: #fff;
  background-color: #e3000f;
  margin-bottom: 10px;
  text-align: left;
  width: max-content;
  margin-bottom: -15px;
  margin-top: 30px;
  border-radius: 5px;
}
.discount-msg .OK {
  padding: 10px 15px;
  color: #fff;
  background-color: #00ae33;
  margin: 20px 0;
  text-align: center;
  width: max-content;
  margin-bottom: -15px;
  margin-top: 30px;
  border-radius: 5px;
}

.checkbox-style {
  width: 30px;
  height: 30px;
  border: 1px solid #dedede;
  display: flex;
  border-radius: 3px;
  position: relative;
  justify-content: center;
  align-items: center;
}
.checkbox-style i {
  opacity: 0;
}
.checkbox-style.on i {
  opacity: 1;
}
.checkbox-style input {
  position: absolute;
  display: none;
}

.account-pages .field-validation-error {
  font-weight: bold;
  color: #c01b00;
  display: block;
  width: max-content;
  border: 1px solid #c01b00;
  padding: 5px;
  border-radius: 3px;
  background-color: #ffeaea;
}

.account-pages .successmsg {
  color: #00ae33;
  border: 1px solid #00ae33;
  padding: 5px;
  font-weight: 700;
  border-radius: 3px;
  width: max-content;
  background-color: #c2ffd4;
}

.head-section #mc-section .mc-head {
  height: 6px;
}

#newsletterform .newsletter--form > form {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 25px 0;
}
@media (max-width: 767px) {
  #newsletterform .newsletter--form > form {
    flex-direction: column;
    padding-bottom: unset;
  }
}
#newsletterform .newsletter--form > form > span {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  max-width: 300px;
  margin-right: 10px;
}
@media (max-width: 767px) {
  #newsletterform .newsletter--form > form > span {
    max-width: 90%;
  }
}
#newsletterform .newsletter--form > form > input#NewsEmailAddress {
  max-width: 500px;
  width: 500px;
  height: 46px;
  padding: 0 15px;
}
@media (max-width: 767px) {
  #newsletterform .newsletter--form > form > input#NewsEmailAddress {
    max-width: 90%;
    margin-top: 10px;
  }
}
#newsletterform .newsletter--form > form > .btn {
  width: 180px;
  height: 46px;
  background-color: #36373a;
  border: unset;
  font-weight: 600;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767px) {
  #newsletterform .newsletter--form > form > .btn {
    width: 100%;
    max-width: 90%;
  }
}
@media (max-width: 400px) {
  #newsletterform .newsletter--form > form > .btn {
    max-width: 300px;
  }
}
#newsletterform .newsletter--form > form > .btn:hover {
  background-color: rgba(93, 94, 99, 0.73);
}
#newsletterform .newsletter--form > form > .btn.loading {
  color: transparent !important;
  position: relative;
}
#newsletterform .newsletter--form > form > .btn.loading:before {
  color: #fff;
  content: "Loading...";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
#newsletterform .newsletter--form > form > button[type=submit] {
  width: 180px;
  height: 46px;
  background-color: #36373a;
  border: unset;
  font-weight: 600;
  font-size: 1rem;
}
@media (max-width: 767px) {
  #newsletterform .newsletter--form > form > button[type=submit] {
    width: 100%;
    max-width: 90%;
  }
}
@media (max-width: 400px) {
  #newsletterform .newsletter--form > form > button[type=submit] {
    max-width: 300px;
  }
}
#newsletterform .newsletter--form > form > button[type=submit]:hover {
  background-color: rgba(93, 94, 99, 0.73);
}

#mailchimpform .mc-head {
  height: 6px;
}
#mailchimpform .mc-head.bottom {
  filter: brightness(0.7);
}
#mailchimpform .title {
  font-size: 1.875rem;
  font-weight: 600;
}
#mailchimpform.footer .forminner {
  color: #fff;
}
#mailchimpform .forminner {
  /*padding-bottom: 20px;*/
  padding-top: 20px;
}
@media (min-width: 992px) {
  #mailchimpform .forminner {
    padding-bottom: 20px;
  }
}
@media (max-width: 991px) {
  #mailchimpform .forminner.expanded {
    padding-bottom: 20px;
  }
}
#mailchimpform .forminner > form {
  align-items: center;
}
@media (max-width: 991px) {
  #mailchimpform .forminner > form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}
#mailchimpform .forminner .section {
  display: flex;
}
@media (min-width: 992px) {
  #mailchimpform .forminner .section {
    align-items: center;
  }
}
@media (max-width: 991px) {
  #mailchimpform .forminner .section {
    width: 100%;
    flex-direction: column;
    gap: 10px;
  }
}
@media (max-width: 991px) {
  #mailchimpform .forminner .section .head {
    display: flex;
    align-items: flex-end;
  }
}
@media (max-width: 991px) {
  #mailchimpform .forminner .section .inner {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
#mailchimpform .forminner .section .inner.top {
  padding: unset;
}
@media (max-width: 991px) {
  #mailchimpform .forminner .section .inner.top {
    display: none;
  }
  #mailchimpform .forminner .section .inner.top > * {
    margin-bottom: 10px;
  }
}
#mailchimpform .forminner .section .inner.pop {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
#mailchimpform .forminner .section .inner.pop > * {
  width: 100%;
}
#mailchimpform .forminner .section .expand-mob {
  margin-top: 10px;
  text-align: center;
}
@media (min-width: 992px) {
  #mailchimpform .forminner .section .expand-mob {
    display: none;
  }
}
#mailchimpform .forminner .section .expand-mob i.open {
  transform: rotate(180deg);
}
#mailchimpform .forminner.popup-content > form .section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
#mailchimpform .forminner.popup-content > form .section > * {
  width: 100%;
}
#mailchimpform .editor-label {
  display: none;
}
#mailchimpform .editor-field input {
  border: 1px solid #010101;
  border-radius: 2px;
  height: 46px;
  font-size: 0.9375rem;
}
#mailchimpform .editor-field button[type=submit] {
  border: 1px solid #010101;
  border-radius: 2px;
  height: 46px;
  font-size: 0.9375rem;
  color: #fff;
  border: unset;
  border-radius: 4px;
  font-size: 1.25rem;
  font-weight: 600;
  max-width: 274px;
  width: 100%;
}
#mailchimpform .editor-field button[type=submit]:hover {
  background-color: #b14c0b;
}
#mailchimpform .editor-field .btn {
  border: 1px solid #010101;
  border-radius: 2px;
  height: 46px;
  font-size: 0.9375rem;
  color: #fff;
  border: unset;
  border-radius: 4px;
  font-size: 1.25rem;
  font-weight: 600;
  max-width: 274px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 991px) {
  #mailchimpform .editor-field .btn {
    max-width: unset;
  }
}
#mailchimpform .editor-field .btn:hover {
  /*background-color: #b14c0b;*/
}
#mailchimpform .editor-field .btn.loading {
  color: transparent;
  position: relative;
}
#mailchimpform .editor-field .btn.loading:before {
  color: #fff;
  content: "Loading...";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
#mailchimpform .mcform.popup .forminner.popup-content {
  max-width: 437px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 50px;
}
#mailchimpform .mcform.popup .forminner.popup-content > .pop-head {
  position: absolute;
  height: 4px;
  width: 100%;
  top: 0;
  left: 0;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
#mailchimpform .mcform.popup .forminner.popup-content > .pop-bg {
  position: absolute;
  height: 155px;
  width: 100%;
  background-color: #F6F6F6;
  bottom: 0;
  left: 0;
}
#mailchimpform .mcform.popup .forminner.popup-content > i.close {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #6e6e6e;
  cursor: pointer;
  font-size: 1.5rem;
}
#mailchimpform .mcform.popup .forminner.popup-content > .mctitle {
  display: none;
}
#mailchimpform .mcform.popup .forminner.popup-content > form {
  display: flex;
  flex-direction: column;
  gap: 11px;
}
#mailchimpform .mcform.popup .forminner.popup-content > form div.title {
  text-align: center;
  margin-bottom: unset;
}
#mailchimpform .mcform.popup .forminner.popup-content > form div.mctitle {
  text-align: center;
}
#mailchimpform .mcform.popup .forminner.popup-content > form > * {
  width: 100%;
}
#mailchimpform .mcform.popup .forminner.popup-content > form div[type=submit].btn {
  max-width: unset;
}
#mailchimpform .mcform.popup .forminner.popup-content > form div.email {
  margin-top: 40px;
}

.contact-form {
  padding: 30px;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  margin-bottom: 30px;
}
.contact-form .form-head {
  font-size: 1.6em;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
}
.contact-form .form-head i {
  margin-right: 15px;
}
.contact-form button {
  border: none;
  display: block;
  padding: 15px;
  border-radius: 3px;
  color: #fff;
  font-weight: 700;
  width: 100%;
  font-size: 1.1em;
  margin: 30px auto 0px;
}
.contact-form .btn {
  border: none;
  display: block;
  padding: 15px;
  border-radius: 3px;
  color: #fff;
  font-weight: 700;
  width: 100%;
  font-size: 1.1em;
  margin: 30px auto 0px;
  text-align: center;
}

.info .info-row {
  position: relative;
  padding-left: 50px;
  margin-bottom: 15px;
}
.info .info-row .icon {
  width: 35px;
  height: 35px;
  display: block;
  line-height: 35px;
  border-radius: 100%;
  position: absolute;
  left: 0px;
  text-align: center;
}
.info .info-row .icon i {
  line-height: 35px;
  color: #fff;
  font-size: 1.1em;
}

.map-countainer {
  margin-bottom: 30px;
}

.type-h2 {
  text-align: center;
}

.type-jpgbann .img-link {
  height: 490px;
  margin: 0 auto;
  display: block;
}
.type-jpgbann .img-link img {
  width: 100%;
  height: auto;
}

.type-presetbann img {
  max-width: none !important;
  width: auto;
  max-height: 490px;
}
.type-presetbann .inner-rel {
  width: 100%;
  height: 100%;
  position: relative;
}
@media (min-width: 551px) {
  .type-presetbann .inner-rel {
    height: 490px;
  }
}
@media (max-width: 550px) {
  .type-presetbann .inner-rel {
    height: 300px;
  }
  .type-presetbann .inner-rel img {
    max-height: 300px;
    width: auto;
  }
  .type-presetbann .inner-rel:before {
    content: "";
    display: block;
    width: calc(100% + 50px);
    left: 50%;
    transform: translateX(-50%);
    top: 0px;
    height: 100%;
    background-color: #000;
    position: absolute;
    z-index: 8;
    opacity: 0.3;
  }
}
.type-presetbann .bann-surround {
  margin: 0 auto;
  display: block;
  position: relative;
  overflow: hidden;
}
.type-presetbann .bann-surround.iscentered .banner-content {
  width: 100%;
  max-width: 460px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 10;
}
@media (min-width: 992px) and (min-width: 1231px) {
  .type-presetbann .bann-surround.iscentered img {
    display: block;
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
  .type-presetbann .bann-surround.iscentered img:first-child {
    position: absolute;
    left: 0px;
    bottom: 0px;
  }
}
@media (min-width: 992px) and (max-width: 1230px) {
  .type-presetbann .bann-surround.iscentered img {
    display: block;
    position: absolute;
    bottom: -50px;
    right: 0px;
  }
  .type-presetbann .bann-surround.iscentered img:first-child {
    position: absolute;
    left: -50px;
    bottom: 0px;
  }
}
@media (max-width: 991px) {
  .type-presetbann .bann-surround.iscentered img {
    display: block;
    position: absolute;
    bottom: 0px;
    right: -100px;
  }
  .type-presetbann .bann-surround.iscentered img:first-child {
    position: absolute;
    left: -100px;
    bottom: 0px;
  }
}
.type-presetbann .bann-surround.notcentered .banner-content {
  width: 100%;
  max-width: 460px;
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  z-index: 10;
}
@media (min-width: 992px) and (min-width: 1231px) {
  .type-presetbann .bann-surround.notcentered img {
    display: block;
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
  .type-presetbann .bann-surround.notcentered img:first-child {
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
}
@media (min-width: 992px) and (max-width: 1230px) {
  .type-presetbann .bann-surround.notcentered img {
    display: block;
    position: absolute;
    bottom: -50px;
    right: 0px;
  }
}
@media (max-width: 991px) {
  .type-presetbann .bann-surround.notcentered img {
    display: block;
    position: absolute;
    bottom: 0px;
    right: -100px;
  }
}
.type-presetbann .bann-surround .banner-content .toptxt {
  padding: 10px 30px;
  font-weight: 700;
  font-size: 1.3em;
  line-height: 16px;
  display: inline-block;
  color: #fff;
  margin-bottom: 30px;
}
.type-presetbann .bann-surround .banner-content h2 {
  color: #333;
  margin: 0px 0px 30px 0px;
  font-size: 3em;
}
@media (max-width: 550px) {
  .type-presetbann .bann-surround .banner-content h2 {
    colour: #fff;
  }
}
@media (max-width: 550px) {
  .type-presetbann .bann-surround .banner-content h2 {
    colour: #fff;
  }
}
.type-presetbann .bann-surround .banner-content .txt {
  color: #333;
  font-size: 2em;
  margin-bottom: 30px;
}
@media (max-width: 550px) {
  .type-presetbann .bann-surround .banner-content .txt {
    colour: #fff;
  }
}
.type-presetbann .bann-surround .banner-content .bannbtn {
  display: inline-block;
  background-color: #fff;
  padding: 0px 30px;
  height: 45px;
  line-height: 45px;
  min-width: 255px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 3px;
  color: #fff;
  background-color: #36373A;
  text-align: center;
}

.content-w-img {
  margin: 30px 0px;
  position: relative;
}
@media (min-width: 992px) {
  .content-w-img .right {
    width: 50%;
    position: absolute;
    height: 100%;
    top: 0px;
    right: 0px;
  }
  .content-w-img .left {
    width: 50%;
    float: left;
  }
}
@media (max-width: 991px) {
  .content-w-img .right {
    height: 300px;
  }
}
.content-w-img .right {
  background-position: center;
  background-size: cover;
}
.content-w-img .txt {
  padding: 50px 45px;
  color: #fff;
}
.content-w-img .txt h3 {
  color: #fff;
  margin: 0px 0px 30px 0px;
  font-size: 2em;
}

.type-customershop .shop-bx {
  padding: 0px 15px;
  cursor: pointer;
  max-width: 440px;
  max-height: 300px;
  overflow: hidden;
  margin: 0 auto;
  transition: box-shadow 0.1s ease-in-out, transform 0.1s;
}

.type-brandicons {
  margin: 100px 0px;
}
.type-brandicons .brand {
  text-align: center;
  position: relative;
  min-height: 50px;
}
.type-brandicons .brand img {
  max-width: 100%;
  height: auto;
}

.widget-display.title h1 {
  font-size: 2.2em;
}
.widget-display.title h2 {
  font-size: 2em;
}
.widget-display.title h3 {
  font-size: 1.8em;
}
.widget-display.content {
  text-align: center;
  margin: -15px 0px;
}
@media (min-width: 992px) {
  .widget-display {
    margin: 30px auto;
  }
}
@media (max-width: 991px) {
  .widget-display {
    margin: 15px auto;
  }
}
.widget-display p {
  margin: 0 auto;
}
.widget-display:first-child {
  margin: 0px auto 15px !important;
}
.widget-display.title h1, .widget-display.title h2, .widget-display.title h3 {
  margin: 15px 0px;
}
.widget-display.banner {
  margin: 0px auto !important;
}
.widget-display.banner a {
  display: block;
}
.widget-display.banner .bx-wrapper {
  max-height: 490px;
}
.widget-display.banner .bx-wrapper .bx-viewport {
  max-height: 490px;
}
.widget-display.bannerfifty .bann {
  max-width: 675px;
  display: block;
  margin: 15px auto;
}
.widget-display.bannerthirds .bann {
  max-width: 440px;
  display: block;
  margin: 15px auto;
}
.widget-display.brandsslider {
  max-width: 1350px;
}
.widget-display.brandsslider .slide {
  text-align: center;
  min-width: 150px;
}
.widget-display.brandsslider .slide img {
  margin: 0 auto;
  width: auto;
  max-height: 50px;
}
.widget-display.featuredcategories {
  margin: 15px auto;
}
.widget-display.featuredcategories .bx-wrapper {
  max-height: 340px;
}
.widget-display.contentwithimg .txtbx {
  color: #fff;
  padding: 30px;
}
.widget-display.contentwithimg .txt-right {
  position: relative;
}
.widget-display.contentwithimg .txt-right .txt-boot {
  min-height: 320px;
  display: flex;
  align-items: center;
}
@media (max-width: 991px) and (min-width: 768px) {
  .widget-display.contentwithimg .txt-right .txt-boot.dw-sm-9 {
    width: 100%;
    float: none;
  }
}
.widget-display.contentwithimg .txt-left {
  position: relative;
}
@media (max-width: 991px) and (min-width: 768px) {
  .widget-display.contentwithimg .txt-left .img-boot.dw-sm-3 {
    width: 100%;
    float: none;
    padding: 15px;
    text-align: center;
  }
  .widget-display.contentwithimg .txt-left .img-boot.dw-sm-3 img {
    display: inline-block;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .widget-display.contentwithimg .txt-left .txt-boot.dw-sm-9 {
    width: 100%;
    float: none;
  }
}
@media (max-width: 767px) {
  .widget-display.contentwithimg .img-boot {
    width: 100%;
    float: none !important;
    text-align: center;
  }
  .widget-display.contentwithimg .img-boot img {
    width: 100%;
    height: auto;
  }
  .widget-display.contentwithimg .txt-boot {
    width: 100%;
    float: none !important;
  }
  .widget-display.contentwithimg .txt-boot .txtbx {
    padding: 15px;
  }
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.popup {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
  z-index: 999;
}
.popup .overlay {
  background-color: #000;
  opacity: 0.5;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
  z-index: 10;
}
.popup .popup-content {
  position: fixed;
  z-index: 20;
  background-color: #fff;
  border-radius: 3px;
  width: calc(100% - 30px);
  max-width: 800px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 50px;
}
.popup .popup-content .container {
  max-width: 100%;
}
.popup .popup-content .inner-rel {
  position: relative;
  width: 100%;
  height: 100%;
}
.popup .popup-content .inner-rel .guide-table {
  overflow: auto;
  height: 100%;
  max-height: calc(100vh - 60px);
}
.popup .popup-content .inner-rel .dw-grid:nth-child(even) {
  background-color: #f1f1f1;
  height: 100%;
}
@media (max-width: 990px) {
  .popup .popup-content .inner-rel .dw-grid:nth-child(even) {
    background-color: #fff;
    height: 100%;
  }
  .popup .popup-content .inner-rel .dw-grid {
    font-size: 0.5rem;
  }
  .popup .popup-content .inner-rel .dw-grid > .key {
    background-color: #fff;
    padding: 5px;
    font-weight: bold;
  }
  .popup .popup-content .inner-rel .dw-grid .value-row {
    border-bottom: 1px solid #e8e8e8;
    border-top: 1px solid #e8e8e8;
  }
  .popup .popup-content .inner-rel .dw-grid .value-row .dw-xxs-1:nth-child(even) {
    background-color: #e8e8e8;
  }
  .popup .popup-content .inner-rel .dw-grid .dw-xxs-12:not(.key) {
    padding: 0;
  }
}
.popup .popup-content .inner-rel .close {
  position: absolute;
  top: -15px;
  right: -15px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: #000;
  color: #fff;
  border-radius: 100%;
  cursor: pointer;
}
.popup .popup-content .inner-rel .scroller {
  max-height: calc(100vh - 30px);
  overflow-y: auto;
  padding: 15px;
}

.help-pop {
  z-index: 100;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  /*&.pop-mob-only {
      @include media (">tablet") {
          position: static;
          width: 100%;
          height: auto;

          .overlay {
              display: none;
          }
          .pop-container{
              position: static;
              transform: none;
              max-width: 100%;
              width: 100%;
              .close {
                  display: none;
              }
          }
      }
  }*/
}
.help-pop .inner {
  width: 100%;
  height: 100%;
  position: relative;
}
.help-pop .inner .overlay {
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}
.help-pop .inner .pop-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #00000026;
  max-width: 530px;
  width: calc(100% - 60px);
}
.help-pop .inner .pop-container .inner {
  padding: 30px;
}
.help-pop .inner .pop-container .title {
  color: #fff;
  font-weight: bold;
  font-size: 1.3em;
  border-bottom: 1px solid #fff;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.help-pop .inner .pop-container .txt {
  color: #fff;
  font-weight: 200;
}
.help-pop .inner .pop-container .close {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #000;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px !important;
  border-radius: 100%;
  color: #fff;
  transform: translate(50%, -50%);
  z-index: 101;
  cursor: pointer;
}
.help-pop .inner .pop-container .close:hover {
  background-color: #333;
}

#passwordform form {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  #passwordform form {
    flex-direction: column;
    gap: 15px;
  }
}
#passwordform .inner-rel > div {
  padding: 25px;
}
#passwordform .inner-rel > div input {
  height: 46px;
  width: 100%;
}
#passwordform .inner-rel > div input[type=text] {
  border: 1px solid #000;
  border-radius: 4px;
  padding-left: 15px;
}
#passwordform .inner-rel > div input[type=submit] {
  color: #fff;
  border: unset;
  border-radius: 4px;
  font-size: 1.125rem;
  font-weight: 600;
}
#passwordform .inner-rel > div label {
  font-size: 1.25rem;
  font-weight: 600;
}

.custom--errmsg {
  border: 1px solid #C01B00;
  background-color: #F1DDE1;
  color: #C01B00;
  padding: 10px;
  text-align: center;
  border-radius: 3px;
}

.packingform {
  padding-bottom: 30px;
}
.packingform .field-w-btn .editor-label {
  float: left;
  width: 150px;
  line-height: 40px;
  margin-bottom: 0px !important;
}
.packingform .field-w-btn .editor-field {
  float: left;
  width: calc(100% - 285px);
}
.packingform .field-w-btn .editor-field input {
  height: 40px;
  width: 100%;
  padding-left: 10px;
}
.packingform .field-w-btn .editor-field .btn {
  font-size: 1.35em;
  background-color: #26b9e4;
  color: #fff;
  font-weight: bold;
  width: 120px;
  text-align: center;
  float: right;
  padding: 0px;
  line-height: 40px;
  border: none;
}
.packingform .field-w-btn .editor-field .btn:hover {
  background-color: #253645;
}
.packingform .g-bx {
  background-color: #f1f1f1;
  padding: 15px;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
}
.packingform .g-bx .editor-label {
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 0px;
}
.packingform .g-bx .btn-1 {
  width: 120px;
  float: right;
  line-height: 40px;
  display: block;
  border-radius: 3px;
  text-align: center;
  font-weight: 700;
  color: #fff;
}
.packingform .summary .side--summary {
  border: 1px solid #dbdbdb;
  border-radius: 3px;
}
.packingform .summary .side--summary h3 {
  margin: 0px;
  padding: 15px;
  background-color: #f1f1f1;
}
.packingform .summary .row {
  padding: 15px 15px 0px 15px;
}
.packingform .summary .row .assigned {
  font-weight: 700;
}
.packingform .summary .row .title {
  font-weight: 700;
}
.packingform .summary .btn-1 {
  width: calc(100% - 30px);
  margin: 15px auto;
  display: block;
  line-height: 20px;
  padding: 10px;
  text-align: center;
  font-weight: 700;
  color: #fff;
  border-radius: 3px;
}
.packingform .person {
  border: 1px solid #d4d4d4;
  margin-top: 15px;
  border-radius: 3px;
}
.packingform .person.shop-users {
  margin: 0px;
  border: none !important;
}
.packingform .person.shop-users .person-list {
  border: none !important;
  padding: 0px !important;
}
.packingform .person .add--pop {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 9999;
}
.packingform .person .add--pop select {
  width: 100%;
  height: 40px;
  border: 1px solid #ddd;
  border-radius: 3px;
}
.packingform .person .add--pop .top {
  background-color: transparent;
  position: relative;
}
.packingform .person .add--pop.closed {
  display: none;
}
.packingform .person .add--pop.open {
  display: block;
}
.packingform .person .add--pop .inner {
  width: 100%;
  height: 100%;
  position: relative;
}
.packingform .person .add--pop .inner .overlay {
  width: 100%;
  height: 100%;
  background-color: #000;
  position: relative;
  opacity: 0.5;
}
.packingform .person .add--pop .inner .content {
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  width: calc(100% - 30px);
  max-height: calc(100% - 30px);
  background-color: #fff;
}
.packingform .person .add--pop .inner .content .inner--content {
  padding: 15px;
}
.packingform .person .add--pop .inner .content .inner--content label {
  margin-bottom: 5px;
  font-size: 1.2em;
  font-weight: 700;
  display: block;
}
.packingform .person .add--pop .inner .content .inner--content .additem {
  color: #fff;
  cursor: pointer;
  line-height: 40px;
  display: inline-block;
  width: 95px;
  text-align: center;
  border-radius: 3px;
  float: right;
  font-weight: 700;
  font-size: 1.3em;
  margin-top: 15px;
}
.packingform .person .add--pop .inner .content .inner--content .dw-xs-8 {
  padding-right: 0px;
  width: calc(100% - 125px);
}
.packingform .person .add--pop .inner .content .inner--content .dw-xs-2 {
  width: 125px;
}
.packingform .person .add--pop .inner .content .inner--content .dw-xs-2 input {
  width: 100%;
}
.packingform .person .add--pop .inner .content .top {
  border-radius: 3px 3px 0px 0px;
}
.packingform .person .add--pop .inner .content .top span {
  font-weight: 700;
  font-size: 1.4em;
  color: #333;
}
.packingform .person .add--pop .inner .content .top i {
  cursor: pointer;
  color: #fff;
  background-color: #000;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 100%;
  position: absolute;
  top: -15px;
  right: -15px;
}
.packingform .person .top {
  background-color: #f1f1f1;
  padding: 15px;
}
.packingform .person .top .removeperson {
  float: right;
  background-color: red;
  color: #fff;
  line-height: 25px;
  padding: 0px 15px;
  border-radius: 3px;
  font-weight: 700;
  font-size: 1.2em;
}
.packingform .person .top .removeperson:hover {
  float: right;
  background-color: #C01B00;
  color: #fff;
}
.packingform .person .top .name {
  width: calc(100% - 150px);
  float: left;
  font-weight: 700;
  font-size: 1.4em;
}
.packingform .person .top .name i {
  margin-right: 5px;
  line-height: 25px;
}
.packingform .person .selections {
  padding: 15px;
}
.packingform .person .selections .selection {
  padding: 5px 32px 5px 5px;
  position: relative;
  margin-right: 5px;
  margin-bottom: 5px;
  float: left;
  border-radius: 3px;
  font-size: 1.2em;
  line-height: 20px;
  border: 1px solid #dbdbdb;
}
.packingform .person .selections .selection .removeitem {
  display: inline-block;
  position: absolute;
  line-height: 20px;
  top: 0px;
  right: 0px;
  width: 32px;
  line-height: 32px;
  text-align: center;
}
.packingform .person .selections .selection.add-item {
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  text-align: center;
  padding: 5px 15px !important;
}
@media (min-width: 992px) {
  .packingform .summary {
    width: 350px;
  }
  .packingform .options {
    width: calc(100% - 350px);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .packingform .summary {
    width: 300px;
  }
  .packingform .options {
    width: calc(100% - 300px);
  }
}
@media (min-width: 768px) {
  .packingform .summary {
    float: right;
  }
}
@media (max-width: 767px) {
  .packingform .summary {
    width: 100%;
    margin-bottom: 15px;
  }
  .packingform .summary .row {
    width: 50%;
    float: left;
  }
  .packingform .summary .options {
    width: 100%;
  }
  .packingform .field-w-btn .editor-label {
    width: 100%;
    line-height: 1;
  }
  .packingform .field-w-btn .editor-label label {
    display: block;
    margin-bottom: 15px;
  }
  .packingform .field-w-btn .editor-field {
    width: calc(100% - 130px);
  }
}
@media (max-width: 550px) {
  .packingform .summary .row {
    width: 100%;
    float: none;
  }
}

.body-no-ecomm .cart-container {
  display: none !important;
}
@media (min-width: 1231px) {
  .body-no-ecomm .head-style-1 .top--widget {
    width: 50% !important;
  }
  .body-no-ecomm .head-style-1 .masthead .head--main .head-right {
    width: 300px;
  }
  .body-no-ecomm .head-style-1 .masthead .head--main .head-center {
    width: calc(100% - 554px);
  }
}
@media (min-width: 992px) {
  .body-no-ecomm .head-style-1 .search--form {
    max-width: 100%;
  }
}
@media (min-width: 1231px) {
  .body-no-ecomm .head-style-2 .masthead .head--main .head-right {
    width: 300px;
    float: right;
  }
}
@media (min-width: 992px) {
  .body-no-ecomm .head-style-2 .top--widget {
    width: 50% !important;
  }
}
@media (min-width: 1231px) {
  .body-no-ecomm .head-style-3 .masthead .head--main .head-right {
    width: 340px;
  }
  .body-no-ecomm .head-style-3 .masthead .head--main .head-center {
    width: calc(100% - 594px);
  }
}
@media (min-width: 992px) {
  .body-no-ecomm .head-style-3 .top--widget {
    width: 100% !important;
  }
}
@media (min-width: 992px) {
  .body-no-ecomm .head-style-4 .top--widget {
    float: right !important;
  }
}

.blog {
  margin-bottom: 30px;
}
@media (max-width: 991px) {
  .blog .flx-bx {
    flex-wrap: wrap;
  }
}
.blog .blogpost {
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .blog .blogpost.hasimg .blog-image {
    width: 30%;
  }
  .blog .blogpost.hasimg .blog-image > * {
    height: 100%;
    background-size: cover;
    background-position: center;
  }
  .blog .blogpost.hasimg .blog-txt {
    width: 70%;
  }
}
@media (max-width: 991px) {
  .blog .blogpost.hasimg .blog-image {
    width: 100%;
    height: 250px;
  }
  .blog .blogpost.hasimg .blog-image > * {
    height: 100%;
    background-size: cover;
    background-position: center;
  }
  .blog .blogpost.hasimg .blog-txt {
    width: 100%;
  }
}