﻿
.contact-form {
    padding: 30px;
    border: 1px solid #dbdbdb;
    border-radius: 3px;
    margin-bottom: 30px;

    .form-head {
        font-size: 1.6em;
        font-weight: 700;
        text-align: center;

        i {
            margin-right: 15px;
        }

        margin-bottom: 30px;
    }

    button {
        border: none;
        display: block;
        padding: 15px;
        border-radius: 3px;
        color: #fff;
        font-weight: 700;
        width: 100%;
        font-size: 1.1em;
        margin: 30px auto 0px;
    }

    .btn {
        border: none;
        display: block;
        padding: 15px;
        border-radius: 3px;
        color: #fff;
        font-weight: 700;
        width: 100%;
        font-size: 1.1em;
        margin: 30px auto 0px;
        text-align: center;
    }
}
.info{
    .info-row{
        position: relative;
        padding-left: 50px;
        margin-bottom: 15px;
        .icon{
            width: 35px;
            height: 35px;
            display: block;
            line-height: 35px;
            border-radius: 100%;
            position: absolute;
            left: 0px;
            text-align: center;
            i{
                line-height: 35px;
                color: #fff;
                font-size: 1.1em;
            }

        }
    }
}
.map-countainer {margin-bottom: 30px;}