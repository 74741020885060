﻿.editor-label {
    font-weight: 700;
    margin: 10px 0px;
}


.editor-field {
    input {
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 3px;
        padding: 10px;

        &.input-validation-error {
            border: 1px solid #c01b00;
        }
    }

    select {
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 3px;
        padding: 10px;
    }

    textarea {
        display: block;
        width: 100%;
        border: 1px solid #ddd;
        height: 150px;
        padding: 15px;
    }

    &.sml {
        input {
            padding: 5px;
        }
    }

    &.nobord {
        input {
            border: none !important;
        }
    }

    &.center-txt {
        input {
            text-align: center;
        }
    }
}


.right-btn {
    &.narr {
        max-width: 500px;
    }

    .text-box, input, select {
        width: calc(100% - 170px) !important;
        float: left;
    }

    .btn {
        float: right;
        width: 155px !important;
        height: 40px;
        line-height: 40px;
        padding: 0px;
        color: #fff;
        font-weight: 700;
        border-radius: 3px;
        text-align: center;
        font-size: 1.2em;
        border: none !important;
    }
}

.editor-field {
    position: relative;

    span.forgot {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        font-size: 1.1em;
    }

    .g-recaptcha {
        max-width: 302px;
        margin: 0 auto;
    }
}

.checkbx-style {
    display: inline-block;
    border: 1px solid #dbdbdb;
    border-radius: 3px;
    width: 30px;
    height: 30px;
    cursor: pointer;

    input[type="checkbox"] {
        display: none;
    }

    i {
        width: 100%;
        line-height: 28px;
        display: block;
        opacity: 0;
    }

    &:hover {
        i {
            opacity: 0.3;
        }
    }

    &.on {
        i {
            opacity: 1 !important;
        }
    }
}

.field-validation-error {
    color: #c01b00;
}

@include media(">tablet") {
    .lr-form-row {
        .title {
            width: 250px;
            float: left;
        }

        .field {
            width: calc(100% - 250px);
            float: left;
        }

        margin-bottom: 15px;
    }
}

.panel {
    &.checkstripe {
        max-width: 600px;
        width: calc(100% - 30px);
        margin: 30px auto;
        border: 1px solid #dedede;
        padding: 30px;

        input {
        }

        label.pay-field {
            display: block;
            padding: 10px;
            border: 1px solid #dedede;
            border-radius: 3px;
        }
    }
}

.discount-msg {
    .KO {
        padding: 10px 15px;
        color: #fff;
        background-color: #e3000f;
        margin-bottom: 10px;
        text-align: left;
        width: max-content;
        margin-bottom: -15px;
        margin-top: 30px;
        border-radius: 5px;
    }

    .OK {
        padding: 10px 15px;
        color: #fff;
        background-color: #00ae33;
        margin: 20px 0;
        text-align: center;
        width: max-content;
        margin-bottom: -15px;
        margin-top: 30px;
        border-radius: 5px;
    }
}


.checkbox-style {
    width: 30px;
    height: 30px;
    border: 1px solid #dedede;
    display: flex;
    border-radius: 3px;
    position: relative;
    justify-content: center;
    align-items: center;

    i {
        opacity: 0;
    }

    &.on {
        i {
            opacity: 1;
        }
    }

    input {
        position: absolute;
        display: none;
    }
}

.account-pages .field-validation-error {
    font-weight: bold;
    color: #c01b00;
    display: block;
    width: max-content;
    border: 1px solid #c01b00;
    padding: 5px;
    border-radius: 3px;
    background-color: #ffeaea;
}

.account-pages .successmsg {
    color: #00ae33;
    border: 1px solid #00ae33;
    padding: 5px;
    font-weight: 700;
    border-radius: 3px;
    width: max-content;
    background-color: #c2ffd4;
}

.head-section #mc-section {
    .mc-head {
        height: 6px;
    }
}

#newsletterform {
    .newsletter--form {
        & > form {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 15px;
            padding: 25px 0;

            @include media("<=phone") {
                flex-direction: column;
                padding-bottom: unset;
            }

            & > span {
                color: #fff;
                font-size: 1.5rem;
                font-weight: 600;
                max-width: 300px;
                margin-right: 10px;

                @media(max-width: 767px) {
                    max-width: 90%;
                }
            }

            & > input#NewsEmailAddress {
                max-width: 500px;
                width: 500px;
                height: 46px;
                padding: 0 15px;

                @media(max-width: 767px) {
                    max-width: 90%;
                    margin-top: 10px;
                }
            }

            & > .btn {
                width: 180px;
                height: 46px;
                background-color: #36373a;
                border: unset;
                font-weight: 600;
                font-size: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;

                @media (max-width: 767px) {
                    width: 100%;
                    max-width: 90%;
                }

                @media(max-width: 400px) {
                    max-width: 300px;
                }

                &:hover {
                    background-color: rgba(93, 94, 99, 0.73);
                }

                &.loading {
                    color: transparent !important;
                    position: relative;

                    &:before {
                        color: #fff;
                        content: "Loading...";
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%,-50%);
                    }
                }
            }

            & > button[type=submit] {
                width: 180px;
                height: 46px;
                background-color: #36373a;
                border: unset;
                font-weight: 600;
                font-size: 1rem;

                @media (max-width: 767px) {
                    width: 100%;
                    max-width: 90%;
                }

                @media(max-width: 400px) {
                    max-width: 300px;
                }

                &:hover {
                    background-color: rgba(93, 94, 99, 0.73);
                }
            }
        }
    }
}

#mailchimpform {

    .mc-head {
        height: 6px;

        &.bottom {
            filter: brightness(0.7);
        }
    }

    .title {
        font-size: 1.875rem;
        font-weight: 600;
    }

    &.footer {
        .forminner {
            color: #fff;
        }
    }

    .forminner {
        /*padding-bottom: 20px;*/
        padding-top: 20px;

        @media (min-width: 992px) {
            padding-bottom: 20px;
        }

        &.expanded {
            @media (max-width: 991px) {
                padding-bottom: 20px;
            }
        }

        & > form {
            align-items: center;

            @media (max-width: 991px) {
                display: flex;
                flex-direction: column;
                gap: 15px;
            }
        }

        .section {
            display: flex;

            @media (min-width: 992px) {
                align-items: center;
            }

            @media (max-width: 991px) {
                width: 100%;
                flex-direction: column;
                gap: 10px;
            }

            .head {
                @media (max-width: 991px) {
                    display: flex;
                    align-items: flex-end;
                }
            }

            .inner {
                @media (max-width: 991px) {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }
            }

            .inner.top {
                padding: unset;

                @media (max-width: 991px) {
                    display: none;

                    & > * {
                        margin-bottom: 10px;
                    }
                }
            }

            .inner.pop {
                display: flex;
                flex-direction: column;
                gap: 10px;

                & > * { width: 100%; }
            }

            .expand-mob {
                margin-top: 10px;
                text-align: center;

                @media (min-width: 992px) {
                    display: none;
                }

                i.open {
                    transform: rotate(180deg);
                }
            }
        }

        &.popup-content {
            & > form .section {
                display: flex;
                flex-direction: column;
                gap: 10px;

                & > * {
                    width: 100%;
                }
            }
        }
    }

    .editor-label {
        display: none;
    }

    .editor-field {
        input {
            border: 1px solid #010101;
            border-radius: 2px;
            height: 46px;
            font-size: 0.9375rem;
        }

        button[type=submit] {
            border: 1px solid #010101;
            border-radius: 2px;
            height: 46px;
            font-size: 0.9375rem;
            color: #fff;
            border: unset;
            border-radius: 4px;
            font-size: 1.25rem;
            font-weight: 600;
            max-width: 274px;
            width: 100%;

            &:hover {
                background-color: #b14c0b;
            }
        }

        .btn {
            border: 1px solid #010101;
            border-radius: 2px;
            height: 46px;
            font-size: 0.9375rem;
            color: #fff;
            border: unset;
            border-radius: 4px;
            font-size: 1.25rem;
            font-weight: 600;
            max-width: 274px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 991px) {
                max-width: unset;
            }

            &:hover {
                /*background-color: #b14c0b;*/
            }

            &.loading {
                color: transparent;
                position: relative;

                &:before {
                    color: #fff;
                    content: "Loading...";
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                }
            }
        }
    }

    .mcform.popup {
        .forminner.popup-content {
            max-width: 437px;
            padding-left: 25px;
            padding-right: 25px;
            padding-top: 50px;

            & > .pop-head {
                position: absolute;
                height: 4px;
                width: 100%;
                top: 0;
                left: 0;
                border-top-left-radius: 2px;
                border-top-right-radius: 2px;
            }

            & > .pop-bg {
                position: absolute;
                height: 155px;
                width: 100%;
                background-color: #F6F6F6;
                bottom: 0;
                left: 0;
            }

            & > i.close {
                position: absolute;
                top: 20px;
                right: 20px;
                color: #6e6e6e;
                cursor: pointer;
                font-size: 1.5rem;
            }

            & > .mctitle {
                display: none;
            }

            & > form {
                display: flex;
                flex-direction: column;
                gap: 11px;


                div.title {
                    text-align: center;
                    margin-bottom: unset;
                }

                div.mctitle {
                    text-align: center;
                }

                & > * {
                    width: 100%;
                }

                & div[type=submit].btn {
                    max-width: unset;
                }

                div.email {
                    margin-top: 40px;
                }
            }
        }
    }
}
