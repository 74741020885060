﻿body {
    margin: 0px;
    font-family: proxima-nova, sans-serif;
    overflow-x: hidden;
}

body:has(.filter:not(.mob-shut)) {
    .head-section {z-index:0!important;}
}

.lazy {
    opacity: 0;
}

.center-text {text-align: center;}

*, input, label {
    -webkit-transition: background-color .1s linear,color .1s, border .1s, opacity .1s, transform 0.1s, box-shadow 0.1s ease-in-out,opacity .3s ease-in-out, transform .1s;
    -ms-transition: background-color .1s linear,color .1s, border .1s, opacity .1s, transform 0.1s, box-shadow 0.1s ease-in-out,opacity .3s ease-in-out, transform .1s;
    transition: background-color .1s linear,color .1s, border .1s, opacity .1s, transform 0.1s, box-shadow 0.1s ease-in-out,opacity .3s ease-in-out, transform .1s;
}

div:before {
    -webkit-transition: background-color .1s linear,color .1s, border .1s, opacity .1s, transform 0.1s, box-shadow 0.1s ease-in-out;
    -ms-transition: background-color .1s linear,color .1s, border .1s, opacity .1s, transform 0.1s, box-shadow 0.1s ease-in-out;
    transition: background-color .1s linear,color .1s, border .1s, opacity .1s, transform 0.1s, box-shadow 0.1s ease-in-out;
}
.no-overflow{overflow: hidden;}

.bx-shad {
    box-shadow: 0px 15px 20px #00000029;
}

.cur-pointer {cursor: pointer;}

.grey-border {
    border: 1px solid #dedede;
    border-radius: 3px;
}

.grey-border-btm {
    border-bottom: 1px solid #dedede;
    
}

.grbg-fb {
    background-color: #fbfbfb;
}

.grbg-f7 {
    background-color: #f7f7f7;
}

.grbg-f5 {
    background-color: #f5f5f5;
}

.center-txt {text-align: center;}

.flx-bx {display: flex;}
.flx-al-cent {align-items: center;}
.flx-ju-end {justify-content: end;}
.flx-ju-space {justify-content: space-between;}

.nomarg{margin: 0px;}

.r-5 {border-radius: 5px;}
.py10{padding-top: 10px; padding-bottom: 10px;}
.py15{padding-top: 15px; padding-bottom: 15px;}
.flx-bx {display: flex;}
.block {display: block;}
.fs12 {font-size: 1.2em;}

.miw30 {min-width: 30px; }

.mt15{margin-top: 15px;}

.gap10 {gap: 10px;}

.p10 {padding: 10px;}
.p15 {padding: 15px;}

.mb10 {margin-bottom: 10px;}



h3{
    &.lg{
        font-size: 1.6em;
    }
}

.block {display: block;}
.white-bg {background-color: #fff;}
.bx-shad {
    box-shadow: 0 0 14px 2px #00000040;
}

.rad3{border-radius: 3px;}

.no-marg{margin: 0px !important;}

.gr-bg {
    background-color: #f7f7f7;
    border: 1px solid #f1f1f1;
}

.no-overflow {overflow: hidden;}

.padd-15{
    padding: 15px;
}
.marg-btm-10 {
    margin-bottom: 10px;
}

.marg-btm-15{
    margin-bottom: 15px;
}

#content{
    background-color: #fff;
    position: relative;
    z-index: 1;
}

input {
    font-family: proxima-nova, sans-serif;
}


textarea {
    font-family: proxima-nova, sans-serif;
}

img {
    border: 0;
    max-width: 100%;
    height: auto;
    display: block;
}

.left-txt{
    text-align: left;
}

.toright{
    float: right;
}

.colour-list {
    margin-top: 5px;
    min-height: 22px;

    .colours {
        .colour {
            width: calc(100% / 10);
            float: left;
            padding: 1px;
            .inner {
                width: 100%;
                padding-top: 100%;
                border-radius: 100%;
                position: relative;

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    height: 100%;
                    border: 1px solid #333;
                    border-radius: 100%;
                    z-index: 5;
                }

                .inner-colours {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 100%;
                    overflow: hidden;

                    span {
                        display: block;
                        float: left;
                        height: 100%;
                    }
                }
            }
        }

        width: 100%;
        /*width: calc(100% - 80px);*/
        float: left;

        .more {
            width: calc((100% / 5) - 2px);
            float: right;
            text-align: right;
            color: #000;
            text-align: center;
            color: #fff;
            font-weight: 700;
            border-radius: 30px;
            line-height: 22px;
            /*display: none;*/
        }

        &.sm-style {
            .colour {
                width: calc(100% / 8) !important;
            }

            .more {
                width: calc((100% / 4) - 2px);
            }
        }
    }
}

#loading-container{
    display: none;
}
a{
    text-decoration: none;
}


h1{
    display: block;
    text-align: center;
    margin: 30px 0px;
    &.sml-marg{
        margin: 30px 0px;
    }
    &.left{
        text-align: left;
    }
    &.xs-marg{
        margin: 5px 0px;
    }

    &.neg-marg{
        margin: -10px 0px;
    }
    
}
h2 {
    font-size: 2em;
    margin: 30px 0px;
}

.grey--bg {
    background-color: #F9F9F9;
}

.center--txt, .center-txt {
    text-align: center;
}

.breadcrumb{
    color: #999;
    font-size: 0.9em;
    margin: 30px 0px;
    a{
        color: #999;
        &:hover{
            text-decoration: underline;
        }
        font-weight: 700;
    }
    span{margin: 0px 5px; font-weight: 700;}
}

.div-line{
    width: 100%;
    border-bottom: 1px solid #f1f1f1;
    margin: 30px 0px;
}

.head-section{
    position: relative;
    z-index: 10;

    &.low-index{
        z-index: 0 !important;
    }

}
.main-content{
    z-index: 9;
    position: relative; 
    background-color: #fff;
}
.menu-section{
    position: relative;
    
}

.err-page{
    min-height: 500px;
    h1{
        text-align: left;
    }
}

.right-txt{
    text-align: right;
}

.terms-page{
    padding-bottom: 30px;
    h1{
        text-align: left;
    }
    h2{
        font-size: 1.3em;
        margin: 15px 0px 10px;
    }
}

.noflex{
    display: block !important;
}

.infotog {
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    cursor: pointer;
    vertical-align: sub;

    @keyframes bounce2 {
        0%, 20%, 50%, 80%, 100% {
            transform: translateY(0);
        }

        40% {
            transform: translateY(-10px);
        }

        60% {
            transform: translateY(-5px);
        }
    }

    &.animate {
        animation: bounce2 1.5s ease 1;
        animation-delay: 1s;
    }

    &:hover {
        animation: none;
        box-shadow: 0px 11px 11px #00000026;
        transform: translateY(-5px);

        
    }
}

.ko{
    border: 1px solid #ff0000;
        padding: 15px;
        border-radius: 3px;
        background-color: #ffcccc;
        color:#ff0000;
        font-weight: 700;
        ul {margin: 0px;}
            li {font-weight: 700;}
        
}

.site-content{
    min-height: 400px;
}

.inner-rel{position: relative;}

.no-p-marg{
    margin: 15px 0px;
    p{
        margin: 0px;
    }
}

#iFrameBasket {
    margin-bottom: 30px;
    strong{font-size: 2em;}
    .btn {border-radius: 3px;width: 100%; position: relative; padding: 15px; text-align: center; display: block; position: relative; font-weight: 700; font-size: 1.1em;
          i{
              margin-left: 15px;
          }
          .items{ width: 32px; height: 32px; line-height: 32px; text-align: center; position: absolute; top: 0px; right: 0px; transform: translate(50%, -50%); border-radius: 100%;}

    }
}
.homebtn.ifr {
    padding: 15px;
    text-align: center;
    display: block;
    position: relative;
    font-weight: 700;
    font-size: 1.1em;
    border-radius: 3px;
    max-width: 196px;
    margin-bottom: 15px;
}

.faqs {
    .faq {
        padding: 0px;
        list-style: none;
        .top {
           
            cursor: pointer;
            font-size: 1.5em;
            display: flex;
            padding: 10px;
            background-color: #fff;
            border-bottom: 2px solid #ebebeb;
            justify-content: space-between;
            align-items: center;
        }

        .body {
            padding: 30px;
        }
    }
}