﻿﻿﻿*, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.visible-lg {
    @include media('>desktop') {
        display: block !important;
    }

    @include media('<=desktop') {
        display: none !important;
    }
}

.center-block{
    margin: 0 auto;
}

.visible-md {
    @include media('<=desktop', '>tablet') {
        display: block !important;
    }

    @include media('>desktop') {
        display: none !important;
    }

    @include media('<=tablet') {
        display: none !important;
    }
}

.visible-sm {
    @include media('<=tablet', '>phone') {
        display: block !important;
    }

    @include media('>tablet') {
        display: none !important;
    }

    @include media('<=phone') {
        display: none !important;
    }
}

.visible-xs {
    @include media('<=phone') {
        display: block !important;
    }

    @include media('>phone') {
        display: none !important;
    }
}

.container {
    margin-right: auto;
    margin-left: auto;
}

.container:before, .container:after, .section:before, .section:after {
    display: table;
    content: " ";
}

.container:after, .section:after {
    clear: both;
}

.clear {
    clear: both;
    display: block;
}

.svg-inline--fa.fa-w-18 {
    width: 60px;
    color: #2bbed8;
}


.section.flex{
    display:flex;
    flex-wrap: wrap;
}

.section.flex:before {
    display: none !important;
}

.section.flex:after {
    display: none !important;
}

.section.sml-g {
    margin: 0px -10px;
    > * {padding: 0px 10px;}
}

.section.xsml-g {
    margin: 0px -7px !important;

    &.flex {grid-row-gap: 14px;}

    > * {
        padding: 0px 7px !important;
    }
}

.gap-15 {
    gap: 15px;
}

.flx-bx {
    display: flex;
}

.fl-al-cent {
    align-items: center;
}
.fl-ju-cent {
    justify-content: center;
}
.fl-al-end {
    align-items: flex-end;
}

.fl-ju-cent {
    justify-content: center;
}

.fl-ju-space {
    justify-content: space-between;
}

.fl-ju-end {
    justify-content: end;
}

.fl-wrap {
    flex-wrap: wrap;
}

.fl-dir-rev{
    flex-direction: row-reverse;
}

.dw-xs-1, .dw-sm-1, .dw-md-1, .dw-lg-1,
.dw-xs-2, .dw-sm-2, .dw-md-2, .dw-lg-2,
.dw-xs-3, .dw-sm-3, .dw-md-3, .dw-lg-3,
.dw-xs-4, .dw-sm-4, .dw-md-4, .dw-lg-4,
.dw-xs-5, .dw-sm-5, .dw-md-5, .dw-lg-5,
.dw-xs-6, .dw-sm-6, .dw-md-6, .dw-lg-6,
.dw-xs-7, .dw-sm-7, .dw-md-7, .dw-lg-7,
.dw-xs-8, .dw-sm-8, .dw-md-8, .dw-lg-8,
.dw-xs-9, .dw-sm-9, .dw-md-9, .dw-lg-9,
.dw-xs-10, .dw-sm-10, .dw-md-10, .dw-lg-10,
.dw-xs-11, .dw-sm-11, .dw-md-11, .dw-lg-11,
.dw-xs-12, .dw-sm-12, .dw-md-12, .dw-lg-12 {
    position: relative;
    min-height: 1px;
    display: block;
}

@media (min-width:768px) {
    .dw-xs-1, .dw-sm-1, .dw-md-1, .dw-lg-1,
    .dw-xs-2, .dw-sm-2, .dw-md-2, .dw-lg-2,
    .dw-xs-3, .dw-sm-3, .dw-md-3, .dw-lg-3,
    .dw-xs-4, .dw-sm-4, .dw-md-4, .dw-lg-4,
    .dw-xs-5, .dw-sm-5, .dw-md-5, .dw-lg-5,
    .dw-xs-6, .dw-sm-6, .dw-md-6, .dw-lg-6,
    .dw-xs-7, .dw-sm-7, .dw-md-7, .dw-lg-7,
    .dw-xs-8, .dw-sm-8, .dw-md-8, .dw-lg-8,
    .dw-xs-9, .dw-sm-9, .dw-md-9, .dw-lg-9,
    .dw-xs-10, .dw-sm-10, .dw-md-10, .dw-lg-10,
    .dw-xs-11, .dw-sm-11, .dw-md-11, .dw-lg-11,
    .dw-xs-12, .dw-sm-12, .dw-md-12, .dw-lg-12,
    .container {
        padding: 0 15px;
    }

    .section {
        margin-right: -15px;
        margin-left: -15px;

        &.narrow-gap {
            margin-left: -10px;
            margin-right: -10px;

            .boot {
                padding: 10px;
            }
        }
    }
}

@media (max-width:767px) {
    .dw-xs-1, .dw-sm-1, .dw-md-1, .dw-lg-1,
    .dw-xs-2, .dw-sm-2, .dw-md-2, .dw-lg-2,
    .dw-xs-3, .dw-sm-3, .dw-md-3, .dw-lg-3,
    .dw-xs-4, .dw-sm-4, .dw-md-4, .dw-lg-4,
    .dw-xs-5, .dw-sm-5, .dw-md-5, .dw-lg-5,
    .dw-xs-6, .dw-sm-6, .dw-md-6, .dw-lg-6,
    .dw-xs-7, .dw-sm-7, .dw-md-7, .dw-lg-7,
    .dw-xs-8, .dw-sm-8, .dw-md-8, .dw-lg-8,
    .dw-xs-9, .dw-sm-9, .dw-md-9, .dw-lg-9,
    .dw-xs-10, .dw-sm-10, .dw-md-10, .dw-lg-10,
    .dw-xs-11, .dw-sm-11, .dw-md-11, .dw-lg-11,
    .dw-xs-12, .dw-sm-12, .dw-md-12, .dw-lg-12,
    .container {
        padding: 0 10px;
    }

    .section {
        margin-right: -10px;
        margin-left: -10px;

        &.narrow-gap {
            margin-left: -5px;
            margin-right: -5px;

            .boot {
                padding: 5px;
            }
        }
    }
}

.dw-xs-1, .dw-xs-2, .dw-xs-3, .dw-xs-4, .dw-xs-5, .dw-xs-6, .dw-xs-7, .dw-xs-8, .dw-xs-9, .dw-xs-10, .dw-xs-11 {
    float: left;
}

.dw-xs-12 {
    width: 100%;
}

.dw-xs-11 {
    width: 91.66666666666666%;
}

.dw-xs-10 {
    width: 83.33333333333334%;
}

.dw-xs-9 {
    width: 75%;
}

.dw-xs-8 {
    width: 66.66666666666666%;
}

.dw-xs-7 {
    width: 58.333333333333336%;
}

.dw-xs-6 {
    width: 50%;
}

.dw-xs-5 {
    width: 41.66666666666667%;
}

.dw-xs-4 {
    width: 33.33333333333333%;
}

.dw-xs-3 {
    width: 25%;
}

.dw-xs-2 {
    width: 16.666666666666664%;
}

.dw-xs-1 {
    width: 8.333333333333332%;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }

    .dw-sm-1, .dw-sm-2, .dw-sm-3, .dw-sm-4, .dw-sm-5, .dw-sm-6, .dw-sm-7, .dw-sm-8, .dw-sm-9, .dw-sm-10, .dw-sm-11 {
        float: left;
    }

    .dw-sm-12 {
        width: 100%;
    }

    .dw-sm-11 {
        width: 91.66666666666666%;
    }

    .dw-sm-10 {
        width: 83.33333333333334%;
    }

    .dw-sm-9 {
        width: 75%;
    }

    .dw-sm-8 {
        width: 66.66666666666666%;
    }

    .dw-sm-7 {
        width: 58.333333333333336%;
    }

    .dw-sm-6 {
        width: 50%;
    }

    .dw-sm-5 {
        width: 41.66666666666667%;
    }

    .dw-sm-4 {
        width: 33.33333333333333%;
    }

    .dw-sm-3 {
        width: 25%;
    }

    .dw-sm-2 {
        width: 16.666666666666664%;
    }

    .dw-sm-1 {
        width: 8.333333333333332%;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }

    .dw-md-1, .dw-md-2, .dw-md-3, .dw-md-4, .dw-md-5, .dw-md-6, .dw-md-7, .dw-md-8, .dw-md-9, .dw-md-10, .dw-md-11 {
        float: left;
    }

    .dw-md-12 {
        width: 100%;
    }

    .dw-md-11 {
        width: 91.66666666666666%;
    }

    .dw-md-10 {
        width: 83.33333333333334%;
    }

    .dw-md-9 {
        width: 75%;
    }

    .dw-md-8 {
        width: 66.66666666666666%;
    }

    .dw-md-7 {
        width: 58.333333333333336%;
    }

    .dw-md-6 {
        width: 50%;
    }

    .dw-md-5 {
        width: 41.66666666666667%;
    }

    .dw-md-4 {
        width: 33.33333333333333%;
    }

    .dw-md-3 {
        width: 25%;
    }

    .dw-md-2 {
        width: 16.666666666666664%;
    }

    .dw-md-1 {
        width: 8.333333333333332%;
    }
}

@media (min-width: 1230px) {
    .container {
        width: 1230px;
    }

    .dw-lg-1, .dw-lg-2, .dw-lg-3, .dw-lg-4, .dw-lg-5, .dw-lg-6, .dw-lg-7, .dw-lg-8, .dw-lg-9, .dw-lg-10, .dw-lg-11 {
        float: left;
    }

    .dw-lg-12 {
        width: 100%;
    }

    .dw-lg-11 {
        width: 91.66666666666666%;
    }

    .dw-lg-10 {
        width: 83.33333333333334%;
    }

    .dw-lg-9 {
        width: 75%;
    }

    .dw-lg-8 {
        width: 66.66666666666666%;
    }

    .dw-lg-7 {
        width: 58.333333333333336%;
    }

    .dw-lg-6 {
        width: 50%;
    }

    .dw-lg-5 {
        width: 41.66666666666667%;
    }

    .dw-lg-4 {
        width: 33.33333333333333%;
    }

    .dw-lg-3 {
        width: 25%;
    }

    .dw-lg-2 {
        width: 16.666666666666664%;
    }

    .dw-lg-1 {
        width: 8.333333333333332%;
    }
}

@media (min-width: 1400px) {
    .container{
        width: 100%;
        max-width: 1400px;

    }
}

@-ms-viewport {
    width: device-width;
}

.visible-xs {
    display: none !important;
}

@media (max-width: 767px) {
    .visible-xs {
        display: block !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-xs.visible-sm {
        display: block !important;
    }
}

@media (min-width: 992px) and (max-width: 1229px) {
    .visible-xs.visible-md {
        display: block !important;
    }
}

@media (min-width: 1230px) {
    .visible-xs.visible-lg {
        display: block !important;
    }
}

.visible-sm {
    display: none !important;
}

@media (max-width: 767px) {
    .visible-sm.visible-xs {
        display: block !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm {
        display: block !important;
    }
}

@media (min-width: 992px) and (max-width: 1229px) {
    .visible-sm.visible-md {
        display: block !important;
    }
}

@media (min-width: 1230px) {
    .visible-sm.visible-lg {
        display: block !important;
    }
}

.visible-md {
    display: none !important;
}

@media (max-width: 767px) {
    .visible-md.visible-xs {
        display: block !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-md.visible-sm {
        display: block !important;
    }
}

@media (min-width: 992px) and (max-width: 1229px) {
    .visible-md {
        display: block !important;
    }
}

@media (min-width: 1230px) {
    .visible-md.visible-lg {
        display: block !important;
    }
}

.visible-lg {
    display: none !important;
}

@media (max-width: 767px) {
    .visible-lg.visible-xs {
        display: block !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-lg.visible-sm {
        display: block !important;
    }
}

@media (min-width: 992px) and (max-width: 1229px) {
    .visible-lg.visible-md {
        display: block !important;
    }
}

@media (min-width: 1230px) {
    .visible-lg {
        display: block !important;
    }
}

.hidden-xs {
    display: block !important;
}

@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hidden-xs.hidden-sm {
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1229px) {
    .hidden-xs.hidden-md {
        display: none !important;
    }
}

@media (min-width: 1230px) {
    .hidden-xs.hidden-lg {
        display: none !important;
    }
}

.hidden-sm {
    display: block !important;
}


@media (max-width: 767px) {
    .hidden-sm.hidden-xs {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm {
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1229px) {
    .hidden-sm.hidden-md {
        display: none !important;
    }
}

@media (min-width: 1230px) {
    .hidden-sm.hidden-lg {
        display: none !important;
    }
}

.hidden-md {
    display: block !important;
}

@media (max-width: 767px) {
    .hidden-md.hidden-xs {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hidden-md.hidden-sm {
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1229px) {
    .hidden-md {
        display: none !important;
    }
}

@media (min-width: 1230px) {
    .hidden-md.hidden-lg {
        display: none !important;
    }
}

.hidden-lg {
    display: block !important;
}


@media (max-width: 767px) {
    .hidden-lg.hidden-xs {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hidden-lg.hidden-sm {
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1229px) {
    .hidden-lg.hidden-md {
        display: none !important;
    }
}

@media (min-width: 1230px) {
    .hidden-lg {
        display: none !important;
    }
}


.carousel {
    position: relative;
}

.carousel-inner {
    position: relative;
    width: 100%;
    z-index: 1;
}

.carousel-inner > .item {
    position: relative;
    display: none;
    -webkit-transition: .6s ease-in-out left;
    -o-transition: .6s ease-in-out left;
    transition: .6s ease-in-out left;
}

.carousel-inner > .item > img, .carousel-inner > .item > a > img {
    line-height: 1;
}

.carousel-inner > .active, .carousel-inner > .next, .carousel-inner > .prev {
    display: block;
}

.carousel-inner > .active {
    left: 0;
}

.carousel-inner > .next, .carousel-inner > .prev {
    position: absolute;
    top: 0;
    width: 100%;
}

.carousel-inner > .next {
    left: 100%;
}

.carousel-inner > .prev {
    left: -100%;
}

.carousel-inner > .next.left, .carousel-inner > .prev.right {
    left: 0;
}

.carousel-inner > .active.left {
    left: -100%;
}

.carousel-inner > .active.right {
    left: 100%;
}

/*.carousel-inner .item a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999999;
}*/

.carousel-indicators {
    position: absolute;
    z-index: 9;
    bottom: 20px;
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: center;
    list-style: none;
}

.carousel-indicators li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    text-indent: -999px;
    cursor: pointer;
    //background-color: $color-indicators;
    border-radius: 10px;
}

.carousel-indicators .active {
    background-color: #2bbed8;
}

.carousel-caption {
    position: absolute;
    left: 50%;
    width: 100%;
    top: 50%;
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    z-index: 10;
    //color: $color-white;
    padding: 0 40px;
    text-align: center;
}

@media (min-width:1230px) {
    .carousel-caption {
        width: 1230px;
    }
}

@media (max-width:1229px) and (min-width:992px) {
    .carousel-caption {
        max-width: 970px;
    }
}

@media (max-width:991px) and (min-width:768px) {
    .carousel-caption {
        max-width: 750px;
    }
}

@media (max-width:767px) {
    .carousel-caption {
        padding: 0 10px;
    }

    .carousel-caption span {
        display: none;
    }
}

.max-w-100 {
    max-width: 100px
}

.max-w-110 {
    max-width: 110px
}

.max-w-120 {
    max-width: 120px
}

.max-w-130 {
    max-width: 130px
}

.max-w-140 {
    max-width: 140px
}

.max-w-150 {
    max-width: 150px
}

.max-w-160 {
    max-width: 160px
}

.max-w-170 {
    max-width: 170px
}

.max-w-180 {
    max-width: 180px
}

.max-w-190 {
    max-width: 190px
}

.max-w-200 {
    max-width: 200px
}

.max-w-210 {
    max-width: 210px
}

.max-w-220 {
    max-width: 220px
}

.max-w-230 {
    max-width: 230px
}

.max-w-240 {
    max-width: 240px
}

.max-w-250 {
    max-width: 250px
}

.max-w-260 {
    max-width: 260px
}

.max-w-270 {
    max-width: 270px
}

.max-w-280 {
    max-width: 280px
}

.max-w-290 {
    max-width: 290px
}

.max-w-300 {
    max-width: 300px
}

.max-w-310 {
    max-width: 310px
}

.max-w-320 {
    max-width: 320px
}

.max-w-330 {
    max-width: 330px
}

.max-w-340 {
    max-width: 340px
}

.max-w-350 {
    max-width: 350px
}

.max-w-360 {
    max-width: 360px
}

.max-w-370 {
    max-width: 370px
}

.max-w-380 {
    max-width: 380px
}

.max-w-390 {
    max-width: 390px
}

.max-w-400 {
    max-width: 400px
}

.max-w-410 {
    max-width: 410px
}

.max-w-420 {
    max-width: 420px
}

.max-w-430 {
    max-width: 430px
}

.max-w-440 {
    max-width: 440px
}

.max-w-450 {
    max-width: 450px
}

.max-w-460 {
    max-width: 460px
}

.max-w-470 {
    max-width: 470px
}

.max-w-480 {
    max-width: 480px
}

.max-w-490 {
    max-width: 490px
}

.max-w-500 {
    max-width: 500px
}

.max-w-510 {
    max-width: 510px
}

.max-w-520 {
    max-width: 520px
}

.max-w-530 {
    max-width: 530px
}

.max-w-540 {
    max-width: 540px
}

.max-w-550 {
    max-width: 550px
}

.max-w-560 {
    max-width: 560px
}

.max-w-570 {
    max-width: 570px
}

.max-w-580 {
    max-width: 580px
}

.max-w-590 {
    max-width: 590px
}

.max-w-600 {
    max-width: 600px
}

.max-w-610 {
    max-width: 610px
}

.max-w-620 {
    max-width: 620px
}

.max-w-630 {
    max-width: 630px
}

.max-w-640 {
    max-width: 640px
}

.max-w-650 {
    max-width: 650px
}

.max-w-660 {
    max-width: 660px
}

.max-w-670 {
    max-width: 670px
}

.max-w-680 {
    max-width: 680px
}

.max-w-690 {
    max-width: 690px
}

.max-w-700 {
    max-width: 700px
}

.max-w-710 {
    max-width: 710px
}

.max-w-720 {
    max-width: 720px
}

.max-w-730 {
    max-width: 730px
}

.max-w-740 {
    max-width: 740px
}

.max-w-750 {
    max-width: 750px
}

.max-w-760 {
    max-width: 760px
}

.max-w-770 {
    max-width: 770px
}

.max-w-780 {
    max-width: 780px
}

.max-w-790 {
    max-width: 790px
}

.max-w-800 {
    max-width: 800px
}

.max-w-810 {
    max-width: 810px
}

.max-w-820 {
    max-width: 820px
}

.max-w-830 {
    max-width: 830px
}

.max-w-840 {
    max-width: 840px
}

.max-w-850 {
    max-width: 850px
}

.max-w-860 {
    max-width: 860px
}

.max-w-870 {
    max-width: 870px
}

.max-w-880 {
    max-width: 880px
}

.max-w-890 {
    max-width: 890px
}

.max-w-900 {
    max-width: 900px
}

.max-w-910 {
    max-width: 910px
}

.max-w-920 {
    max-width: 920px
}

.max-w-930 {
    max-width: 930px
}

.max-w-940 {
    max-width: 940px
}

.max-w-950 {
    max-width: 950px
}

.max-w-960 {
    max-width: 960px
}

.max-w-970 {
    max-width: 970px
}

.max-w-980 {
    max-width: 980px
}

.max-w-990 {
    max-width: 990px
}

.max-w-1000 {
    max-width: 1000px
}

.max-w-1010 {
    max-width: 1010px
}

.max-w-1020 {
    max-width: 1020px
}

.max-w-1030 {
    max-width: 1030px
}

.max-w-1040 {
    max-width: 1040px
}

.max-w-1050 {
    max-width: 1050px
}

.max-w-1060 {
    max-width: 1060px
}

.max-w-1070 {
    max-width: 1070px
}

.max-w-1080 {
    max-width: 1080px
}

.max-w-1090 {
    max-width: 1090px
}

.max-w-1000 {
    max-width: 1100px
}

.max-w-1010 {
    max-width: 1110px
}

.max-w-1020 {
    max-width: 1120px
}

.max-w-1030 {
    max-width: 1130px
}

.max-w-1040 {
    max-width: 1140px
}

.max-w-1050 {
    max-width: 1150px
}

.max-w-1060 {
    max-width: 1160px
}

.max-w-1070 {
    max-width: 1170px
}

.max-w-1080 {
    max-width: 1180px
}

.max-w-1090 {
    max-width: 1190px
}

.max-w-1200 {
    max-width: 1200px
}

.max-w-1210 {
    max-width: 1210px
}

.max-w-1220 {
    max-width: 1220px
}

.max-w-1230 {
    max-width: 1230px
}

.max-w-1240 {
    max-width: 1240px
}

.max-w-1250 {
    max-width: 1250px
}

.max-w-1260 {
    max-width: 1260px
}

.max-w-1270 {
    max-width: 1270px
}

.max-w-1280 {
    max-width: 1280px
}

.max-w-1290 {
    max-width: 1290px
}

.max-w-1300 {
    max-width: 1300px
}

.max-w-1310 {
    max-width: 1310px
}

.max-w-1320 {
    max-width: 1320px
}

.max-w-1330 {
    max-width: 1330px
}

.max-w-1340 {
    max-width: 1340px
}

.max-w-1350 {
    max-width: 1350px
}

.max-w-1360 {
    max-width: 1360px
}

.max-w-1370 {
    max-width: 1370px
}

.max-w-1380 {
    max-width: 1380px
}

.max-w-1390 {
    max-width: 1390px
}

.max-w-1400 {
    max-width: 1400px
}

.max-w-1410 {
    max-width: 1410px
}

.max-w-1420 {
    max-width: 1420px
}

.max-w-1430 {
    max-width: 1430px
}

.max-w-1440 {
    max-width: 1440px
}

.max-w-1450 {
    max-width: 1450px
}

.max-w-1460 {
    max-width: 1460px
}

.max-w-1470 {
    max-width: 1470px
}

.max-w-1480 {
    max-width: 1480px
}

.max-w-1490 {
    max-width: 1490px
}

.max-w-1500 {
    max-width: 1500px
}

.max-w-1510 {
    max-width: 1510px
}

.max-w-1520 {
    max-width: 1520px
}

.max-w-1530 {
    max-width: 1530px
}

.max-w-1540 {
    max-width: 1540px
}

.max-w-1550 {
    max-width: 1550px
}

.max-w-1560 {
    max-width: 1560px
}

.max-w-1570 {
    max-width: 1570px
}

.max-w-1580 {
    max-width: 1580px
}

.max-w-1590 {
    max-width: 1590px
}

.max-w-1600 {
    max-width: 1600px
}

.max-w-1610 {
    max-width: 1610px
}

.max-w-1620 {
    max-width: 1620px
}

.max-w-1630 {
    max-width: 1630px
}

.max-w-1640 {
    max-width: 1640px
}

.max-w-1650 {
    max-width: 1650px
}

.max-w-1660 {
    max-width: 1660px
}

.max-w-1670 {
    max-width: 1670px
}

.max-w-1680 {
    max-width: 1680px
}

.max-w-1690 {
    max-width: 1690px
}

.max-w-1700 {
    max-width: 1700px
}

.max-w-1710 {
    max-width: 1710px
}

.max-w-1720 {
    max-width: 1720px
}

.max-w-1730 {
    max-width: 1730px
}

.max-w-1740 {
    max-width: 1740px
}

.max-w-1750 {
    max-width: 1750px
}

.max-w-1760 {
    max-width: 1760px
}

.max-w-1770 {
    max-width: 1770px
}

.max-w-1780 {
    max-width: 1780px
}

.max-w-1790 {
    max-width: 1790px
}

.max-w-1800 {
    max-width: 1800px
}

.max-w-1810 {
    max-width: 1810px
}

.max-w-1820 {
    max-width: 1820px
}

.max-w-1830 {
    max-width: 1830px
}

.max-w-1840 {
    max-width: 1840px
}

.max-w-1850 {
    max-width: 1850px
}

.max-w-1860 {
    max-width: 1860px
}

.max-w-1870 {
    max-width: 1870px
}

.max-w-1880 {
    max-width: 1880px
}

.max-w-1890 {
    max-width: 1890px
}

.max-w-1900 {
    max-width: 1900px
}

.max-w-1910 {
    max-width: 1910px
}

.max-w-1920 {
    max-width: 1920px
}

.max-w-1930 {
    max-width: 1930px
}

.max-w-1940 {
    max-width: 1940px
}

.max-w-1950 {
    max-width: 1950px
}

.max-w-1960 {
    max-width: 1960px
}

.max-w-1970 {
    max-width: 1970px
}

.max-w-1980 {
    max-width: 1980px
}

.max-w-1990 {
    max-width: 1990px
}

.max-w-2000 {
    max-width: 2000px
}

.max-w-2010 {
    max-width: 2010px
}

.max-w-2020 {
    max-width: 2020px
}

.max-w-2030 {
    max-width: 2030px
}

.max-w-2040 {
    max-width: 2040px
}

.max-w-2050 {
    max-width: 2050px
}

.max-w-2060 {
    max-width: 2060px
}

.max-w-2070 {
    max-width: 2070px
}

.max-w-2080 {
    max-width: 2080px
}

.max-w-2090 {
    max-width: 2090px
}


.min-h-1 {
    min-height: 1px;
}

.min-h-2 {
    min-height: 2px;
}

.min-h-10 {
    min-height: 10px
}

.min-h-15 {
    min-height: 15px
}

.min-h-20 {
    min-height: 20px
}

.min-h-25 {
    min-height: 25px
}

.min-h-30 {
    min-height: 30px
}

.min-h-35 {
    min-height: 35px
}

.min-h-40 {
    min-height: 40px
}

.min-h-45 {
    min-height: 45px
}

.min-h-50 {
    min-height: 50px
}

.min-h-55 {
    min-height: 55px
}

.min-h-60 {
    min-height: 60px
}

.min-h-65 {
    min-height: 65px
}

.min-h-70 {
    min-height: 70px
}

.min-h-75 {
    min-height: 75px
}

.min-h-80 {
    min-height: 80px
}

.min-h-85 {
    min-height: 85px
}

.min-h-20 {
    min-height: 20px;
}

.min-h-100 {
    min-height: 100px;
}

.min-h-110 {
    min-height: 110px;
}

.min-h-120 {
    min-height: 120px;
}

.min-h-130 {
    min-height: 130px;
}

.min-h-140 {
    min-height: 140px;
}

.min-h-150 {
    min-height: 150px;
}
.min-h-160 {
    min-height: 160px;
}

.min-h-190 {
    min-height: 190px;
}

.min-h-200{
    min-height: 200px;
}

.min-h-210 {
    min-height: 210px;
}

.min-h-220 {
    min-height: 220px;
}

.min-h-230 {
    min-height: 230px;
}

.min-h-250 {
    min-height: 250px;
}

.min-h-270 {
    min-height: 270px;
}

.min-h-500 {
    min-height: 500px;
}

.vert-padd-5 {
    padding: 5px 0px;
}

.vert-padd-10 {
    padding: 10px 0px;
}

.vert-padd-15 {
    padding: 15px 0px;
}

.vert-padd-20 {
    padding: 20px 0px;
}

.vert-padd-30 {
    padding: 30px 0px;
}

.vert-padd-45 {
    padding: 45px 0px;
}

.vert-padd-60 {
    padding: 60px 0px;
}

.full-w {width: 100%;}

.center-txt {text-align: center;}

.marg-top-30{margin-top: 30px;}

.dw-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    .dw-xxs-12 {
        grid-column: span 12;
    }

    .dw-xxs-11 {
        grid-column: span 11;
    }

    .dw-xxs-10 {
        grid-column: span 10;
    }

    .dw-xxs-9 {
        grid-column: span 9;
    }

    .dw-xxs-8 {
        grid-column: span 8;
    }

    .dw-xxs-7 {
        grid-column: span 7;
    }

    .dw-xxs-6 {
        grid-column: span 6;
    }

    .dw-xxs-5 {
        grid-column: span 5;
    }

    .dw-xxs-4 {
        grid-column: span 4;
    }

    .dw-xxs-3 {
        grid-column: span 3;
    }

    .dw-xxs-2 {
        grid-column: span 2;
    }

    .dw-xxs-1 {
        grid-column: span 1;
    }

    @media (min-width:500px) {

        .dw-xs-12 {
            grid-column: span 12;
        }

        .dw-xs-11 {
            grid-column: span 11;
        }

        .dw-xs-10 {
            grid-column: span 10;
        }

        .dw-xs-9 {
            grid-column: span 9;
        }

        .dw-xs-8 {
            grid-column: span 8;
        }

        .dw-xs-7 {
            grid-column: span 7;
        }

        .dw-xs-6 {
            grid-column: span 6;
        }

        .dw-xs-5 {
            grid-column: span 5;
        }

        .dw-xs-4 {
            grid-column: span 4;
        }

        .dw-xs-3 {
            grid-column: span 3;
        }

        .dw-xs-2 {
            grid-column: span 2;
        }

        .dw-xs-1 {
            grid-column: span 1;
        }
        /*FLEXBOX*/
        .xs-flx-col {
            flex-direction: column;
        }

        .xs-flx-row {
            flex-direction: row;
        }

        .xs-flx-col-rev {
            flex-direction: column-reverse;
        }

        .xs-flx-row-rev {
            flex-direction: row-reverse;
        }

        .xs-al-start {
            align-items: center;
        }

        .xs-al-center {
            align-items: center;
        }

        .xs-al-end {
            align-items: flex-end;
        }

        .xs-ju-start {
            justify-content: flex-start;
        }

        .xs-ju-center {
            justify-content: center;
        }

        .xs-ju-end {
            justify-content: end;
        }

        .xs-ju-evenly {
            justify-content: space-evenly;
        }

        .xs-ju-around {
            justify-content: space-around;
        }

        .xs-ju-between {
            justify-content: space-between;
        }
        /*FLEX ITEM*/
        .xs-self-start {
            align-self: flex-start;
        }

        .xs-self-end {
            align-self: flex-end;
        }

        .xs-self-center {
            align-self: center;
        }
    }
    /*TABLET*/
    @media (min-width:768px) {
        .dw-sm-12 {
            grid-column: span 12;
        }

        .dw-sm-11 {
            grid-column: span 11;
        }

        .dw-sm-10 {
            grid-column: span 10;
        }

        .dw-sm-9 {
            grid-column: span 9;
        }

        .dw-sm-8 {
            grid-column: span 8;
        }

        .dw-sm-7 {
            grid-column: span 7;
        }

        .dw-sm-6 {
            grid-column: span 6;
        }

        .dw-sm-5 {
            grid-column: span 5;
        }

        .dw-sm-4 {
            grid-column: span 4;
        }

        .dw-sm-3 {
            grid-column: span 3;
        }

        .dw-sm-2 {
            grid-column: span 2;
        }

        .dw-sm-1 {
            grid-column: span 1;
        }

        .visible-xs {
            display: none !important;
        }
    }


    @media (min-width:992px) {
        .dw-md-12 {
            grid-column: span 12;
        }

        .dw-md-11 {
            grid-column: span 11;
        }

        .dw-md-10 {
            grid-column: span 10;
        }

        .dw-md-9 {
            grid-column: span 9;
        }

        .dw-md-8 {
            grid-column: span 8;
        }

        .dw-md-7 {
            grid-column: span 7;
        }

        .dw-md-6 {
            grid-column: span 6;
        }

        .dw-md-5 {
            grid-column: span 5;
        }

        .dw-md-4 {
            grid-column: span 4;
        }

        .dw-md-3 {
            grid-column: span 3;
        }

        .dw-md-2 {
            grid-column: span 2;
        }

        .dw-md-1 {
            grid-column: span 1;
        }
    }


    @media (min-width: 1230px) {

        .dw-lg-12 {
            grid-column: span 12;
        }

        .dw-lg-11 {
            grid-column: span 11;
        }

        .dw-lg-10 {
            grid-column: span 10;
        }

        .dw-lg-9 {
            grid-column: span 9;
        }

        .dw-lg-8 {
            grid-column: span 8;
        }

        .dw-lg-7 {
            grid-column: span 7;
        }

        .dw-lg-6 {
            grid-column: span 6;
        }

        .dw-lg-5 {
            grid-column: span 5;
        }

        .dw-lg-4 {
            grid-column: span 4;
        }

        .dw-lg-3 {
            grid-column: span 3;
        }

        .dw-lg-2 {
            grid-column: span 2;
        }

        .dw-lg-1 {
            grid-column: span 1;
        }
    }

    @media (min-width: 1671px) {
        .dw-xl-12 {
            grid-column: span 12;
        }

        .dw-xl-11 {
            grid-column: span 11;
        }

        .dw-xl-10 {
            grid-column: span 10;
        }

        .dw-xl-9 {
            grid-column: span 9;
        }

        .dw-xl-8 {
            grid-column: span 8;
        }

        .dw-xl-7 {
            grid-column: span 7;
        }

        .dw-xl-6 {
            grid-column: span 6;
        }

        .dw-xl-5 {
            grid-column: span 5;
        }

        .dw-xl-4 {
            grid-column: span 4;
        }

        .dw-xl-3 {
            grid-column: span 3;
        }

        .dw-xl-2 {
            grid-column: span 2;
        }

        .dw-xl-1 {
            grid-column: span 1;
        }
    }

    @media (min-width:1671px) {

        .dw-xxl-12 {
            grid-column: span 12;
        }

        .dw-xxl-11 {
            grid-column: span 11;
        }

        .dw-xxl-10 {
            grid-column: span 10;
        }

        .dw-xxl-9 {
            grid-column: span 9;
        }

        .dw-xxl-8 {
            grid-column: span 8;
        }

        .dw-xxl-7 {
            grid-column: span 7;
        }

        .dw-xxl-6 {
            grid-column: span 6;
        }

        .dw-xxl-5 {
            grid-column: span 5;
        }

        .dw-xxl-4 {
            grid-column: span 4;
        }

        .dw-xxl-3 {
            grid-column: span 3;
        }

        .dw-xxl-2 {
            grid-column: span 2;
        }

        .dw-xxl-1 {
            grid-column: span 1;
        }
    }
}