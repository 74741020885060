﻿.listing {

    #products {
        opacity: 1;

        &.loading {
            opacity: 0;
        }
    }

    &.home {
        .category {
            @include media('>desktop') {
                width: 20% !important;
            }
        }
    }

    .category {
        .listing-link {
            max-width: 220px;
            margin: 0 auto 30px auto;
            display: block;
            position: relative;

            @include media('>phone') {
                min-height: 465px;
            }

            .prod-actions {
                .btn {
                    width: calc(100% - 40px);
                    float: left;
                }
            }

            .add-fav {
                width: 40px;
                height: 40px;
                position: absolute;
                margin-top: 15px;
                bottom: 0px;
                right: 0px;

                .heart {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 1.8em;
                }

                .plus {
                    color: #333;
                    position: absolute;
                    bottom: 5px;
                    right: 5px;
                    background-color: #fff;
                    border-radius: 100%;
                    font-size: 0.90em;
                }
            }


            .img--container {
                position: relative;



                .sticker {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                }

                .avaliable--options {
                    position: absolute;
                    bottom: 0px;
                    left: 0px;

                    .tag {
                        color: #fff;
                        font-weight: 700;
                        margin-top: 6px;
                        line-height: 20px;
                        padding: 0px 10px;
                        font-size: 0.75em;
                        display: inline-block;
                    }
                }
            }

            .title {
                font-weight: 700;
                max-width: 250px;
                min-height: 60px;
                margin-top: 30px;
            }

            .price {
                margin-top: 15px;

                .from {
                }

                .val {
                    font-weight: 700;
                }
            }

            .btn {
                max-width: 160px;
                line-height: 20px;
                font-size: 0.9em;
                padding: 10px;
                font-weight: 700;
                color: #fff;
                text-align: center;
                border-radius: 3px;
                margin-top: 15px;
                display: block;
            }
        }
    }


}

div.links.hide--wish {
    display: none;
}

.product-listingpage {
    margin-top: 25px;

    .paging {
        margin: 40px auto 30px 60px;
        text-align: center;
        display: inline-block;
        clear: both;


        .pagination {
            display: block;
            float: left;
            width: 43px;
            height: 43px;
            line-height: 43px;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            border-right: 1px solid #ccc;
            color: #36373A;
            font-weight: 700;

            &:hover {
                background-color: #ccc;
            }

            &:first-child {
                border-left: 1px solid #ccc;
            }

            &.current {
                background-color: #36373A;
                color: #fff;
            }
        }
    }



    .listing-container {



        @include media('>tablet') {
            padding-left: 10px;
            /*            @include media('>desktop') {
                width: calc(100% - 288px);
                float: left;
            }

            @include media('<=desktop', '>tablet') {
                width: calc(100% - 250px);
                float: left;
            }*/
        }

        .listing-head {
            margin-bottom: 30px;

            h1 {
                margin: 0px 0px 15px 0px;
                text-align: left;
            }
        }

        .filter--btn {
            line-height: 30px;
            margin-bottom: 30px;
            border-radius: 3px;
            text-align: center;
            color: #fff;
        }
    }
}

.filter {
    background-color: #F7F7F7;
    border: 1px solid #f1f1f1;
    padding: 15px;

    h3 {
        font-size: 1.3em;
        margin-top: 0px;

        span {
            line-height: 30px;
        }

        i {
            margin-right: 10px;
            font-size: 1.1em;
            line-height: 30px;
        }
    }

    .prod-colour-picker {
        .section {
            margin: 0px -5px;

            .sml-pad {
                padding: 5px;
                width: calc(100% / 6);
            }
        }
    }

    .coloursquare {
        width: 28px;
        height: 28px;
        position: relative;
        display: block;
        border-radius: 100%;
        overflow: hidden;
        cursor: pointer;
        margin: 0 auto;
        border: 1px solid #000;




        input {
            display: none;
        }
    }

    .row {
        label {
            display: block;
            position: relative;
            padding: 10px 10px 10px 40px;
            cursor: pointer;

            input {
                display: none;
            }

            .dummycheck {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0px;
            }
        }
    }

    @include media('>tablet') {
        .filter-overlay {
            display: none !important;
        }
    }

    @include media('<=tablet') {
        position: fixed;
        top: 0px;
        left: 0px;
        bottom: 0px;
        width: 280px;
        z-index: 9999;
        overflow: auto;
        height: 100vh;
        transform: translate(-0%);
        transition-duration: 0.2s;

        &.mob-shut {
            transform: translateX(-100%);
        }
    }
}

@include media("<=tablet") {
    .filter-overlay {
        opacity: 0.5;
        display: block;
        position: fixed;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        background-color: #000;
        z-index: 9998;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        transition: opacity .2s ease-out;

        &.hide {
            opacity: 0;
            height: 0;
        }
    }
}

.filter-accordion {
    .top {
        padding: 5px 50px 5px 0px;
        position: relative;
        border-bottom: 1px solid #f1f1f1;
        margin-bottom: 10px;
        margin-top: 10px;

        i {
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
        }



        &.closed {
            i {
                transform: translateY(-50%) rotate(180deg);
            }
        }

        strong {
            font-size: 1.15em;
        }
    }

    .body {
        overflow: hidden;

        &.open {
            max-height: 999999px;
        }

        &.closed {
            max-height: 0px;
        }
    }

    .selected-filters {
        border: 1px solid #f1f1f1;
        padding: 15px;
        border-radius: 3px;
        background-color: #fff;

        .selected-filter {
            margin-bottom: 10px;
            cursor: pointer;

            i {
                margin-right: 5px;
            }

            span {
                font-size: 0.9em;
                text-decoration: underline;
            }

            &:hover {
                span {
                    text-decoration: none;
                }
            }
        }

        .clear-filters {
            padding: 10px;
            background-color: #f7f7f7;
            color: #000;
            border-radius: 3px;
            text-align: center;
            display: block;
            font-weight: 700;
            cursor: pointer;

            &:hover {
                background-color: #333;
                color: #fff;
            }
        }
    }
}

.dummycheck {
    width: 30px;
    height: 30px;
    background-color: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 3px;
    display: block;
    cursor: pointer;

    i {
        width: 30px;
        line-height: 28px;
        text-align: center;
        visibility: hidden;
    }
}

label:has(:checked) .dummycheck i {
    visibility: visible !important;
}

@include media('>tablet') {
    .filter-boot {
        width: 285px;
        position: sticky;
        left: 0px;
        top: 15px;

        .filter-accordion {
            max-height: calc(100vh - 120px);
            overflow: auto;
            padding: 0px 15px;
            margin: 0px -15px;
        }
    }

    .listing-boot {
        width: calc(100% - 285px);
    }
}


.cat-link {
    cursor: pointer;
    display: block;
    box-shadow: 0px 11px 11px #00000026;
    border-radius: 3px;
    margin: 15px auto;

    .img-container {
        height: 240px;
        background-color: #fff;
        position: relative;
        border-radius: 3px 3px 0px 0px;

        img {
            position: absolute;
            max-width: 100%;
            height: auto;
            left: 50%;
            top: 50%;
            max-height: 100%;
            transform: translateX(-50%) translateY(-50%);
        }

        img.pimg {
            width: auto;
        }
    }

    .bottom {
        line-height: 30px;
        padding: 10px;
        color: #fff;
        font-weight: 700;
        text-align: center;
    }
}

.dep-sel {
    margin-bottom: 30px;

    .select-department-btn {
        line-height: 40px;
        text-align: center;
        color: #fff;
        font-weight: 700;
        border-radius: 3px;
        max-width: 300px;
        margin: 0 auto;
    }
}

.dep-pop {
    position: fixed;
    left: 0px;
    top: 0px;
    display: grid;
    grid-row-start: 0;
    grid-column-start: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999999999;

    .inner {
        display: grid;
    }

    .inner > * {
        grid-row-start: 1;
        grid-column-start: 1;
    }

    .overlay {
        opacity: 0.5;
        background-color: #000;
        width: 100%;
        height: 100%;
    }

    .content {
        background-color: #fff;
        border-radius: 5px;
        position: relative;
        z-index: 1;
        padding: 30px;
        width: calc(100% - 60px);
        max-width: 1000px;

        .close {
            cursor: pointer;
            position: absolute;
            right: 0px;
            top: 0px;
            transform: translate(50%, -50%);
            border-radius: 100%;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
        }
    }

    .flx-bx {
        width: 100%;
        height: 100vh;
    }

    .dep-list {
        max-height: calc(100vh - 200px);
        min-height: 350px;
        overflow: auto;
        padding: 0px 15px;
    }
}


@keyframes bouncing-loader {
    100% {
        opacity: 0.1;
        transform: translate3d(0px, -1rem, 0px);
    }
}

.loader {
    text-align: center;
}

.loading-text {
    h2 {
        margin-bottom: 90px;
    }
}

.bouncing-loader {
    display: flex;
    justify-content: center;
    position: absolute;
    top: calc(50%);
    left: 50%;
    transition: none;
    transform: translate(-50%, -50%);
    text-align: center;
}

.bouncing-loader > div {
    width: 1rem;
    height: 1rem;
    margin: 3rem 0.2rem;
    border-radius: 50%;
    transition: none;
    animation: 0.6s ease 0s infinite alternate none running bouncing-loader;
}

.bouncing-loader > div:nth-child(2) {
    animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
    animation-delay: 0.4s;
}

.bouncing-loader > div:nth-child(4) {
    animation-delay: 0.6s;
}

.box {
    .heading {
        min-height: 30px;
    }
}

.cats-toggler {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 1.5rem;
    border-radius: 3px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    i {
        font-size: 1.2rem;
    }

    @include media('>=tablet') {
        display: none;
    }
}

.cats-toggler.toggled {
    i {
        transform: rotate(180deg);
    }
}

.condensed-cats {

    .cat-title {
        padding: 0.75rem 1rem 0;
        max-width: fit-content;
        font-weight: bold;

        @include media('<tablet') {
            display: none;
        }
    }

    .cat-flex {
        display: flex;
        grid-gap: 0.75rem;
        flex-wrap: wrap;
    }

    .border {
        padding: 1rem;
        border-radius: 0 3px 3px 3px;
    }

    .cat-link {
        margin: 0;

        @include media('<tablet') {
            flex-grow:1;
        }
    }

    .cat-link.first {
        order: -1;
    }

    @include media('<tablet') {
        display: none;
    }
}

.pword-bar {
    font-weight: 700;
    color: #fff;
    padding: 15px 25px;
    display: flex;
    align-items: center;
    gap: 15px;
    border-radius: 4px;

    span u { cursor: pointer; }

}

.password-required { font-size: 0.95rem; }
