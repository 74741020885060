﻿.popup {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100%;
    z-index: 999;


    .overlay {
        background-color: #000;
        opacity: 0.5;
        position: fixed;
        top: 0px;
        left: 0px;
        height: 100vh;
        width: 100%;
        z-index: 10;
    }

    .popup-content {
        position: fixed;
        z-index: 20;
        background-color: #fff;
        border-radius: 3px;
        width: calc(100% - 30px);
        max-width: 800px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-height: 50px;


        .container {
            max-width: 100%;
        }

        .inner-rel {
            position: relative;
            width: 100%;
            height: 100%;

            .guide-table {
                overflow: auto;
                height: 100%;
                max-height: calc(100vh - 60px);
            }

            .dw-grid:nth-child(even) {
                background-color: #f1f1f1;
                height: 100%;
            }

            @include media("<tablet") {
                .dw-grid:nth-child(even) {
                    background-color: #fff;
                    height: 100%;
                }

                .dw-grid {
                    font-size: 0.5rem;

                    > .key {
                        background-color: #fff;
                        padding: 5px;
                        font-weight: bold;
                    }

                    .value-row {
                        border-bottom: 1px solid #e8e8e8;
                        border-top: 1px solid #e8e8e8;

                        .dw-xxs-1:nth-child(even) {
                            background-color: #e8e8e8;
                        }
                    }

                    .dw-xxs-12:not(.key) {
                        padding: 0;
                    }
                }
            }





            .close {
                position: absolute;
                top: -15px;
                right: -15px;
                width: 30px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                background-color: #000;
                color: #fff;
                border-radius: 100%;
                cursor: pointer;
            }

            .scroller {
                max-height: calc(100vh - 30px);
                overflow-y: auto;
                padding: 15px;
            }
        }
    }
}

.help-pop {
    z-index: 100;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;



    .inner {
        width: 100%;
        height: 100%;
        position: relative;

        .overlay {
            width: 100%;
            height: 100%;
            background-color: #000;
            opacity: 0.5;
        }

        .pop-container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;
            border-radius: 5px;
            box-shadow: 0px 0px 20px #00000026;
            max-width: 530px;
            width: calc(100% - 60px);

            .inner {
                padding: 30px;
            }

            .title {
                color: #fff;
                font-weight: bold;
                font-size: 1.3em;
                border-bottom: 1px solid #fff;
                margin-bottom: 10px;
                padding-bottom: 10px;
            }

            .txt {
                color: #fff;
                font-weight: 200;
            }

            .close {
                position: absolute;
                top: 0px;
                right: 0px;
                background-color: #000;
                width: 25px;
                height: 25px;
                text-align: center;
                line-height: 25px !important;
                border-radius: 100%;
                color: #fff;
                transform: translate(50%, -50%);
                z-index: 101;
                cursor: pointer;

                &:hover {
                    background-color: #333;
                }
            }
        }
    }
    /*&.pop-mob-only {
        @include media (">tablet") {
            position: static;
            width: 100%;
            height: auto;

            .overlay {
                display: none;
            }
            .pop-container{
                position: static;
                transform: none;
                max-width: 100%;
                width: 100%;
                .close {
                    display: none;
                }
            }
        }
    }*/
}

#passwordform {

    form {
        display: flex;
        align-items: center;

        @include media("<=phone") {
            flex-direction: column;
            gap: 15px;
        }

    }

    .inner-rel {
        & > div {
            padding: 25px;

            input {
                height: 46px;
                width: 100%;

                &[type=text] {
                    border: 1px solid #000;
                    border-radius: 4px;
                    padding-left: 15px;
                }

                &[type=submit] {
                    color: #fff;
                    border: unset;
                    border-radius: 4px;
                    font-size: 1.125rem;
                    font-weight: 600;
                }
            }

            label {
                font-size: 1.25rem;
                font-weight: 600;
            }
        }
    }
}
